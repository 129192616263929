import React, { useState, useEffect } from "react";

import { getUser, getIdToken } from "../../../utils/storage";

// import type { ComparisonBrand } from "../types/types";
import DataMeasureBar from "./DataMeasureBar";
import Mentions from "./Mentions";
import PositiveSentiment from "./PositiveSentiment";
import NegativeSentiment from "./NegativeSentiment";

import PeopleReached from "./PeopleReached";
import Axios from "axios";

const Summary = (props) => {
  const {
    client,
    brand1,
    brand2,
    brand3,
    brand4,
    period,
    sentiment,
    country,
    language,
    sources,
    setToast,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [highestMention, setHighestMention] = useState(0);
  const [mentionsData, setMentionsData] = useState([]);

  useEffect(() => {
    const postRequests = [];
    [brand1, brand2, brand3, brand4].forEach((brand) => {
      if (brand) {
        postRequests.push(
          Axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_mentions_summary`,
            {
              clientRef: brand.compareDbRef,
              uid,
              idToken,
              period,
              sentiment,
              country,
              language,
              sources,
            },
            { headers: { "content-type": "application/json" } }
          )
        );
      }
    });

    Axios.all(postRequests)
      .then(
        Axios.spread((result1, result2, result3, result4) => {
          const mentionsSummary = [];
          [result1, result2, result3, result4].forEach((result) => {
            if (result) {
              mentionsSummary.push(result.data);
            }
          });
          const highestMention = Math.max(
            ...mentionsSummary.map((mention) => mention.total),
            0
          );
          setHighestMention(highestMention);
          setMentionsData(mentionsSummary);
        })
      )
      .catch((error) => {
        console.log("Error fetching all...", error);
        setToast({
          show: true,
          type: "warning",
          message: "Error batch-fetching mentions summary. Please refresh",
        });
      });
  }, [brand1, brand2, brand3, brand4, period, sentiment, country, language]);

  return (
    <div className="row has-cards move-down">
      <div className="column">
        <div className="card-2">
          <div className="row stretch">
            <Mentions
              mentionsData={mentionsData}
              highestMention={highestMention}
              brand1={brand1}
              brand2={brand2}
              brand3={brand3}
              brand4={brand4}
            />

            {/* <PeopleReached /> */}

            <PositiveSentiment
              mentionsData={mentionsData}
              brand1={brand1}
              brand2={brand2}
              brand3={brand3}
              brand4={brand4}
              sentiment={sentiment}
            />

            <NegativeSentiment
              mentionsData={mentionsData}
              brand1={brand1}
              brand2={brand2}
              brand3={brand3}
              brand4={brand4}
              sentiment={sentiment}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Summary;
