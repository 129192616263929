import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { getUser, getIdToken } from "../../utils/storage";
import { AllSurveys } from "./AllSurveys";
import FilteredSurveys from "./FilteredSurveys";
import { _getTotalSurveysQuestionsAndRespondents } from "../../services/api/ask/surveyQuestionsAndRespondents";

const SurveysList = (props) => {
  const {
    client,
    setToast,
    toggleNewSurveyModalOpen,
    allSurveysList,
    setAllSurveysList,
    filter,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [totalQuestionsAndRespondents, setTotalQuestionsAndRespondents] =
    useState([]);

  const [versusCredits] = useState(client.versusCredits);

  const activeFilter = useSelector((state) => state.surveyFilters.activeFilter);
  const params = {
    clientRef: client.clientRef,
    uid: user.uid,
    idToken,
  };
  const newParams = filter.sortField
    ? { ...params, sortField: filter.sortField, sortOrder: filter.sortOrder }
    : params;
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_campaigns`,

        newParams,
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const surveys = result.data.campaigns;
          setAllSurveysList(surveys);
        }
      })
      .catch((error) => {
        console.log("Error obtaining surveys", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving surveys. Please refresh",
        });
      });
  }, [client.clientRef, idToken, setToast, user.uid, filter]);

  useEffect(() => {
    if (allSurveysList.length > 0) {
      (async () => {
        const { data, error, success } =
          await _getTotalSurveysQuestionsAndRespondents({
            uid: user.uid,
            idToken,
            campaignsRefs: allSurveysList.map(
              (campaign) => campaign.campaignRef
            ),
          });

        if (!success) {
          console.log(
            "Error obtaining surveys questions and respondents",
            error
          );
          setToast({
            show: true,
            type: "warning",
            message:
              "We had problems retrieving total surveys questions and respondents. Please refresh",
          });
          return;
        }
        setTotalQuestionsAndRespondents(data);
      })();
    }
  }, [allSurveysList, idToken, user.uid, setToast]);

  if (allSurveysList <= 0) {
    return (
      <div className="row has-cards wrap">
        <div
          className="card-2 active"
          onClick={versusCredits > 0 ? toggleNewSurveyModalOpen : null}
        >
          <div className="full-height vertical center padded padded-large">
            <h3>No surveys created</h3>
            <h2 className="main push-up">Create a new survey!</h2>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-plus icon large"
              src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
              alt=""
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row has-cards wrap">
        {activeFilter.filter === "All" ? (
          <AllSurveys
            client={client}
            setToast={setToast}
            allSurveysList={allSurveysList}
            totalQuestionsAndRespondents={totalQuestionsAndRespondents}
          />
        ) : activeFilter.filter === "Draft" ? (
          <FilteredSurveys
            client={client}
            setToast={setToast}
            SurveysList={allSurveysList}
            totalQuestionsAndRespondents={totalQuestionsAndRespondents}
            status="draft"
          />
        ) : activeFilter.filter === "Live" ? (
          <FilteredSurveys
            client={client}
            setToast={setToast}
            SurveysList={allSurveysList}
            totalQuestionsAndRespondents={totalQuestionsAndRespondents}
            status="live"
          />
        ) : (
          <FilteredSurveys
            client={client}
            setToast={setToast}
            SurveysList={allSurveysList}
            totalQuestionsAndRespondents={totalQuestionsAndRespondents}
            status="finished"
          />
        )}
      </div>
    );
  }
};

export default SurveysList;
