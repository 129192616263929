import React from "react";

const TotalRespondents = (props) => {
  const { campaign, totalRespondents } = props;

  const country = campaign.country || { country: {} };

  return (
    <div>
      <div className="data huge">{totalRespondents}</div>
      <h3 className="move-up move-down">Respondents</h3>
      <div>
        <div className="tag move-left">{campaign.gender.join(", ")}</div>
        <div className="tag move-left">
          {campaign.start_age}-{campaign.end_age} yrs
        </div>
        <div className="tag">
          {campaign.stateOrRegion.join(", ")}, {country.country_name}
        </div>
      </div>
    </div>
  );
};

export default TotalRespondents;
