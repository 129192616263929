import React from "react";

import Logo from "../../images/versus-full.svg";

import Login from "./Login";
// import Signup from "./Signup.old";
import Signup from "./Signup";
import ForgotPassword from "./ForgotPassword";

const Access = (props) => {
  const { action, toast, setToast } = props;

  return (
    <div className="sign-up">
      <img
        src={Logo}
        width="150px"
        alt="Versus Logo"
        className="bottom-breather big-breath"
      />
      <h1 className="bold">
        {action === "login"
          ? "Log in"
          : action === "signup"
          ? "Create an account"
          : "Password Reset"}
      </h1>
      {action === "signup" && <h2>Enter your details and sign up</h2>}
      {action === "login" ? (
        <Login toast={toast} setToast={setToast} />
      ) : action === "signup" ? (
        <Signup toast={toast} setToast={setToast} />
      ) : (
        <ForgotPassword setToast={setToast} />
      )}
    </div>
  );
};

export default Access;
