import React from "react";

import SocialMedia from "./MentionsSources/SocialMedia";
import News from "./MentionsSources/News";
import Others from "./MentionsSources/Others";

const MentionsSources = (props) => {
  const {
    filterPeriod,
    client,
    setToast,
    filterSentiment,
    filterCountry,
    filterLanguage,
    toggleSource,
    filterKeywordOrHashtag,
    sources,
  } = props;

  return (
    <div className="row has-cards">
      <div className="column">
        <div className="card-2">
          <div className="card-section padded-x">
            <h3>Mention sources</h3>
            <div
              className="row push-down push-up"
              style={{ height: "500px", overflow: "auto" }}
            >
              <SocialMedia
                filterPeriod={filterPeriod}
                client={client}
                setToast={setToast}
                filterSentiment={filterSentiment}
                filterCountry={filterCountry}
                filterLanguage={filterLanguage}
                filterKeywordOrHashtag={filterKeywordOrHashtag}
                toggleSource={toggleSource}
                sources={sources}
              />
              <News
                filterPeriod={filterPeriod}
                client={client}
                setToast={setToast}
                filterSentiment={filterSentiment}
                filterKeywordOrHashtag={filterKeywordOrHashtag}
                toggleSource={toggleSource}
                sources={sources}
              />
              <Others
                filterPeriod={filterPeriod}
                client={client}
                setToast={setToast}
                filterSentiment={filterSentiment}
                toggleSource={toggleSource}
                sources={sources}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentionsSources;
