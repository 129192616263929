import React from "react";

const Detractors = (props) => {
  const { detractors, sentiment } = props;

  return (
    <div
      className="column padded-right no-padding-left"
      style={{
        opacity:
          sentiment === "positive sentiment" ||
          sentiment === "neutral sentiment"
            ? 0.1
            : 1,
      }}
    >
      <h5 className="push-up">Detractors</h5>
      {Array.isArray(detractors) &&
        detractors.map((detractor, i) => (
          <div className="psuedo-card" key={i}>
            <div className="row">
              <div className="avatar move-left">
                <img
                  src={detractor.profile_image_url_https}
                  alt="Twitter DP"
                  className="negative"
                />
                {/* {getInitials(promoter.name)} */}
              </div>
              <div>
                <div className="row center">
                  <p className="medium-text medium-weight">
                    {detractor.name}
                    <br />
                  </p>
                  <div
                    data-tooltip="followers"
                    className="small-text muted with-icon move-right"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-users icon small nudge-left"
                    >
                      <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                      <circle cx="9" cy="7" r="4"></circle>
                      <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                      <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                    </svg>{" "}
                    {detractor.followers_count}
                  </div>
                  <div
                    data-tooltip="Mentions"
                    className="small-text muted with-icon move-right"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-at-sign icon small nudge-left"
                    >
                      <circle cx="12" cy="12" r="4"></circle>
                      <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                    </svg>{" "}
                    {detractor.friends_count}
                  </div>
                </div>
                <div className="small-text muted">{detractor.description}</div>
              </div>
            </div>
          </div>
        ))}

      {/* <div className="psuedo-card">
        <div className="row"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
          fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          className="feather feather-file-text icon source-icon in-baseline-row move-left negative">
          <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
          <polyline points="14 2 14 8 20 8"></polyline>
          <line x1="16" y1="13" x2="8" y2="13"></line>
          <line x1="16" y1="17" x2="8" y2="17"></line>
          <polyline points="10 9 9 9 8 9"></polyline>
        </svg>
          <div>
            <div className="row center">
              <p className="medium-text medium-weight">The punch<br /></p>
              <div data-tooltip="followers" className="small-text muted with-icon move-right"><svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                className="feather feather-users icon small nudge-left">
                <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                <circle cx="9" cy="7" r="4"></circle>
                <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
              </svg> 12k</div>
              <div data-tooltip="Mentions" className="small-text muted with-icon move-right"><svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                className="feather feather-at-sign icon small nudge-left">
                <circle cx="12" cy="12" r="4"></circle>
                <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
              </svg> 8</div>
            </div>
            <div className="small-text muted">The Punch newspaper was founded by two friends, James Aboderin, an
                    accountant, and Sam Amuka, a columnist and editor at the...</div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
export default Detractors;
