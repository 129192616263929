import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../../utils/storage";

const QuestionsTotal = (props) => {
  const { client, setToast ,tooltip, setTooltip } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [totalQuestions, setTotalQuestions] = useState(0);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_questions`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const total = result.data.totalQuestions;
          setTotalQuestions(total);
        }
      })
      .catch((error) => {
        console.log("Error obtaining total questions", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving total surveys. Please refresh",
        });
      });
  }, [client.clientRef, idToken, setToast, user.uid]);

  return (
    <div className="card-section padded horizontal expand">
      <div className="data huge">{totalQuestions}</div>
      <h3 className="move-down">Questions</h3>
      <div
        style={{
          display: tooltip.step === 2 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content top"
      >
        {/* step 2 tool tip */}
        <div className="card-section">
          <div>Questions</div>
          <p className="move-down">
            This is the total number of survey questions that have been sent out
          </p>

          <div className="inline-tooltip-frame">
            <span onClick={() => setTooltip({
              show: false,
              type: "",
              title: "",
              message: "",
              step: 0,
              max_step: 5
            })}>
              Skip tutorial
          </span>
            <button className="inline-tooltip-button"onClick={() => setTooltip({
              show: true,
              type: "",
              title: "",
              message: "",
              step: 3,
              max_step: 5
            })} >
              Next
          </button>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default QuestionsTotal;
