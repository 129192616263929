import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getUser, getIdToken, getClient } from "../../utils/storage";
import DummyButton from "../DummyButton";
import Demographics from "../Shared/createCampaign/Demographics";
import SampleSize from "../Shared/createCampaign/SampleSize";

const NewSurveyModal = (props) => {
  const {
    isNewSurveyModalOpen,
    toggleNewSurveyModalOpen,
    setToast,
    tooltip,
    setTooltip,
  } = props;

  const [newSurveyStep, setNewSurveyStep] = useState("one"); // "one", "two", "three"
  const [showDummyButton, setShowDummyButton] = useState(false);
  const [insuffientFunds, setInsuffientFunds] = useState(false);
  //show tooltip only once
  const showNewSurveyTooltip = sessionStorage.getItem("showNewSurveyTooltip");
  const showTooltip = (status) => {
    sessionStorage.setItem("showNewSurveyTooltip", status);
  };

  const country = "Nigeria";

  const defaultSurveyData = {
    campaignName: "",
    description: "",
    gender: [],
    startAge: "26",
    endAge: "45",
    country: process.env.REACT_APP_VERSUS_ENV === "dev" ? 160 : 2, //"NG",
    stateOrRegion: [],
    numberOfRespondents: "500",
    interest_ids: [],
    lga_ids: [],
    sports_fan: false,
    soccer_fan: "",
    favorite_team_id: "",
    mediaLink: "",
  };

  const user = getUser(),
    idToken = getIdToken(),
    client = getClient();
  const history = useHistory();

  const gotoSurveyStep = (step) => setNewSurveyStep(step);

  const resetNewSurveyData = () => {
    setNewSurveyData(defaultSurveyData);
  };

  const closeNewSurveyModal = () => {
    // Reset new survey step to one
    resetNewSurveyData();
    setNewSurveyStep("one");
    // Toggle new survey modal
    toggleNewSurveyModalOpen();
  };

  const [newSurveyData, setNewSurveyData] = useState(defaultSurveyData);

  const setSportsFan = (e) => {
    setNewSurveyData({
      ...newSurveyData,
      sports_fan: e.currentTarget.value === "true",
    });
  };

  const openModal = (value) => {
    if (value == "Two") {
      gotoSurveyStep("two");
      setTooltip({
        show: true,
        type: "",
        title: "",
        message: "",
        step: 5.3,
        max_step: 5,
      });
    }
    if (value == "Three") {
      gotoSurveyStep("three");
      setTooltip({
        show: true,
        type: "",
        title: "",
        message: "",
        step: 5.6,
        max_step: 5,
      });
    }
  };
  const [isValid, setIsValid] = useState(true);

  const validateLink = (e) => {
    try {
      if (e.target.name === "mediaLink") {
        if (e.target.value.length === 0) {
          setIsValid(true);
          setToast({ show: false, type: "", message: "" });
          return;
        }
        new URL(e.target.value);
        setIsValid(true);
        setToast({ show: false, type: "", message: "" });
      }
    } catch (error) {
      setIsValid(false);
      setToast({
        show: true,
        type: "warning",
        message: `Please enter a valid link`,
      });
    }
  };
  const handleNewSurveyData = (e) => {
    if (e.target.name === "numberOfRespondents") {
      // Ensure number is not greater than client versus credit can buy
      if (e.target.value * 0.5 * 2 > client.versusCredits) {
        setToast({
          show: true,
          type: "warning",
          message: `You should buy more versus credits to target more than ${client.versusCredits} respondents`,
        });
        setInsuffientFunds(true);
      } else {
        setToast({ show: false, type: "", message: "" });
        e.target.value = Math.ceil(e.target.value);
        setInsuffientFunds(false);
      }
      setNewSurveyData({
        ...newSurveyData,
        numberOfRespondents: e.target.value,
      });
    } else if (e.target.name === "gender") {
      const { checked, value } = e.target;
      let genders = newSurveyData.gender.slice();
      if (checked) {
        if (!genders.includes(value)) {
          genders.push(value);
        }
      } else {
        if (genders.includes(value)) {
          genders = genders.filter((g) => g !== value);
        }
      }
      setNewSurveyData({ ...newSurveyData, gender: genders });
    } else if (e.target.name === "soccer_fan") {
      let { checked } = e.target;
      setNewSurveyData({ ...newSurveyData, [e.target.name]: checked });
    } else if (e.target.name === "mediaLink") {
      validateLink(e);
      setNewSurveyData({ ...newSurveyData, [e.target.name]: e.target.value });
    } else {
      setNewSurveyData({ ...newSurveyData, [e.target.name]: e.target.value });
    }
  };

  const handleSliderChange = (values) =>
    setNewSurveyData({
      ...newSurveyData,
      startAge: values[0],
      endAge: values[1],
    });

  const handleCreateNewSurvey = () => {
    //set the tooltip for the next view
    setTooltip({
      show: true,
      type: "",
      title: "",
      message: "",
      step: 3,
      max_step: 7,
    });
    setShowDummyButton(true);
    // setToast({ show: true, type: 'processing', message: 'Birthing your new campaign...' });
    const {
      campaignName,
      description,
      gender,
      startAge,
      endAge,
      country,
      stateOrRegion,
      numberOfRespondents,
      soccer_fan,
      sports_fan,
      interest_ids,
      lga_ids,
      favorite_team_id,
      mediaLink,
    } = newSurveyData;
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_add_campaign`,

      method: "post",
      headers: { "Content-type": "application/json" },
      data: {
        uid: user.uid,
        idToken,
        clientRef: client.clientRef,
        campaignName,
        description,
        gender,
        startAge,
        endAge,
        country,
        stateOrRegion,
        numberOfRespondents,
        soccer_fan,
        sports_fan,
        interest_ids,
        lga_ids,
        favorite_team_id,
        mediaLink,
      },
    })
      .then((result) => {
        const campaignRef = result.data.campaignRef;
        // console.log("campaignRef", campaignRef);
        setToast({ show: false, type: "", message: "" });
        setShowDummyButton(false);
        resetNewSurveyData();
        setNewSurveyStep("one");
        // Close NewSurveyModal
        toggleNewSurveyModalOpen();
        history.push(`/dashboard/ask/new-survey/${campaignRef}`);
      })
      .catch((error) => {
        setShowDummyButton(false);
        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Fail. Something must have gone wrong",
        });
      });
  };

  const { gender, campaignName, description, numberOfRespondents } =
    newSurveyData;

  return (
    <div>
      <div
        className="modal-2"
        style={{
          display: isNewSurveyModalOpen ? "flex" : "none",
          opacity: isNewSurveyModalOpen ? 1 : 0,
        }}
      >
        <div className="modal-container">
          <h2>New Survey</h2>
          <div onClick={closeNewSurveyModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x icon close"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
          <div className="progress-container bottom-breather push-down">
            <div className="row spaced">
              <h5>Survey Details</h5>
              <h5>Demographics</h5>
              <h5>Sample Size</h5>
            </div>
            <div className="progress-tracker">
              <div
                className="progress-bar"
                style={{
                  width:
                    newSurveyStep === "two"
                      ? "52%"
                      : newSurveyStep === "three"
                      ? "100%"
                      : "10%",
                }}
              ></div>
            </div>
          </div>

          {/* Step 1 */}
          <div
            className="step-1 fill w-form"
            style={{
              display: newSurveyStep === "one" ? "block" : "none",
              opacity: newSurveyStep === "one" ? 1 : 0,
              height: newSurveyStep === "one" ? "100%" : "0px",
              width: "600px",
            }}
          >
            <div>
              <label htmlFor="campaign-name" className="field-label large">
                Tell us about your survey
              </label>
              <label htmlFor="campaign-name" className="field-label">
                Name your Survey
              </label>
              <div
                style={{
                  display:
                    tooltip.step === 5.2 && showNewSurveyTooltip === null
                      ? "flex"
                      : "none",
                  width: "300px",
                }}
                className=" inline-tooltip-content-3 top-left"
              >
                {/* step 2 tool tip */}
                <div className="card-section">
                  <div>Survey Details</div>
                  <p className="move-down">
                    Enter your survey name and survey information{" "}
                  </p>
                  <h6>
                    To reduce bias, do not provide your brand name in the Survey
                    name or description{" "}
                  </h6>

                  <div className="inline-tooltip-frame">
                    <span
                      onClick={() => {
                        setTooltip({
                          show: false,
                          type: "",
                          title: "",
                          message: "",
                          step: 0,
                          max_step: 7,
                        });
                        showTooltip(false);
                      }}
                    >
                      Skip tutorial{" "}
                    </span>
                    <button
                      className="inline-tooltip-button"
                      onClick={() => {
                        openModal("Two");
                        showTooltip(false);
                      }}
                    >
                      Next{" "}
                    </button>
                  </div>
                </div>
              </div>

              <input
                type="text"
                id="campaign-name"
                value={newSurveyData.campaignName}
                name="campaignName"
                maxLength="256"
                className="input large w-input"
                onChange={handleNewSurveyData}
              />
              <label htmlFor="campaign-description" className="field-label">
                Describe what it is about
              </label>
              <p className="helper nudge-up">
                Help your respondents understand what the survey about
              </p>
              <textarea
                id="campaign-description"
                name="description"
                maxLength="5000"
                value={newSurveyData.description}
                placeholder="Explain your survey in one sentence..."
                className="input large textarea fill w-input"
                onChange={handleNewSurveyData}
              ></textarea>

              <label htmlFor="campaign-description" className="field-label">
                Add media(optional)
              </label>
              <p className="helper nudge-up">
                A link to a YouTube or Vimeo video to include the content as
                part of your survey
              </p>

              <input
                type="text"
                id="media-link"
                value={newSurveyData.mediaLink}
                name="mediaLink"
                maxLength="1000"
                className="input large w-input"
                onChange={handleNewSurveyData}
                // onBlur={validateLink}
              />
              {campaignName && description && isValid ? (
                <button
                  onClick={() => gotoSurveyStep("two")}
                  className="button primary medium large w-button"
                >
                  Next
                </button>
              ) : (
                <DummyButton
                  text="&nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;"
                  extraClass="large w-button"
                />
              )}
            </div>
          </div>

          {/* Step 2 */}
          {newSurveyStep == "two" && (
            <Demographics
              idToken={idToken}
              client={client}
              user={user}
              gender={gender}
              tooltip={tooltip}
              setTooltip={setTooltip}
              setSportsFan={setSportsFan}
              openModal={openModal}
              activeStep={newSurveyStep}
              surveyData={newSurveyData}
              setNewSurveyData={setNewSurveyData}
              gotoSurveyStep={gotoSurveyStep}
              handleNewSurveyData={handleNewSurveyData}
              handleSliderChange={handleSliderChange}
            />
          )}

          {/* Step 3 */}
          <SampleSize
            tooltip={tooltip}
            country={country}
            setTooltip={setTooltip}
            activeStep={newSurveyStep}
            insuffientFunds={insuffientFunds}
            showDummyButton={showDummyButton}
            numberOfRespondents={numberOfRespondents}
            surveyData={newSurveyData}
            gotoSurveyStep={gotoSurveyStep}
            handleNewSurveyData={handleNewSurveyData}
            handleCreateNewSurvey={handleCreateNewSurvey}
          />
          {/* <div className="divider"></div><a href="/"  className="button bottom-breather white w-button">Cancel</a> */}
        </div>
      </div>
    </div>
  );
};

export default NewSurveyModal;
