import React, { useState } from "react";

import RequestsSummary from "./RequestsSummary/RequestsSummary";
import MakeDeposit from "./MakeDeposit";

const Interact = (props) => {
  const {
    toggleNewRequestModalOpen,
    client,
    user,
    setToast,
    tooltip,
    setTooltip,
    isFilterExpanded,
    setIsFilterExpanded,
    filter,
  } = props;

  const [showMakeDepositDialog, setShowMakeDepositDialog] = useState(false);

  const toggleShowMakeDepositDialog = () => {
    setShowMakeDepositDialog((showMakeDepositDialog) => !showMakeDepositDialog);
  };

  return (
    <div className="content" style={{ paddingLeft: "10%" }}>
      <RequestsSummary
        toggleShowMakeDepositDialog={toggleShowMakeDepositDialog}
        toggleNewRequestModalOpen={toggleNewRequestModalOpen}
        tooltip={tooltip}
        setTooltip={setTooltip}
        isFilterExpanded={isFilterExpanded}
        setIsFilterExpanded={setIsFilterExpanded}
        filter={filter}
      />
      {/* Make deposit modal */}
      {showMakeDepositDialog ? (
        <MakeDeposit
          versusCredits={client.versusCredits}
          toggleShowMakeDepositDialog={toggleShowMakeDepositDialog}
          showMakeDepositDialog={showMakeDepositDialog}
          user={user}
          client={client}
          setToast={setToast}
        />
      ) : (
        ""
      )}
    </div>
  );
};

export default Interact;
