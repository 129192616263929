import React, { useState } from "react";

const FreeFormResponses = ({ optionData }) => {
  const [showFreeFormResponses, setShowFreeFormResponses] = useState(false);
  return (
    <>
      <div
        role="button"
        data-w-id="65b8c97c-0d6f-4c3b-bcb2-2c267dd7a0f2"
        className="button subtle small move-up"
        style={{
          opacity: !showFreeFormResponses ? 1 : 0,
          cursor: "pointer",
          display: !showFreeFormResponses ? "inline-block" : "none",
          animation: "fadeIn 5s",
          WebkitAnimation: "fadeIn 5s",
        }}
        onClick={() => setShowFreeFormResponses(true)}
      >
        Show text responses
      </div>
      <div
        role="button"
        data-w-id="672ebc65-9850-bc9d-c71e-e832be03e38f"
        className="button subtle small move-up hide"
        style={{
          opacity: showFreeFormResponses ? 1 : 0,
          cursor: "pointer",
          display: showFreeFormResponses ? "inline-block" : "none",
          animation: "fadeIn 5s",
          WebkitAnimation: "fadeIn 5s",
        }}
        onClick={() => setShowFreeFormResponses(false)}
      >
        Hide text responses
      </div>
      <div
        data-w-id="9376b1c0-c042-633c-fb80-ffa8584a1fab"
        className="other-responses"
        style={{
          opacity: showFreeFormResponses ? 1 : 0,
          display: showFreeFormResponses ? "block" : "none",
          height: "fit-content",
          maxHeight: "10rem",
          overflowY: "scroll",
        }}
      >
        {optionData.freeFormOptionResponses?.map((response, index) => (
          <a
            key={index}
            className="table-row-other small left-align w-inline-block"
          >
            <div style={{ flex: "none" }} className="table-body-cell">
              {index + 1}.
            </div>
            <div className="table-body-cell tiny">{response}</div>
          </a>
        ))}
      </div>
    </>
  );
};

export default FreeFormResponses;
