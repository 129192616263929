import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import axios from "axios";
import querystring from "query-string";

import { signup, getIdToken } from "../services/auth";
import { saveUser, saveIdToken } from "../utils/storage";

const CreateProfile = (props) => {
  const { setToast } = props;
  const urlParams = querystring.parse(useLocation().search);
  const { email, brandName, token } = urlParams;

  const history = useHistory();

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const toggleShowPassword = () =>
    setIsPasswordVisible((isPasswordVisible) => !isPasswordVisible);

  const [profileData, setProfileData] = useState({
    firstName: "",
    lastName: "",
    password: "",
  });
  const handleChangeProfileData = (e) => {
    setProfileData({ ...profileData, [e.target.name]: e.target.value });
  };

  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] = useState(
    false
  );
  const handleAreRequiredFieldsCompleted = () => {
    const unfilledVals = Object.values(profileData).filter(
      (data) => data === ""
    );
    if (unfilledVals.length <= 0) setAreRequiredFieldsCompleted(true);
  };

  const [isCreatingProfile, setIsCreatingProfile] = useState(false);

  const handleCreateProfile = (e) => {
    e.preventDefault();

    setIsCreatingProfile(true);

    console.log("profile", profileData);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_confirm_team_member`,
        {
          email,
          token,
          firstName: profileData.firstName,
          lastName: profileData.lastName,
        },
        {
          headers: { "Content-type": "application/json" },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          console.log("result", result.data);
          signup(email, profileData.password)
            .then((result) => {
              const user = result.user;
              saveUser(user);
              getIdToken()
                .then((idToken) => {
                  saveIdToken(idToken);

                  setIsCreatingProfile(false);

                  history.push("/login");
                })
                .catch((error) => {
                  console.log("error obtaining idToken", error);
                  setToast({
                    show: true,
                    type: "danger",
                    message:
                      error.message ||
                      "There were problems getting user idToken",
                  });

                  setIsCreatingProfile(false);
                });
            })
            .catch((error) => {
              console.log("Error creating new user account", error);
              setToast({
                show: true,
                type: "danger",
                message:
                  error.message ||
                  "There were problems saving personal details",
              });

              setIsCreatingProfile(false);
            });
        }
      })
      .catch((error) => {
        console.log("Error confirming team member", error);
        setToast({
          show: true,
          type: "warning",
          message:
            error.mesage || "Failed to confirm team member. Please refresh",
        });

        setIsCreatingProfile(false);
      });

    return false;
  };

  useEffect(() => handleAreRequiredFieldsCompleted());

  if (!email || !brandName || !token) {
    return (
      <div>
        <div className="sign-up">
          <img
            src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
            height="32"
            srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
            sizes="102px"
            alt=""
            className="bottom-breather big-breath"
          />

          <h1 className="bold">Welcome to Versus</h1>
          <h2>
            Hey hey, looks like you missed your way. But do come back when you
            have a link from the good folks{" "}
            <span role="img" aria-label="Emoji">
              🤨
            </span>
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="sign-up">
      <img
        src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
        height="32"
        srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
        sizes="102px"
        alt=""
        className="bottom-breather big-breath"
      />
      <h1 className="bold">Welcome to {brandName}’s Versus team</h1>
      <h2>Before you access the dashboard you need to create your profile.</h2>
      <p className="with-icon positive bottom-breather">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-lock icon push-left"
        >
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>{" "}
        We use 128-bit encryption. Your details are safe.
      </p>
      <div className="throw-down form-block w-form">
        <form onSubmit={handleCreateProfile}>
          <div className="control">
            <label>Email</label>
            <input
              type="text"
              className="input disabled large w-input"
              value={email}
              disabled
            />
            <div className="divider"></div>
          </div>
          <div className="control">
            <label htmlFor="firstName">First name</label>
            <input
              type="text"
              className="input large w-input"
              maxLength="256"
              name="firstName"
              id="firstName"
              onChange={handleChangeProfileData}
            />
          </div>
          <div className="control">
            <label htmlFor="lastName">Last name</label>
            <input
              type="text"
              className="input large w-input"
              maxLength="256"
              name="lastName"
              id="lastName"
              onChange={handleChangeProfileData}
            />
          </div>
          <div className="control">
            <label htmlFor="password">Password</label>
            <input
              type={isPasswordVisible ? "text" : "password"}
              className="input large w-input"
              maxLength="256"
              name="password"
              id="password"
              onChange={handleChangeProfileData}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-eye icon in-input"
              src="https://assets.website-files.com/img/image-placeholder.svg"
              alt=""
              onClick={toggleShowPassword}
            >
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg>
            <label className="helper">
              No less than 6 letters. Pro tip: make it a long funny phrase.
            </label>
          </div>
          {isCreatingProfile ? (
            <button className="button is-disabled large wide w-button" disabled>
              Creating your profile...
            </button>
          ) : areRequiredFieldsCompleted ? (
            <button
              type="submit"
              className="button primary large wide w-button"
            >
              Create profile
            </button>
          ) : (
            <button className="button is-disabled large wide w-button" disabled>
              Create profile
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default CreateProfile;
