import React, { useState, useEffect } from "react";
import Axios from "axios";

import PromoterInfluencers from "./PromoterInfluencers";
import DetractorInfluencers from "./DetractorInfluencers";

import { getUser, getIdToken } from "../../../utils/storage";

const Influencers = (props) => {
  const {
    client,
    brand1,
    brand2,
    brand3,
    brand4,
    period,
    sentiment,
    country,
    language,
    setToast,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [influencers, setInfluencers] = useState([]);

  useEffect(() => {
    // Do not fetch top influencers for neutral sentiment
    if (sentiment === "neutral sentiment") {
      return;
    }

    const postRequests = [];
    [brand1, brand2, brand3, brand4].forEach((brand) => {
      if (brand) {
        postRequests.push(
          Axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_top_influencers`,
            {
              clientRef: brand.compareDbRef,
              uid,
              idToken,
              period,
              sentiment,
              country,
              language,
            },
            { headers: { "content-type": "application/json" } }
          )
        );
      }
    });

    Axios.all(postRequests)
      .then(
        Axios.spread((result1, result2, result3, result4) => {
          // console.log('top influencers', '\n', result1, '\n', result2, '\n', result3, '\n', result4);
          const topInfluencers = [];
          [result1, result2, result3, result4].forEach((result) => {
            if (result) {
              topInfluencers.push(result.data.topInfluencers);
            }
          });

          setInfluencers(topInfluencers);
        })
      )
      .catch((error) => {
        console.log("Error fetching top influencers", error);
        setToast({
          show: true,
          type: "warning",
          message: "Error batch-fetching top influencers. Please refresh",
        });
      });
  }, [brand1, brand2, brand3, brand4, period, sentiment, country, language]);

  if (influencers.length <= 0) {
    return <div></div>;
  } else {
    return (
      <>
        <PromoterInfluencers
          sentiment={sentiment}
          brand1={brand1}
          brand1Influencers={influencers.find(
            (influencer) => influencer.clientRef === brand1.compareDbRef
          )}
          brand2={brand2}
          brand2Influencers={
            brand2
              ? influencers.find(
                  (influencer) => influencer.clientRef === brand2.compareDbRef
                )
              : null
          }
          brand3={brand3}
          brand3Influencers={
            brand3
              ? influencers.find(
                  (influencer) => influencer.clientRef === brand3.compareDbRef
                )
              : null
          }
          brand4={brand4}
          brand4Influencers={
            brand4
              ? influencers.find(
                  (influencer) => influencer.clientRef === brand4.compareDbRef
                )
              : null
          }
        />
        <DetractorInfluencers
          sentiment={sentiment}
          brand1={brand1}
          brand1Influencers={influencers.find(
            (influencer) => influencer.clientRef === brand1.compareDbRef
          )}
          brand2={brand2}
          brand2Influencers={
            brand2
              ? influencers.find(
                  (influencer) => influencer.clientRef === brand2.compareDbRef
                )
              : null
          }
          brand3={brand3}
          brand3Influencers={
            brand3
              ? influencers.find(
                  (influencer) => influencer.clientRef === brand3.compareDbRef
                )
              : null
          }
          brand4={brand4}
          brand4Influencers={
            brand4
              ? influencers.find(
                  (influencer) => influencer.clientRef === brand4.compareDbRef
                )
              : null
          }
        />
      </>
    );
  }
};

export default Influencers;
