import React, { useState, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import axios from "axios";

import { getUser, getIdToken, saveClient } from "../utils/storage";

import Nav from "./Nav";
import Spacer from "./Spacer";
// import ComparisonContainer from "./Comparisons/ComparisonsContainer";
import Ask from "./Ask/Ask";
import NewSurveyModal from "./Ask/NewSurveyModal";
import NewRequestModal from "./Interact/NewRequestModal";
import NewSurvey from "./Ask/NewSurvey";
import ListenContainer from "./Listen/ListenContainer";
import Survey from "./Ask/Survey/Survey";
import FullWidthLoader from "./FullWidthLoader";
import Settings from "./Settings/Settings";
import Account from "./Account/Account";
import CompareContainer from "./Compare/CompareContainer";
import ListenBanner from "./Listen/ListenBanner";
import UpgradeModal from "./Listen/UpgradeModal";
import Interact from "./Interact/Interact";
import MultimediaRequest from "./Interact/MultimediaRequests/MultimediaRequest";
import { useIntercom } from "react-use-intercom";
import { useDispatch } from "react-redux";
import { setSubscriptionPlan } from "../store/subscriptionPlanSlice";
import CampaignFilter from "./CampaignFilter";

const Dashboard = (props) => {
  const { screen, setToast } = props;
  const history = useHistory();
  const { boot } = useIntercom();
  const dispatch = useDispatch();

  const [client, setClient] = useState(null);
  const [team, setTeam] = useState(null);
  const [isNavExpanded, setIsNavExpanded] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const [isFilterOpen, setIsFilterOpen] = useState(false);

  const [isNewSurveyModalOpen, setIsNewSurveyModalOpen] = useState(false);
  const [isNewRequestModalOpen, setIsNewRequestModalOpen] = useState(false);
  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);
  const [tooltip, setTooltip] = useState({
    show: false,
    type: "",
    title: "",
    message: "",
    step: 5.2,
    max_step: 5,
  });
  const [filter, setFilter] = useState({
    sortField: null,
    sortOrder: null,
  });

  const user = getUser(),
    idToken = getIdToken();

  const toggleNavExpand = () =>
    setIsNavExpanded((isNavExpanded) => !isNavExpanded);
  const toggleFilterOpen = () =>
    setIsFilterOpen((isFilterOpen) => !isFilterOpen);
  const toggleNewSurveyModalOpen = () =>
    setIsNewSurveyModalOpen((isNewSurveyModalOpen) => !isNewSurveyModalOpen);
  const toggleNewRequestModalOpen = () =>
    setIsNewRequestModalOpen((isNewRequestModalOpen) => !isNewRequestModalOpen);
  useEffect(() => {
    setIsFilterExpanded(false);
  }, [screen]);
  useEffect(() => {
    //get client
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client`,
      method: "post",
      headers: { "Content-type": "application/json" },
      data: { email: user.email, uid: user.uid, idToken },
    })
      .then(({ data }) => {
        const clientData = data.client;
        const teamData = data.team;
        saveClient(clientData);
        setClient(clientData);
        setTeam(teamData);
        dispatch(setSubscriptionPlan(data.subscriptionPlan));
        boot({
          name: `${teamData.firstName} ${teamData.lastName}`,
          email: teamData.email,
        });
      })
      .catch((error) => {
        console.log("error getting client", error);
        // history.push("/login");
        history.push(`/login?ret=${window.location.pathname}`);
      });
  }, [boot, history, idToken, user.email, user.uid]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!client) {
    return <FullWidthLoader />;
  }

  if (client && client.smeSetupStage === "createdClient") {
    return <Redirect to="/subscribe" />;
  }

  if (client && !client.isOnboarded) {
    return <Redirect to={`/onboarding?token=${client.onboardingToken}`} />;
  }

  return (
    <div className="body-2">
      <Nav
        isNavExpanded={isNavExpanded}
        toggleNavExpand={toggleNavExpand}
        user={user}
        client={client}
        isNewSurveyModalOpen={isNewSurveyModalOpen}
        isNewRequestModalOpen={isNewRequestModalOpen}
        openUpgradeModal={() => setOpenUpgradeModal(true)}
        team={team}
        tooltip={tooltip}
        setTooltip={setTooltip}
      />
      {screen === "listen" ? <ListenBanner client={client} /> : ""}
      <div
        className={`section-2 ${screen === "ask" ? "center" : ""}`}
        style={{
          filter:
            isNewSurveyModalOpen || isNewRequestModalOpen
              ? "blur(16px)"
              : "none",
        }}
      >
        <Spacer
          isNavExpanded={isNavExpanded}
          isFilterExpanded={isFilterExpanded}
          screen={screen}
        />

        {screen === "listen" ? (
          <ListenContainer
            client={client}
            setToast={setToast}
            isFilterOpen={isFilterOpen}
            toggleFilterOpen={toggleFilterOpen}
            tooltip={tooltip}
            setTooltip={setTooltip}
          />
        ) : screen === "ask" ? (
          <Ask
            isNewSurveyModalOpen={isNewSurveyModalOpen}
            toggleNewSurveyModalOpen={toggleNewSurveyModalOpen}
            isFilterExpanded={isFilterExpanded}
            setIsFilterExpanded={setIsFilterExpanded}
            client={client}
            setToast={setToast}
            tooltip={tooltip}
            setTooltip={setTooltip}
            filter={filter}
          />
        ) : screen === "new-survey" ? (
          <NewSurvey
            setToast={setToast}
            tooltip={tooltip}
            setTooltip={setTooltip}
          />
        ) : screen === "survey" ? (
          <Survey
            client={client}
            isNavExpanded={isNavExpanded}
            toggleNavExpand={toggleNavExpand}
            setToast={setToast}
            tooltip={tooltip}
            setTooltip={setTooltip}
          />
        ) : screen === "compare" ? (
          <CompareContainer
            client={client}
            setToast={setToast}
            isFilterOpen={isFilterOpen}
            toggleFilterOpen={toggleFilterOpen}
            tooltip={tooltip}
            setTooltip={setTooltip}
          />
        ) : screen === "settings" ? (
          <Settings client={client} setToast={setToast} />
        ) : screen === "account" ? (
          <Account
            client={client}
            setToast={setToast}
            user={user}
            team={team}
          />
        ) : screen === "interact" ? (
          <Interact
            client={client}
            setToast={setToast}
            user={user}
            toggleNewRequestModalOpen={toggleNewRequestModalOpen}
            tooltip={tooltip}
            setTooltip={setTooltip}
            isFilterExpanded={isFilterExpanded}
            setIsFilterExpanded={setIsFilterExpanded}
            filter={filter}
          />
        ) : screen === "multimedia-request" ? (
          <MultimediaRequest
            client={client}
            setToast={setToast}
            user={user}
            isNavExpanded={isNavExpanded}
          />
        ) : (
          ""
        )}
      </div>

      {/* New survey modals */}
      <NewSurveyModal
        isNewSurveyModalOpen={isNewSurveyModalOpen}
        toggleNewSurveyModalOpen={toggleNewSurveyModalOpen}
        setToast={setToast}
        tooltip={tooltip}
        setTooltip={setTooltip}
      />
      {/* New Request modals */}
      <NewRequestModal
        isNewRequestModalOpen={isNewRequestModalOpen}
        toggleNewRequestModalOpen={toggleNewRequestModalOpen}
        setToast={setToast}
        setTooltip={setTooltip}
        tooltip={tooltip}
      />
      {/* Upgrade Modal */}
      <UpgradeModal
        client={client}
        showUpgradeModal={openUpgradeModal}
        toggleShowUpgradeModal={() => setOpenUpgradeModal(false)}
        setToast={setToast}
      />
      {isFilterExpanded && (screen === "interact" || screen === "ask") && (
        <CampaignFilter
          isFilterExpanded={isFilterExpanded}
          isNavExpanded={isNavExpanded}
          setFilter={setFilter}
          filter={filter}
          screen={screen}
        />
      )}
    </div>
  );
};

export default Dashboard;
