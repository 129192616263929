import React from "react";
import RangeSlider from "../../RangeSlider/RangeSlider";
import StateFilter from "./StateFilter";

const earningsMonthlyList = [
  "below_100_USD",
  "100_to_500_USD",
  "500_to_1000_USD",
  "above_1000_USD",
];
const inHouseholdList = ["below_3", "3_to_5", "5_to_10", "above_10"];

const SurveyFilter = (props) => {
  const {
    isNavExpanded,
    setToast,
    handleSetStates,
    states,
    campaign,
    setShowResponseFilterModal,
    // handleSliderChange,
    handleSetAge,
    gender,
    handleSetGender,
    tribe,
    handleSetTribe,
    educationLevel,
    handleSetEducationLevel,
    religion,
    handleSetReligion,
    relationshipStatus,
    handleSetRelationshipStatus,
    employmentStatus,
    handleSetEmploymentStatus,
    childrenInHousehold,
    handleSetChildrenInHousehold,
    personalEarningsMonthly,
    handleSetPersonalEarningsMonthly,
    householdEarningsMonthly,
    handleSetHouseholdEarningsMonthly,
    personsInHousehold,
    handleSetPersonsInHousehold,
    childrenUnder18,
    handleSetChildrenUnder18,
    handleOnAgeChange,
    inputValues,
  } = props;

  return (
    <div className="side-spacer thin left">
      <div className="side-bar fixed">
        <div
          className="spacer"
          style={{
            width: isNavExpanded ? "200px" : "52px",
            height: "935.531px",
          }}
        ></div>
        <div className="div-block-7">
          <h3>Filter responses</h3>
          {/* <span
            style={{ cursor: "not-allowed" }}
            className="button no-margin small subtle with-icon black push-down w-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-save icon small nudge-left"
            >
              <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
              <polyline points="17 21 17 13 7 13 7 21"></polyline>
              <polyline points="7 3 7 8 15 8"></polyline>
            </svg>{" "}
            Save filter
          </span>
          <div className="dropdown move-left">
            <span
              style={{ cursor: "not-allowed" }}
              className="button with-icon small subtle black w-button"
            >
              Load filter
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down icon small nudge-right"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </span>
            @TODO Saved filters dropdown 
            <div
              className="dropdown-list"
              style={{ height: "0px", display: "none" }}
            >
              <span className="dropdown-link">Filter 1</span>
              <span className="dropdown-link">Filter 2</span>
              <span className="dropdown-link">Filter 3</span>
            </div>
          </div> */}
          <a
            data-w-id="75475d1c-8c6f-24c8-132f-25ac3946ceb3"
            href="#"
            className="button subtle small w-button"
            onClick={() => setShowResponseFilterModal(true)}
          >
            Apply response filter
          </a>
          <StateFilter
            states={states}
            setToast={setToast}
            handleSetStates={handleSetStates}
          />
          <div className="push-down">
            <div className="no-margin fill w-form">
              <form
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
              >
                <h5 className="move-up top-breather">Age</h5>
                <div className="row spaced">
                  <h5>
                    <input
                      type="text"
                      name="startAge"
                      placeholder={campaign.startAge}
                      value={inputValues.startAge}
                      className="input filter w-input"
                      onChange={handleOnAgeChange}
                    />
                  </h5>
                  <h5>
                    <input
                      type="text"
                      name="endAge"
                      placeholder={campaign.endAge}
                      value={inputValues.endAge}
                      className="input filter w-input"
                      onChange={handleOnAgeChange}
                    />
                  </h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    domain={[16, 65]}
                    values={[inputValues.startAge, inputValues.endAge]}
                    handleSliderChange={handleSetAge}
                  />
                </div>
              </form>
            </div>
            <div id="rangeSlider" className="fill"></div>
          </div>

          <div
            className="spacer"
            style={{ width: "52px", height: "0px" }}
          ></div>
          <div>
            <h5 className="top-breather">Gender</h5>
            <span
              className={`side-bar-link ${
                gender.includes("male") ? "active" : ""
              }`}
              onClick={() => handleSetGender("male")}
            >
              Male
            </span>
            <span
              className={`side-bar-link ${
                gender.includes("female") ? "active" : ""
              }`}
              onClick={() => handleSetGender("female")}
            >
              Female
            </span>
            {/* <span
              className={`side-bar-link ${
                filterVars.includes("non-binary") ? "active" : ""
              }`}
              onClick={() => handleChangeFilterVars("gender", "non-binary")}
            >
              Non-binary
            </span> */}
          </div>
          <div>
            <h5 className="top-breather">Tribe</h5>
            <span
              className={`side-bar-link ${
                tribe.includes("Yoruba") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Yoruba")}
            >
              Yoruba
            </span>
            <span
              className={`side-bar-link ${
                tribe.includes("Hausa") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Hausa")}
            >
              Hausa
            </span>
            <span
              className={`side-bar-link ${
                tribe.includes("Igbo") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Igbo")}
            >
              Igbo
            </span>
            <span
              className={`side-bar-link ${
                tribe.includes("Other") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Other")}
            >
              Other
            </span>
          </div>
          <div>
            <h5 className="top-breather">Education Level</h5>
            <span
              className={`side-bar-link ${
                educationLevel.includes("No schooling completed")
                  ? "active"
                  : ""
              }`}
              onClick={() => handleSetEducationLevel("No schooling completed")}
            >
              No schooling completed
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Nursery school to 8th grade")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducationLevel("Nursery school to 8th grade")
              }
            >
              Nursery school to 8th grade
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Some high school no diploma")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducationLevel("Some high school no diploma")
              }
            >
              Some high school no diploma
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes(
                  "High school graduate diploma or the equivalent (for example: GED)"
                )
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducationLevel(
                  "High school graduate diploma or the equivalent (for example: GED)"
                )
              }
            >
              High school graduate diploma or the equivalent (for example: GED)
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Some college credit no degree")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducationLevel("Some college credit no degree")
              }
            >
              Some college credit no degree
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Trade/technical/vocational training")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducationLevel("Trade/technical/vocational training")
              }
            >
              Trade/technical/vocational training
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Associate degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducationLevel("Associate degree")}
            >
              Associate degree
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Bachelor's degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducationLevel("Bachelor's degree")}
            >
              Bachelor's degree
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Master's degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducationLevel("Master's degree")}
            >
              Master's degree
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Professional degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducationLevel("Professional degree")}
            >
              Professional degree
            </span>
            <span
              className={`side-bar-link ${
                educationLevel.includes("Doctorate degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducationLevel("Doctorate degree")}
            >
              Doctorate degree
            </span>
          </div>
          <div>
            <h5 className="top-breather">Relationship status</h5>
            <span
              className={`side-bar-link ${
                relationshipStatus.includes("Single never married")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetRelationshipStatus("Single never married")
              }
            >
              Single never married
            </span>
            <span
              className={`side-bar-link ${
                relationshipStatus.includes("Married or domestic partnership")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetRelationshipStatus("Married or domestic partnership")
              }
            >
              Married or domestic partnership
            </span>
            <span
              className={`side-bar-link ${
                relationshipStatus.includes("Divorced") ? "active" : ""
              }`}
              onClick={() => handleSetRelationshipStatus("Divorced")}
            >
              Divorced
            </span>
            <span
              className={`side-bar-link ${
                relationshipStatus.includes("Separated") ? "active" : ""
              }`}
              onClick={() => handleSetRelationshipStatus("Separated")}
            >
              Separated
            </span>
            <span
              className={`side-bar-link ${
                relationshipStatus.includes("Widowed") ? "active" : ""
              }`}
              onClick={() => handleSetRelationshipStatus("Widowed")}
            >
              Widowed
            </span>
          </div>
          <div>
            <h5 className="top-breather"> No. of children </h5>
            {inHouseholdList.map((list, i) => (
              <span
                key={i}
                className={`side-bar-link ${
                  childrenInHousehold.includes(list) ? "active" : ""
                }`}
                onClick={() => handleSetChildrenInHousehold(list)}
              >
                {list.replace(/_/g, " ")}
              </span>
            ))}
          </div>
          <div>
            <h5 className="top-breather">Employment status</h5>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Fully employed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Fully employed")}
            >
              Fully employed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Partime employed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Partime employed")}
            >
              Partime employed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Self-employed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Self-employed")}
            >
              Self-employed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Unemployed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Unemployed")}
            >
              Unemployed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Retired") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Retired")}
            >
              Retired
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("A homemaker") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("A homemaker")}
            >
              A homemaker
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("A student") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("A student")}
            >
              A student
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Military") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Military")}
            >
              Military
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Unable to work") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Unable to work")}
            >
              Unable to work
            </span>
          </div>
          <div>
            <h5 className="top-breather">Religion</h5>
            <span
              className={`side-bar-link ${
                religion.includes("Catholic") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Catholic")}
            >
              Catholic
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Protestant (Anglican Baptist)")
                  ? "active"
                  : ""
              }`}
              onClick={() => handleSetReligion("Protestant (Anglican Baptist)")}
            >
              Protestant (Anglican Baptist)
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Christian Orthodox") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Christian Orthodox")}
            >
              Christian orthodox
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Muslim") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Muslim")}
            >
              Muslim
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Hindu") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Hindu")}
            >
              Hindu
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Buddhist") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Buddhist")}
            >
              Buddhist
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Atheist (do not believe in God)")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetReligion("Atheist (do not believe in God)")
              }
            >
              Atheist (do not believe in God)
            </span>
          </div>
          <div>
            <h5 className="top-breather"> Personal earnings monthly </h5>
            {earningsMonthlyList.map((list, i) => (
              <span
                key={i}
                className={`side-bar-link ${
                  personalEarningsMonthly.includes(list) ? "active" : ""
                }`}
                onClick={() => handleSetPersonalEarningsMonthly(list)}
              >
                {list.replace(/_/g, " ")}
              </span>
            ))}
          </div>

          <div>
            <h5 className="top-breather"> Household earnings monthly </h5>
            {earningsMonthlyList.map((list, i) => (
              <span
                key={i}
                className={`side-bar-link ${
                  householdEarningsMonthly.includes(list) ? "active" : ""
                }`}
                onClick={() => handleSetHouseholdEarningsMonthly(list)}
              >
                {list.replace(/_/g, " ")}
              </span>
            ))}
          </div>

          <div>
            <h5 className="top-breather"> Persons in household </h5>
            {inHouseholdList.map((list, i) => (
              <span
                key={i}
                className={`side-bar-link ${
                  personsInHousehold.includes(list) ? "active" : ""
                }`}
                onClick={() => handleSetPersonsInHousehold(list)}
              >
                {list.replace(/_/g, " ")}
              </span>
            ))}
          </div>

          <div>
            <h5 className="top-breather">Children under 18</h5>
            <span
              className={`side-bar-link ${
                childrenUnder18.includes("Yes") ? "active" : ""
              }`}
              onClick={() => handleSetChildrenUnder18("Yes")}
            >
              Yes
            </span>
            <span
              className={`side-bar-link ${
                childrenUnder18.includes("No") ? "active" : ""
              }`}
              onClick={() => handleSetChildrenUnder18("No")}
            >
              No
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SurveyFilter;
