import React from "react";

import RangeSlider from "../../../RangeSlider/RangeSlider";

const InteractFilter = (props) => {
  const {
    request,
    isNavExpanded,
    age,
    handleSetAge,
    gender,
    handleSetGender,
    tribe,
    handleSetTribe,
    education,
    handleSetEducation,
    religion,
    handleSetReligion,
    maritalStatus,
    handleSetMaritalStatus,
    employmentStatus,
    handleSetEmploymentStatus,
    handleSetChildrenInHousehold,
    handleSetPersonalMonthlyIncome,
    handleSetHouseholdMonthlyIncome,
    handleSetPeopleInHousehold,
    anyChildUnder18,
    handleSetAnyChildUnder18,
  } = props;

  return (
    <div className="side-spacer thin left">
      <div className="side-bar fixed">
        <div
          className="spacer"
          style={{ width: isNavExpanded ? "200px" : "52px" }}
        ></div>
        <div className="div-block-7">
          <h3>Filters</h3>
          {/* <div className="div-block-10">
            <a
              data-w-id="becba575-251c-4a36-149e-60d18d6ff9f9"
              href="/"
              className="button no-margin small subtle with-icon black push-down w-button"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-save icon small nudge-left"
              >
                <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z"></path>
                <polyline points="17 21 17 13 7 13 7 21"></polyline>
                <polyline points="7 3 7 8 15 8"></polyline>
              </svg>{" "}
              Save filter
            </a>
          </div>
          <div className="dropdown move-left">
            <a
              href="/"
              className="button with-icon small subtle black w-button"
            >
              Load filter
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
                className="feather feather-chevron-down icon small nudge-right"
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            </a>
            <div
              className="dropdown-list"
              style={{ height: "0px", display: "none" }}
            >
              <a href="/" className="dropdown-link">
                Filter 1
              </a>
              <a href="/" className="dropdown-link">
                Filter 2
              </a>
              <a href="/" className="dropdown-link">
                Filter 3
              </a>
            </div>
          </div> */}
          <div className="push-down">
            <div className="no-margin fill w-form">
              <div>
                <h5 className="move-up top-breather">Age</h5>
                <div className="row spaced">
                  <h5>16</h5>
                  <h5>65</h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    values={[
                      `${age[0] || request.startAge}`,
                      `${age[1] || request.endAge}`,
                    ]}
                    handleSliderChange={handleSetAge}
                  />
                </div>
              </div>
            </div>
            <div id="rangeSlider" className="fill"></div>
          </div>
          <div
            className="spacer"
            style={{ width: "52px", height: "0px" }}
          ></div>
          <div>
            <h5 className="top-breather">Gender</h5>
            <span
              className={`side-bar-link ${
                gender.includes("Male") ? "active" : ""
              }`}
              onClick={() => handleSetGender("Male")}
            >
              Male
            </span>
            <span
              className={`side-bar-link ${
                gender.includes("Female") ? "active" : ""
              }`}
              onClick={() => handleSetGender("Female")}
            >
              Female
            </span>
            {/* <span
              className={`side-bar-link ${
                filterVars.includes("non-binary") ? "active" : ""
              }`}
              onClick={() => handleChangeFilterVars("gender", "non-binary")}
            >
              Non-binary
            </span> */}
          </div>
          <div>
            <h5 className="top-breather">Tribe</h5>
            <span
              className={`side-bar-link ${
                tribe.includes("Yoruba") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Yoruba")}
            >
              Yoruba
            </span>
            <span
              className={`side-bar-link ${
                tribe.includes("Hausa") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Hausa")}
            >
              Hausa
            </span>
            <span
              className={`side-bar-link ${
                tribe.includes("Igbo") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Igbo")}
            >
              Igbo
            </span>
            <span
              className={`side-bar-link ${
                tribe.includes("Other") ? "active" : ""
              }`}
              onClick={() => handleSetTribe("Other")}
            >
              Other
            </span>
          </div>
          <div>
            <h5 className="top-breather">Education Level</h5>
            <span
              className={`side-bar-link ${
                education.includes("No schooling completed") ? "active" : ""
              }`}
              onClick={() => handleSetEducation("No schooling completed")}
            >
              No schooling completed
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Nursery school to 8th grade")
                  ? "active"
                  : ""
              }`}
              onClick={() => handleSetEducation("Nursery school to 8th grade")}
            >
              Nursery school to 8th grade
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Some high school no diploma")
                  ? "active"
                  : ""
              }`}
              onClick={() => handleSetEducation("Some high school no diploma")}
            >
              Some high school no diploma
            </span>
            <span
              className={`side-bar-link ${
                education.includes(
                  "High school graduate diploma or the equivalent (for example: GED)"
                )
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducation(
                  "High school graduate diploma or the equivalent (for example: GED)"
                )
              }
            >
              High school graduate diploma or the equivalent (for example: GED)
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Some college credit no degree")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducation("Some college credit no degree")
              }
            >
              Some college credit no degree
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Trade/technical/vocational training")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetEducation("Trade/technical/vocational training")
              }
            >
              Trade/technical/vocational training
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Associate degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducation("Associate degree")}
            >
              Associate degree
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Bachelor's degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducation("Bachelor's degree")}
            >
              Bachelor's degree
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Master's degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducation("Master's degree")}
            >
              Master's degree
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Professional degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducation("Professional degree")}
            >
              Professional degree
            </span>
            <span
              className={`side-bar-link ${
                education.includes("Doctorate degree") ? "active" : ""
              }`}
              onClick={() => handleSetEducation("Doctorate degree")}
            >
              Doctorate degree
            </span>
          </div>
          <div>
            <h5 className="top-breather">Relationship status</h5>
            <span
              className={`side-bar-link ${
                maritalStatus.includes("Single never married") ? "active" : ""
              }`}
              onClick={() => handleSetMaritalStatus("Single never married")}
            >
              Single never married
            </span>
            <span
              className={`side-bar-link ${
                maritalStatus.includes("Married or domestic partnership")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetMaritalStatus("Married or domestic partnership")
              }
            >
              Married or domestic partnership
            </span>
            <span
              className={`side-bar-link ${
                maritalStatus.includes("Divorced") ? "active" : ""
              }`}
              onClick={() => handleSetMaritalStatus("Divorced")}
            >
              Divorced
            </span>
            <span
              className={`side-bar-link ${
                maritalStatus.includes("Separated") ? "active" : ""
              }`}
              onClick={() => handleSetMaritalStatus("Separated")}
            >
              Separated
            </span>
            <span
              className={`side-bar-link ${
                maritalStatus.includes("Widowed") ? "active" : ""
              }`}
              onClick={() => handleSetMaritalStatus("Widowed")}
            >
              Widowed
            </span>
          </div>
          <div>
            <div className="no-margin fill top-breather w-form">
              <div>
                <h5 className="move-up">No. of children</h5>
                <div className="row spaced">
                  <h5>0</h5>
                  <h5>20</h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    domain={[0, 20]}
                    values={[3]}
                    handleSliderChange={handleSetChildrenInHousehold}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className="top-breather">Employment status</h5>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Fully employed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Fully employed")}
            >
              Fully employed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Partime employed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Partime employed")}
            >
              Partime employed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Self-employed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Self-employed")}
            >
              Self-employed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Unemployed") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Unemployed")}
            >
              Unemployed
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Retired") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Retired")}
            >
              Retired
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("A homemaker") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("A homemaker")}
            >
              A homemaker
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("A student") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("A student")}
            >
              A student
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Military") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Military")}
            >
              Military
            </span>
            <span
              className={`side-bar-link ${
                employmentStatus.includes("Unable to work") ? "active" : ""
              }`}
              onClick={() => handleSetEmploymentStatus("Unable to work")}
            >
              Unable to work
            </span>
          </div>
          <div>
            <h5 className="top-breather">Religion</h5>
            <span
              className={`side-bar-link ${
                religion.includes("Catholic") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Catholic")}
            >
              Catholic
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Protestant (Anglican Baptist)")
                  ? "active"
                  : ""
              }`}
              onClick={() => handleSetReligion("Protestant (Anglican Baptist)")}
            >
              Protestant (Anglican Baptist)
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Christian Orthodox") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Christian Orthodox")}
            >
              Christian orthodox
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Muslim") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Muslim")}
            >
              Muslim
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Hindu") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Hindu")}
            >
              Hindu
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Buddhist") ? "active" : ""
              }`}
              onClick={() => handleSetReligion("Buddhist")}
            >
              Buddhist
            </span>
            <span
              className={`side-bar-link ${
                religion.includes("Atheist (do not believe in God)")
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                handleSetReligion("Atheist (do not believe in God)")
              }
            >
              Atheist (do not believe in God)
            </span>
          </div>
          <div>
            <div className="no-margin fill w-form">
              <div>
                <h5 className="move-up top-breather">
                  Personal earnings monthly
                </h5>
                <div className="row spaced">
                  <h5>0</h5>
                  <h5>2,000,000</h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    domain={[0, 2000000]}
                    values={[20000]}
                    handleSliderChange={handleSetPersonalMonthlyIncome}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="no-margin fill w-form">
              <div>
                <h5 className="move-up top-breather">
                  Household earnings monthly
                </h5>
                <div className="row spaced">
                  <h5>0</h5>
                  <h5>2,000,000</h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    domain={[0, 2000000]}
                    values={[50000]}
                    handleSliderChange={handleSetHouseholdMonthlyIncome}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className="no-margin fill w-form">
              <div
                id="email-form-2"
                name="email-form-2"
                data-name="Email Form 2"
              >
                <h5 className="move-up top-breather">Persons in household</h5>
                <div className="row spaced">
                  <h5>1</h5>
                  <h5>20</h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    domain={[1, 20]}
                    values={[4]}
                    handleSliderChange={handleSetPeopleInHousehold}
                  />
                </div>
              </div>
            </div>
          </div>
          <div>
            <h5 className="top-breather">Children under 18</h5>
            <span
              className={`side-bar-link ${
                anyChildUnder18.includes("Yes") ? "active" : ""
              }`}
              onClick={() => handleSetAnyChildUnder18("Yes")}
            >
              Yes
            </span>
            <span
              className={`side-bar-link ${
                anyChildUnder18.includes("No") ? "active" : ""
              }`}
              onClick={() => handleSetAnyChildUnder18("No")}
            >
              No
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractFilter;
