import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../../utils/storage";

const News = (props) => {
  const {
    filterPeriod,
    client,
    setToast,
    filterSentiment,
    filterKeywordOrHashtag,
    sources,
    toggleSource,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [newsSources, setNewsSources] = useState({
    totalSources: 0,
    sources: [],
  });

  const url = `${
    client.clientRef === "12a3c24d-8782-4e40-bc1f-c8abb8e431c7"
      ? "https://us-central1-versus-prod.cloudfunctions.net/versus_v2_prod_get_news_mentions_sources"
      : process.env.REACT_APP_API_BASE_URL +
        "/versus_v2_get_news_mentions_sources"
  }`;

  useEffect(() => {
    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          sentiment: filterSentiment,
          keyword_or_hashtag: filterKeywordOrHashtag,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const totalSources = result.data.totalSources;
          const sources = result.data.sources;
          setNewsSources({ totalSources, sources });
        }
      })
      .catch((error) => {
        console.log("Error obtaining news mentions sources", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems retrieving news mentions sources. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    filterSentiment,
    filterKeywordOrHashtag,
    idToken,
    setToast,
    url,
    user.uid,
  ]);

  return (
    <div
      className="column rel"
      style={{
        opacity:
          sources.categories.length === 0 || sources.categories.includes("news")
            ? 1
            : 0.2,
      }}
    >
      <h3
        className="card-title"
        data-tooltip={
          sources.categories.includes("news")
            ? "Clear filter by News"
            : "Filter by News"
        }
        onClick={() =>
          toggleSource(
            null,
            "news",
            newsSources.sources.map((src) => src.source)
          )
        }
      >
        News <span className="data small">{newsSources.totalSources}</span>
      </h3>
      <div className="source-container">
        {newsSources.sources.map((source, i) => (
          <span
            data-tooltip={
              sources.news.includes(source.source)
                ? `Clear filter by ${source.source}`
                : `Filter by ${source.source}`
            }
            className="link-block"
            key={i}
          >
            <div
              className="bar-container has-cover"
              style={{
                cursor: "pointer",
                opacity:
                  sources.news.length === 0 ||
                  sources.news.includes(source.source)
                    ? 1
                    : 0.2,
              }}
              onClick={() => toggleSource(source.source, "news")}
            >
              <div className="medium-text nudge-right">{source.source}</div>
              <div className="data tiny nudge-left">{source.total}</div>
              <div className="measure-bar tall cover">
                <div
                  className="metric stat"
                  style={{
                    width: `${
                      (source.total / newsSources.totalSources) * 100
                    }%`,
                    height: "7.99219px",
                  }}
                ></div>
              </div>
            </div>
          </span>
        ))}
      </div>
      {/* <div className="hider-2 s" style={{ opacity: 0 }}></div> */}
    </div>
  );
};

export default News;
