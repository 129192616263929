import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import PositiveMostPopular from "./MentionsMostPopular/PositiveMostPopular";
import NegativeMostPopular from "./MentionsMostPopular/NegativeMostPopular";

const MentionsMostPopular = (props) => {
  const {
    filterPeriod,
    client,
    setToast,
    handleMentionModal,
    filterSentiment,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [popularMentions, setPopularMentions] = useState({
    positive: [],
    negative: [],
  });

  const url = `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_most_popular_mentions`;

  useEffect(() => {
    // Do not fetch mentions for neutral sentiment
    if (filterSentiment === "neutral sentiment") {
      return;
    }

    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          country: filterCountry,
          language: filterLanguage,
          keyword_or_hashtag: filterKeywordOrHashtag,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const mostPopularMentions = result.data.popularMentions;
          setPopularMentions({
            positive: mostPopularMentions.positive,
            negative: mostPopularMentions.negative,
          });
        }
      })
      .catch((error) => {
        console.log("Error obtaining mentions summary", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems retrieving most popular mentions. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    filterSentiment,
    idToken,
    setToast,
    url,
    user.uid,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  ]);

  return (
    <div className="row has-cards">
      <div className="column">
        <div className="card-2">
          <div className="card-section padded-x">
            <h3>Most popular mentions</h3>
            <div
              className="row push-down"
              style={{ height: "500px", overflow: "auto" }}
            >
              <PositiveMostPopular
                positivePopularMentions={popularMentions.positive}
                handleMentionModal={handleMentionModal}
                sentiment={filterSentiment}
              />
              <NegativeMostPopular
                negativePopularMentions={popularMentions.negative}
                handleMentionModal={handleMentionModal}
                sentiment={filterSentiment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentionsMostPopular;
