import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import Dashboard from "./components/Dashboard";
import Access from "./components/Access/Access";
import Onboarding from "./components/Onboarding/Onboarding";

import { getUser } from "./utils/storage";
import Toast from "./components/Toast/Toast";
import CreateProfile from "./components/CreateProfile";
import Subscribe from "./components/Subscribe";
import SubscribeSuccess from "./components/SubscribeSuccess";
import SubscribeCancel from "./components/SubscribeCancel";

const App = () => {
  const user = getUser();
  const [toast, setToast] = useState({
    show: false,
    type: "",
    message: "",
  });

  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to={`${user ? "/dashboard/listen" : "/login"}`} />
        </Route>
        <Route exact path="/dashboard">
          <Redirect to={`${user ? "/dashboard/listen" : "/login"}`} />
        </Route>
        <Route exact path="/dashboard/listen">
          {user ? (
            <Dashboard screen="listen" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/compare">
          {user ? (
            <Dashboard screen="compare" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/interact">
          {user ? (
            <Dashboard screen="interact" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/interact/request/:ref">
          {user ? (
            <Dashboard screen="multimedia-request" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/ask">
          {user ? (
            <Dashboard screen="ask" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/ask/new-survey/:ref">
          {user ? (
            <Dashboard screen="new-survey" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/ask/survey/:ref">
          {user ? (
            <Dashboard screen="survey" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/settings">
          {user ? (
            <Dashboard screen="settings" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/dashboard/account">
          {user ? (
            <Dashboard screen="account" setToast={setToast} />
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
        <Route exact path="/onboarding">
          <Onboarding setToast={setToast} />
        </Route>
        <Route exact path="/login">
          <Access action="login" setToast={setToast} />
        </Route>
        <Route exact path="/signup">
          <Access action="signup" setToast={setToast} />
        </Route>
        <Route exact path="/forgot-password">
          <Access action="forgotpassword" setToast={setToast} />
        </Route>
        <Route exact path="/create-profile">
          <CreateProfile setToast={setToast} />
        </Route>
        <Route exact path="/subscribe">
          <Subscribe setToast={setToast} />
        </Route>
        <Route exact path="/subscribe-success">
          <SubscribeSuccess />
        </Route>
        <Route exact path="/subscribe-cancel">
          <SubscribeCancel />
        </Route>
        <Route>
          <Redirect to="/" />
        </Route>
      </Switch>
      <Toast toast={toast} setToast={setToast} />
    </Router>
  );
};

export default App;
