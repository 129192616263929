import React from "react";

const ConfirmModal = (props) => {
    const {
        showConfirmModal,
        toggleShowConfirmModal,
        handleRemoveCompareBrand,
        isDeleting
    } = props;

    return (
        <div
            style={{ display: showConfirmModal ? "flex" : "none", opacity: 1 }}
            className="modal"
        >
            <div className="modal-container">
                <h2>Confirm action</h2>
                <div onClick={() => toggleShowConfirmModal()}>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x icon close"
                    >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                </div>
                <div
                    style={{
                        opacity: 1,
                        transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                    }}
                    className="modal-card"
                >
                    <div className="modal-body">
                        <div className="fill throw-down w-form">
                            <label htmlFor="name" className="body-3">Are you sure you want to permanently delete this comparison brand?<br /></label>
                        </div>
                    </div>
                    <div className="modal-footer" style={{ opacity: 1, display: "flex" }}>
                        {isDeleting ? (
                            <span className="button is-disabled small primary w-button">
                                Deleting ...
                            </span>
                        ) : (
                            <span
                                data-w-id="43a1d301-a868-caa5-2194-3934134d1305"
                                className="button danger w-button"
                                onClick={() => handleRemoveCompareBrand()}
                            >
                                Delete
                            </span>)
                        }
                        <span
                            className="button subtle push-right w-button"
                            onClick={() => toggleShowConfirmModal()}
                        >
                            Cancel
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ConfirmModal;
