import { authInstance } from "../utils/firebase";
import * as auth from "firebase/auth";

const signup = (email, password) => {
  return new Promise((resolve, reject) => {
    auth
      .createUserWithEmailAndPassword(authInstance, email, password)
      .then((user) => resolve(user))
      .catch((error) => reject(error));
    // .catch((error) => reject(new Error(error.message)));
  });
};

/**
 * 
 * firebase.auth().createUserWithEmailAndPassword(email, password).catch(function(error) {
  // Handle Errors here.
  var errorCode = error.code;
  var errorMessage = error.message;
  // ...
})
 */

const login = (email, password) => {
  return new Promise((resolve, reject) => {
    auth
      .signInWithEmailAndPassword(authInstance, email, password)
      .then((user) => resolve(user))
      .catch((error) => reject(new Error(error.message)));
  });
};

const logout = () => {
  return new Promise((resolve, reject) => {
    auth
      .signOut()
      .then(() => resolve("User signed out"))
      .catch((error) => reject(new Error(error.message)));
  });
};

const getIdToken = () => {
  return new Promise((resolve, reject) => {
    authInstance.currentUser
      .getIdToken()
      .then((idToken) => resolve(idToken))
      .catch((error) => reject(new Error(error.message)));
  });
};

const updatePassword = (newPassword) => {
  return new Promise((resolve, reject) => {
    auth
      .updatePassword(authInstance.currentUser, newPassword)
      .then(() => resolve("Updated user password"))
      .catch((error) => reject(new Error(error.message)));
  });
};

const sendPasswordResetEmail = (email) => {
  return new Promise((resolve, reject) => {
    auth
      .sendPasswordResetEmail(authInstance, email, {
        url: `${process.env.REACT_APP_FRONTEND_URL}/login`,
      })
      .then(() => resolve("Sent password reset email"))
      .catch((error) => reject(new Error(error.message)));
  });
};

export {
  login,
  logout,
  getIdToken,
  signup,
  updatePassword,
  sendPasswordResetEmail,
};
