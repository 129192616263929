import React, { useState, useEffect } from "react";
import Axios from "axios";

import { getUser, getIdToken } from "../../../utils/storage";

import PopularPositiveMentions from "./PopularPositiveMentions";
import PopularNegativeMentions from "./PopularNegativeMentions";

const PopularMentions = (props) => {
  const {
    client,
    setToast,
    brand1,
    brand2,
    brand3,
    brand4,
    period,
    sentiment,
    country,
    language,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [popularMentions, setPopularMentions] = useState([]);

  useEffect(() => {
    // Do not fetch mentions for neutral sentiment
    if (sentiment === "neutral sentiment") {
      return;
    }

    const postRequests = [];
    [brand1, brand2, brand3, brand4].forEach((brand) => {
      if (brand) {
        postRequests.push(
          Axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_most_popular_mentions`,
            {
              clientRef: brand.compareDbRef,
              uid,
              idToken,
              period,
              country,
              language,
            },
            { headers: { "content-type": "application/json" } }
          )
        );
      }
    });

    Axios.all(postRequests)
      .then(
        Axios.spread((result1, result2, result3, result4) => {
          const popMentions = [];
          [result1, result2, result3, result4].forEach((result) => {
            if (result) {
              popMentions.push(result.data.popularMentions);
            }
          });

          setPopularMentions(popMentions);
        })
      )
      .catch((error) => {
        console.log("Error fetching most popular mentions", error);
        setToast({
          show: true,
          type: "warning",
          message: "Error batch-fetching most popular mentions. Please refresh",
        });
      });
  }, [brand1, brand2, brand3, brand4, period, sentiment, country, language]);

  if (popularMentions.length <= 0) {
    return <div></div>;
  } else {
    return (
      <>
        <PopularPositiveMentions
          sentiment={sentiment}
          brand1={brand1}
          brand1Mentions={popularMentions.find(
            (mention) => mention.clientRef === brand1.compareDbRef
          )}
          brand2={brand2}
          brand2Mentions={
            brand2
              ? popularMentions.find(
                  (mention) => mention.clientRef === brand2.compareDbRef
                )
              : null
          }
          brand3={brand3}
          brand3Mentions={
            brand3
              ? popularMentions.find(
                  (mention) => mention.clientRef === brand3.compareDbRef
                )
              : null
          }
          brand4={brand4}
          brand4Mentions={
            brand4
              ? popularMentions.find(
                  (mention) => mention.clientRef === brand4.compareDbRef
                )
              : null
          }
        />
        <PopularNegativeMentions
          sentiment={sentiment}
          brand1={brand1}
          brand1Mentions={popularMentions.find(
            (mention) => mention.clientRef === brand1.compareDbRef
          )}
          brand2={brand2}
          brand2Mentions={
            brand2
              ? popularMentions.find(
                  (mention) => mention.clientRef === brand2.compareDbRef
                )
              : null
          }
          brand3={brand3}
          brand3Mentions={
            brand3
              ? popularMentions.find(
                  (mention) => mention.clientRef === brand3.compareDbRef
                )
              : null
          }
          brand4={brand4}
          brand4Mentions={
            brand4
              ? popularMentions.find(
                  (mention) => mention.clientRef === brand4.compareDbRef
                )
              : null
          }
        />
      </>
    );
  }
};

export default PopularMentions;
