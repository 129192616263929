import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { clearUserData } from "../utils/storage";
// import { logout } from "../services/auth";
import Avatar from "./Avatar";
import { useSelector } from "react-redux";

const Nav = (props) => {
  const {
    isNavExpanded,
    toggleNavExpand,
    user,
    client,
    isNewSurveyModalOpen,
    isNewRequestModalOpen,
    team,
    openUpgradeModal,
  } = props;

  const history = useHistory();
  const subscriptionPlan = useSelector((state) => state.subscriptionPlan.plan);

  const [showUserDropdown, setShowUserDropdown] = useState(false);

  const toggleUserActionDropdown = () =>
    setShowUserDropdown((showUserDropdown) => !showUserDropdown);

  const showUpgradeModal = () => {
    openUpgradeModal();
    toggleUserActionDropdown();
  };
  const logoutUser = () => {
    clearUserData();
    history.push("/login");
    // logout()
    //   .then((msg) => {
    //     console.log("logout msg", msg);
    //     history.push("/login");
    //   })
    //   .catch((err) => console.error("logout error", err));
  };

  return (
    <div
      className="nav-container-2 h100"
      style={{
        width: isNavExpanded ? "200px" : "72px",
        filter:
          isNewSurveyModalOpen || isNewRequestModalOpen ? "blur(16px)" : "none",
      }}
      onMouseOver={toggleNavExpand}
      onMouseOut={toggleNavExpand}
    >
      <div className="nav-2">
        <div className="nav-section-2">
          <div className="row fill spaced align-bottom">
            <Link
              to="/"
              className="brand-2 w-inline-block hidden-link-text"
              style={{
                display: `${isNavExpanded ? "block" : "none"}`,
                opacity: "1",
              }}
            >
              brand-2
            </Link>
            <Link
              to="/"
              className="brand-2 small-brand w-inline-block hidden-link-text"
              style={{
                display: `${isNavExpanded ? "none" : "block"}`,
                opacity: "1",
              }}
            >
              brand-2 (small)
            </Link>
            <div className="div-block-4" style={{ display: "none" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-left icon purple"
                src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                alt=""
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>
            </div>
          </div>
          <div className="div-block-3">
            {subscriptionPlan.isListenActive ? (
              <Link
                to="/dashboard/listen"
                className="nav-link-2 with-icon"
                style={{
                  color: `${isNavExpanded ? "rgba(74, 85, 104)" : "rgba(0, 0, 0, 0)"
                    }`,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-radio icon move-left purple"
                  style={{
                    opacity: `${isNavExpanded ? "0.6" : "1"}`,
                    transformStyle: "preserve-3d",
                    transform: `scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) ${isNavExpanded
                      ? "translate3d(0px, 0px, 0px)"
                      : "translate3d(8px, 0px, 0px)"
                      }`,
                  }}
                >
                  <circle cx="12" cy="12" r="2"></circle>
                  <path d="M16.24 7.76a6 6 0 0 1 0 8.49m-8.48-.01a6 6 0 0 1 0-8.49m11.31-2.82a10 10 0 0 1 0 14.14m-14.14 0a10 10 0 0 1 0-14.14"></path>
                </svg>{" "}

                Listen
              </Link>
            ) : (
                ""
              )}

            {subscriptionPlan.isAskActive ? (
              <Link
                to="/dashboard/ask"
                className="nav-link-2 with-icon"
                style={{
                  color: `${isNavExpanded ? "rgba(74, 85, 104)" : "rgba(0, 0, 0, 0)"
                    }`,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-message-circle icon move-left purple"
                  style={{
                    opacity: `${isNavExpanded ? "0.6" : "1"}`,
                    transformStyle: "preserve-3d",
                    transform: `scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) ${isNavExpanded
                      ? "translate3d(0px, 0px, 0px)"
                      : "translate3d(8px, 0px, 0px)"
                      }`,
                  }}
                >
                  <path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path>
                </svg>
                Ask
              </Link>
            ) : (
                ""
              )}

            <Link
              to="/dashboard/interact"
              className="nav-link-2 with-icon"
              style={{
                color: `${isNavExpanded ? "rgba(74, 85, 104)" : "rgba(0, 0, 0, 0)"
                  }`,
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-repeat icon move-left purple"
                data-w-id="558b6506-cc60-ba97-ed28-06170665940f"
                style={{
                  opacity: `${isNavExpanded ? "0.6" : "1"}`,
                  transformStyle: "preserve-3d",
                  transform: `scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) ${isNavExpanded
                    ? "translate3d(0px, 0px, 0px)"
                    : "translate3d(8px, 0px, 0px)"
                    }`,
                }}
              >
                <polyline points="17 1 21 5 17 9"></polyline>
                <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                <polyline points="7 23 3 19 7 15"></polyline>
                <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
              </svg>
              Interact
            </Link>

            {subscriptionPlan.isCompareActive ? (
              <Link
                to="/dashboard/compare"
                className="nav-link-2 with-icon"
                style={{
                  color: `${isNavExpanded ? "rgba(74, 85, 104)" : "rgba(0, 0, 0, 0)"
                    }`,
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-message-circle icon move-left purple"
                  style={{
                    opacity: `${isNavExpanded ? "0.6" : "1"}`,
                    transformStyle: "preserve-3d",
                    transform: `scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg) ${isNavExpanded
                      ? "translate3d(0px, 0px, 0px)"
                      : "translate3d(8px, 0px, 0px)"
                      }`,
                  }}
                >
                  <line x1="18" y1="20" x2="18" y2="10"></line>
                  <line x1="6" y1="20" x2="6" y2="14"></line>
                  <line x1="12" y1="20" x2="12" y2="4"></line>
                </svg>
                Compare
              </Link>
            ) : (
                ""
              )}
          </div>
        </div>

        <div className="nav-section-2">
          <div className="profile">
            <div className="dropdown user-actions">
              {/* Temporarily supply client for backwards compatibility */}
              <Avatar user={user} team={team} client={client} />
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-chevron-down icon nudge-right purple"
                src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                alt=""
                style={{
                  display: `${isNavExpanded ? "block" : "none"}`,
                  opacity: `${isNavExpanded ? "0.6" : "0"}`,
                }}
                onClick={toggleUserActionDropdown}
              >
                <polyline points="6 9 12 15 18 9"></polyline>
              </svg>
            
              <div
                className="dropdown-list dropdown-right small anchor-left"
                style={{
                  height: `${showUserDropdown ? "" : "0px"}`,
                  display: `${showUserDropdown ? "flex" : "none"}`,
                  width: `${showUserDropdown ? "120px" : ""}`,
                }}
              >
                <Link
                  to="/dashboard/account"
                  className="dropdown-link with-icon"
                  onClick={toggleUserActionDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-user icon small nudge-left"
                  >
                    <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path>
                    <circle cx="12" cy="7" r="4"></circle>
                  </svg>
                  Account
                </Link>
                <Link
                  to="/dashboard/settings"
                  className="dropdown-link with-icon"
                  onClick={toggleUserActionDropdown}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-settings icon small nudge-left"
                  >
                    <circle cx="12" cy="12" r="3"></circle>
                    <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"></path>
                  </svg>
                  Settings
                </Link>
                <span
                  onClick={showUpgradeModal}
                  className="dropdown-link with-icon"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-credit-card icon small nudge-left"
                  >
                    <rect
                      x="1"
                      y="4"
                      width="22"
                      height="16"
                      rx="2"
                      ry="2"
                    ></rect>
                    <line x1="1" y1="10" x2="23" y2="10"></line>
                  </svg>
                  Billing
                </span>
                <span onClick={logoutUser} className="dropdown-link with-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-log-out icon small nudge-left"
                  >
                    <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
                    <polyline points="16 17 21 12 16 7"></polyline>
                    <line x1="21" y1="12" x2="9" y2="12"></line>
                  </svg>
                  Log out
                </span>
              </div>
            </div>
          </div>
          <div className="divider push-up in-sidebar"></div>
          <div className="row center no-padding push-up spaced bottom-fill no-margin push-up">
            <div className="row center">
              <div
                className={`avatar ${client.logoURL ? "with-picture" : ""}`}
                style={{
                  backgroundImage: `${client.logoURL ? "url(" + client.logoURL + ")" : ""
                    }`,
                }}
              ></div>
              <div
                className="medium-text muted move-right"
                style={{
                  display: `${isNavExpanded ? "block" : "none"}`,
                  opacity: `${isNavExpanded ? "1" : "0"}`,
                }}
              >
                {client.organizationName}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Nav;
