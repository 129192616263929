import React, { Fragment, useEffect } from "react";
import { Link } from "react-router-dom";
import SurveyQuestions from "./SurveyList/SurveyQuestions";
import SurveyRespondents from "./SurveyList/SurveyRespondents";
import SurveyTimeVariable from "./SurveyList/SurveyTimeVariable";

export const AllSurveys = (props) => {
  const { client, setToast, allSurveysList, totalQuestionsAndRespondents } =
    props;

  useEffect(() => {
    // Start Hotjar tracking
    if (window.hj) {
      window.hj("trigger", "survey_creation");
      console.log("hotjar started");
    }

    // Clean up the effect
    return () => {};
  }, []);

  return (
    <Fragment>
      {allSurveysList.map((campaign) => (
        <div className="column _25" key={campaign.campaignRef}>
          <Link
            to={
              campaign.status === "draft"
                ? `/dashboard/ask/new-survey/${campaign.campaignRef}`
                : `/dashboard/ask/survey/${campaign.campaignRef}`
            }
            className="card-2 active w-inline-block"
          >
            <div
              className={`card-marker ${
                campaign.status === "draft"
                  ? "pink"
                  : campaign.status === "live"
                  ? "active"
                  : campaign.status === "finished"
                  ? "blue"
                  : "important"
              }`}
            ></div>
            <div className="card-section vertical padded">
              <div
                className={`chip ${
                  campaign.status === "draft"
                    ? "pink"
                    : campaign.status === "live"
                    ? "primary"
                    : campaign.status === "finished"
                    ? "blue"
                    : "important"
                }`}
              >
                {campaign.status}
              </div>
              <div className="data move-down">{campaign.campaignName}</div>
              <SurveyTimeVariable campaign={campaign} />
            </div>
            <div className="row">
              <div className="card-section expand padded">
                <SurveyQuestions
                  client={client}
                  setToast={setToast}
                  campaignRef={campaign.campaignRef}
                  totalQuestionsAndRespondents={totalQuestionsAndRespondents}
                />
                <SurveyRespondents
                  client={client}
                  setToast={setToast}
                  campaignRef={campaign.campaignRef}
                  numberOfRespondents={campaign.numberOfRespondents}
                  totalQuestionsAndRespondents={totalQuestionsAndRespondents}
                />
                <div className="bar-container">
                  <div className="measure-bar short">
                    <div
                      className="metric primary _0"
                      style={{ height: "7.99219px" }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row"></div>
          </Link>
        </div>
      ))}
    </Fragment>
  );
};
