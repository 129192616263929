import React, { useState } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

const Alerts = (props) => {
  const { setToast, client, team } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [alertOptions, setAlertOptions] = useState({
    criticalMentions: client.alertCriticalMentions,
    daily: client.alertDaily,
    weekly: client.alertWeekly,
    monthly: client.alertMonthly,
  });

  const [isUpdatingAlertOptions, setIsUpdatingAlertOptions] = useState(false);

  const handleChangeAlertOption = (option) => {
    setAlertOptions({ ...alertOptions, [option]: !alertOptions[option] });
  };

  const handleSubmitAlertOptions = () => {
    if (team && team.canEdit) {
      setIsUpdatingAlertOptions(true);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/versus_v2_update_alert_options`,
          {
            alertOptions,
            email: user.email,
            uid: user.uid,
            idToken,
          },
          { headers: { "content-type": "application/json" } }
        )
        .then(({ status, data }) => {
          setIsUpdatingAlertOptions(false);
          if (status === 200) {
            setToast({
              show: true,
              type: "success",
              message: data.message || "Successfully updated alert options",
            });
          }
        })
        .catch((error) => {
          setIsUpdatingAlertOptions(false);
          console.log("Error updating alert options", error.message);
          setToast({
            show: true,
            type: "warning",
            message:
              error.response.data.message ||
              "We had problems updating alert options",
          });
        });
    } else return;
  };
  /*
  useEffect(() => {
    if (client === undefined || client === null) return;

    if (client.alertOptions) {
      setAlertOptions({
        criticalMentions: client.alertOptions.criticalMentions,
        daily: client.alertOptions.daily,
        weekly: client.alertOptions.weekly,
        monthly: client.alertOptions.monthly,
      });
    }
  }, [client]);
  */

  return (
    <div className="card-2">
      <div className="card-section">
        <h3>Alerts and notifications</h3>
        {/* <div className="row center push-down">
          <div
            className={`toggle ${
              alertOptions.criticalMentions ? "on" : "off"
            } big`}
            style={{
              cursor: team
                ? team.canEdit
                  ? "pointer"
                  : "not-allowed"
                : "not-allowed",
            }}
            onClick={() =>
              team
                ? team.canEdit
                  ? handleChangeAlertOption("criticalMentions")
                  : null
                : null
            }
          >
            <div
              className={`toggle-switch ${
                alertOptions.criticalMentions ? "on" : "off"
              } big`}
            ></div>
          </div>
          <div className="move-right">
            Notify me of <strong>critical mentions</strong>
          </div>
        </div> */}
        <div className="row center push-down">
          <div
            className={`toggle ${alertOptions.daily ? "on" : "off"} big`}
            style={{
              cursor: team
                ? team.canEdit
                  ? "pointer"
                  : "not-allowed"
                : "not-allowed",
            }}
            onClick={() =>
              team
                ? team.canEdit
                  ? handleChangeAlertOption("daily")
                  : null
                : null
            }
          >
            <div
              className={`toggle-switch ${
                alertOptions.daily ? "on" : "off"
              } big`}
            ></div>
          </div>
          <div className="move-right">
            Email <strong>daily</strong> sentiment reports
          </div>
        </div>
        <div className="row center push-down">
          <div
            className={`toggle ${alertOptions.weekly ? "on" : "off"} big`}
            style={{
              cursor: team
                ? team.canEdit
                  ? "pointer"
                  : "not-allowed"
                : "not-allowed",
            }}
            onClick={() =>
              team
                ? team.canEdit
                  ? handleChangeAlertOption("weekly")
                  : null
                : null
            }
          >
            <div
              className={`toggle-switch ${
                alertOptions.weekly ? "on" : "off"
              } big`}
            ></div>
          </div>
          <div className="move-right">
            Email <strong>weekly</strong> sentiment reports
          </div>
        </div>
        <div className="row center push-down">
          <div
            className={`toggle ${alertOptions.monthly ? "on" : "off"} big`}
            style={{
              cursor: team
                ? team.canEdit
                  ? "pointer"
                  : "not-allowed"
                : "not-allowed",
            }}
            onClick={() =>
              team
                ? team.canEdit
                  ? handleChangeAlertOption("monthly")
                  : null
                : null
            }
          >
            <div
              className={`toggle-switch ${
                alertOptions.monthly ? "on" : "off"
              } big`}
            ></div>
          </div>
          <div className="move-right">
            Email <strong>monthly</strong> sentiment reports
          </div>
        </div>
        {isUpdatingAlertOptions ? (
          <span className="button is-disabled small primary w-button">
            Saving changes...
          </span>
        ) : (
          <span
            className="button small primary w-button"
            onClick={handleSubmitAlertOptions}
          >
            Save changes
          </span>
        )}
      </div>
    </div>
  );
};

export default Alerts;
