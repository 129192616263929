import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { getUser, getIdToken } from "../../utils/storage";

import UpgradeModal from "./UpgradeModal";
import MentionsLimits from "./MentionsLimits";

const MentionsSummary = (props) => {
  const subscriptionPlan = useSelector((state) => state.subscriptionPlan.plan);

  const {
    client,
    setToast,
    filterPeriod,
    filterSentiment,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
    sources, // array of mention sources selected for filtering
    setTooltip,
    tooltip,
  } = props; // lastWeek, lastMonth, today

  const user = getUser(),
    idToken = getIdToken();

  const [summary, setSummary] = useState({
    total: 0,
    positive: 0,
    negative: 0,
    neutral: 0,
    percentagePositive: 0,
    percentageNegative: 0,
    percentageNeutral: 0,
  });

  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const toggleShowUpgradeModal = () =>
    setShowUpgradeModal((showUpgradeModal) => !showUpgradeModal);

  const url = `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_mentions_summary`;
  useEffect(() => {
    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          sentiment: filterSentiment,
          country: filterCountry,
          language: filterLanguage,
          keyword_or_hashtag: filterKeywordOrHashtag,
          sources,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          setSummary({
            total: result.data.total,
            percentagePositive: result.data.percentagePositive || 0,
            percentageNegative: result.data.percentageNegative || 0,
            percentageNeutral: result.data.percentageNeutral || 0,
          });
        }
      })
      .catch((error) => {
        console.log("Error obtaining mentions summary", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems retrieving mentions summary. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    filterSentiment,
    idToken,
    setToast,
    sources,
    url,
    user.uid,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  ]);

  return (
    <div className="row has-cards move-down">
      <div
        style={{
          display: tooltip.step === 3 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content-3 left-up"
      >
        {/* step 3 tool tip */}
        <div className="card-section">
          <div>Versus Listen</div>
          <p className="move-down">Perform online social listening</p>

          <div className="inline-tooltip-frame">
            <span
              onClick={() =>
                setTooltip({
                  show: false,
                  type: "",
                  title: "",
                  message: "",
                  step: 0,
                  max_step: 7,
                })
              }
            >
              Skip tutorial
            </span>
            <button
              className="inline-tooltip-button"
              onClick={() =>
                setTooltip({
                  show: true,
                  type: "",
                  title: "",
                  message: "",
                  step: subscriptionPlan.isCompareActive ? 4 : 5,
                  max_step: 7,
                })
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <div className="column">
        <div className="card-2">
          <div className="row stretch">
            <div className="card-section horizontal padded">
              <div className="data huge">{summary.total}</div>
              <h3 className="move-down">Mentions</h3>

              <MentionsLimits
                client={client}
                showUpgradeModal={showUpgradeModal}
                toggleShowUpgradeModal={toggleShowUpgradeModal}
                tooltip={tooltip}
                setTooltip={setTooltip}
              />
            </div>
            {/* <div className="card-section padded horizontal">
              <div className="data huge">7,408</div>
              <h3 className="move-down">People reached</h3>
            </div> */}
            <div className="card-section-2">
              <div className="row">
                <div className="expand">
                  <div className="bar-container vertical nudge-down">
                    <div
                      className="measure-bar short"
                      style={{
                        opacity:
                          filterSentiment === "neutral sentiment" ||
                          filterSentiment === "negative sentiment"
                            ? 0.1
                            : 1,
                      }}
                    >
                      <div
                        style={{
                          width: `${summary.percentagePositive}%`,
                          height: "7.99219px",
                        }}
                        className="sentiment positive-2 single test-data"
                      ></div>
                    </div>
                    <div
                      className="measure-bar short move-down"
                      style={{
                        opacity:
                          filterSentiment === "neutral sentiment" ||
                          filterSentiment === "positive sentiment"
                            ? 0.1
                            : 1,
                      }}
                    >
                      <div
                        style={{
                          width: `${summary.percentageNegative}%`,
                          height: "7.99219px",
                        }}
                        className="sentiment negative single test-data"
                      ></div>
                    </div>
                    <div
                      className="measure-bar short move-down"
                      style={{
                        opacity:
                          filterSentiment === "positive sentiment" ||
                          filterSentiment === "negative sentiment"
                            ? 0.1
                            : 1,
                      }}
                    >
                      <div
                        style={{
                          width: `${summary.percentageNeutral}%`,
                          height: "7.99219px",
                        }}
                        className="sentiment neutral-2 single test-data"
                      ></div>
                    </div>
                  </div>
                  <h3 className="move-down">Sentiment of mentions</h3>
                </div>
                <div className="row move-right">
                  <div
                    className="row center push-left"
                    style={{
                      opacity:
                        filterSentiment === "neutral sentiment" ||
                        filterSentiment === "negative sentiment"
                          ? 0.1
                          : 1,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-smile icon large positive move-left"
                      src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                      alt=""
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M8 14s1.5 2 4 2 4-2 4-2"></path>
                      <line x1="9" y1="9" x2="9.01" y2="9"></line>
                      <line x1="15" y1="9" x2="15.01" y2="9"></line>
                    </svg>
                    <div>
                      <p className="data positive-2">
                        {summary.percentagePositive}%
                      </p>
                      <p className="small-text muted">Positive</p>
                    </div>
                  </div>
                  <div
                    className="row center push-left"
                    style={{
                      opacity:
                        filterSentiment === "neutral sentiment" ||
                        filterSentiment === "positive sentiment"
                          ? 0.1
                          : 1,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-frown icon large negative move-left"
                      src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                      alt=""
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <path d="M16 16s-1.5-2-4-2-4 2-4 2"></path>
                      <line x1="9" y1="9" x2="9.01" y2="9"></line>
                      <line x1="15" y1="9" x2="15.01" y2="9"></line>
                    </svg>
                    <div>
                      <p className="data negative">
                        {summary.percentageNegative}%
                      </p>
                      <p className="small-text muted">Negative</p>
                    </div>
                  </div>
                  <div
                    className="row center"
                    style={{
                      opacity:
                        filterSentiment === "positive sentiment" ||
                        filterSentiment === "negative sentiment"
                          ? 0.1
                          : 1,
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-meh icon large neutral move-left"
                      src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                      alt=""
                    >
                      <circle cx="12" cy="12" r="10"></circle>
                      <line x1="8" y1="15" x2="16" y2="15"></line>
                      <line x1="9" y1="9" x2="9.01" y2="9"></line>
                      <line x1="15" y1="9" x2="15.01" y2="9"></line>
                    </svg>
                    <div>
                      <p className="data">{summary.percentageNeutral}%</p>
                      <p className="small-text muted">Neutral</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeModal
        client={client}
        showUpgradeModal={showUpgradeModal}
        toggleShowUpgradeModal={toggleShowUpgradeModal}
        isOnboarding={false}
      />
    </div>
  );
};

export default MentionsSummary;
