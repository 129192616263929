import React, { useRef, useState } from "react";

const ResponseFilterModal = (props) => {
  const { campaignQuestions, setTargetFilterObject, showModal, setShowModal } =
    props;

  const formRef = useRef();
  const [activeQuestionRef, setActiveQuestionRef] = useState(null);
  const [activeQuestion, setActiveQuestion] = useState(null);
  const [activeQuestionResponseType, setActiveQuestionResponseType] =
    useState(null);
  const [activeOptionName, setActiveOptionName] = useState(null);
  const [Q_options, setQ_options] = useState([]);
  const [activeOption, setActiveOption] = useState(null);

  const handleSubmit = (e) => {
    e.preventDefault();

    setTargetFilterObject({
      targetQuestionRefToFilterBy: activeQuestionRef,
      targetResponseOptionToFilterBy: activeOption,
      targetQuestionToFilterBy: activeQuestion,
      targetResponseOptionNameToFilterBy: activeOptionName,
    });

    setShowModal(false);
  };

  const handleClearFilter = (e) => {
    e.preventDefault();
    setTargetFilterObject({
      targetQuestionRefToFilterBy: null,
      targetResponseOptionToFilterBy: null,
    });
    setActiveQuestionRef(null);
    setActiveQuestion(null);
    setActiveQuestionResponseType(null);
    setActiveOption(null);
    setActiveOptionName(null);
    formRef.current.reset();
    setShowModal(false);
  };

  const handleSelectQuestion = (e) => {
    let qRef = e.target.value;
    if (qRef) {
      let selectedQ = campaignQuestions.find((cp) => cp.questionRef == qRef);

      if (selectedQ) {
        if (Object.keys(selectedQ).length) {
          setActiveQuestionRef(qRef);
          setActiveQuestion(selectedQ.question);
          setActiveQuestionResponseType(selectedQ.response_type);
          setQ_options(selectedQ.options);
        }
      }
    }
  };

  const handleSelectOption = (e) => {
    // convert to number
    let _option = parseInt(e.target.value);
    if (_option) {
      let option_object = Q_options.find((o) => o.id == _option);
      setActiveOptionName(option_object.option);
      setActiveOption(_option);
    }
  };

  return (
    <div
      style={{ display: showModal ? "flex" : "none", zIndex: "999999999" }}
      className="modal"
    >
      <div className="modal-container">
        <h2 className="modal-text">Filter by Reponses</h2>
        <div
          data-w-id="6945843a-5459-9292-4e32-15406451650f"
          onClick={() => setShowModal(false)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close modal-text"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          style={{
            zIndex: "999999999",
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          className="modal-card"
        >
          <div className="modal-header"></div>
          <div className="modal-body">
            <div className="form-block" style={{ maxWidth: "100%" }}>
              <form
                ref={formRef}
                onSubmit={handleSubmit}
                id="email-form-3"
                name="email-form-3"
                data-name="Email Form 3"
                method="get"
                aria-label="Email Form 3"
              >
                <label htmlFor="Question">Question</label>
                <select
                  onChange={handleSelectQuestion}
                  id="Question"
                  name="Question"
                  data-name="Question"
                  className="input large w-select"
                >
                  <option value="Another option">Select a question</option>
                  {campaignQuestions.map((q, i) => (
                    <option key={i} value={q.questionRef}>
                      {" "}
                      {i + 1}. {q.question}?
                    </option>
                  ))}
                </select>
                <br />
                <label htmlFor="State">Response</label>
                <select
                  onChange={handleSelectOption}
                  id="State"
                  name="State"
                  data-name="State"
                  className="input large w-select"
                >
                  <option value="Default">Select an option</option>
                  {Q_options.map((q) => (
                    <option key={q.option} value={q.id}>
                      {activeQuestionResponseType === "freeform"
                        ? "Responses"
                        : q.option}
                    </option>
                  ))}
                </select>
                <label htmlFor="email" className="data hidden">
                  204
                </label>
                <label className="helper hidden">Helper text</label>
                <div className="row col-16">
                  <input
                    onClick={handleClearFilter}
                    defaultValue="Clear filter"
                    data-wait="Please wait..."
                    data-w-id="6945843a-5459-9292-4e32-154064516524"
                    className="button wide w-button"
                  />{" "}
                  &nbsp;
                  <input
                    type="submit"
                    value="Apply filter"
                    data-wait="Please wait..."
                    data-w-id="6945843a-5459-9292-4e32-154064516525"
                    className="button primary wide w-button"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseFilterModal;
