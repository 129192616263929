import React, { useState } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

import Cost from "./Cost";
import Respondents from "./Respondents";
// import DownloadAndShare from "../DownloadAndShare";
import FilteredRespondents from "./FilteredRespondents";

const RequestSidebar = (props) => {
  const {
    setToast,
    request,
    age,
    gender,
    tribe,
    education,
    religion,
    maritalStatus,
    employmentStatus,
    childrenInHousehold,
    personalMonthlyIncome,
    householdMonthlyIncome,
    peopleInHousehold,
    anyChildUnder18,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();
  const clientRef = getClient().clientRef;

  const [isEndingRequest, setIsEndingRequest] = useState(false);
  const [isChangingSurveyStatus, setIsChangingSurveyStatus] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState(request?.status);

  const handleToggleSurveyStatus = () => {
    setIsChangingSurveyStatus(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_toggle_survey_status`,
        {
          uid,
          idToken,
          clientRef,
          mediaType: "multimedia",
          campaignRef: request?.campaignRef,
        }
      )
      .then(({ data }) => {
        setSurveyStatus(data?.data?.status);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error Changing survey status. Please refresh",
        });
      })
      .finally(() => {
        setIsChangingSurveyStatus(false);
      });
  };

  const handleEndRequest = () => {
    setIsEndingRequest(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_end_multimedia_request`,
        {
          uid,
          idToken,
          clientRef,
          requestRef: request.campaignRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setIsEndingRequest(false);
        setToast({
          show: true,
          type: "success",
          message: "You have successfully ended this request",
        });
        console.log({ data });
        //  history.push(`/dashboard/interact`)
      })
      .catch((error) => {
        setIsEndingRequest(false);
        console.log("Error ending request:", error);
        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Fail. Something must have gone wrong",
        });
      });
  };

  if (!request) return "";
  return (
    <div className="side-spacer">
      <div className="side-bar feed padded">
        <div>
          <span
            className={`chip ${
              request.status !== "live" ? "blue" : ""
            } bold push-up`}
          >
            {surveyStatus?.toLowerCase() || request?.status}
          </span>
          {["live", "paused"].includes(request.status) ? (
            <span
              id="btn-survey"
              data-w-id="b69e4f6b-ec32-1c13-05ac-a389f1135f49"
              className={`button small subtle float-right no-margin w-button ${
                isChangingSurveyStatus ? "is-disabled" : ""
              } `}
              onClick={() => {
                if (!isChangingSurveyStatus) {
                  handleToggleSurveyStatus();
                }
              }}
            >
              {(surveyStatus?.toLowerCase() || request?.status) == "paused"
                ? "Resume survey"
                : "Pause survey"}
            </span>
          ) : (
            ""
          )}

          <Cost request={request} />

          <div className="cta-btns">
            {["live", "paused"].includes(request.status) ? (
              <div style={{ pointerEvents: isEndingRequest ? "none" : "all" }}>
                <span
                  id="survey-status"
                  style={{
                    backgroundColor: isEndingRequest ? "lightgray" : "",
                  }}
                  className="button primary wide w-button"
                  onClick={handleEndRequest}
                >
                  {`${isEndingRequest ? "Ending request..." : "End Request"}`}
                </span>
              </div>
            ) : (
              ""
            )}
            {/* <span id="survey-status" onClick={handleEndSurvey} className={`button primary wide w-button ${isEndingSurvey ? "is-disabled" : ""}`}>End survey</span> */}
          </div>
        </div>
        <div className="divider in-sidebar"></div>
        <div>
          <div className="row">
            {request.mediaType === "video" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-video icon move-left"
                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                loading="lazy"
                alt=""
              >
                <polygon points="23 7 16 12 23 17 23 7"></polygon>
                <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
              </svg>
            ) : request.mediaType === "photo" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-camera icon move-left"
                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                loading="lazy"
                alt=""
              >
                <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                <circle cx="12" cy="13" r="4"></circle>
              </svg>
            ) : request.mediaType === "audio" ? (
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-mic icon move-left"
                src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                loading="lazy"
                alt=""
              >
                <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                <line x1="12" y1="19" x2="12" y2="23"></line>
                <line x1="8" y1="23" x2="16" y2="23"></line>
              </svg>
            ) : (
              ""
            )}
          </div>
          <h3 className="move-down">Media type</h3>
        </div>
        <div className="divider in-sidebar"></div>
        <Respondents request={request} />
        <div className="divider in-sidebar"></div>
        <FilteredRespondents
          request={request}
          age={age}
          gender={gender}
          tribe={tribe}
          education={education}
          religion={religion}
          maritalStatus={maritalStatus}
          employmentStatus={employmentStatus}
          childrenInHousehold={childrenInHousehold}
          personalMonthlyIncome={personalMonthlyIncome}
          householdMonthlyIncome={householdMonthlyIncome}
          peopleInHousehold={peopleInHousehold}
          anyChildUnder18={anyChildUnder18}
        />
        <div className="divider in-sidebar"></div>
        {/* <div>
          <p v-if="!filters.length" className="helper">
            Files will be deleted after <strong>30 days</strong>, please
            download them before then
          </p>
          <DownloadAndShare
            requestRef={request.requestRef}
            setToast={setToast}
          />
        </div> */}
        {/* <div className="divider in-sidebar"></div>
        <a
          data-w-id="288428c4-7c21-8fac-c4be-1e675e5464c6"
          href="#"
          className="button no-margin move-down wide w-button"
        >
          Request input from Versus insights team
        </a>
        <p className="helper">
          The team will review your data and draw up key insights based on their
          findings
        </p> */}
      </div>
    </div>
  );
};

export default RequestSidebar;
