import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setActiveFilter } from "../../store/surveyFilters";

const SurveysFilterTabs = () => {
  const filterOptions = ["All", "Draft", "Live", "Finished"];
  const dispatch = useDispatch();
  const activeFilter = useSelector((state) => state.surveyFilters.activeFilter);

  const handleFilters = (e, option, index) => {
    e.stopPropagation();
    dispatch(setActiveFilter({ index, filter: option }));
  };
  return (
    <div className="askFiltersTabs">
      {filterOptions.map((option, index) => (
        <button
          className={index === activeFilter.index ? "active-border-bottom" : ""}
          key={index}
          onClick={(e) => handleFilters(e, option, index)}
        >
          {option}
        </button>
      ))}
    </div>
  );
};

export default SurveysFilterTabs;
