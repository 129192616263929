import React, { useState } from "react";
import { Link } from "react-router-dom";

import { sendPasswordResetEmail } from "../../services/auth";

const ForgotPassword = (props) => {
  const { setToast } = props;

  const [email, setEmail] = useState("");

  const [
    isSendingPasswordResetEmail,
    setIsSendingPasswordResetEmail,
  ] = useState(false);

  const handleResetPassword = () => {
    setIsSendingPasswordResetEmail(true);

    sendPasswordResetEmail(email)
      .then((msg) => {
        setIsSendingPasswordResetEmail(false);

        console.log(msg);
        setToast({
          show: true,
          type: "success",
          message: msg || "Successfully sent password reset email",
        });
      })
      .catch((error) => {
        setIsSendingPasswordResetEmail(false);

        console.log("Error sending password reset email", error);
        setToast({
          show: true,
          type: "warning",
          message: error.message || "Error sending pasword reset email",
        });
      });
    console.log("forgot password submit", email);
  };

  return (
    <div>
      <p className="with-icon positive bottom-breather">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-lock icon push-left"
        >
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>{" "}
        We use 128-bit encryption. Your details are safe.
      </p>
      <div className="form-block w-form">
        <div>
          <div className="control">
            <label>Email</label>
            <input
              type="text"
              className="input large w-input"
              maxLength="256"
              name="email"
              onChange={(e) => setEmail(e.target.value)}
              required=""
            />
          </div>

          {email === "" ? (
            <button
              className="button primary large wide w-button is-disabled"
              disabled
            >
              Reset
            </button>
          ) : isSendingPasswordResetEmail ? (
            <button
              className="button primary large wide w-button is-disabled"
              disabled
            >
              Sending password reset email...
            </button>
          ) : (
            <button
              className="button primary large wide w-button"
              onClick={handleResetPassword}
            >
              Reset
            </button>
          )}
          <div>
            Already have an account? <Link to="/login">Log in.</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
