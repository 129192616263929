import React, { useState, useEffect } from "react";
import axios from "axios";

import { getClient, getUser, getIdToken } from "../../../../utils/storage";

const Respondents = (props) => {
  const { request } = props;

  const [actualRespondents, setActualRespondents] = useState(0);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_request_respondents`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
          requestRef: request.campaignRef ?? "",
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setActualRespondents(data.totalNumberOfRequestRespondents);
      })
      .catch((error) => {
        console.log("Error getting client requests", error);
      });
  }, []);

  return (
    <>
      <div className="row spaced baseline">
        <h3 className="no-margin push-down nudge-up">Respondents</h3>
        <h3 className="no-margin push-down nudge-up">
          <strong className="data small">{actualRespondents}</strong> /{" "}
          {request.numberOfRespondents}
        </h3>
      </div>
      <div className="bar-container">
        <div className="measure-bar short">
          <div
            className={`metric ${
              request.status === "live" ? "primary" : "blue"
            }`}
            style={{
              height: "7.98828px",
              width: `${Math.floor(
                (actualRespondents / request.numberOfRespondents) * 100
              )}%`,
            }}
          ></div>
        </div>
      </div>
    </>
  );
};

export default Respondents;
