import React, { useEffect, useState } from "react";

import DataMeasureBar from "./DataMeasureBar";

const Mentions = (props) => {
  const {
    mentionsData,
    highestMention,
    brand1,
    brand2,
    brand3,
    brand4,
  } = props;

  if (mentionsData.length <= 0) {
    return <div></div>;
  } else {
    return (
      <div className="card-section horizontal padded expand">
        <h3>Mentions</h3>

        {brand1 ? (
          <DataMeasureBar
            brand={brand1}
            highestMention={highestMention}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand1.compareDbRef
            )}
          />
        ) : (
          ""
        )}
        {brand2 ? (
          <DataMeasureBar
            brand={brand2}
            highestMention={highestMention}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand2.compareDbRef
            )}
          />
        ) : (
          ""
        )}
        {brand3 ? (
          <DataMeasureBar
            brand={brand3}
            highestMention={highestMention}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand3.compareDbRef
            )}
          />
        ) : (
          ""
        )}
        {brand4 ? (
          <DataMeasureBar
            brand={brand4}
            highestMention={highestMention}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand4.compareDbRef
            )}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default Mentions;
