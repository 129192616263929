const saveUser = (user) => localStorage.setItem("user", JSON.stringify(user));

const saveIdToken = (idToken) => localStorage.setItem("idToken", idToken);

const getUser = () => JSON.parse(localStorage.getItem("user"));

const getIdToken = () => localStorage.getItem("idToken");

const saveClient = (client) =>
  localStorage.setItem("client", JSON.stringify(client));

const getClient = () => JSON.parse(localStorage.getItem("client"));

const clearUserData = () => localStorage.clear();

export {
  saveUser,
  getUser,
  saveClient,
  getClient,
  clearUserData,
  saveIdToken,
  getIdToken,
};
