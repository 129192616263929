import React, { useState, useEffect } from "react";
import { RequiredField } from "./shared";

const PersonalDetails = (props) => {
  const {
    client,
    handleAddPersonalDetails,
    subscriptionPlan,
    isSavingPersonalDetails,
    onboardingData,
  } = props;

  const [personalDetailsData, setPersonalDetailsData] = useState({
    officeAddress: onboardingData?.officeAddress || client?.officeAddress || "",
    responseEmails:
      onboardingData?.responseEmails || client?.responseEmails || "",
  });

  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] =
    useState(false);

  const handleAreRequiredFieldsCompleted = () => {
    if (
      subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_ASK_STARTER_PLAN ||
      subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_LISTEN_STARTER_PLAN
    ) {
      setAreRequiredFieldsCompleted(true);
      // if (personalDetailsData?.password === "") {
      //   setAreRequiredFieldsCompleted(false);
      // } else {
      //   setAreRequiredFieldsCompleted(true);
      // }
    } else {
      // Subscription plan is neither ASK_STARTER_PLAN nor LISTEN_STARTER_PLAN
      if (
        personalDetailsData?.officeAddress === "" ||
        personalDetailsData?.responseEmails === ""
      ) {
        setAreRequiredFieldsCompleted(false);
      } else setAreRequiredFieldsCompleted(true);
    }
  };

  const handleChangePersonalDetailsData = (e) => {
    // e.preventDefault();
    setPersonalDetailsData({
      ...personalDetailsData,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    handleAreRequiredFieldsCompleted();
  }, [personalDetailsData]);

  return (
    <div className="form-block w-form">
      <div className="control">
        <label>
          First name
          {/* <RequiredField /> */}
        </label>
        <input
          value={client.firstName}
          type="text"
          className="input large w-input"
          maxLength="256"
          disabled="disabled"
        />
      </div>
      <div className="control">
        <label>
          Last name
          {/* <RequiredField /> */}
        </label>
        <input
          value={client.lastName}
          type="text"
          className="input large w-input"
          maxLength="256"
          disabled="disabled"
        />
      </div>
      <div className="control">
        <label>
          Email
          {/* <RequiredField /> */}
        </label>
        <input
          value={client.email}
          type="text"
          className="input large w-input"
          maxLength="256"
          disabled="disabled"
        />
      </div>

      {subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_ASK_STARTER_PLAN ||
      subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_LISTEN_STARTER_PLAN ? (
        ""
      ) : (
        <>
          <div className="divider spacer"></div>
          <div className="control">
            <label>
              Organization name
              {/* <RequiredField /> */}
            </label>
            <input
              value={client.organizationName}
              type="text"
              className="input large w-input"
              maxLength="256"
              disabled="disabled"
            />
          </div>
          <div className="control">
            <label>
              Office address
              <RequiredField />
            </label>
            <input
              name="officeAddress"
              type="text"
              className="input large w-input"
              maxLength="256"
              value={personalDetailsData?.officeAddress}
              onChange={handleChangePersonalDetailsData}
            />
          </div>
          <div className="divider spacer"></div>
          <div className="control">
            <label>
              Response emails
              <RequiredField />
            </label>
            <textarea
              id="field-6"
              name="responseEmails"
              placeholder="pr@acme.org, brand-comms@acme.org"
              maxLength="5000"
              className="input textarea w-input"
              value={personalDetailsData?.responseEmails}
              onChange={handleChangePersonalDetailsData}
            ></textarea>
            <label className="helper">
              The emails of team members that would respond to mentions.
              Separate by comma.
            </label>
          </div>
        </>
      )}

      <div className="divider spacer"></div>
      <div style={{ textAlign: "center" }} className="muted">
        By clicking <span className="main-color">"Next"</span>, you agree to the{" "}
        <br />
        <a href="https://versus.ng/terms">Terms and conditions.</a>
      </div>
      {isSavingPersonalDetails ? (
        <span className="button is-disabled large wide w-button">
          Saving account details...
        </span>
      ) : areRequiredFieldsCompleted ? (
        <span
          onClick={() =>
            handleAddPersonalDetails({
              responseEmails: personalDetailsData.responseEmails,
              officeAddress: personalDetailsData.officeAddress,
            })
          }
          className="button primary large wide w-button"
        >
          Next
        </span>
      ) : (
        <span className="button is-disabled large wide w-button">Next</span>
      )}
      {/* <span
        onClick={() =>
          handleAddPersonalDetails({
            password: PersonalDetailsData.password,
            responseEmails: PersonalDetailsData.responseEmails,
            officeAddress: PersonalDetailsData.officeAddress,
          })
        }
        className="button primary large wide w-button"
      >
        Save account details
      </span> */}
    </div>
  );
};

export default PersonalDetails;
