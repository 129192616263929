import React, { useState, useEffect } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import { Link } from "react-router-dom";

import { signup, getIdToken } from "../../services/auth";
import { saveUser, saveIdToken, saveClient } from "../../utils/storage";

const Signup = (props) => {
  const { setToast } = props;

  const [signupData, setSignupData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });
  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] =
    useState(false);
  // eslint-disable-next-line no-unused-vars

  const [isCreatingClient, setIsCreatingClient] = useState(false);
  const [isCreatedClient, setIsCreatedClient] = useState(false);
  // const [hasPaidForSubscription, setHasPaidForSubscription] = useState(false);
  // const [isOnboarded, setIsOnboarded] = useState(false);
  // const [onboardingToken, setOnboardingToken] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleAreRequiredFieldsCompleted = () => {
    const unfilledVals = Object.values(signupData).filter(
      (data) => data === ""
    );
    if (unfilledVals.length <= 0) {
      setAreRequiredFieldsCompleted(true);
    }
  };
  const handleChange = (event) => {
    // event.preventDefault();

    setSignupData({ ...signupData, [event.target.name]: event.target.value });
  };

  async function addSMEClient(clientData) {
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_add_sme_client`,
        clientData,
        { headers: { "content-type": "application/json" } }
      );
      setIsCreatingClient(false);
      const client = result.data.client;
      saveClient(client);
      // setOnboardingToken(client.onboardingToken);

      if (result.status === 200) {
        setIsCreatedClient(true);
      } else {
        //pass to the catch block
        setToast({
          show: true,
          type: "danger",
          message: "Failed to add new client",
        });
      }
    } catch (error) {
      setIsCreatingClient(false);
      console.log("Error adding new client", error);
      setToast({
        show: true,
        type: "danger",
        message: "Failed to add new client",
      });
    }
  }

  function handleFirebaseSignup(email, password) {
    signup(email, password)
      .then((result) => {
        const user = result.user;
        saveUser(user);
        getIdToken()
          .then(async (idToken) => {
            // console.log('user after idtoken', user);
            saveIdToken(idToken);
            const { password, confirmPassword, ...clientData } = signupData;
            await addSMEClient(clientData);
          })
          .catch((error) => {
            console.log("error obtaining idToken", error);
            setToast({
              show: true,
              type: "danger",
              message:
                error.message || "There were problems getting user idToken",
            });
          });
      })
      .catch((error) => {
        setIsCreatingClient(false);
        console.log("Error creating new user account", error);
        setToast({
          show: true,
          type: "danger",
          message:
            error.code && error.code === "auth/email-already-in-use"
              ? error.message
              : "Failed to create new user account",
        });
      });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsCreatingClient(true);
    // signup user
    handleFirebaseSignup(signupData.email, signupData.password);
  };

  useEffect(() => {
    handleAreRequiredFieldsCompleted();
  });

  if (isCreatedClient) {
    return <Redirect to="/subscribe" />;
  }

  // if (hasPaidForSubscription) {
  //   return <Redirect to={`/onboarding?token=${onboardingToken}`} />;
  // }

  // if (isOnboarded) {
  //   return <Redirect to="/dashboard/listen" />;
  // }

  return (
    <div className="sign-up" style={{ padding: "0px" }}>
      <div>
        <div className="form-block w-form">
          <form onSubmit={handleSubmit}>
            <div className="control throw-down">
              <label>First name</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="firstName"
                onChange={handleChange}
                required
              />
            </div>
            <div className="control">
              <label>Last name</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="lastName"
                onChange={handleChange}
                required
              />
            </div>
            <div className="control">
              <label>Email</label>
              <input
                type="email"
                className="input large w-input"
                maxLength="256"
                name="email"
                onChange={handleChange}
                required
              />
            </div>
            <div className="control">
              <label>Password</label>
              <input
                type={showPassword ? "text" : "password"}
                className="input large w-input"
                maxLength="256"
                name="password"
                onChange={handleChange}
                required
              />
              {!showPassword && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="feather feather-eye icon in-input"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              )}

              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-eye icon in-input"
                src="https://assets.website-files.com/img/image-placeholder.svg"
                alt=""
                onClick={() => setShowPassword(!showPassword)}
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              <label className="helper">
                No less than 8 letters. Pro tip: make it a long funny phrase.
              </label>
            </div>
            <div className="control">
              <label>Confirm Password</label>
              <input
                type={showConfirmPassword ? "text" : "password"}
                className="input large w-input"
                maxLength="256"
                name="confirmPassword"
                onChange={handleChange}
                required
              />
              {!showConfirmPassword && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="feather feather-eye icon in-input"
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88"
                  />
                </svg>
              )}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-eye icon in-input"
                src="https://assets.website-files.com/img/image-placeholder.svg"
                alt=""
                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
              >
                <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                <circle cx="12" cy="12" r="3"></circle>
              </svg>
              {signupData.confirmPassword.trim().length > 0 &&
                signupData.password !== signupData.confirmPassword && (
                  <label style={{ color: "red" }} className="helper">
                    Passwords do not match.
                  </label>
                )}
            </div>

            {isCreatingClient ? (
              <button className="button is-disabled large wide w-button">
                Creating client...
              </button>
            ) : !areRequiredFieldsCompleted ||
              signupData.password !== signupData.confirmPassword ? (
              <button
                className="button is-disabled large wide w-button"
                disabled
              >
                Sign up now
              </button>
            ) : (
              <button
                className="button primary large wide w-button"
                onClick={handleSubmit}
              >
                Sign up now
              </button>
            )}
          </form>
          <div style={{ textAlign: "center" }}>
            Already have an account? <Link to="/login">Log in.</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
