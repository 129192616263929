import React, { useEffect } from "react";

const SubscribeCancel = (props) => {
  useEffect(() => {
    // Start Hotjar tracking
    if (window.hj) {
      window.hj("trigger", "subscribe-cancel");
    }

    // Clean up the effect
    return () => {};
  }, []);
  return (
    <div className="sign-up">
      <img
        src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
        height="32"
        srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
        sizes="102px"
        alt=""
        className="bottom-breather big-breath"
      />

      <h1 className="bold with-icon important bottom-breather">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-alert-triangle icon push-left"
        >
          <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
          <line x1="12" y1="9" x2="12" y2="13"></line>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>
        Payment cancelled!
      </h1>
      <h2 className="">Sorry we could not confirm your payment</h2>
      <div>
        <a href="/subscribe" className="button large w-button">
          Go Back
        </a>
      </div>
    </div>
  );
};

export default SubscribeCancel;
