import React from "react";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";

dayjs.extend(relativeTime);

const AllMentions = (props) => {
  const { newsfeed, handleMentionModal } = props;

  return (
    <div>
      <div className="row center throw-down spaced">
        <h3 className="card-title move-up with-icon no-margin">All mentions</h3>
      </div>
      {newsfeed.map((feed, i) => (
        <div className="psuedo-card move-down" key={i}>
          <div
            className="row"
            onClick={() =>
              handleMentionModal({
                isOpen: true,
                showMention: true,
                mention: feed,
              })
            }
          >
            <div
              className={`marker small nudge-down move-left ${
                feed.sentiment_class === 0
                  ? "negative"
                  : feed.sentiment_class === 2
                  ? "positive-2"
                  : ""
              }`}
            ></div>
            <p className="medium-text medium-weight">
              {feed.sourceType === "news" || feed.sourceType === "others"
                ? feed.title
                : feed.sourceType === "twitter"
                ? feed.text
                : feed.message}
              <br />
            </p>
          </div>
          <div className="row center spaced push-right">
            {feed.sourceType === "news" || feed.sourceType === "others" ? (
              <div className="row center no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-file-text icon small nudge-left"
                  src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                  alt=""
                >
                  <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
                  <polyline points="14 2 14 8 20 8"></polyline>
                  <line x1="16" y1="13" x2="8" y2="13"></line>
                  <line x1="16" y1="17" x2="8" y2="17"></line>
                  <polyline points="10 9 9 9 8 9"></polyline>
                </svg>
                <div className="small-text muted">{feed.source}</div>
              </div>
            ) : feed.sourceType === "twitter" ? (
              <div className="row center no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-twitter icon small nudge-left"
                  src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                  alt=""
                >
                  <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                </svg>
                <div className="small-text muted">{feed.user.name}</div>
              </div>
            ) : feed.sourceType === "facebook" ? (
              <div className="row center no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-facebook icon small nudge-left"
                >
                  <path d="M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z"></path>
                </svg>
                <div className="small-text muted">{feed.from.name}</div>
              </div>
            ) : (
              <div className="row center no-padding">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  width="24"
                  height="24"
                  stroke="currentColor"
                  strokeWidth="2"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="icon small nudge-left"
                >
                  <rect x="2" y="2" width="20" height="20" rx="5" ry="5"></rect>
                  <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                  <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                </svg>
              </div>
            )}
            <div className="small-text muted">
              {dayjs().to(dayjs(feed.created_at.value))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllMentions;
