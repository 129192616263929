import React, { useState } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import { uploadLogo } from "../../services/storage";

const OrganizationInfo = (props) => {
  const { client, setToast } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [logoURL, setLogoURL] = useState(client.logoURL);
  const [logoFile, setLogoFile] = useState(null);

  const handleChangeLogoFile = (e) => {
    setLogoFile(e.target.files[0]);
  };

  const handleSaveLogo = (e) => {
    setToast({
      show: true,
      type: "processing",
      message: "Updating client logo...",
    });

    uploadLogo(client.brandName, client.clientRef, logoFile)
      .then((snapshot) => {
        snapshot.ref
          .getDownloadURL()
          .then((url) => {
            console.log("successfully uploaded logo", url);
            setLogoURL(url);
            // Add url to client data
            // const clientData = { ...client, logoURL: url };
            axios
              .post(
                `${process.env.REACT_APP_API_BASE_URL}/versus_v2_edit_client`,
                {
                  idToken,
                  uid: user.uid,
                  email: user.email,
                  clientData: { logoURL: url },
                },
                { headers: { "content-type": "application/json" } }
              )
              .then((result) => {
                if (result.status === 200) {
                  console.log("Updated client logo");
                  setToast({
                    show: true,
                    type: "success",
                    message: "Updated client logo",
                  });
                }
              })
              .catch((error) => {
                console.log("Error updating client logo", error);
                setToast({
                  show: true,
                  type: "warning",
                  message:
                    error.response.data.message ||
                    "Had trouble updating client logo. Please try again",
                });
              });
          })
          .catch((error) => console.log("Error getting logo url", error));
      })
      .catch((error) => {
        console.log("Error uploading logo", error);
      });
  };

  return (
    <div className="card-2">
      <div className="card-section">
        <h3 className="push-up">Organization info</h3>
        <div className="control row">
          <div
            className={`avatar ${
              client.logoURL ? "with-picture" : "alpha"
            } large`}
            style={{
              backgroundImage: `${
                client.logoURL ? "url(" + logoURL + ")" : ""
              }`,
            }}
          ></div>
          <div className="move-right vertical align-left">
            <div>Logo</div>
            <div className="meta">240kb maximum size</div>
            <label htmlFor="logo-input">
              <span
                className="button small subtle with-icon w-button"
                // style={{ cursor: "not-allowed" }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-upload icon small nudge-left"
                >
                  <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                  <polyline points="17 8 12 3 7 8"></polyline>
                  <line x1="12" y1="3" x2="12" y2="15"></line>
                </svg>{" "}
                Upload logo
              </span>
            </label>
            <input
              type="file"
              id="logo-input"
              style={{ display: "none" }}
              onChange={handleChangeLogoFile}
            />
          </div>
        </div>
        <div className="form-block push-down fill w-form">
          <form>
            <div className="row">
              <div className="control move-left">
                <label htmlFor="organization-name">Organization name</label>
                <input
                  type="text"
                  id="organization-name"
                  className="input-2 w-input"
                  maxLength="256"
                  defaultValue={client.organizationName}
                  disabled
                />
              </div>
              <div className="control">
                <label htmlFor="organization-address">
                  Organization address
                </label>
                <input
                  type="text"
                  id="oranization-address"
                  className="input-2 w-input"
                  maxLength="256"
                  defaultValue={client.officeAddress}
                  disabled
                />
              </div>
            </div>
          </form>
          <span
            className="button small primary w-button"
            onClick={handleSaveLogo}
          >
            Save changes
          </span>
        </div>
      </div>
    </div>
  );
};

export default OrganizationInfo;
