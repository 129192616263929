import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import Promoters from "./TopInfluencers/Promoters";
import Detractors from "./TopInfluencers/Detractors";

const TopInfluencers = (props) => {
  const {
    filterPeriod,
    client,
    setToast,
    filterSentiment,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [topInfluencers, setTopInfluencers] = useState({
    promoters: [],
    detractors: [],
  });

  const url = `${
    client.clientRef === "12a3c24d-8782-4e40-bc1f-c8abb8e431c7"
      ? "https://us-central1-versus-prod.cloudfunctions.net/versus_v2_prod_get_top_influencers"
      : process.env.REACT_APP_API_BASE_URL + "/versus_v2_get_top_influencers"
  }`;

  useEffect(() => {
    // Do not fetch top influencers for neutral sentiment
    if (filterSentiment === "neutral sentiment") {
      return;
    }

    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          country: filterCountry,
          language: filterLanguage,
          keyword_or_hashtag: filterKeywordOrHashtag,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const topInfluencers = result.data.topInfluencers;
          setTopInfluencers({
            promoters: topInfluencers.positive,
            detractors: topInfluencers.negative,
          });
        }
      })
      .catch((error) => {
        console.log("Error obtaining mentions summary", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving top influencers. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    filterSentiment,
    idToken,
    setToast,
    url,
    user.uid,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  ]);

  return (
    <div className="row has-cards">
      <div className="column">
        <div className="card-2">
          <div className="card-section padded-x">
            <h3>Top influencers talking about you</h3>
            <div
              className="row push-down"
              style={{ height: "500px", overflow: "auto" }}
            >
              <Promoters
                promoters={topInfluencers.promoters}
                sentiment={filterSentiment}
              />
              <Detractors
                detractors={topInfluencers.detractors}
                sentiment={filterSentiment}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopInfluencers;
