import React from "react";

import RequestsSummary from "./RequestsSummary";
import RespondentsSummary from "./RespondentsSummary";
import VersusCredits from "./VersusCredits";

const SummaryCards = (props) => {
  const {toggleNewRequestModalOpen, toggleShowMakeDepositDialog, tooltip, setTooltip } = props;

  return (
    <div className="row has-cards move-down">
      <div className="column">
        <div className="card-2">
          <div className="row stretch">
            <RequestsSummary />
            <RespondentsSummary />
          </div>
        </div>
      </div>
      <VersusCredits
        toggleShowMakeDepositDialog={toggleShowMakeDepositDialog} setTooltip={setTooltip} tooltip={tooltip} toggleNewRequestModalOpen={toggleNewRequestModalOpen}
      />

    </div>
  );
};

export default SummaryCards;
