import React from "react";

const ImagePlayer = (props) => {
  const { responseInView, toggleViewCarousel } = props;

  return (
    <div className="row has-cards wrap">
      {responseInView.mediaUrls.length > 0 &&
        responseInView.mediaUrls.map((urel, i) => (
          <div className="column _33" key={i}>
            <span
              className="w-inline-block w-lightbox"
              style={{ cursor: "pointer" }}
              onClick={toggleViewCarousel}
            >
              <img
                src={urel}
                loading="lazy"
                alt="Scout response"
                className="image-4"
              />
            </span>
          </div>
        ))}
    </div>
  );
};

export default ImagePlayer;
