import React, { useState, useEffect } from "react";
import Axios from "axios";

import { getIdToken, getUser } from "../../../utils/storage";

import TotalFilteredRespondents from "./SurveySideBar/TotalFilteredRespondents";
import TotalRespondents from "./SurveySideBar/TotalRespondents";
import TotalQuestionsAndCost from "./SurveySideBar/TotalQuestionsAndCost";
import DownloadAndShare from "../../Shared/DownloadAndShare";
import { useSelector } from "react-redux";
import CSVExport from "../../Shared/CSVExport";

const SurveySidebar = (props) => {
  const {
    //
    clientRef,
    campaign,
    setToast,
    age,
    states,
    targetFilterObject,
    gender,
    tribe,
    educationLevel,
    religion,
    relationshipStatus,
    employmentStatus,
    childrenInHousehold,
    personalEarningsMonthly,
    householdEarningsMonthly,
    personsInHousehold,
    childrenUnder18,
    setTooltip,
    tooltip,
  } = props;

  const subscriptionPlan = useSelector((state) => state.subscriptionPlan.plan);

  const uid = getUser().uid,
    idToken = getIdToken();

  const [totalRespondents, setTotalRespondents] = useState(0);

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_survey_respondents`,
      {
        uid,
        idToken,
        campaignRef: campaign.campaignRef,
      },
      { headers: { "content-type": "application/json" } }
    )
      .then(({ data, status }) => {
        setTotalRespondents(data.totalSurveyRespondents);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error fetching respondents. Please refresh",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="side-spacer">
      <div className="side-bar feed padded">
        <TotalQuestionsAndCost
          clientRef={clientRef}
          // totalRespondents={totalRespondents}
          totalRespondents={campaign.numberOfRespondents}
          setToast={setToast}
          campaign={campaign}
        />

        <div className="divider in-sidebar"></div>

        <TotalRespondents
          campaign={campaign}
          totalRespondents={totalRespondents}
          setToast={setToast}
        />

        <div className="divider in-sidebar"></div>

        <TotalFilteredRespondents
          clientRef={clientRef}
          campaignRef={campaign.campaignRef}
          setToast={setToast}
          age={age}
          states={states}
          targetFilterObject={targetFilterObject}
          gender={gender}
          tribe={tribe}
          educationLevel={educationLevel}
          religion={religion}
          relationshipStatus={relationshipStatus}
          employmentStatus={employmentStatus}
          childrenInHousehold={childrenInHousehold}
          personalEarningsMonthly={personalEarningsMonthly}
          householdEarningsMonthly={householdEarningsMonthly}
          personsInHousehold={personsInHousehold}
          childrenUnder18={childrenUnder18}
        />
        <div className="divider in-sidebar"></div>
        <div>
          <div>
            {subscriptionPlan.isExportShareReportsActive ? (
              <>
                <DownloadAndShare
                  clientRef={clientRef}
                  campaignRef={campaign.campaignRef}
                  tribe={tribe}
                  age={age}
                  path={"versus_v2_get_survey_report"}
                  reportName="SurveyReport"
                  buttonStyle="wide"
                  setTooltip={setTooltip}
                  tooltip={tooltip}
                />
                <CSVExport
                  clientRef={clientRef}
                  campaignRef={campaign.campaignRef}
                  path={"versus_v2_get_survey_report_as_csv"}
                  reportName="SurveyReport"
                  buttonStyle="wide"
                />
              </>
            ) : (
              ""
            )}
            {/* <span
              style={{ cursor: "not-allowed" }}
              className="button no-margin wide with-icon"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-mail icon move-left"
              >
                <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
                <polyline points="22,6 12,13 2,6"></polyline>
              </svg>{" "}
              Share with team
                </span> */}
          </div>
        </div>
        {/* <div className="divider in-sidebar"></div>
        <span
          style={{ cursor: "not-allowed" }}
          className="button no-margin move-down wide w-button"
        >
          Request input from Versus insights team
            </span>
        <p className="helper">
          The team will review your data and draw up key insights based on
          their findings
            </p> */}
      </div>
    </div>
  );
};

export default SurveySidebar;
