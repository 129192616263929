import React, { useState, useEffect } from "react";

const SurveyRespondents = (props) => {
  const { campaignRef, numberOfRespondents, totalQuestionsAndRespondents } =
    props;

  const [totalRespondents, setTotalRespondents] = useState(0);

  useEffect(() => {
    const campaignData = totalQuestionsAndRespondents.find(
      (entry) => entry.campaignRef === campaignRef
    );
    if (campaignData) {
      setTotalRespondents(campaignData.totalRespondents);
    }
  }, [campaignRef, totalQuestionsAndRespondents]);

  return (
    <div className="row spaced baseline">
      <h3 className="no-margin push-down nudge-up">Respondents</h3>
      <h3 className="no-margin push-down nudge-up">
        <strong className="data small">{totalRespondents}</strong> /{" "}
        {numberOfRespondents}
      </h3>
    </div>
  );
};

export default SurveyRespondents;
