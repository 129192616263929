import React, { useEffect, useState } from "react";
import Modal from "../../Shared/Modal";

const AddVideoLinkModal = ({
  showModal,
  setShowModal,
  newQuestionData,
  setNewQuestionData,
}) => {
  const type = "video";
  const [vUrl, setVUrl] = useState(
    newQuestionData?.media_type == type ? newQuestionData?.media_link : ""
  );
  const [videoLinkUrl, setVideoLinkURL] = useState(
    newQuestionData?.media_type == type ? newQuestionData?.media_link : ""
  );
  const [linkNotValid, setLinkNotValid] = useState(false);

  let delay = 1000 * 3; // sec

  const handleAddVideoLink = (_url) => {
    let url = _url;
    try {
      new URL(url);
      if (/youtube/.test(url)) {
        url = url.replace("watch?v=", "embed/");
      } else if (/vimeo/.test(url)) {
        if (!/player.vimeo/.test(url)) {
          const urlArray = url.split("/");
          const videoId = urlArray[urlArray.length - 1];
          url = `https://player.vimeo.com/video/${videoId}`;
        }
      }
      setVideoLinkURL(url);
      setLinkNotValid(false);
    } catch (error) {
      setLinkNotValid(true);
    }
  };

  const handleSaveVideoLink = (e) => {
    e.preventDefault();
    if (videoLinkUrl) {
      setNewQuestionData({
        ...newQuestionData,
        media_link: videoLinkUrl,
        media_type: type,
      });
      setShowModal(false);
    }
  };

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      handleAddVideoLink(vUrl);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [vUrl]);

  return (
    <Modal
      title=""
      openModal={showModal}
      onCloseModal={() => setShowModal(false)}
    >
      <div className="no-margin w-form">
        <form
          onSubmit={handleSaveVideoLink}
          id="email-form-3"
          name="email-form-3"
          data-name="Email Form 3"
          method="get"
          data-wf-page-id="63f7bbc5acfc212cfc492c62"
          data-wf-element-id="c09d24bd-e76d-e7f8-8eba-dff71e106aa1"
          aria-label="Email Form 3"
        >
          <label htmlFor="name-8">Source</label>
          <div className="w-layout-grid grid-6">
            <input
              type="text"
              name="vUrl"
              value={vUrl}
              onChange={(e) => setVUrl(e.target.value)}
              className="input large w-node-c09d24bd-e76d-e7f8-8eba-dff71e106aa5-1e106a96 w-input"
              maxlength="256"
              data-name="videoLinkUrl"
              placeholder="e.g https://youtu.be/INRnca..."
              id="name-6"
            />
          </div>
          <div
            className="w-form-fail"
            style={{ display: linkNotValid ? "block" : "none" }}
            tabIndex="-1"
            role="region"
            aria-label="Email Form 3 failure"
          >
            <div>Video link not valid.</div>
          </div>
          <label htmlFor="" className="helper">
            Upload a custom video to an online video streaming site -{" "}
            <strong>YouTube</strong> or <strong>Vimeo</strong> and use the video
            URL for embedding video in the survey.
          </label>
          {videoLinkUrl && (
            <>
              <label htmlFor="email" className="push-down">
                Preview
              </label>
              <div
                style={{ paddingTop: "56.17021276595745%" }}
                className="w-video w-embed"
              >
                <iframe
                  className="embedly-embed"
                  src={`${videoLinkUrl}`}
                  width="940"
                  height="528"
                  title="YouTube embed"
                  allow="autoplay; fullscreen; encrypted-media; picture-in-picture;"
                  allowFullScreen={true}
                ></iframe>
              </div>
            </>
          )}
          <input
            type="submit"
            style={{
              cursor: linkNotValid || !videoLinkUrl ? "not-allowed" : "pointer",
            }}
            disabled={linkNotValid || !videoLinkUrl ? true : false}
            value={"Save changes"}
            data-wait="Please wait..."
            data-w-id="c09d24bd-e76d-e7f8-8eba-dff71e106ab1"
            className={`button primary wide w-button ${
              linkNotValid || !videoLinkUrl ? "subtle" : ""
            }`}
          />
        </form>
      </div>
    </Modal>
  );
};

export default AddVideoLinkModal;
