import React, { PureComponent } from "react";
import Chart from "chart.js";

let thisLineChart;

export default class SentimentLineChart extends PureComponent {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    let blue = myChartRef.createLinearGradient(0, 0, 0, 200);
    blue.addColorStop(0, "#bac8ff");
    blue.addColorStop(1, "#dbe4ff");

    let red = myChartRef.createLinearGradient(0, 0, 0, 200);
    red.addColorStop(0, "#ffa8a8");
    red.addColorStop(1, "#ffe3e3");

    if (typeof thisLineChart !== "undefined") thisLineChart.destroy();

    thisLineChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: "Negative",
            borderColor: "#fa5252",
            lineTension: 0,
            backgroundColor: red,
            pointBackgroundColor: "#fa5252",
            pointBorderColor: "#fa5252",
            pointHoverBackgroundColor: "#ffc9c9",
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#ffc9c9",
            data:
              this.props.sentiment === "positive sentiment" ||
              this.props.sentiment === "neutral sentiment"
                ? null
                : this.props.negativeSentimentData,
          },
          {
            label: "Positive",
            borderColor: "#4c6ef5",
            lineTension: 0,
            backgroundColor: blue,
            pointBackgroundColor: "#4c6ef5",
            pointBorderColor: "#4c6ef5",
            pointHoverBackgroundColor: "#bac8ff",
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#bac8ff",
            data:
              this.props.sentiment === "negative sentiment" ||
              this.props.sentiment === "neutral sentiment"
                ? null
                : this.props.positiveSentimentData,
          },
          {
            label: "Neutral",
            borderColor: "#f8f9fa",
            lineTension: 0,
            backgroundColor: "#f8f9fa",
            pointBackgroundColor: "#f8f9fa",
            pointBorderColor: "#f8f9fa",
            pointHoverBackgroundColor: "#f8f9fa",
            borderWidth: 0,
            pointRadius: 0,
            pointHoverRadius: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#f8f9fa",
            data:
              this.props.sentiment === "positive sentiment" ||
              this.props.sentiment === "negative sentiment"
                ? null
                : this.props.neutralSentimentData,
          },
        ],
      },
      options: {
        tooltips: {
          backgroundColor: "#212529",
          mode: "index",
          intersect: false,
          titleFontFamily: "sans-serif",
          titleFontStyle: "500",
          bodyFontFamily: "sans-serif",
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              stacked: true,
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 3,
                padding: 8,
                min: 0,
                max: this.max, // Your absolute max value
                callback: function (value) {
                  return ((value / this.max) * 100).toFixed(0) + "%"; // convert it to percentage
                },
              },
              gridLines: {
                drawBorder: false,
                color: "#dee2e6",
                borderDash: [8, 8],
                borderWidth: 1,
                lineWidth: 1,
                zeroLineColor: "transparent",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontFamily: "proxima nova",
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
              },
              gridLines: {
                drawBorder: false,
                display: false,
                color: "#dee2e6",
                borderDash: [8, 8],
                borderWidth: 1,
                lineWidth: 1,
              },
            },
          ],
        },
      },
    });
  };

  render() {
    return (
      <div>
        <div className="chart-bg-2">
          <div className="html-embed sentiment-chart w-embed">
            <canvas
              id="sentimentArea"
              width="940"
              height="180"
              ref={this.chartRef}
            ></canvas>
          </div>
        </div>
      </div>
    );
  }
}
