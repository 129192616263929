import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

import InnerLoader from "../../../InnerLoader";

const RequestsSummary = (props) => {
  const [numberOfRequests, setNumberOfRequests] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_requests`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setIsLoading(false);
        setNumberOfRequests(data.totalNumberOfRequests);
      })
      .catch((error) => {
        console.log("Error getting total requests", error);
      });
  }, []);

  return (
    <div className="card-section horizontal padded expand">
      {isLoading ? (
        <InnerLoader />
      ) : (
        <>
          <div className="data huge">{numberOfRequests}</div>
          <h3 className="move-down">Requests</h3>
        </>
      )}
    </div>
  );
};

export default RequestsSummary;
