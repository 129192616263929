import React from "react";
import DummyButton from "../../DummyButton";

const CampaignTask = ({
  activeStep,
  gotoSurveyStep,
  newRequestData,
  handleNewRequestData,
}) => {
  const { link, category, instructions } = newRequestData;

  return (
    <div
      data-w-id="8bcb77d7-29eb-f02d-0dc0-d11a8e3a308b"
      className="step-1b fill-2 w-form"
      style={{
        display: activeStep === "campaignTask" ? "block" : "none",
        opacity: activeStep === "campaignTask" ? 1 : 0,
        height: activeStep === "campaignTask" ? "100%" : "0px",
        width: "600px",
      }}
    >
      <label htmlFor="name-7" className="field-label large">
        Campaign{" "}
      </label>
      <div className="div-block-29">
        <label htmlFor="field-6" className="field-label">
          Category
        </label>
        <select
          id="category"
          name="category"
          data-name="category"
          className="input large w-select"
          onChange={handleNewRequestData}
        >
          <option value="">Select a category...</option>
          <option value="social_task">Social task</option>
          <option value="custom_task">Custom task</option>
        </select>
        <label htmlFor="name-7" className="field-label">
          Link
        </label>
        <input
          type="text"
          className="input large w-input"
          maxLength="256"
          name="link"
          data-name="link"
          placeholder=""
          id="link"
          onChange={handleNewRequestData}
        />
        <label htmlFor="name-7" className="field-label">
          Instructions
        </label>
        <div className="w-layout-grid grid-12">
          <textarea
            id="instructions"
            name="instructions"
            maxLength="5000"
            data-name="instructions"
            placeholder=""
            className="input large textarea fill w-node-_8bcb77d7-29eb-f02d-0dc0-d11a8e3a309f-fd21f6e8 w-input"
            onChange={handleNewRequestData}
          ></textarea>
        </div>

        {link && category && instructions ? (
          <button
            onClick={() => gotoSurveyStep("two")}
            className="button primary medium large w-button"
          >
            Next
          </button>
        ) : (
          <DummyButton
            text="&nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;"
            extraClass="large w-button"
          />
        )}
      </div>
    </div>
  );
};

export default CampaignTask;
