import React, { useState, useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";

import { getUser, getIdToken } from "../../utils/storage";

const TeamMembers = (props) => {
  const { client, setToast, team } = props;
  const user = getUser(),
    idToken = getIdToken();
  const subscriptionPlan = useSelector((s) => s.subscriptionPlan.plan);

  const [teamMembers, setTeamMembers] = useState([]);

  const [newTeamMemberEmail, setNewTeamMemberEmail] = useState("");
  const [isNewTeamMemberEmailValid, setIsNewTeamMemberEmailValid] =
    useState(false);
  const [isInvitingNewTeamMember, setIsInvitingNewTeamMember] = useState(false);

  const [responseEmails, setResponseEmails] = useState(client.responseEmails);
  const [isChangingResponseEmails, setIsChangingResponseEmails] =
    useState(false);
  const [isResendingInvite, setIsResendingInvite] = useState(false);
  const [memberRemoved, setMemberRemoved] = useState("");

  const handleCanEdit = (teamRef) => {
    setToast({
      show: true,
      type: "processing",
      message: "Updating team member edit privilege...",
    });
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_change_can_edit_privilege`,
        {
          teamRef,
          email: user.email,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ status, data }) => {
        if (status === 200) {
          // update team member privilege
          setTeamMembers(
            teamMembers.map((member) => {
              if (member.teamRef === teamRef) {
                // toggle the current privilege
                return { ...member, canEdit: !member.canEdit };
              }
              return member;
            })
          );
          setToast({
            show: true,
            type: "success",
            message: "Successfully updated team member privilege",
          });
        }
      })
      .catch((error) => {
        console.log("Error removing team member", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.message ||
            error.response.data.message ||
            "We had problems updating team member privilege",
        });
      });
  };

  const handleSubmitResponseEmails = () => {
    setIsChangingResponseEmails(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_change_response_emails`,
        {
          responseEmails,
          email: user.email,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ status, data }) => {
        setIsChangingResponseEmails(false);
        if (status === 200) {
          setToast({
            show: true,
            type: "success",
            message: data.message || "Successfully changed response emails",
          });
        }
      })
      .catch((error) => {
        setIsChangingResponseEmails(false);
        console.log("Error changing response emails", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.response.data.message ||
            "We had problems changing response emails",
        });
      });
  };

  const handleInviteNewTeamMember = () => {
    setIsInvitingNewTeamMember(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_invite_new_team_member`,
        {
          newTeamMemberEmail,
          email: user.email,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ status, data }) => {
        setIsInvitingNewTeamMember(false);
        if (status === 200) {
          setToast({
            show: true,
            type: "success",
            message: data.message || "Successfully added new team member",
          });
          setTeamMembers((prev) => [...prev, data.newTeamMember]);
        }
      })
      .catch((error) => {
        setIsInvitingNewTeamMember(false);
        console.log("Error adding new team member", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.response.data.message ||
            "We had problems adding new team member",
        });
      });
  };

  const handleRemoveTeamMember = (teamRef) => {
    setMemberRemoved(teamRef);
    setToast({
      show: true,
      type: "processing",
      message: "Removing team member...",
    });
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_remove_team_member`,
        {
          teamRef,
          email: user.email,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ status, data }) => {
        setMemberRemoved("");
        if (status === 200) {
          setToast({
            show: true,
            type: "success",
            message: data.message || "Successfully removed team member",
          });
          // Remove removed team member
          const newTeamMembers = teamMembers.filter(
            (member) => member.teamRef !== data.teamRef
          );
          setTeamMembers(newTeamMembers);
        }
      })
      .catch((error) => {
        setMemberRemoved("");
        console.log("Error removing team member", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.response.data.message ||
            "We had problems removing team member",
        });
      });
  };

  const handleResendInvite = (newTeamMemberEmail) => {
    setIsResendingInvite(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_resend_team_member_invite`,
        {
          newTeamMemberEmail,
          email: user.email,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ status, data }) => {
        setIsResendingInvite(false);
        if (status === 200) {
          setToast({
            show: true,
            type: "success",
            message:
              data.message || "Successfully resent new team member invite",
          });
        }
      })
      .catch((error) => {
        setIsResendingInvite(false);
        console.log("Error resending new team member invite", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.response.data.message ||
            "We had problems resending new team member invite",
        });
      });
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_team_members`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          setTeamMembers(result.data.teamMembers);
        }
      })
      .catch((error) => {
        console.log("Error obtaining team members", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving your team members",
        });
      });
  }, [client.clientRef, idToken, setToast, user.uid]);

  useEffect(() => {
    // Validate new team member email
    isEmailValid(newTeamMemberEmail)
      ? setIsNewTeamMemberEmailValid(true)
      : setIsNewTeamMemberEmailValid(false);
  }, [newTeamMemberEmail]);

  return (
    <div className="card-2">
      <div className="card-section">
        <h3 className="push-up">Team members</h3>
        <div className="form-block push-down fill">
          <div>
            {subscriptionPlan.isTeamMembersActive ? (
              <div className="control">
                <label>Invite team member</label>
                <div className="row">
                  <input
                    type="text"
                    className="input grouped w-input"
                    maxLength="256"
                    disabled={team ? (team.canEdit ? false : true) : true}
                    onChange={(e) => setNewTeamMemberEmail(e.target.value)}
                  />
                  {!isNewTeamMemberEmailValid ? (
                    <span className="button is-disabled small grouped group-end no-margin w-button">
                      Invite
                    </span>
                  ) : !isInvitingNewTeamMember ? (
                    <span
                      className="button small grouped group-end no-margin w-button"
                      onClick={handleInviteNewTeamMember}
                    >
                      Invite
                    </span>
                  ) : (
                    <span className="button is-disabled small grouped group-end no-margin w-button">
                      Sending invite...
                    </span>
                  )}
                </div>
                <label className="helper">
                  Add via email. Ex: john@acme.com
                </label>
              </div>
            ) : (
              ""
            )}
            <div className="table">
              <div className="table-body">
                {teamMembers.map((member) => (
                  <div className="table-row comfy" key={member.id}>
                    <div className="table-body-cell _40">
                      <div className="avatar">
                        {member.email.charAt(0).toUpperCase()}
                      </div>
                      <div className="push-right">
                        <div className="main">{member.email}</div>
                      </div>
                    </div>
                    <div className="table-body-cell">
                      {member.status === "pending" ? (
                        <div className="chip">Pending</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="table-body-cell">
                      {member.status === "pending" ? (
                        isResendingInvite ? (
                          <span
                            className="button subtle small is-disabled"
                            // style={{ cursor: "not-allowed" }}
                          >
                            Resending...
                          </span>
                        ) : (
                          <span
                            className="button subtle small"
                            // style={{ cursor: "not-allowed" }}
                            onClick={() => handleResendInvite(member.email)}
                          >
                            Resend invite
                          </span>
                        )
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="table-body-cell">
                      <div className="meta push-left">Can edit</div>
                      {member.email === user.email ? (
                        <div
                          className={`toggle big ${
                            member.canEdit ? "on" : "off"
                          }`}
                          style={{
                            cursor: "not-allowed",
                          }}
                        >
                          <div
                            className={`toggle-switch big ${
                              member.canEdit ? "on" : "off"
                            }`}
                          ></div>
                        </div>
                      ) : (
                        <div
                          className={`toggle big ${
                            member.canEdit ? "on" : "off"
                          }`}
                          style={{
                            cursor: team
                              ? team.isAdmin
                                ? "pointer"
                                : "not-allowed"
                              : "not-allowed",
                          }}
                          onClick={() => handleCanEdit(member.teamRef)}
                        >
                          <div
                            className={`toggle-switch big ${
                              member.canEdit ? "on" : "off"
                            }`}
                          ></div>
                        </div>
                      )}
                    </div>
                    <div className="table-body-cell right-align">
                      {team ? (
                        memberRemoved === member.teamRef ? (
                          <span className="button small subtle with-icon w-button is-disabled">
                            ...
                          </span>
                        ) : team.isAdmin ? (
                          member.email === user.email ? (
                            <span
                              className="button small subtle with-icon w-button"
                              style={{ cursor: "not-allowed" }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash-2 icon small nudge-right"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </span>
                          ) : (
                            <span
                              className="button small subtle with-icon w-button"
                              onClick={() =>
                                handleRemoveTeamMember(member.teamRef)
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="currentColor"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                className="feather feather-trash-2 icon small nudge-right"
                              >
                                <polyline points="3 6 5 6 21 6"></polyline>
                                <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                                <line x1="10" y1="11" x2="10" y2="17"></line>
                                <line x1="14" y1="11" x2="14" y2="17"></line>
                              </svg>
                            </span>
                          )
                        ) : (
                          <span
                            className="button small subtle with-icon w-button"
                            style={{
                              cursor: team
                                ? team.isAdmin
                                  ? "pointer"
                                  : "not-allowed"
                                : "not-allowed",
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              stroke="currentColor"
                              strokeWidth="2"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              className="feather feather-trash-2 icon small nudge-right"
                            >
                              <polyline points="3 6 5 6 21 6"></polyline>
                              <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                              <line x1="10" y1="11" x2="10" y2="17"></line>
                              <line x1="14" y1="11" x2="14" y2="17"></line>
                            </svg>
                          </span>
                        )
                      ) : (
                        <span
                          className="button small subtle with-icon w-button"
                          style={{
                            cursor: team
                              ? team.isAdmin
                                ? "pointer"
                                : "not-allowed"
                              : "not-allowed",
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-trash-2 icon small nudge-right"
                          >
                            <polyline points="3 6 5 6 21 6"></polyline>
                            <path d="M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2"></path>
                            <line x1="10" y1="11" x2="10" y2="17"></line>
                            <line x1="14" y1="11" x2="14" y2="17"></line>
                          </svg>
                        </span>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        {subscriptionPlan.isResponseEmailsActive ? (
          <>
            <div className="divider spacer"></div>
            <div className="w-form">
              <div>
                <div className="control">
                  <label>Response emails</label>
                  <textarea
                    defaultValue={client.responseEmails}
                    maxLength="5000"
                    className="input textarea fill"
                    onChange={(e) => setResponseEmails(e.target.value)}
                    disabled={team ? (team.canEdit ? false : true) : true}
                  ></textarea>
                  <label className="helper">
                    The emails of team members that would respond to mentions.
                    Separate by comma.
                  </label>
                </div>
              </div>
            </div>
            {isChangingResponseEmails ? (
              <span className="button is-disabled small primary w-button">
                Saving changes...
              </span>
            ) : (
              <span
                className="button small primary w-button"
                onClick={handleSubmitResponseEmails}
              >
                Save changes
              </span>
            )}
          </>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default TeamMembers;

const isEmailValid = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
