import React from "react";

const VideoPlayer = (props) => {
  const { responseInView } = props;

  return (
    <div
      style={{ paddingTop: "56.17021276595745%" }}
      className="w-video w-embed"
    >
      <iframe
        className="embedly-embed"
        src={responseInView.mediaUrls[0]}
        width="940"
        height="528"
        scrolling="no"
        title="Video response"
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen={true}
      ></iframe>
    </div>
  );
};

export default VideoPlayer;
