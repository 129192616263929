import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import OrganizationInfo from "./OrganizationInfo";
import TeamMembers from "./TeamMembers";
import SearchTerms from "./SearchTerms";
import ComparisonBrands from "./ComparisonBrands";
import Alerts from "./Alerts";
import FullWidthLoader from "../FullWidthLoader";
import { useSelector } from "react-redux";

const Settings = (props) => {
  const { setToast } = props;

  const user = getUser(),
    idToken = getIdToken();
  const history = useHistory();
  const subscriptionPlan = useSelector((s) => s.subscriptionPlan.plan);

  const [client, setClient] = useState(null);
  const [team, setTeam] = useState(null);
  const [fbIntegration, setFbIntegration] = useState(null);
  const [igIntegration, setIgIntegration] = useState(null);
  const [isFbDisconnecting, setIsFbDisconnecting] = useState(false);
  const [isIgDisconnecting, setIsIgDisconnecting] = useState(false);

  const handleFBDisconnect = async () => {
    setIsFbDisconnecting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_disconnect_fb_ig_mentions`,
        {
          clientRef: client.clientRef,
          serviceName: "facebook",
          idToken,
          uid: user.uid,
        },
        { headers: { "content-type": "application/json" } }
      );

      if (response.status === 200) {
        setIsFbDisconnecting(false);
        setFbIntegration({ ...fbIntegration, isFbPageConnected: false });
        setToast({
          show: true,
          type: "success",
          message: "Successfully disconnected Facebook",
        });
      }
    } catch (error) {
      setIsFbDisconnecting(false);
      console.log("Error disconnecting Facebook page", error);
      setToast({
        show: true,
        type: "warning",
        message: "Facebook page disconnection failed",
      });
    }
  };

  const handleIGDisconnect = async () => {
    setIsIgDisconnecting(true);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_disconnect_fb_ig_mentions`,
        {
          clientRef: client.clientRef,
          serviceName: "instagram",
          idToken,
          uid: user.uid,
        },
        { headers: { "content-type": "application/json" } }
      );

      if (response.status === 200) {
        setIsIgDisconnecting(false);
        setIgIntegration({ ...igIntegration, isIgAccountConnected: false });
      }
    } catch (error) {
      setIsIgDisconnecting(false);
      console.log("Error disconnecting Instagram account", error);
      setToast({
        show: true,
        type: "warning",
        message: "Instagram account disconnection failed",
      });
    }
  };

  useEffect(() => {
    if (client) return;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client`,
        {
          uid: user.uid,
          email: user.email,
          idToken,
        },
        { "Content-type": "Application/json" }
      )
      .then(({ status, data }) => {
        if (status === 200) {
          setClient(data.client);
          setTeam(data.team);
          setFbIntegration(data.fbIntegration);
          setIgIntegration(data.igIntegration);
        }
      })
      .catch((error) => {
        console.log("Error getting client team", error);

        setToast({
          show: true,
          type: "warning",
          message: "Had some hiccups along the way. Mind refreshing?",
        });

        history.push("/login");
      });
  }, [client, history, idToken, setToast, user.email, user.uid]);

  if (!client) return <FullWidthLoader />;

  return (
    <div className="content medium">
      <div className="row center">
        <h1 className="no-margin push-left">
          <span className="muted">Settings</span>
        </h1>
      </div>
      <div className="row has-cards push-down"></div>
      <div className="row has-cards push-down">
        <div className="column">
          <OrganizationInfo client={client} setToast={setToast} />
          <TeamMembers client={client} setToast={setToast} team={team} />
          {subscriptionPlan.isListenActive ? (
            <SearchTerms
              client={client}
              setToast={setToast}
              team={team}
              fbIntegration={fbIntegration}
              igIntegration={igIntegration}
              handleFBDisconnect={handleFBDisconnect}
              handleIGDisconnect={handleIGDisconnect}
              isFbDisconnecting={isFbDisconnecting}
              isIgDisconnecting={isIgDisconnecting}
            />
          ) : (
            ""
          )}
          {subscriptionPlan.isCompareActive ? (
            <ComparisonBrands client={client} setToast={setToast} team={team} />
          ) : (
            ""
          )}
          {subscriptionPlan.isAlertsAndNotificationsActive ? (
            <Alerts client={client} setToast={setToast} team={team} />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};

export default Settings;
