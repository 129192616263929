import { useEffect, useState } from "react"


const UseDropDown = (el = null, initialState) => {

    const [isActive, setIsActive] = useState(initialState);
    const [activeMenu, setActiveMenu] = useState(null);

    useEffect(() => {
        const onClick = (e) => {
            // If the active element exists and is clicked outside of the active element
            if (el.current !== null && !el.current.parentElement.contains(e.target)) {
                setIsActive(!isActive);
                setActiveMenu(null);
            }
        }
        // If the item is active (ie open) then listen for clicks outside
        if (isActive) {
            window.addEventListener("click", onClick);
        }
        // cleanUp
        return () => {
            // remove listen when the component is close/is not in view
            window.removeEventListener('click', onClick)
        }
    }, [isActive, el])

    return [isActive, setIsActive, activeMenu, setActiveMenu]
}


export default UseDropDown;
