import React from "react";

const SurveyTimeVariable = (props) => {
  const { campaign } = props;

  const formattedDateTime = (date) => {
    const dateTime = new Date(date);
    return dateTime.toLocaleString("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  };

  const dateTimeVariable1 = () => {
    if (["draft", "live"].includes(campaign?.status?.toLowerCase())) {
      return (
        <>
          <h5 class="no-margin">Created: </h5> &nbsp;&nbsp;
          <h5 class="no-margin move-down">
            {formattedDateTime(campaign?.created_at)}
          </h5>
        </>
      );
    } else if (
      ["finished", "stopped"].includes(campaign?.status?.toLowerCase())
    ) {
      return (
        <>
          <h5 class="no-margin">Started: </h5> &nbsp;&nbsp;
          <h5 class="no-margin move-down">
            {formattedDateTime(campaign?.startDate)}
          </h5>
        </>
      );
    }
  };

  const dateTimeVariable2 = () => {
    if (campaign?.status?.toLowerCase() == "live") {
      return (
        <>
          <h5 class="no-margin">Started: </h5> &nbsp;&nbsp;
          <h5 class="no-margin move-down">
            {formattedDateTime(campaign?.startDate)}
          </h5>
        </>
      );
    } else if (campaign?.status?.toLowerCase() == "draft") {
      return (
        <>
          <h5 class="no-margin">Modified: </h5> &nbsp;&nbsp;
          <h5 class="no-margin move-down">
            {formattedDateTime(campaign?.updated_at || campaign?.created_at)}
          </h5>
        </>
      );
    } else if (campaign?.status?.toLowerCase() == "finished") {
      return (
        <>
          <h5 class="no-margin">Ended: </h5> &nbsp;&nbsp;
          <h5 class="no-margin move-down">
            {formattedDateTime(campaign?.endDate)}
          </h5>
        </>
      );
    } else if (campaign?.status?.toLowerCase() == "stopped") {
      return (
        <>
          <h5 class="no-margin">Stopped: </h5> &nbsp;&nbsp;
          <h5 class="no-margin move-down">
            {formattedDateTime(campaign?.deactivated_at || campaign?.startDate)}
          </h5>
        </>
      );
    }
  };

  return (
    <div class="time-variables push-down">
      <div class="row spaced baseline">{dateTimeVariable1()}</div>
      <div class="row spaced baseline">{dateTimeVariable2()}</div>
    </div>
  );
};

export default SurveyTimeVariable;
