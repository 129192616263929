import axios from "axios";
import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";
import RangeSlider from "../RangeSlider/RangeSlider";

const EditSurveyModal = ({
  setToast,
  campaign = {},
  setCampaign = () => {},
  showEditSurveyModal,
  toggleShowEditSurveyModal,
  user,
  idToken,
  client,
}) => {
  const history = useHistory();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [interests, setInterests] = useState([]);

  const handleSliderChange = (values) => {
    setCampaign({ ...campaign, startAge: values[0], endAge: values[1] });
  };
  const [isValid, setIsValid] = useState(true);

  const validateLink = (e) => {
    try {
      if (e.target.name === "mediaLink") {
        if (e.target.value.length === 0) {
          setIsValid(true);
          setToast({ show: false, type: "", message: "" });
          return;
        }
        new URL(e.target.value);
        setIsValid(true);
        setToast({ show: false, type: "", message: "" });
      }
    } catch (error) {
      setIsValid(false);
      setToast({
        show: true,
        type: "warning",
        message: `Please enter a valid link`,
      });
    }
  };
  const handleEditSurvey = (e) => {
    // if (e.target.name === "numberOfRespondents") e.target.value = Math.ceil(e.target.value);
    if (e.target.name === "gender") {
      let { checked, value } = e.target;
      value = value.toLowerCase();
      let genders = campaign.gender.slice();
      if (checked) {
        if (!genders.includes(value)) {
          genders.push(value);
        }
      } else {
        if (genders.includes(value)) {
          genders = genders.filter((g) => g !== value);
        }
      }
      setCampaign({ ...campaign, gender: genders });
    } else if (
      e.target.name === "sports_fan" ||
      e.target.name === "soccer_fan"
    ) {
      let { checked } = e.target;
      setCampaign({ ...campaign, [e.target.name]: checked });
    } else if (e.target.name === "mediaLink") {
      validateLink(e);
      setCampaign({ ...campaign, [e.target.name]: e.target.value });
    } else {
      setCampaign({ ...campaign, [e.target.name]: e.target.value });
    }
  };
  const handleSubmitEditedSurvey = () => {
    setIsSubmitting(true);
    const editData = {
      ...campaign,
      uid: user.uid,
      idToken,
      clientRef: client.clientRef,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_edit_campaign`,
        editData,
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        setIsSubmitting(false);
        if (result.status === 200) {
          // Send toast message for successful edit
          setToast({
            show: true,
            type: "success",
            message: "Successfully updated campaign",
          });

          // Close edit survey modal
          toggleShowEditSurveyModal();

          history.push(`/dashboard/ask`);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        console.log("Error updating campaign", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.message ||
            "Something happened when we tried editing campaign. Please refresh",
        });
      });
  };
  useEffect(() => {
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_interests`, {
        uid: user.uid,
        idToken,
        clientRef: client.clientRef,
      })
      .then(({ status, data }) => {
        if (status === 200) {
          setInterests(data.interests);
        }
      })
      .catch((error) =>
        console.log("Error getting organization sectors", error)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="modal"
      style={{ display: showEditSurveyModal ? "flex" : "none", opacity: 1 }}
    >
      <div className="modal-container">
        <h2>Edit survey</h2>
        <div onClick={toggleShowEditSurveyModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-body">
            <div className="fill throw-down w-form">
              <div>
                <label htmlFor="campaignName" className="field-label">
                  Survey Name
                </label>
                <input
                  type="text"
                  maxLength="256"
                  name="campaignName"
                  className="input w-input"
                  value={campaign.campaignName}
                  onChange={handleEditSurvey}
                />
                <label htmlFor="description" className="field-label">
                  Survey Description
                </label>
                <p className="helper nudge-up">
                  Your respondents see this when they take the survey
                </p>
                <textarea
                  maxLength="5000"
                  name="description"
                  className="input textarea fill w-input"
                  value={campaign.description}
                  onChange={handleEditSurvey}
                ></textarea>
                <label htmlFor="campaign-description" className="field-label">
                  Add media(optional)
                </label>
                <p className="helper nudge-up">
                  A link to a YouTube or Vimeo video to include the content as
                  part of your survey
                </p>

                <input
                  type="text"
                  id="media-link"
                  value={campaign.mediaLink}
                  name="mediaLink"
                  maxLength="1000"
                  className="input large w-input"
                  onChange={handleEditSurvey}
                />
                <label htmlFor="name-8" className="field-label">
                  Target gender
                </label>
                <label className="w-checkbox move-down">
                  <input
                    type="checkbox"
                    value="Male"
                    name="gender"
                    className="w-checkbox-input checkbox"
                    onChange={handleEditSurvey}
                    defaultChecked={campaign.gender.includes("male")}
                  />
                  <span className="w-form-label">Male</span>
                </label>
                <label className="w-checkbox">
                  <input
                    type="checkbox"
                    value="Female"
                    name="gender"
                    className="w-checkbox-input checkbox"
                    onChange={handleEditSurvey}
                    defaultChecked={campaign.gender.includes("female")}
                  />
                  <span className="w-form-label">Female</span>
                </label>
                <label className="w-checkbox">
                  <input
                    type="checkbox"
                    value="Other"
                    name="gender"
                    className="w-checkbox-input checkbox"
                    onChange={handleEditSurvey}
                    defaultChecked={campaign.gender.includes("other")}
                  />
                  <span className="w-form-label">Non-binary</span>
                </label>
                {/* <select
                  name="gender"
                  className="input select large w-select"
                  value={campaign.gender}
                  onChange={handleEditSurvey}
                  multiple
                >
                  <option value="male">Male</option>
                  <option value="female">Female</option>
                  <option value="other">Non-binary</option>
                </select> */}

                <label htmlFor="name-4" className="field-label">
                  Target age range
                </label>
                <div className="row spaced push-down">
                  <h5>16</h5>
                  <h5>65</h5>
                </div>
                <div className="html-embed-2 w-embed">
                  <RangeSlider
                    handleSliderChange={handleSliderChange}
                    values={[
                      `${campaign.startAge ? campaign.startAge : "23"}`,
                      `${campaign.endAge ? campaign.endAge : "44"}`,
                    ]}
                  />
                </div>
                <label htmlFor="name-4" className="field-label">
                  Filter by scout’s industry/interest
                </label>
                <select
                  id="interest_id"
                  name="interest_id"
                  className="input select large w-select"
                  value={campaign.interest_id}
                  onChange={handleEditSurvey}
                >
                  <option value="">Select one...</option>
                  {interests.map((interest) => {
                    return (
                      <option key={interest.id} value={interest.id}>
                        {interest.name}
                      </option>
                    );
                  })}
                </select>
                <label htmlFor="name-4" className="field-label">
                  Sports Fan
                </label>
                <label className="w-checkbox move-down">
                  <input
                    type="checkbox"
                    value={campaign.sports_fan}
                    name="sports_fan"
                    className="w-checkbox-input checkbox"
                    onChange={handleEditSurvey}
                    defaultChecked={campaign.sports_fan === true}
                  />
                  <span className="w-form-label">Yes</span>
                </label>

                <label htmlFor="name-4" className="field-label">
                  Football/Soccer Fan
                </label>

                <label className="w-checkbox move-down">
                  <input
                    type="checkbox"
                    value={campaign.soccer_fan}
                    name="soccer_fan"
                    className="w-checkbox-input checkbox"
                    onChange={handleEditSurvey}
                    defaultChecked={campaign.soccer_fan === true}
                  />
                  <span className="w-form-label">Yes</span>
                </label>

                <label htmlFor="name-4" className="field-label">
                  Football Team
                </label>
                <select
                  id="favorite_team_id"
                  name="favorite_team_id"
                  className="input select large w-select"
                  onChange={handleEditSurvey}
                  value={campaign.favorite_team_id}
                >
                  <option value="">Select one...</option>
                  <option value="1">Chelsea F.C</option>
                  <option value="2">Liverpool F.C</option>
                  <option value="3">Tottenham F.C</option>
                  <option value="4">Arsenal F.C</option>
                  <option value="5">Manchester United F.C</option>
                  <option value="6">Manchester City F.C</option>
                  <option value="7">Other</option>
                  <option value="8">I don't have a favorite team listed</option>
                </select>

                <label htmlFor="country" className="field-label">
                  Location
                </label>
                <p className="data">Nigeria</p>
                <p className="helper">More locations coming soon</p>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {isSubmitting ? (
              <span className="button primary w-button is-disabled">
                Saving...
              </span>
            ) : isValid ? (
              <span
                className="button primary w-button"
                onClick={handleSubmitEditedSurvey}
              >
                Save
              </span>
            ) : (
              <span className="button primary w-button is-disabled">Save</span>
            )}
            <span
              className="button subtle push-right w-button"
              onClick={toggleShowEditSurveyModal}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSurveyModal;
