import React from "react";
import moment from "moment";

const NegativeMostPopular = (props) => {
  const { negativePopularMentions, handleMentionModal, sentiment } = props;

  return (
    <div
      className="column padded-left"
      style={{
        opacity:
          sentiment === "positive sentiment" ||
          sentiment === "neutral sentiment"
            ? 0.1
            : 1,
      }}
    >
      {/* News */}
      {/* <div className="psuedo-card">
        <div className="row">
          <div className="marker small nudge-down move-left negative"></div>
          <p className="medium-text medium-weight">Alpha corp drives growth with 3m digital customers in Q2'18, what
                  does this mean for the rest of the sector...<br /></p>
        </div>
        <div className="row center spaced push-right">
          <div className="row center no-padding"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24"
            viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
            strokeLinejoin="round" className="feather feather-file-text icon small nudge-left"
            src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg" alt="">
            <path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path>
            <polyline points="14 2 14 8 20 8"></polyline>
            <line x1="16" y1="13" x2="8" y2="13"></line>
            <line x1="16" y1="17" x2="8" y2="17"></line>
            <polyline points="10 9 9 9 8 9"></polyline>
          </svg>
            <div className="small-text muted">The punch</div>
            <div data-tooltip="Daily views" className="tag small violet with-icon move-right"><svg
              xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
              stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
              className="feather feather-eye icon small nudge-left">
              <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
              <circle cx="12" cy="12" r="3"></circle>
            </svg> <strong>12.3k</strong> </div>
          </div>
          <div className="small-text muted">5 min ago</div>
        </div>
      </div> */}

      {/* Tweets */}
      {negativePopularMentions.map((mention, i) => (
        <div className="psuedo-card" key={i}>
          <div
            className="row"
            onClick={() =>
              handleMentionModal({
                isOpen: true,
                showMention: true,
                mention,
              })
            }
          >
            <div className="marker small nudge-down move-left negative"></div>
            <p className="medium-text medium-weight">
              {mention.text}
              <br />
            </p>
          </div>
          <div className="row center spaced push-right">
            <div className="row center no-padding">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-twitter icon small nudge-left"
                src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                alt=""
              >
                <path d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
              </svg>
              <div className="small-text muted">{mention.user.screen_name}</div>
              <div
                data-tooltip="Followers"
                className="tag small violet with-icon move-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-users icon small nudge-left"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>{" "}
                <strong>{mention.user.followers_count}</strong>
              </div>
              {/* <div
                data-tooltip="Retweets"
                className="tag small violet with-icon move-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-repeat icon small nudge-left"
                >
                  <polyline points="17 1 21 5 17 9"></polyline>
                  <path d="M3 11V9a4 4 0 0 1 4-4h14"></path>
                  <polyline points="7 23 3 19 7 15"></polyline>
                  <path d="M21 13v2a4 4 0 0 1-4 4H3"></path>
                </svg>{" "}
                <strong>{mention.retweet_count}</strong>
              </div> */}
              {/* <div
                data-tooltip="Likes"
                className="tag small violet with-icon move-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-heart icon small nudge-left"
                >
                  <path d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"></path>
                </svg>{" "}
                <strong>{mention.user.favourites_count}</strong>
              </div> */}
            </div>
            <div className="small-text muted">
              {moment(mention.created_at.value, "YYYY-MM-DD").fromNow()}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default NegativeMostPopular;
