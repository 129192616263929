import React, { useState, useEffect } from "react";

import { getIdToken, getUser } from "../../../../utils/storage";

import Axios from "axios";

const TotalFilteredRespondents = (props) => {
  const {
    clientRef,
    campaignRef,
    setToast,
    age,
    states,
    targetFilterObject,
    gender,
    tribe,
    educationLevel,
    religion,
    relationshipStatus,
    employmentStatus,
    childrenInHousehold,
    personalEarningsMonthly,
    householdEarningsMonthly,
    personsInHousehold,
    childrenUnder18,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [totalFilteredRespondents, setTotalFilteredRespondents] = useState(0);

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_filtered_survey_respondents`,
      {
        uid,
        idToken,
        clientRef,
        campaignRef,
        age,
        states,
        gender,
        tribe,
        educationLevel,
        religion,
        relationshipStatus,
        employmentStatus,
        childrenInHousehold,
        personalEarningsMonthly,
        householdEarningsMonthly,
        personsInHousehold,
        childrenUnder18,
        ...targetFilterObject,
      },
      { headers: { "content-type": "application/json" } }
    )
      .then(({ data, status }) => {
        setTotalFilteredRespondents(data.totalFilteredSurveyRespondents);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error fetching filtered respondents. Please refresh",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    age,
    states,
    gender,
    tribe,
    educationLevel,
    religion,
    relationshipStatus,
    employmentStatus,
    childrenInHousehold,
    personalEarningsMonthly,
    householdEarningsMonthly,
    personsInHousehold,
    childrenUnder18,
    targetFilterObject,
  ]);

  return (
    <div>
      <div className="row spaced">
        <div>
          <div className="data huge violet-text">
            {totalFilteredRespondents}
          </div>
          <h3 className="move-down violet-text move-up">
            Filtered Respondents
          </h3>
        </div>
      </div>
      <div>
        {targetFilterObject.targetQuestionRefToFilterBy === null ? (
          ""
        ) : (
          <span className="tag violet move-left">
            {targetFilterObject.targetQuestionToFilterBy.length > 30
              ? `${targetFilterObject.targetQuestionToFilterBy.slice(0, 27)}...`
              : targetFilterObject.targetQuestionToFilterBy}
            - {targetFilterObject.targetResponseOptionNameToFilterBy}
          </span>
        )}
      </div>
      <div>
        {states.length === 0
          ? ""
          : states.map((state, i) => (
              <span className="tag violet move-left" key={i}>
                {state}
              </span>
            ))}
      </div>
      <div>
        <span className="tag violet move-left">{`${age[0]}-${age[1]} yrs`}</span>
      </div>
      <div>
        {gender.length === 0
          ? ""
          : gender.map((gender, i) => (
              <span className="tag violet move-left" key={i}>
                {gender}
              </span>
            ))}
      </div>
      <div>
        {tribe.length === 0
          ? ""
          : tribe.map((tribe, i) => (
              <span className="tag violet move-left" key={i}>
                {tribe}
              </span>
            ))}
      </div>
      <div>
        {educationLevel.length === 0
          ? ""
          : educationLevel.map((educationLevel, i) => (
              <span className="tag violet move-left" key={i}>
                {educationLevel}
              </span>
            ))}
      </div>
      <div>
        {religion.length === 0
          ? ""
          : religion.map((religion, i) => (
              <span className="tag violet move-left" key={i}>
                {religion}
              </span>
            ))}
      </div>
      <div>
        {relationshipStatus.length === 0
          ? ""
          : relationshipStatus.map((relationshipStatus, i) => (
              <span className="tag violet move-left" key={i}>
                {relationshipStatus}
              </span>
            ))}
      </div>
      <div>
        {employmentStatus.length === 0
          ? ""
          : employmentStatus.map((employmentStatus, i) => (
              <span className="tag violet move-left" key={i}>
                {employmentStatus}
              </span>
            ))}
      </div>
      <div>
        {childrenInHousehold.length === 0 ? (
          ""
        ) : (
          <>
            <span className="tag violet move-left">
              {`children in household`}
            </span>
            {childrenInHousehold.map((item, i) => (
              <span key={i} className="tag violet move-left">
                {item.replace(/_/g, " ")}{" "}
              </span>
            ))}
          </>
        )}
      </div>
      <div>
        {personalEarningsMonthly.length === 0 ? (
          ""
        ) : (
          <>
            <span className="tag violet move-left">
              {/* ₦{" "} */}
              {`personal earnings monthly`}
            </span>
            {personalEarningsMonthly.map((item, i) => (
              <span key={i} className="tag violet move-left">
                {item.replace(/_/g, " ")}{" "}
              </span>
            ))}
          </>
        )}
      </div>
      <div>
        {householdEarningsMonthly.length === 0 ? (
          ""
        ) : (
          <>
            <span className="tag violet move-left">
              {/* ₦{" "} */}
              {`household earnings monthly`}
            </span>
            {householdEarningsMonthly.map((item, i) => (
              <span key={i} className="tag violet move-left">
                {item.replace(/_/g, " ")}{" "}
              </span>
            ))}
          </>
        )}
      </div>
      <div>
        {personsInHousehold.length === 0 ? (
          ""
        ) : (
          <>
            <span className="tag violet move-left">
              {`persons in household`}{" "}
            </span>
            {personsInHousehold.map((item, i) => (
              <span key={i} className="tag violet move-left">
                {item.replace(/_/g, " ")}{" "}
              </span>
            ))}
          </>
        )}
      </div>
      <div>
        {childrenUnder18.length === 0
          ? ""
          : childrenUnder18.map((childrenUnder18, i) => (
              <span className="tag violet move-left" key={i}>
                {childrenUnder18} children under 18
              </span>
            ))}
      </div>
    </div>
  );
};

export default TotalFilteredRespondents;
