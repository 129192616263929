/**
 * Upgrade Modal - Enable clients upgrade to a higher subscription plan
 */

import React, { useEffect, useState } from "react";
import axios from "axios";

import useScript from "../CustomHooks/useScript";
import DummyButton from "../DummyButton";

const UpgradeModal = (props) => {
  const {
    client,
    showUpgradeModal,
    toggleShowUpgradeModal,
    isOnboarding,
    setToast,
  } = props;

  const [loadedStripe, errorLoadingStripe] = useScript(
    "https://js.stripe.com/v3/"
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState("");
  const [completedUpgradeRequest, setCompletedUpgradeRequest] = useState(false);
  const [isSendingUpgradeRequest, setIsSendingUpgradeRequest] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(
    client.subscriptionPlanRef || ""
  );
  const [billingPeriod, setBillingPeriod] = useState("monthly");

  const handleSelectSubscriptionPlan = (event) => {
    const plan = event.target.value;
    setSubscriptionPlan(plan);
  };

  const handleSelectPlan = (ref) => {
    setSelectedPlan(ref);
    setSubscriptionPlan(ref);
  };

  const handleSubmitUpgradeRequest = () => {
    if (!selectedPlan) return;
    setIsSendingUpgradeRequest(true);
    // Subscribe to paid plans
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_stripe_create_checkout_session`,
        { subscriptionPlan: selectedPlan, client, isOnboarding, billingPeriod },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        const sessionId = result.data.sessionId;
        const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
        stripe.redirectToCheckout({ sessionId }).then(function (result) {
          if (result.error) {
            console.log(
              "Stripe redirect failed due to browser or network error",
              result.error.message
            );
            if (setToast) {
              setToast({
                show: true,
                type: "warning",
                message: result.error.message,
              });
            }
          }
        });
      })
      .catch((error) => {
        console.log("Error creating Stripe checkout session", error);
        if (setToast) {
          setToast({
            show: true,
            type: "danger",
            message: "Failed to create Stripe checkout session",
          });
        }
      })
      .finally(() => {
        setIsSendingUpgradeRequest(false);
      });
  };

  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_subscription_plans`
      )
      .then(({ status, data }) => {
        if (status === 200) {
          const upgradablePlans = data.subscriptionPlans.filter(
            (plan) =>
              plan.subscriptionPlanRef !== client.subscriptionPlanRef &&
              plan.subscriptionPlanRef !==
                "45b6d9bb-abca-4e0c-b55d-7ea894a8af13"
          );
          setSubscriptionPlans(upgradablePlans);
        }
      })
      .catch((error) => console.log("Error getting subscription plans", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (completedUpgradeRequest) {
    return (
      <div className="modal" style={{ display: "flex", opacity: 1 }}>
        <div className="modal-container">
          <h2>Success!</h2>
          {!isOnboarding ? (
            <div onClick={() => setCompletedUpgradeRequest(false)}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-x icon close"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          ) : (
            ""
          )}
          <div
            className="modal-card"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <div className="modal-header">
              <div className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-check"
                >
                  <polyline points="20 6 9 17 4 12"></polyline>
                </svg>
              </div>
            </div>
            <div className="modal-body">
              <h3 className="fill">
                You have been successfully subscribed to a free plan.
              </h3>
              {isOnboarding ? (
                <p className="medium-text muted fill">
                  You can now close this browser window.
                </p>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className="modal"
      style={{ display: showUpgradeModal ? "flex" : "none", opacity: 1 }}
    >
      <div className="modal-container">
        <h2>Billing</h2>
        <div onClick={toggleShowUpgradeModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-header">
            <div className="meta">Select a plan</div>
          </div>
          <div className="modal-body">
            <div className="w-form">
              <form>
                {subscriptionPlans.map((plan) => (
                  <div
                    className={`plan ${
                      selectedPlan === plan.subscriptionPlanRef ? "active" : ""
                    }`}
                    key={plan.subscriptionPlanRef}
                    onClick={() => handleSelectPlan(plan.subscriptionPlanRef)}
                  >
                    <div className="row spaced">
                      <label className="w-radio">
                        <input
                          type="radio"
                          value={plan.subscriptionPlanRef}
                          name="subscription-plan"
                          checked={selectedPlan === plan.subscriptionPlanRef}
                          className="w-form-formradioinput radio w-radio-input"
                          onChange={handleSelectSubscriptionPlan}
                        />
                        <span className="badge bold no-margin w-form-label">
                          {plan.subscriptionPlanName.toUpperCase()}
                        </span>
                      </label>
                      <p>
                        {plan.price.indexOf("$") > -1 ? (
                          <span>
                            {plan.price}
                            <span className="muted medium-text">/month</span>
                          </span>
                        ) : (
                          plan.price
                        )}
                      </p>
                    </div>
                    <div className="push-right">
                      <h4 className="push-down">{plan.blurb}</h4>
                      <p className="medium-text muted">{plan.details}</p>
                    </div>
                  </div>
                ))}
              </form>
            </div>
            <p className="medium-text muted fill">
              The following features/services can be included as add-ons:
              comparison analysis, consulting, archive reporting, and African
              regional sentiment heatmap
            </p>
          </div>
          <div className="modal-footer">
            {isSendingUpgradeRequest ? (
              <DummyButton text="Sending upgrade request..." />
            ) : loadedStripe && !errorLoadingStripe ? (
              <div>
                <span
                  style={{
                    pointerEvents: !Boolean(selectedPlan) ? "none" : "auto",
                  }}
                  className="button primary w-button"
                  onClick={handleSubmitUpgradeRequest}
                >
                  Upgrade{" "}
                  {`${
                    subscriptionPlan
                      ? "to " + subscriptionPlan.toUpperCase()
                      : ""
                  }`}
                </span>
                <span
                  className="button subtle push-right w-button"
                  onClick={toggleShowUpgradeModal}
                >
                  Cancel
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;
