import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";

import { getUser, getClient, getIdToken } from "../../../../utils/storage";

import AudioPlayer from "./AudioPlayer";
import ImagePlayer from "./ImagePlayer";
import VideoPlayer from "./VideoPlayer";

const ResponseModal = (props) => {
  const {
    viewResponseModal,
    closeResponseModal,
    responseInView,
    toggleViewCarousel,
    handleChangeResponseInView,
    approveResponse,
    rejectResponse,
    responseAction,
    toggleIsShareModalOpen,
  } = props;

  const history = useHistory();

  const [isDownloading, setIsDownloading] = useState(false);

  const downloadResponse = () => {
    setIsDownloading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_download_request_response`,
        {
          requestRef: responseInView.requestRef,
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
          scoutRef: responseInView.scoutRef,
        },
        {
          headers: { "content-type": "application/json" },
          // responseType: "blob",
        }
      )
      .then((result) => {
        setIsDownloading(false);
        // const url = window.URL.createObjectURL(new Blob([result.data]));
        const url = result.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "responses.zip");
        link.click();
        // window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.log("error downloading response", error, error.response);
        if (error && error.response && error.response.status === 401) {
          // Retrun to login screen
          history.push("/login");
        }
      });
  };

  if (!responseInView) return "";

  return (
    <div
      className="modal"
      style={{ display: viewResponseModal ? "flex" : "none", opacity: 1 }}
    >
      <div className="modal-container">
        <h2>View Response</h2>
        <div onClick={closeResponseModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-header">
            <div className="meta">
              {new Date(responseInView.created_at).toDateString()}
            </div>
          </div>
          <div className="modal-body">
            {responseInView.mediaType === "video" ? (
              <VideoPlayer responseInView={responseInView} />
            ) : responseInView.mediaType === "audio" ? (
              <AudioPlayer responseInView={responseInView} />
            ) : (
              <ImagePlayer
                responseInView={responseInView}
                toggleViewCarousel={toggleViewCarousel}
              />
            )}

            <div className="row spaced push-down">
              {responseInView.status === "pending" ? (
                <div className="row">
                  {responseAction.isApproving &&
                  responseAction.ref === responseInView.responseRef ? (
                    <span className="button small no-margin move-left positive">
                      ...
                    </span>
                  ) : (
                    <span
                      data-tooltip="Approve"
                      className="button small no-margin move-left positive is-icon w-button"
                      onClick={() =>
                        approveResponse(responseInView.responseRef)
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-thumbs-up icon small"
                      >
                        <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
                      </svg>
                    </span>
                  )}
                  {responseAction.isRejecting &&
                  responseAction.ref === responseInView.responseRef ? (
                    <span className="button small no-margin move-left positive">
                      ...
                    </span>
                  ) : (
                    <span
                      data-tooltip="Reject"
                      data-w-id="a5ede4e9-9b14-523d-df46-6f79270d9992"
                      className="button small no-margin move-left negative is-icon w-button"
                      onClick={() => rejectResponse(responseInView.responseRef)}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-thumbs-down icon small"
                      >
                        <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
                      </svg>
                    </span>
                  )}
                </div>
              ) : (
                ""
              )}
              {responseInView.status === "approved" ? (
                <div className="row">
                  <div className="chip blue">{responseInView.status}</div>
                </div>
              ) : (
                ""
              )}
              {responseInView.status === "rejected" ? (
                <div>
                  <div className="chip red">Rejected</div>
                  <ul className="move-down">
                    <li>{responseInView.reason_for_rejection}</li>
                    {/* {responseInView.reasonForRejection.map((reason, i) => (
                      <li>{reason}</li>
                    ))} */}
                    <li>{responseInView.optional_message}</li>
                  </ul>
                </div>
              ) : (
                ""
              )}
              {responseInView.status === "approved" ? (
                <div className="row right">
                  {isDownloading ? (
                    <span
                      data-tooltip="Download"
                      className="button small no-margin move-left subtle is-icon w-button"
                    >
                      ...
                    </span>
                  ) : (
                    <span
                      data-tooltip="Download"
                      className="button small no-margin move-left subtle is-icon w-button"
                      onClick={downloadResponse}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-download icon small"
                      >
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                        <polyline points="7 10 12 15 17 10"></polyline>
                        <line x1="12" y1="15" x2="12" y2="3"></line>
                      </svg>
                    </span>
                  )}
                  <span
                    data-tooltip="Share"
                    className="button small no-margin subtle is-icon w-button"
                    onClick={toggleIsShareModalOpen}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-share icon small"
                    >
                      <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                      <polyline points="16 6 12 2 8 6"></polyline>
                      <line x1="12" y1="2" x2="12" y2="15"></line>
                    </svg>
                  </span>
                </div>
              ) : (
                <div className="row right"></div>
              )}
            </div>
          </div>
          <div
            className="modal-footer spaced"
            style={{ opacity: 1, display: "flex" }}
          >
            <span
              className="button has-icon w-button"
              onClick={() => handleChangeResponseInView("previous")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-left icon move-left"
              >
                <line x1="19" y1="12" x2="5" y2="12"></line>
                <polyline points="12 19 5 12 12 5"></polyline>
              </svg>{" "}
              Previous
            </span>
            <span
              className="button has-icon w-button"
              onClick={() => handleChangeResponseInView("next")}
            >
              Next{" "}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right icon move-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponseModal;
