import React from "react";

const HeaderTitle = (props) => {
  const {tooltip,setTooltip} = props
  return (
    <div className="row center">
      <h1 className="no-margin push-left">
        <span className="muted">Interact requests</span>
      </h1>
     
    </div>
  );
};

export default HeaderTitle;
