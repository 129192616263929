import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";
import CompareModal from "./CompareModal";
import ConfirmModal from "./confirmModal";

const ComparisonBrands = (props) => {
  const { client, setToast, team } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [comparisonBrands, setComparisonBrands] = useState([]);
  const [isUpdatingComparisonBrand, setIsUpdatingComparisonBrand] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const [activeBrandData, setActiveBrandData] = useState({});
  const [brandToBeDeleted, setBrandToBeDeleted] = useState({});

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const [showCompareModal, setShowCompareModal] = useState(false);
  const toggleShowCompareModal = () => {
    if (team && team.canEdit) {
      setShowCompareModal((showCompareModal) => !showCompareModal);
    } else {
      return;
    }
  };

  const toggleShowConfirmModal = () => setShowConfirmModal(!showConfirmModal);
  const toggleSetRefresh = () => setRefresh((refresh) => !refresh);

  const handleRemoveCompareBrand = (brand) => {
    if (team && team.canEdit) {
      setIsRemoving(true);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/versus_v2_remove_comparison_brand `,
          {
            comparisonBrand: brandToBeDeleted,
            email: user.email,
            uid: user.uid,
            idToken,
          },
          { headers: { "content-type": "application/json" } }
        )
        .then(({ status, data }) => {
          setIsRemoving(false);
          if (status === 200) {
            setToast({
              show: true,
              type: "success",
              message: data.message || "Successfully removed comparison brand",
            });
          }
          toggleSetRefresh();
          toggleShowConfirmModal();
        })
        .catch((error) => {
          setIsRemoving(false);
          console.log("Error removing comparison brand", error.message);
          setToast({
            show: true,
            type: "warning",
            message:
              error.message ||
              error.response.data.message ||
              "We had problem removing comparison brand",
          });
          toggleSetRefresh();
          toggleShowConfirmModal();
        });
    } else return;
  };

  const handleAddCompareBrandToBeRemoved = (brand) => {
    if (team && team.canEdit) {
      setShowConfirmModal(!showCompareModal);
      setBrandToBeDeleted(brand);
    } else return;
  };

  const handleEditCompareBrand = (brand) => {
    setActiveBrandData(brand);
    toggleShowCompareModal();
  };

  const updateCamparisonBrands = (brand) => {
    let brands;
    if (activeBrandData.action) {
      brands = [...comparisonBrands, brand]
    } else {
      brands = comparisonBrands.map(b => {
        if (brand.compareDbRef === b.compareDbRef) {
          return brand;
        }
        return b;
      })
    }
    setComparisonBrands(brands);
  };

  const handleSubmitComparisonBrand = () => {
    if (team && team.canEdit) {
      setIsUpdatingComparisonBrand(true);
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/versus_v2_update_comparison_brands`,
          {
            comparisonBrand: activeBrandData,
            email: user.email,
            uid: user.uid,
            idToken,
          },
          { headers: { "content-type": "application/json" } }
        )
        .then(({ status, data }) => {
          setIsUpdatingComparisonBrand(false);
          if (status === 200) {
            updateCamparisonBrands(data.data.data);
            setToast({
              show: true,
              type: "success",
              message: data.message || "Successfully updated comparison brands",
            });
          }
          toggleShowCompareModal();
        })
        .catch((error) => {
          setIsUpdatingComparisonBrand(false);
          console.log("Error updating comparison brands", error.message);
          setToast({
            show: true,
            type: "warning",
            message:
              error.message ||
              error.response.data.message ||
              "We had problems updating comparison brands",
          });
          toggleShowCompareModal();
        });
    } else return;
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_comparison_brands`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          setComparisonBrands(result.data.comparisonBrands);
        }
      })
      .catch((error) => {
        console.log("Error obtaining comparison brands", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving your comparison brands",
        });
      });
  }, [client.clientRef, idToken, setToast, user.uid, refresh]);

  return (
    <div className="card-2">
      <div className="card-section">
        <h3>Comparison brands</h3>
        <div className="throw-down">
          <div className="table">
            <div className="table-header">
              <div className="table-header-cell _30">Brand name</div>
              <div className="table-header-cell">Other names</div>
            </div>
            <div className="table-body">
              {comparisonBrands.map((brand, i) => (
                <div className="table-row" key={i}>
                  <div className="table-body-cell main _30">
                    {brand.brandName}
                  </div>
                  <div className="table-body-cell">{brand.otherBrandName}</div>
                  <div style={{ marginRight: '15px' }}>
                    <span
                      data-w-id="ce37e6ca-f4fe-7475-6a12-445c6cc7cbf5"
                      className="button no-margin subtle is-icon table-action-icon auto-height black push-down w-button"
                      style={{
                        cursor: team
                          ? team.canEdit
                            ? "pointer"
                            : "not-allowed"
                          : "not-allowed",
                      }}
                      onClick={() =>
                        team
                          ? team.canEdit
                            ? handleEditCompareBrand(brand)
                            : null
                          : null
                      }
                    >
                      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit-2 icon small">
                        <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z"></path>
                      </svg>
                    </span>
                  </div>
                  <div className="dropdown" style={{ opacity: 1 }}>
                    <span
                      className="button no-margin subtle is-icon table-action-icon auto-height black push-down w-button"
                      style={{
                        cursor: team
                          ? team.canEdit
                            ? "pointer"
                            : "not-allowed"
                          : "not-allowed",
                      }}
                      onClick={() =>
                        team
                          ? team.canEdit
                            ? handleAddCompareBrandToBeRemoved(brand)
                            : null
                          : null
                      }
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        className="feather feather-x icon small"
                      >
                        <line x1="18" y1="6" x2="6" y2="18"></line>
                        <line x1="6" y1="6" x2="18" y2="18"></line>
                      </svg>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </div>
          {comparisonBrands.length < 3 && (
            <span
              style={{
                cursor: team
                  ? team.canEdit
                    ? "pointer"
                    : "not-allowed"
                  : "not-allowed",
              }}
              className="button subtle wide with-icon w-button"
              onClick={() => {
                toggleShowCompareModal();
                setActiveBrandData({
                  action: "new",
                  brandName: '',
                  otherBrandName: ''
                });
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-plus-square icon move-left"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2"></rect>
                <line x1="12" y1="8" x2="12" y2="16"></line>
                <line x1="8" y1="12" x2="16" y2="12"></line>
              </svg>{" "}
              Add comparison brand{" "}
              <strong className="push-right light">
                {comparisonBrands.length}/3
              </strong>
            </span>
          )}
        </div>
      </div>

      <CompareModal
        showCompareModal={showCompareModal}
        toggleShowCompareModal={toggleShowCompareModal}
        handleAddCamparisonBrand={handleSubmitComparisonBrand}
        handleUpdateComparisonBrand={handleSubmitComparisonBrand}
        isUpdatingComparisonBrand={isUpdatingComparisonBrand}
        data={activeBrandData}
        setActiveBrandData={setActiveBrandData}
      />

      <ConfirmModal
        showConfirmModal={showConfirmModal}
        toggleShowConfirmModal={toggleShowConfirmModal}
        handleRemoveCompareBrand={handleRemoveCompareBrand}
        isDeleting={isRemoving}
      />
    </div>
  );
};

export default ComparisonBrands;
