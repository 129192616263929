import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";
// import { uniqBy } from 'lodash-es';

import { getUser, getIdToken } from "../../../utils/storage";
import SurveyFilter from "./SurveyFilter";
import SurveySideBar from "./SurveySidebar";
import SurveyQuestions from "./SurveyQuestions";
import FullWidthLoader from "../../FullWidthLoader";
import ResponseFilterModal from "./ResponseFilterModal";

const Survey = (props) => {
  const campaignRef = props.match.params.ref;

  const { client, isNavExpanded, setToast, setTooltip, tooltip } = props;
  const [inputValues, setInputValues] = useState({
    startAge: 0,
    endAge: 65,
  });
  const [campaignQuestions, setCampaignQuestions] = useState([]);
  useEffect(() => {
    // Start Hotjar tracking
    if (window.hj) {
      window.hj("trigger", "survey-result");
    }

    // Clean up the effect
    return () => {};
  }, []);
  // Demographic filters
  const [age, setAge] = useState([]);
  const [states, setStates] = useState([]);
  const [gender, setGender] = useState([]);
  const [tribe, setTribe] = useState([]);
  const [educationLevel, setEducationLevel] = useState([]);
  const [religion, setReligion] = useState([]);
  const [relationshipStatus, setRelationshipStatus] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState([]);
  const [childrenInHousehold, setChildrenInHousehold] = useState([]);
  const [personalEarningsMonthly, setPersonalEarningsMonthly] = useState([]);
  const [householdEarningsMonthly, setHouseholdEarningsMonthly] = useState([]);
  const [personsInHousehold, setPersonsInHousehold] = useState([]);
  const [childrenUnder18, setChildrenUnder18] = useState([]);
  const [targetFilterObject, setTargetFilterObject] = useState({
    targetQuestionRefToFilterBy: null,
    targetResponseOptionToFilterBy: null,
    targetQuestionToFilterBy: null,
    targetResponseOptionNameToFilterBy: null,
  });
  const [showResponseFilterModal, setShowResponseFilterModal] = useState(false);

  const handleOnAgeChange = (e) => {
    setInputValues({ ...inputValues, [e.target.name]: e.target.value });
  };

  // Demographic filter handlers
  const handleSetAge = (value) => {
    setInputValues({ ...inputValues, startAge: value[0], endAge: value[1] });
    setAge(value);
  };

  const handleSetStates = (value) => {
    const newStates = states.includes(value)
      ? states.filter((state) => state !== value)
      : states.concat([value]);
    setStates(newStates);
  };

  const handleSetGender = (value) => {
    const newGender = gender.includes(value)
      ? gender.filter((gender) => gender !== value)
      : gender.concat([value]);
    setGender(newGender);
  };

  const handleSetTribe = (value) => {
    const newTribe = tribe.includes(value)
      ? tribe.filter((tribe) => tribe !== value)
      : tribe.concat([value]);
    setTribe(newTribe);
  };

  const handleSetEducationLevel = (value) => {
    const newEducationLevel = educationLevel.includes(value)
      ? educationLevel.filter((educationLevel) => educationLevel !== value)
      : educationLevel.concat([value]);
    setEducationLevel(newEducationLevel);
  };

  const handleSetReligion = (value) => {
    const newReligion = religion.includes(value)
      ? religion.filter((religion) => religion !== value)
      : religion.concat([value]);
    setReligion(newReligion);
  };

  const handleSetRelationshipStatus = (value) => {
    const newRelationshipStatus = relationshipStatus.includes(value)
      ? relationshipStatus.filter(
          (relationshipStatus) => relationshipStatus !== value
        )
      : relationshipStatus.concat([value]);
    setRelationshipStatus(newRelationshipStatus);
  };

  const handleSetEmploymentStatus = (value) => {
    const newEmploymentStatus = employmentStatus.includes(value)
      ? employmentStatus.filter(
          (employmentStatus) => employmentStatus !== value
        )
      : employmentStatus.concat([value]);
    setEmploymentStatus(newEmploymentStatus);
  };

  const handleSetChildrenInHousehold = (value) => {
    const newChildrenInHousehold = childrenInHousehold.includes(value)
      ? childrenInHousehold.filter((item) => item !== value)
      : childrenInHousehold.concat(value);
    setChildrenInHousehold(newChildrenInHousehold);
  };

  const handleSetPersonalEarningsMonthly = (value) => {
    const newPersonalEarningsMonthly = personalEarningsMonthly.includes(value)
      ? personalEarningsMonthly.filter((item) => item !== value)
      : personalEarningsMonthly.concat(value);
    setPersonalEarningsMonthly(newPersonalEarningsMonthly);
  };

  const handleSetHouseholdEarningsMonthly = (value) => {
    const newHouseholdEarningsMonthly = householdEarningsMonthly.includes(value)
      ? householdEarningsMonthly.filter((item) => item !== value)
      : householdEarningsMonthly.concat(value);
    setHouseholdEarningsMonthly(newHouseholdEarningsMonthly);
  };

  const handleSetPersonsInHousehold = (value) => {
    const newPersonsInHousehold = personsInHousehold.includes(value)
      ? personsInHousehold.filter((item) => item !== value)
      : personsInHousehold.concat(value);
    setPersonsInHousehold(newPersonsInHousehold);
  };

  const handleSetChildrenUnder18 = (value) => {
    const newChildrenUnder18 = childrenUnder18.includes(value)
      ? childrenUnder18.filter((childrenUnder18) => childrenUnder18 !== value)
      : childrenUnder18.concat([value]);
    setChildrenUnder18(newChildrenUnder18);
  };

  // End Demographic filters
  const [campaign, setCampaign] = useState(null);

  const user = getUser(),
    idToken = getIdToken();

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_survey_data`,
        {
          clientRef: client.clientRef,
          campaignRef,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data, status }) => {
        if (status === 200) {
          const { campaign } = data;
          setCampaign(campaign);
          setInputValues({
            ...inputValues,
            startAge: campaign.start_age,
            endAge: campaign.end_age,
          });
          setAge([campaign.start_age, campaign.end_age]);
          // setGender(campaign.gender);
        }
      })
      .catch((error) => {
        console.log("Error obtaining campaign", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "Error obtaining campaign. Please refresh.",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [campaignRef]);

  if (!campaign) {
    return <FullWidthLoader />;
  } else {
    return (
      <div>
        <SurveyFilter
          isNavExpanded={isNavExpanded}
          setToast={setToast}
          handleSetStates={handleSetStates}
          states={states}
          age={age}
          campaign={campaign}
          setShowResponseFilterModal={setShowResponseFilterModal}
          handleSetAge={handleSetAge}
          inputValues={inputValues}
          handleOnAgeChange={handleOnAgeChange}
          gender={gender}
          handleSetGender={handleSetGender}
          tribe={tribe}
          handleSetTribe={handleSetTribe}
          educationLevel={educationLevel}
          handleSetEducationLevel={handleSetEducationLevel}
          religion={religion}
          handleSetReligion={handleSetReligion}
          relationshipStatus={relationshipStatus}
          handleSetRelationshipStatus={handleSetRelationshipStatus}
          employmentStatus={employmentStatus}
          handleSetEmploymentStatus={handleSetEmploymentStatus}
          handleSetChildrenInHousehold={handleSetChildrenInHousehold}
          childrenInHousehold={childrenInHousehold}
          handleSetPersonalEarningsMonthly={handleSetPersonalEarningsMonthly}
          personalEarningsMonthly={personalEarningsMonthly}
          handleSetHouseholdEarningsMonthly={handleSetHouseholdEarningsMonthly}
          householdEarningsMonthly={householdEarningsMonthly}
          handleSetPersonsInHousehold={handleSetPersonsInHousehold}
          personsInHousehold={personsInHousehold}
          childrenUnder18={childrenUnder18}
          handleSetChildrenUnder18={handleSetChildrenUnder18}
        />
        <SurveyQuestions
          isNavExpanded={isNavExpanded}
          campaign={campaign}
          setCampaignQuestions={setCampaignQuestions}
          targetFilterObject={targetFilterObject}
          clientRef={client.clientRef}
          setToast={setToast}
          age={age}
          states={states}
          gender={gender}
          tribe={tribe}
          educationLevel={educationLevel}
          religion={religion}
          relationshipStatus={relationshipStatus}
          employmentStatus={employmentStatus}
          childrenInHousehold={childrenInHousehold}
          personalEarningsMonthly={personalEarningsMonthly}
          householdEarningsMonthly={householdEarningsMonthly}
          personsInHousehold={personsInHousehold}
          childrenUnder18={childrenUnder18}
        />
        <SurveySideBar
          clientRef={client.clientRef}
          campaign={campaign}
          setToast={setToast}
          age={age}
          states={states}
          targetFilterObject={targetFilterObject}
          gender={gender}
          tribe={tribe}
          inputValues={inputValues}
          educationLevel={educationLevel}
          religion={religion}
          relationshipStatus={relationshipStatus}
          employmentStatus={employmentStatus}
          childrenInHousehold={childrenInHousehold}
          personalEarningsMonthly={personalEarningsMonthly}
          householdEarningsMonthly={householdEarningsMonthly}
          personsInHousehold={personsInHousehold}
          childrenUnder18={childrenUnder18}
          setTooltip={setTooltip}
          tooltip={tooltip}
        />
        <ResponseFilterModal
          campaignQuestions={campaignQuestions}
          showModal={showResponseFilterModal}
          setShowModal={setShowResponseFilterModal}
          setTargetFilterObject={setTargetFilterObject}
        />
      </div>
    );
  }
};

export default withRouter(Survey);
