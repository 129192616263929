import React from "react";

export const ActiveRadio = ({ handleUnit }) => {
  return (
    <div
      className="toggle off big"
      style={{ backgroundColor: "rgb(69, 23, 166)" }}
      onClick={() => {
        handleUnit("single_select");
      }}
    >
      <div
        className="toggle-switch big off"
        style={{
          backgroundColor: "rgb(255, 255, 255)",
          transform:
            "translate3d(16px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
      ></div>
    </div>
  );
};

export const NotActiveRadio = ({ handleUnit }) => {
  return (
    <div
      className="toggle off big"
      style={{ backgroundColor: "rgb(203, 213, 224)" }}
      onClick={() => {
        handleUnit("multi_select");
      }}
    >
      <div
        className="toggle-switch big off"
        style={{
          backgroundColor: "rgb(113, 128, 150)",
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
        }}
      ></div>
    </div>
  );
};
