import React from "react";

const CampaignFilter = (props) => {
  const { isFilterExpanded, filter, setFilter, screen } = props;
  return (
    <div className="side-spacer thin left">
      <div className="side-bar fixed">
        <div
          className="spacer"
          style={{
            width: isFilterExpanded ? "52px" : "52px",
            height: "935.531px",
          }}
        ></div>
        <div className="div-block-7">
          <h2>Sort by</h2>

          <div>
            <h5 className="top-breather">Survey Name</h5>
            <span
              className={`side-bar-link ${
                (filter.sortField === "campaignName" ||
                  filter.sortField === "name") &&
                filter.sortOrder === "asc"
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                setFilter({
                  sortField: screen === "interact" ? "name" : "campaignName",
                  sortOrder: "asc",
                })
              }
            >
              Ascending(A-Z)
            </span>
            <span
              className={`side-bar-link ${
                filter.sortField === "campaignName" &&
                filter.sortOrder === "desc"
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                setFilter({
                  sortField: screen === "interact" ? "name" : "campaignName",
                  sortOrder: "desc",
                })
              }
            >
              Descending(Z-A)
            </span>
          </div>
          <div>
            <h5 className="top-breather">Date Created</h5>
            <span
              className={`side-bar-link ${
                filter.sortField === "created_at" && filter.sortOrder === "desc"
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                setFilter({ sortField: "created_at", sortOrder: "desc" })
              }
            >
              Ascending(New - Old)
            </span>
            <span
              className={`side-bar-link ${
                filter.sortField === "created_at" && filter.sortOrder === "asc"
                  ? "active"
                  : ""
              }`}
              onClick={() =>
                setFilter({ sortField: "created_at", sortOrder: "asc" })
              }
            >
              Descending(Old - New)
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignFilter;
