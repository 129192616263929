import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

import InnerLoader from "../../../InnerLoader";

const RespondentsSummary = (props) => {
  const [numberOfRespondents, setNumberOfRespondents] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_client_request_respondents`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setIsLoading(false);
        setNumberOfRespondents(data.totalNumberOfClientRequestRespondents);
      })
      .catch((error) => {
        console.log("Error getting total respondents", error);
      });
  }, []);

  return (
    <div className="card-section padded expand">
      {isLoading ? (
        <InnerLoader />
      ) : (
        <>
          <div className="data huge">{numberOfRespondents}</div>
          <h3 className="move-down">Respondents</h3>
        </>
      )}
    </div>
  );
};

export default RespondentsSummary;
