import React, { useEffect, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
import queryString from "query-string";

const SubscribeSuccess = (props) => {
  const onboardingToken = props.location.search
    ? queryString.parse(props.location.search).token
    : null;

  const [isReadyToOnboard, setIsReadyToOnboard] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setIsReadyToOnboard(true);
    }, 90000);
  });

  useEffect(() => {
    // Start Hotjar tracking
    if (window.hj) {
      window.hj("trigger", "subscribe-success");
    }

    // Clean up the effect
    return () => {};
  }, []);

  if (!onboardingToken) {
    return (
      <div>
        <div className="sign-up">
          <img
            src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
            height="32"
            srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
            sizes="102px"
            alt=""
            className="bottom-breather big-breath"
          />

          <h1 className="bold">Welcome to Versus</h1>
          <h2>Hey hey, looks like you missed your way.</h2>
        </div>
      </div>
    );
  }

  if (isReadyToOnboard) {
    return <Redirect to={`/onboarding/?token=${onboardingToken}`} />;
  }

  return (
    <div className="sign-up">
      <img
        src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
        height="32"
        srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
        sizes="102px"
        alt=""
        className="bottom-breather big-breath"
      />

      <h1 className="bold with-icon primary bottom-breather">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-check-circle icon push-left"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
        Payment successful
      </h1>
      <h2 className="">
        Thank you for your order. Please hold on while we prepare your profile
        for onboarding...{" "}
      </h2>
      <p className="bottom-breather muted">
        This might take about a few minutes
      </p>
      <div>
        <div className="loader">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(SubscribeSuccess);
