import React, { useEffect, useState } from "react";

import NegativeSentimentDataMeasureBar from "./NegativeSentimentDataMeasureBar";

const NegativeSentiment = (props) => {
  const { mentionsData, brand1, brand2, brand3, brand4, sentiment } = props;

  if (mentionsData.length <= 0) {
    return <div></div>;
  } else {
    return (
      <div
        className="card-section horizontal padded expand"
        style={{ opacity: sentiment === "positive sentiment" ? 0.1 : 1 }}
      >
        <h3>Negative sentiment</h3>

        {brand1 ? (
          <NegativeSentimentDataMeasureBar
            brand={brand1}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand1.compareDbRef
            )}
          />
        ) : (
          ""
        )}
        {brand2 ? (
          <NegativeSentimentDataMeasureBar
            brand={brand2}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand2.compareDbRef
            )}
          />
        ) : (
          ""
        )}
        {brand3 ? (
          <NegativeSentimentDataMeasureBar
            brand={brand3}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand3.compareDbRef
            )}
          />
        ) : (
          ""
        )}
        {brand4 ? (
          <NegativeSentimentDataMeasureBar
            brand={brand4}
            mention={mentionsData.find(
              (mention) => mention.clientRef === brand4.compareDbRef
            )}
          />
        ) : (
          ""
        )}
      </div>
    );
  }
};

export default NegativeSentiment;
