import Axios from "axios";
import React, { useEffect, useState } from "react";
import useDebounce from "../useDebounce";

const AddLocationModal = ({
  stateId,
  states,
  setStates,
  setLgaId,
  openModal,
  surveyData,
  setOpenModal,
  activeStep,
  stateLga,
  setStateLga,
  activeStateIds,
  setActiveStateIds,
  setNewSurveyData,
  handleNewSurveyData,
  locationModalAction,
  handleUpdatedEstimatedRespondent,
}) => {
  const [isLoadingStates, setIsLoadingStates] = useState(false);
  const [selectedStateId, setSelectedStateId] = useState(0);
  const [oldStateId, setOldSatetId] = useState(stateId);
  const [oldLgas, setOldLgas] = useState([]);
  const [showLgaDropdown, setShowLgaDropdown] = useState(false);
  const [selectedLgas, setSelectedLgas] = useState([]);
  const [filteredSelectedLgas, setFilteredSelectedLgas] = useState([]);
  const [q, setQ] = useState();

  let [startSearch, search] = useDebounce(q, 500);

  const handleSearchLga = (q) => {
    if (q) {
      let lgaList = stateLga?.filter((lga) => {
        let regex = new RegExp(q, "ig");
        return regex.test(lga.name);
      });
      setFilteredSelectedLgas(lgaList);
    } else {
      setFilteredSelectedLgas([]);
    }
  };

  const handleSelectedState = (e) => {
    const stateId = e.target.value;
    const uniqueIds = new Set([...activeStateIds, stateId]);
    setActiveStateIds(Array.from(uniqueIds));
    setSelectedLgas([]);
    setSelectedStateId(stateId);
  };

  const handleSelectedLga = (lga) => {
    // make unique
    const lgaExists = selectedLgas?.some((item) => item.id === lga.id);

    if (lgaExists) {
      const newSelectedLga = selectedLgas?.filter((item) => item.id !== lga.id);
      setSelectedLgas(newSelectedLga);
      return;
    }
    setSelectedLgas([...selectedLgas, lga]);
  };

  const handleUpdateLocation = () => {
    const oldLgaIds = oldLgas?.map((lga) => lga.id);
    const newLgaIds = selectedLgas?.map((lga) => lga.id);

    if (selectedStateId && oldStateId) {
      // remove oldStateId and lga
      const newStateIds = surveyData?.stateOrRegion?.filter(
        (id) => id != stateId
      );
      const surveyDatalga_ids = surveyData?.lga_ids?.filter(
        (id) => !oldLgaIds.includes(id)
      );
      const uniqueStateIds = new Set([...newStateIds, selectedStateId]);
      const uniqueLgaIds = new Set([...surveyDatalga_ids, ...newLgaIds]);

      setNewSurveyData({
        ...surveyData,
        stateOrRegion: Array.from(uniqueStateIds),
        lga_ids: Array.from(uniqueLgaIds),
      });
      setActiveStateIds([]);
      setSelectedLgas({});
      setOldLgas({});
      handleUpdatedEstimatedRespondent();
      setOpenModal(false);
    }
  };

  const handleDeleteLocation = () => {
    const ids = selectedLgas?.map((lga) => lga.id);
    const newStateIds = surveyData?.stateOrRegion?.filter(
      (id) => id != stateId
    );
    const newLgaIds = surveyData?.lga_ids?.filter((id) => !ids.includes(id));

    setNewSurveyData({
      ...surveyData,
      stateOrRegion: newStateIds,
      lga_ids: newLgaIds,
    });
    setActiveStateIds([]);
    setSelectedLgas({});
    handleUpdatedEstimatedRespondent();
    setOpenModal(false);
  };

  const handleAddLocation = () => {
    const ids = selectedLgas?.map((lga) => lga.id);
    const uniqueLgaIds = new Set([...surveyData?.lga_ids, ...ids]);
    const uniqueStateIds = new Set([
      ...surveyData?.stateOrRegion,
      ...activeStateIds,
    ]);

    setNewSurveyData({
      ...surveyData,
      stateOrRegion: Array.from(uniqueStateIds),
      lga_ids: Array.from(uniqueLgaIds),
    });
    handleUpdatedEstimatedRespondent();
    setOpenModal(false);
  };

  useEffect(() => {
    if (startSearch) {
      handleSearchLga(search);
    }
  }, [startSearch, search]);

  useEffect(() => {
    if (states.length == 0) {
      setIsLoadingStates(true);
      Axios.post(`${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_states`)
        .then(({ status, data }) => {
          if (status === 200) {
            if (data.data) {
              setStates(data.data);
            }
          }
        })
        .catch((error) =>
          console.log("Error getting organization sectors", error)
        )
        .finally(() => {
          setIsLoadingStates(false);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);

  useEffect(() => {
    if (selectedStateId !== 0 && states.length) {
      const state = states.find((state) => state.id == selectedStateId);
      if (state) {
        setStateLga(state?.lga);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedStateId]);

  useEffect(() => {
    if (locationModalAction == "update" && states.length) {
      const state = states.find((_state) => {
        return _state?.id == stateId;
      });
      if (state) {
        setSelectedStateId(state.id);
        const lgas = state?.lga
          ?.map((lga) => {
            if (surveyData?.lga_ids?.includes(lga?.id)) {
              return lga;
            }
          })
          .filter((lga) => lga);
        setSelectedLgas(lgas);
        setOldLgas(lgas);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stateId]);

  return (
    <div
      data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a509"
      className="modal"
      style={{
        display: openModal ? "flex" : "none",
        opacity: openModal ? 1 : 0,
      }}
    >
      <div className="modal-container">
        <h2>Add a Location</h2>
        <div data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a50d">
          <svg
            onClick={() => setOpenModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card-location-filter"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-header"></div>
          <div className="modal-body" style={{ minHeight: "280px" }}>
            <div className="no-margin w-form">
              <label htmlFor="Industry-or-Interet-2">State</label>
              <select
                id="Industry-or-Interet-2"
                name="Industry-or-Interet-2"
                data-name="Industry Or Interet 2"
                className="input large w-select"
                value={selectedStateId}
                onChange={handleSelectedState}
              >
                <option value="">
                  {" "}
                  {isLoadingStates ? "Loading ..." : "Select State..."}{" "}
                </option>
                {states.map((state) => {
                  return (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  );
                })}
              </select>
              &nbsp;
              <label htmlFor="Industry-or-Interet-2">
                Local Government Area
              </label>
              <div
                style={{ minHeight: "50px" }}
                data-hover="false"
                data-delay="0"
                id="w-node-f5104c23-d019-0d24-519f-928b04075c47-2a3b9cb4"
                className="input large nudge-left w-dropdown"
              >
                <div
                  className="dropdown-toggle-2 w-dropdown-toggle"
                  id="w-dropdown-toggle-0"
                  aria-controls="w-dropdown-list-0"
                  aria-haspopup="menu"
                  aria-expanded="false"
                  role="button"
                  tabIndex="0"
                >
                  <div
                    className="icon-3 w-icon-dropdown-toggle"
                    onClick={() => setShowLgaDropdown(!showLgaDropdown)}
                    aria-hidden="true"
                  ></div>
                  <div
                    className="dropdown-toggle-2"
                    style={{ width: "470px", overflow: "scroll" }}
                  >
                    {selectedLgas?.map((_lga) => {
                      return (
                        <a
                          key={_lga.id}
                          href="#"
                          className="button with-object tag location dropdown w-button"
                          onClick={() => handleSelectedLga(_lga)}
                        >
                          {_lga.name}
                        </a>
                      );
                    })}
                  </div>
                </div>
                {showLgaDropdown ? (
                  <nav
                    className="dropdown-list-input w-dropdown-list w--open"
                    id="w-dropdown-list-0"
                    aria-labelledby="w-dropdown-toggle-0"
                    style={{
                      height: "112px",
                    }}
                  >
                    <div className="search survey">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        strokeLinejoin="round"
                        className="feather feather-search icon"
                        src="https://cdn.prod.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                        alt=""
                      >
                        <circle cx="11" cy="11" r="8"></circle>
                        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
                      </svg>
                      <input
                        className="input survey-search w-input"
                        maxlength="256"
                        name="field-6"
                        data-name="Field 6"
                        placeholder="Search"
                        type="text"
                        id="field-6"
                        required=""
                        onChange={(e) => {
                          setQ(e.target.value);
                        }}
                      />
                    </div>
                    <div
                      className="column"
                      style={{ marginLeft: "-12px", maxHeight: "120px" }}
                    >
                      {(filteredSelectedLgas?.length
                        ? filteredSelectedLgas
                        : stateLga
                      ).map((lga) => {
                        const inSelectedLga = selectedLgas?.some(
                          (item) => item.id === lga.id
                        );
                        return (
                          <label
                            key={lga.id}
                            value={lga.id}
                            className="w-checkbox checkbox-field-2 move-down dropdown"
                          >
                            <input
                              type="checkbox"
                              id="checkbox-11"
                              name="checkbox-11"
                              data-name="Checkbox 11"
                              className="w-checkbox-input checkbox"
                              checked={inSelectedLga ? true : false}
                              onChange={() => handleSelectedLga(lga)}
                            />
                            <span
                              className="checkbox-label dropdown w-form-label"
                              htmlFor="checkbox-11"
                            >
                              {lga.name}
                            </span>
                          </label>
                        );
                      })}
                    </div>
                  </nav>
                ) : (
                  ""
                )}
              </div>
              {!showLgaDropdown ? (
                <div className="row col-16">
                  <input
                    onClick={() => handleDeleteLocation()}
                    type="button"
                    value="Delete"
                    data-wait="Please wait..."
                    data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a519"
                    className="button danger wide w-button"
                  />
                  &nbsp; &nbsp;
                  <input
                    onClick={() => {
                      if (locationModalAction == "new") {
                        handleAddLocation();
                      } else {
                        handleUpdateLocation();
                      }
                    }}
                    type="button"
                    value="Save Changes"
                    data-wait="Please wait..."
                    data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a51a"
                    className="button primary wide w-button"
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddLocationModal;
