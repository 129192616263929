import React, { useState } from "react";
import axios from "axios";
import UpgradeModal from "../Listen/UpgradeModal";
import { getUser, getIdToken } from "../../utils/storage";

const SearchTerms = (props) => {
  console.log("ig", process.env.REACT_APP_IG_REDIRECT_URL);
  const {
    client,
    setToast,
    team,
    fbIntegration,
    igIntegration,
    handleFBDisconnect,
    handleIGDisconnect,
    isFbDisconnecting,
    isIgDisconnecting,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [searchTerms, setSearchTerms] = useState({
    brandName: client.brandName,
    otherBrandNames: client.otherBrandNames,
    twitterHandles: client.twitterHandles,
    countriesToTrack: client.countriesToTrack || [],
    languagesToTrack: client.languagesToTrack || [],
  });

  const [isUpdatingSearchTerms, setIsUpdatingSearchTerms] = useState(false);

  const [openUpgradeModal, setOpenUpgradeModal] = useState(false);

  const handleChangeSearchTerms = (e) => {
    setSearchTerms({ ...searchTerms, [e.target.name]: e.target.value });
  };

  const handleSubmitSearchTerms = () => {
    setIsUpdatingSearchTerms(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_change_search_terms`,
        {
          searchTerms,
          email: user.email,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ status, data }) => {
        setIsUpdatingSearchTerms(false);
        if (status === 200) {
          setToast({
            show: true,
            type: "success",
            message: data.message || "Successfully updated search terms",
          });
        }
      })
      .catch((error) => {
        setIsUpdatingSearchTerms(false);
        console.log("Error updating search terms", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.response.data.message ||
            "We had problems updating search terms",
        });
      });
  };

  return (
    <div className="card-2">
      <div className="card-section">
        <h3>Search terms</h3>
        <div className="form-block fill throw-down w-form">
          <div>
            <div className="control">
              <label>Brand name</label>
              <input
                type="text"
                className="input med"
                maxLength="256"
                disabled={team ? (team.canEdit ? false : true) : true}
                defaultValue={client.brandName}
                name="brandName"
                onChange={handleChangeSearchTerms}
              />
              <label className="helper">
                Versus will make this name top priority
              </label>
            </div>
            <div className="control">
              <label>Other brand names</label>
              <textarea
                maxLength="5000"
                className="input textarea large fill"
                disabled={team ? (team.canEdit ? false : true) : true}
                defaultValue={client.otherBrandNames}
                name="otherBrandNames"
                onChange={handleChangeSearchTerms}
              ></textarea>
              <label className="helper">
                Other ways people refer to your brand. Separate with a comma.
                Ex: Acme, Acme corp, Acme bank, etc.
                <br />‍<br />
                Versus will track these mentions too.
              </label>
            </div>
            <div className="control">
              <label>Twitter handles</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="twitterHandles"
                placeholder="@exampleHandle"
                defaultValue={client.twitterHandles}
                disabled={team ? (team.canEdit ? false : true) : true}
                onChange={handleChangeSearchTerms}
              />
              <label className="helper">
                Your brand’s twitter handles. Separate with a comma.
                <br />
              </label>
            </div>
            <div className="control">
              <label>Account type</label>
              <select
                id="Account-Type"
                name="twitterAccountType"
                dataname="field"
                className="input select large w-select"
                defaultValue={client.twitterAccountType}
                disabled={team ? (team.canEdit ? false : true) : true}
                onChange={handleChangeSearchTerms}
              >
                <option value="">Select one...</option>
                <option value="Basic">Basic</option>
                <option value="Pro">Pro</option>
              </select>
            </div>
            <div className="control">
              <label htmlFor="field-8">Authentication token</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="twitterAuthToken"
                dataname="Field 5"
                placeholder="*************************"
                id="Auth-Token"
                defaultValue={client.twitterAuthToken}
                disabled={team ? (team.canEdit ? false : true) : true}
                onChange={handleChangeSearchTerms}
              />
            </div>

            {fbIntegration && fbIntegration.isFbPageConnected ? (
              <>
                <div className="divider spacer"></div>
                <div className="control">
                  <label>Facebook Page</label>
                  <input
                    type="text"
                    className="input large w-input"
                    maxLength="256"
                    name="facebookPage"
                    defaultValue={fbIntegration.fbPageName}
                    disabled
                  />
                  <label className="helper">
                    You are currently receiving mentions from this Facebook page
                    <br />
                  </label>
                  {/* <p className="nudge-left">{client.fbPageName}</p> */}
                  <button
                    onClick={handleFBDisconnect}
                    className="button small w-button is-disabled"
                  >
                    {isFbDisconnecting ? "disconnecting..." : "Disconnect"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="divider spacer"></div>
                <div className="control">
                  <label>Facebook Page</label>

                  <a
                    href={`${process.env.REACT_APP_FB_GRAPH_API_BASEURL}/dialog/oauth?client_id=${process.env.REACT_APP_FB_APP_ID}&redirect_uri=${process.env.REACT_APP_FB_REDIRECT_URL}&state=${client.clientRef}***&scope=public_profile,pages_show_list,pages_manage_metadata,pages_read_user_content`}
                    className="button small w-button"
                    // target="_blank"
                    // rel="noopener noreferrer"
                  >
                    Connect
                  </a>
                </div>
              </>
            )}
            {igIntegration && igIntegration.isIgAccountConnected ? (
              <>
                <div className="divider spacer"></div>
                <div className="control">
                  <label>Instagram Account</label>
                  <input
                    type="text"
                    className="input large w-input"
                    maxLength="256"
                    name="instagramAccount"
                    defaultValue={`${igIntegration.igAccountName} | ${igIntegration.igAccountUsername}`}
                    disabled
                  />
                  <label className="helper">
                    You are currently receiving mentions from this Instagram
                    page
                    <br />
                  </label>
                  {/* <p className="nudge-left">{client.fbPageName}</p> */}
                  <button
                    onClick={handleIGDisconnect}
                    className="button small w-button is-disabled"
                  >
                    {isIgDisconnecting ? "disconnecting..." : "Disconnect"}
                  </button>
                </div>
              </>
            ) : (
              <>
                <div className="divider spacer"></div>
                <div className="control">
                  <label>Instagram Account</label>

                  <a
                    href={`${process.env.REACT_APP_FB_GRAPH_API_BASEURL}/dialog/oauth?client_id=${process.env.REACT_APP_FB_APP_ID}&redirect_uri=${process.env.REACT_APP_IG_REDIRECT_URL}&state=${client.clientRef}***&scope=public_profile,pages_show_list,instagram_basic,pages_manage_metadata,instagram_manage_comments`}
                    className="button small w-button"
                    // target="_blank"
                    // rel="noopener noreferrer"
                  >
                    Connect
                  </a>
                </div>
              </>
            )}
            <div className="divider spacer"></div>
          </div>
        </div>

        {isUpdatingSearchTerms ? (
          <span className="button is-disabled small primary w-button">
            Saving changes...
          </span>
        ) : (
          <span
            className="button small primary w-button"
            onClick={handleSubmitSearchTerms}
          >
            Save changes
          </span>
        )}
      </div>
      <UpgradeModal
        client={client}
        showUpgradeModal={openUpgradeModal}
        toggleShowUpgradeModal={() => setOpenUpgradeModal(false)}
      />
    </div>
  );
};

export default SearchTerms;
