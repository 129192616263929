import React from "react";

import type { ComparisonBrand, ComparisonBrands } from "../types/types";
import BrandSelect from "./BrandSelect";

const BrandSelector = (props) => {
  const {
    brand1,
    brand2,
    brand3,
    brand4,
    comparisonBrands,
    setBrand1,
    setBrand2,
    setBrand3,
    setBrand4,
  } = props;

  return (
    <div className="row baseline">
      {brand1 ? (
        <div>
          <div className="dropdown move-left">
            <BrandSelect
              brand={brand1}
              brands={comparisonBrands}
              setBrand={setBrand1}
            />
          </div>
          {comparisonBrands.length > 1 ? (
            <div className="move-left meta push-left">vs</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {brand2 ? (
        <div>
          <div className="dropdown move-left">
            <BrandSelect
              brand={brand2}
              brands={comparisonBrands}
              setBrand={setBrand2}
            />
          </div>
          {comparisonBrands.length > 2 ? (
            <div className="move-left meta push-left">vs</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {brand3 ? (
        <div>
          <div className="dropdown move-left">
            <BrandSelect
              brand={brand3}
              brands={comparisonBrands}
              setBrand={setBrand3}
            />
          </div>
          {comparisonBrands.length > 3 ? (
            <div className="move-left meta push-left">vs</div>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {brand4 ? (
        <div>
          <div className="dropdown move-left">
            <BrandSelect
              brand={brand4}
              brands={comparisonBrands}
              setBrand={setBrand4}
            />
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
};

export default BrandSelector;
