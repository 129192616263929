import React, { useState } from "react";
import HeaderTitle from "./HeaderTitle";
import SummaryCards from "./SummaryCards/SummaryCards";
import RequestsListTitle from "./RequestsListTitle";
import RequestsCards from "./RequestsCards/RequestsCards";
import SurveySearchAndSort from "../../Shared/SurveySearchAndSort";

const RequestsSummary = (props) => {
  const {
    toggleNewRequestModalOpen,
    toggleShowMakeDepositDialog,
    tooltip,
    setTooltip,
    isFilterExpanded,
    setIsFilterExpanded,
    filter,
  } = props;
  const [requests, setRequests] = useState([]);
  const [filteredRequests, setFilteredRequests] = useState([]);

  return (
    <div>
      <div className="container-sticky">
        <HeaderTitle tooltip={tooltip} setTooltip={setTooltip} />
        <SummaryCards
          toggleNewRequestModalOpen={toggleNewRequestModalOpen}
          toggleShowMakeDepositDialog={toggleShowMakeDepositDialog}
          tooltip={tooltip}
          setTooltip={setTooltip}
        />
        <SurveySearchAndSort
          allSurveysList={requests}
          setAllSurveysList={setRequests}
          filteredSurveysList={filteredRequests}
          setFilteredSurveysList={setFilteredRequests}
          isFilterExpanded={isFilterExpanded}
          setIsFilterExpanded={setIsFilterExpanded}
        />
      </div>
      <div className="request-tab-menu row center move-up push-down grey-border-bottom">
        <RequestsListTitle
          toggleNewRequestModalOpen={toggleNewRequestModalOpen}
          tooltip={tooltip}
          setTooltip={setTooltip}
        />
      </div>
      <RequestsCards
        requests={filteredRequests.length ? filteredRequests : requests}
        setRequests={setRequests}
        filter={filter}
      />
    </div>
  );
};

export default RequestsSummary;
