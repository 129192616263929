import React from "react";

const RespondModal = (props) => {
  const { client, respondModal, handleChangeResponseEmailData } = props;

  const responseEmails = client?.responseEmails?.split(", ");

  return (
    <div
      className="respond-step"
      style={{
        display: respondModal.isOpen ? "block" : "none",
        width: "600px",
        opacity: respondModal.isOpen ? 1 : 0,
      }}
    >
      <div className="modal-body">
        <div className="">
          <div>
            <div className="control throw-down">
              <label htmlFor="email">Select pr email</label>
              <select
                className="input select large"
                name="email"
                onChange={handleChangeResponseEmailData}
              >
                <option value="">Select email</option>
                {responseEmails &&
                  responseEmails.map((email, i) => (
                    <option value={email} key={i}>
                      {email}
                    </option>
                  ))}
              </select>
            </div>
            <div className="control">
              <label htmlFor="message">Compose your message</label>
              <textarea
                maxLength="5000"
                placeholder="Write here..."
                className="input textarea fill"
                name="message"
                onChange={handleChangeResponseEmailData}
              ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RespondModal;
