import React from "react";

const Spacer = ({ isNavExpanded, isFilterExpanded, screen }) => {
  const getWidth = () => {
    if (isFilterExpanded) {
      return screen === "interact" ? "200px" : "320px";
    }
    if (isNavExpanded) return "252px";
    return "82px";
  };

  return <div className="spacer h100" style={{ width: getWidth() }}></div>;
};

export default Spacer;
