import React from "react";

const FullWidthLoader = () => {
  return (
    <div className="loader-wrapper">
      <div className="loader">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
};

export default FullWidthLoader;
