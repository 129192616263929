import React, { useRef } from "react";
import NPS from "../../../images/NPS.png";
import CustomSelect from "../../Shared/CustomSelect";
import UseDropDown from "../../CustomHooks/useDropdown";
import { NPS_OPTIONS_GROUP } from "../../Shared/constants";

const Nps = ({
  isNewQuestionModalOpen,
  newQuestionData,
  newQuestionStep,
  setNewQuestionStep,
  handleQuestion,
  handleSubmitQuestion,
  text = "Add question",
  isSubmitting,
  resetNewQuestionData,
  questionType,
  choices,
  handleConditionalBranching,
}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive, activeMenu, setActiveMenu] = UseDropDown(
    dropdownRef,
    false
  );

  const handleDropDown = (e) => {
    const menuId = e.currentTarget.id;

    setActiveMenu(menuId);
    setIsActive(true);
    if (menuId === activeMenu) {
      setActiveMenu(null);
      setIsActive(false);
    }
  };

  const getChoiceByNpsGroup = (npsGroupUpperLimit) => {
    const choice = choices.find(
      (c) =>
        c.text?.option.length && Number(c.text.option) === npsGroupUpperLimit
    );
    if (!choice) return null;
    return choice;
  };

  return (
    <>
      <div
        className="card active"
        style={{
          opacity:
            isNewQuestionModalOpen &&
            newQuestionStep === "two" &&
            newQuestionData.optionsType === "nps"
              ? 1
              : 0,
          display:
            isNewQuestionModalOpen &&
            newQuestionStep === "two" &&
            newQuestionData.optionsType === "nps"
              ? "block"
              : "none",
        }}
      >
        <div className="card-section-2">
          <span
            // onClick={() => setNewQuestionStep("one")}
            onClick={() => {
              setNewQuestionStep("one");
              // reset input fields and choices
              resetNewQuestionData();
            }}
            className="with-icon w--current"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left icon nudge-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
            Back
          </span>
          <div className="callout move-down">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-info icon positive-2 push-left"
              src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
              loading="lazy"
              alt=""
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
            <p
              style={{ marginTop: "0px", marginBottom: "0px" }}
              className="helper no-margin expand positive-2"
            >
              You must apply branching after creating your survey questions.
            </p>
          </div>
          <div className="tag short option move-up push-down">NPS</div>
          <div className="no-margin w-form">
            <div>
              <label htmlFor="question-nps">Question</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="name-6"
                data-name="Name 6"
                placeholder="How likely are you to..."
                id="question-nps"
                onChange={handleQuestion}
                value={newQuestionData.question}
              />
              <label htmlFor="email" className="push-down">
                Answer format
              </label>
              <a href="#" data-tooltip="This is what the user sees">
                <img
                  src={NPS}
                  // srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5d5aca943f27890e5287dd3c_Screenshot%202019-08-19%20at%205.12.47%20PM-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5d5aca943f27890e5287dd3c_Screenshot%202019-08-19%20at%205.12.47%20PM-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5d5aca943f27890e5287dd3c_Screenshot%202019-08-19%20at%205.12.47%20PM-p-1080.png 1080w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5d5aca943f27890e5287dd3c_Screenshot%202019-08-19%20at%205.12.47%20PM.png 1346w"
                  sizes="100vw"
                  alt=""
                  className="rounded fill"
                />
              </a>

              {NPS_OPTIONS_GROUP.map((optionsGroup, id) => (
                <div key={id} className="w-layout-grid grid-17">
                  <input
                    type="text"
                    className="input nudge-left large w-input"
                    maxLength="256"
                    name="nps"
                    placeholder={optionsGroup.label}
                    disabled
                  />
                  <CustomSelect
                    id={id}
                    ref={dropdownRef}
                    disabled={
                      newQuestionData.disableBranching ||
                      questionType !== "edit"
                    }
                    placeholder={
                      Boolean(
                        getChoiceByNpsGroup(optionsGroup.upperLimit)?.text
                          ?.skip_logic.jump_questionText
                      )
                        ? getChoiceByNpsGroup(optionsGroup.upperLimit)?.text
                            ?.skip_logic.jump_questionText
                        : "No branching"
                    }
                    activeMenu={activeMenu}
                    isActive={isActive}
                    optionsType={newQuestionData.optionsType}
                    handleClick={handleDropDown}
                  >
                    <a
                      // href="#"
                      className="w-dropdown-link"
                      tabIndex={id}
                      onClick={() => {
                        handleConditionalBranching(
                          getChoiceByNpsGroup(optionsGroup.upperLimit)?.text
                            .id ?? optionsGroup.upperLimit,
                          "nps",
                          null,
                          false,
                          optionsGroup.name
                        );
                        setActiveMenu(null);
                        setIsActive(false);
                      }}
                    >
                      No branching
                    </a>

                    {newQuestionData.otherQuestions &&
                      newQuestionData.otherQuestions.length > 0 &&
                      newQuestionData.otherQuestions.map((q, i) => (
                        <a
                          // href="#"
                          key={q.questionRef}
                          style={{
                            textOverflow: "ellipsis",
                            overflowX: "clip",
                          }}
                          className="w-dropdown-link"
                          tabIndex={id}
                          onClick={() => {
                            handleConditionalBranching(
                              getChoiceByNpsGroup(optionsGroup.upperLimit)?.text
                                .id ?? optionsGroup.upperLimit,
                              "nps",
                              q.questionRef,
                              false,
                              optionsGroup.name
                            );
                            setActiveMenu(null);
                            setIsActive(false);
                          }}
                        >
                          {`${q.sequence}. ${q.question}`}
                        </a>
                      ))}
                    <a
                      // href="#"
                      className="w-dropdown-link"
                      tabIndex={id}
                      onClick={() => {
                        handleConditionalBranching(
                          getChoiceByNpsGroup(optionsGroup.upperLimit)?.text
                            .id ?? optionsGroup.upperLimit,
                          "nps",
                          null,
                          true,
                          optionsGroup.name
                        );
                        setActiveMenu(null);
                        setIsActive(false);
                      }}
                    >
                      End survey
                    </a>
                  </CustomSelect>
                </div>
              ))}

              {isSubmitting ? (
                <button
                  className="button is-disabled wide w-button"
                  style={{ cursor: "not-allowed" }}
                >
                  {text}
                </button>
              ) : (
                <button
                  className="button primary wide w-button"
                  onClick={handleSubmitQuestion}
                >
                  {text}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Nps;
