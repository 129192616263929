import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

import InnerLoader from "../../../InnerLoader";
import RequestCard from "./RequestCard";

const RequestsCards = (props) => {
  const { requests, setRequests, filter } = props;
  const [isLoading, setIsLoading] = useState(true);
  const params = {
    uid: getUser().uid,
    idToken: getIdToken(),
    clientRef: getClient().clientRef,
  };
  const newParams = filter.sortField
    ? { ...params, sortField: filter.sortField, sortOrder: filter.sortOrder }
    : params;
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_requests`,
        newParams,
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setIsLoading(false);
        setRequests(data.requests);
      })
      .catch((error) => {
        //set isloading to false since the value is null
        setIsLoading(false);
        console.log("Error getting client requests", error);
      });
  }, [filter]);

  return (
    <div className="row has-cards wrap stretch">
      {isLoading ? <InnerLoader /> : <RequestCard requests={requests} />}
    </div>
  );
};

export default RequestsCards;
