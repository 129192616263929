import React, { PureComponent } from "react";
import Chart from "chart.js";

let thisLineChart;

export default class MentionsLineChart extends PureComponent {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    if (typeof thisLineChart !== "undefined") thisLineChart.destroy();

    thisLineChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: this.props.brand1.brandName,
            borderColor: "#7950f2",
            backgroundColor: "transparent",
            lineTension: 0,
            pointBackgroundColor: "#7950f2",
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "#7950f2",
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: this.props.brand1Data ? this.props.brand1Data.mentions : [],
          },
          {
            label: this.props.brand2 ? this.props.brand2.brandName : null,
            borderColor: "RGBA(82, 172, 244, 0.2)",
            backgroundColor: "transparent",
            lineTension: 0,
            pointBackgroundColor: "#4dabf7",
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "RGBA(82, 172, 244, 0.2)",
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: this.props.brand2Data ? this.props.brand2Data.mentions : [],
          },
          {
            label: this.props.brand3 ? this.props.brand3.brandName : null,
            borderColor: "RGBA(66, 216, 170, 0.2)",
            backgroundColor: "transparent",
            pointBackgroundColor: "#38d9a9",
            lineTension: 0,
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "RGBA(66, 216, 170, 0.2)",
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(220,220,220,1)",
            data: this.props.brand3Data ? this.props.brand3Data.mentions : [],
          },
          {
            label: this.props.brand4 ? this.props.brand4.brandName : null,
            borderColor: "rgba(244, 197, 72, 0.2)",
            backgroundColor: "transparent",
            pointBackgroundColor: "rgba(244, 197, 72, 1.00)",
            lineTension: 0,
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "rgba(244, 197, 72, 0.2)",
            borderWidth: 2,
            pointBorderWidth: 0,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "rgba(244, 197, 72, 1.00)",
            data: this.props.brand4Data ? this.props.brand4Data.mentions : [],
          },
        ],
      },
      options: {
        tooltips: {
          backgroundColor: "#212529",
          mode: "index",
          intersect: false,
          titleFontFamily: "sans-serif",
          titleFontStyle: "500",
          bodyFontFamily: "sans-serif",
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 8,
              },
              gridLines: {
                drawBorder: false,
                color: "#dee2e6",
                borderDash: [8, 8],
                borderWidth: 1,
                lineWidth: 1,
                zeroLineBorderDash: [8, 8],
                zeroLineColor: "#dee2e6",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontFamily: "proxima nova",
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
              },
              gridLines: {
                drawBorder: false,
                display: false,
                color: "#dee2e6",
                borderDash: [8, 8],
                borderWidth: 1,
                lineWidth: 1,
              },
            },
          ],
        },
      },
    });
  };

  render() {
    return (
      <div>
        <div className="chart-bg-2">
          <div className="html-embed sentiment-chart w-embed">
            <canvas
              id="mentionReach"
              width="940"
              height="180"
              ref={this.chartRef}
            ></canvas>
          </div>
        </div>
      </div>
    );
  }
}
