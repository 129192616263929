import React from "react";
import DummyButton from "../../DummyButton";
import DisplayTaskCategory from "./DisplayTaskCategory";

const SampleSize = ({
  country,
  tooltip,
  setTooltip,
  activeStep,
  surveyData,
  insuffientFunds,
  showDummyButton,
  numberOfRespondents,
  gotoSurveyStep,
  handleNewSurveyData,
  usdPerRespondent_interact = 0.2,
  versusCreditsPerRespondent = 1,
  handleCreateNewSurvey,
  surveyType = "campaign",
}) => {
  return (
    <div
      className="step-3 fill w-form"
      style={{
        display: activeStep === "three" ? "block" : "none",
        opacity: activeStep === "three" ? 1 : 0,
        height: activeStep === "three" ? "100%" : "0px",
        width: "600px",
      }}
    >
      <div>
        <div className="subhead no-margin">
          {`${surveyData.campaignName || surveyData.requestName}: ${
            surveyData.description.length <= 35
              ? surveyData.description
              : surveyData.description.slice(0, 35)
          }...`}{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => gotoSurveyStep("one")}
            className="primary"
          >
            Edit
          </span>
        </div>
        {surveyType == "request" && (
          <DisplayTaskCategory
            surveyData={surveyData}
            gotoSurveyStep={gotoSurveyStep}
          />
        )}
        <div className="subhead no-margin move-down">
          {`${surveyData.gender}, ${surveyData.startAge}-${surveyData.endAge}, in ${country}`}{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => gotoSurveyStep("two")}
            className="primary"
          >
            {" "}
            Edit
          </span>
        </div>
        <label
          htmlFor="name-3"
          className="field-label white-text large throw-down move-up"
        >
          How many respondents do you want?
        </label>
        <div
          style={{
            display: tooltip.step === 5.6 ? "flex" : "none",
            width: "300px",
          }}
          className=" inline-tooltip-content-3 top-left"
        >
          {/* step 2 tool tip */}
          <div className="card-section">
            <div>Total Respondents</div>
            <p className="move-down">
              Here you are going to enter the number of respondents (i.e Scouts)
              you want to target in your survey.{" "}
            </p>

            <div className="inline-tooltip-frame">
              <span
                onClick={() =>
                  setTooltip({
                    show: false,
                    type: "",
                    title: "",
                    message: "",
                    step: 0,
                    max_step: 7,
                  })
                }
              >
                Skip tutorial{" "}
              </span>
              <button
                className="inline-tooltip-button"
                onClick={() =>
                  setTooltip({
                    show: true,
                    type: "",
                    title: "",
                    message: "",
                    step: 3,
                    max_step: 7,
                  })
                }
              >
                Finish{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="row center">
          <input
            type="number"
            id="name-2"
            value={surveyData.numberOfRespondents}
            name="numberOfRespondents"
            // maxLength="256"
            min="1"
            step="1"
            className="input large w-input"
            // defaultValue="500"
            onChange={handleNewSurveyData}
          />
        </div>
        <div className="row center">
          <p className="helper nudge-up">
            Tip: At least <strong className="black-text">384</strong>{" "}
            respondents to ensure 95% confidence level with 5% error margin
          </p>
          <div
            data-tooltip="This reflects industry standard confidence level and margin of error for surveys targeted at a population size of 10,000+"
            className="row center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-help-circle icon small move-right white-text"
              src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
              alt=""
            >
              <circle cx="12" cy="12" r="10"></circle>
              <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
              <line x1="12" y1="17" x2="12.01" y2="17"></line>
            </svg>
            {/* <p className="helper no-margin nudge-right">Info</p> */}
          </div>
        </div>
        <div className="divider darker push-up"></div>
        <label htmlFor="name-3" className="field-label">
          Estimated cost
        </label>
        <p>
          <span className="data primary lighter large">{`${
            surveyData.numberOfRespondents * versusCreditsPerRespondent //* 0.5 * 2
          }`}</span>{" "}
          <span className="primary lighter data">VC</span> or{" "}
          <span className="data large">{`$${(
            surveyData.numberOfRespondents * usdPerRespondent_interact
          ).toFixed(2)}`}</span>{" "}
          per question
        </p>
        <p className="meta fill">{`for ${surveyData.numberOfRespondents} respondents`}</p>
        {showDummyButton || numberOfRespondents <= 0 ? (
          <DummyButton
            extraClassName="primary large"
            text={`${
              showDummyButton ? "Creating Survey ..." : "Create Survey"
            } `}
          />
        ) : (
          <button
            //check if the credit is sufficient
            onClick={
              insuffientFunds
                ? console.log("Insuffient Funds")
                : handleCreateNewSurvey
            }
            className={"button primary large"}
          >
            Create Survey
          </button>
        )}
      </div>
    </div>
  );
};

export default SampleSize;
