import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import querystring from "query-string";

import Newsfeed from "./Newsfeed";
import MentionDetailsModal from "./MentionDetailsModal";
import Filter from "../Filter";
import Listen from "./Listen";
import { useSelector } from "react-redux";

const ListenContainer = (props) => {
  const { client, setToast, isFilterOpen, toggleFilterOpen } = props;

  const isFirstLogin = querystring.parse(useLocation().search).firstlogin;
  const subscriptionPlan = useSelector((s) => s.subscriptionPlan.plan);

  const [filters, setFilters] = useState({
    period: "last 7 days", // "last 30 days", "today"
    sentiment: "any sentiment", // "positive sentiment", "negative sentiment", "neutral sentiment"
    country: "",
    language: "",
    keyword_or_hashtag: "",
    sources: {
      categories: [],
      socialmedia: [],
      news: [],
      others: [],
    },
  });

  const handleChangePeriod = (period) => setFilters({ ...filters, period });
  const handleChangeSentiment = (sentiment) =>
    setFilters({ ...filters, sentiment });
  const handleChangeFilter = (filter, value) =>
    setFilters((prev) => ({ ...prev, [filter]: value }));
  const toggleSource = (source, category, sourcesList) => {
    // console.log(source);
    // console.log(category);
    // console.log(sourcesList);
    // Get a shallow copy of current sources
    const sources = { ...filters.sources }; //filters.sources.slice();

    if (!source) {
      console.log("sourcesList", sourcesList);
      // filter by category
      // Add all sources under referenced category
      sources[category] = sources[category].length > 0 ? [] : sourcesList;
      sources.categories = sources.categories.includes(category)
        ? sources.categories.filter((cat) => category !== cat)
        : sources.categories.concat([category]);
      setFilters({ ...filters, sources });
    } else {
      // Add or remove source if it's present or absent in list
      sources[category] = sources[category].includes(source)
        ? sources[category].filter((src) => source !== src)
        : sources[category].concat([source]);

      // Empty categories if all cource categories are empty
      if (
        sources.news.length <= 0 &&
        sources.socialmedia.length <= 0 &&
        sources.others.length <= 0
      )
        sources.categories = [];
      // Set sources in filter state
      setFilters({ ...filters, sources });
    }
  };
  const handleClearFilters = () =>
    setFilters({
      ...filters,
      period: "last 7 days",
      sentiment: "any sentiment",
      sources: { categories: [], socialmedia: [], news: [], others: [] },
    });

  const [isMentionsfeedOpen, setIsMentionsfeedOpen] = useState(true);

  const [mentionModal, setMentionModal] = useState({
    isOpen: false,
    showMention: true,
    mention: {},
  });

  const [respondModal, setRespondModal] = useState({
    isOpen: false,
  });

  const toggleRespondModal = () => {
    setMentionModal({
      ...mentionModal,
      showMention: !mentionModal.showMention,
    });
    setRespondModal({ ...respondModal, isOpen: !respondModal.isOpen });
  };

  const handleMentionModal = (mentionModal) => setMentionModal(mentionModal);

  const handleCloseMentionModal = () => {
    setRespondModal({ ...respondModal, isOpen: false });
    setMentionModal({
      ...mentionModal,
      isOpen: false,
      showMention: true,
      mention: {},
    });
  };

  const toggleIsMentionsfeedOpen = () =>
    setIsMentionsfeedOpen((isMentionsfeedOpen) => !isMentionsfeedOpen);

  useEffect(() => {
    if (isFirstLogin && isFirstLogin === "true") {
      setToast({
        show: true,
        type: "info",
        message:
          "Data is being fetched. Please check again within the next 24-48 hours.",
      });
    }
  }, [isFirstLogin, setToast]);

  if (!subscriptionPlan.isListenActive) {
    return (
      <div className="content full">
        <div className="sign-up">
          <div className="bottom-breather big-breath">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-zap-off icon large"
            >
              <polyline points="12.41 6.75 13 2 10.57 4.92"></polyline>
              <polyline points="18.57 12.91 21 10 15.66 10"></polyline>
              <polyline points="8 8 3 14 12 14 11 22 16 16"></polyline>
              <line x1="1" y1="1" x2="23" y2="23"></line>
            </svg>
          </div>

          <h1 className="bold">Listen dashboard is turned off</h1>
          <h2>
            Please contact the{" "}
            <a href="mailto:info@versus.africa">Versus Team</a> to add this
            feature.
          </h2>
        </div>
      </div>
    );
  }

  return (
    <>
      <Filter
        isFilterOpen={isFilterOpen}
        filters={filters}
        handleChangePeriod={handleChangePeriod}
        handleChangeSentiment={handleChangeSentiment}
        handleClearFilters={handleClearFilters}
        countriesToTrack={client.countriesToTrack}
        languagesToTrack={client.languagesToTrack}
        handleChangeFilter={handleChangeFilter}
        // onChangeFilter={onChangeFilter}
        // toggleFilterOpen={toggleFilterOpen}
      />
      <div>
        <Listen
          isFilterOpen={isFilterOpen}
          toggleFilterOpen={toggleFilterOpen}
          setToast={setToast}
          isMentionsfeedOpen={isMentionsfeedOpen}
          toggleIsMentionsfeedOpen={toggleIsMentionsfeedOpen}
          client={client}
          filterPeriod={filters.period}
          handleMentionModal={handleMentionModal}
          filterSentiment={filters.sentiment}
          filterCountry={filters.country}
          filterLanguage={filters.language}
          filterKeywordOrHashtag={filters.keyword_or_hashtag}
          toggleSource={toggleSource}
          sources={filters.sources}
        />
        <MentionDetailsModal
          client={client}
          mentionModal={mentionModal}
          handleCloseMentionModal={handleCloseMentionModal}
          respondModal={respondModal}
          toggleRespondModal={toggleRespondModal}
          setToast={setToast}
        />
        <Newsfeed
          client={client}
          setToast={setToast}
          isMentionsfeedOpen={isMentionsfeedOpen}
          toggleIsMentionsfeedOpen={toggleIsMentionsfeedOpen}
          filterPeriod={filters.period}
          filterSentiment={filters.sentiment}
          filterCountry={filters.country}
          filterLanguage={filters.language}
          filterKeywordOrHashtag={filters.keyword_or_hashtag}
          handleChangeFilter={handleChangeFilter}
          handleMentionModal={handleMentionModal}
          sources={filters.sources}
        />
      </div>
    </>
  );
};

export default ListenContainer;
