import React, { useState } from "react";
import DownloadAndShare from "../Shared/DownloadAndShare";

import MentionsSummary from "./MentionsSummary";
import FilterBarViewControl from "./FilterBarViewControl";
import MentionsFeedViewControl from "./MentionsFeedViewControl";
import MentionsCharts from "./MentionsCharts";
import MentionsSources from "./MentionsSources";
// import TrendingKeywords from './TrendingKeywords';
import TopInfluencers from "./TopInfluencers";
import MentionsMostPopular from "./MentionsMostPopular";
// import VersusTeamInsights from './InsightsFromVersusTeam';
import { useSelector } from "react-redux";


const Listen = (props) => {
  const {
    client,
    setToast,
    filterPeriod,
    isFilterOpen,
    toggleFilterOpen,
    isMentionsfeedOpen,
    toggleIsMentionsfeedOpen,
    handleMentionModal,
    filterSentiment,
    filterLanguage,
    filterKeywordOrHashtag,
    filterCountry,
    toggleSource,
    sources,
  } = props;



  const [tooltip, setTooltip] = useState({
    show: true,
    type: "",
    title: "",
    message: "",
    step: 1,
    max_step:7
  });

  const subscriptionPlan = useSelector((state) => state.subscriptionPlan.plan);


  return (
    <div className="content full">
      <div className="row center spaced push-up">
        <FilterBarViewControl
          isFilterOpen={isFilterOpen}
          toggleFilterOpen={toggleFilterOpen}
        />
        <MentionsFeedViewControl
          isMentionsfeedOpen={isMentionsfeedOpen}
          toggleIsMentionsfeedOpen={toggleIsMentionsfeedOpen}
          tooltip={tooltip}
          setTooltip={setTooltip}
        />
      </div>

      <div className="row center spaced">
        <h1 className="no-margin push-left">
          <span className="muted">Showing</span>{" "}
          {filterPeriod.replace(/last/i, "past")}
        </h1>
        {subscriptionPlan.isExportShareReportsActive ? (
          <DownloadAndShare
            period={filterPeriod}
            sentiment={filterSentiment}
            country={filterCountry}
            language={filterLanguage}
            KeywordOrHashtag={filterKeywordOrHashtag}
            sources={sources}
            clientRef={client.clientRef}
            brandName={client.brandName}
            path={"versus_v2_get_pdf_report"}
            reportName="ListenReport"
            tooltip={tooltip}
            setTooltip={setTooltip}
          />
        ) : (
          ""
        )}
        
      </div>
      
      <MentionsSummary
        filterPeriod={filterPeriod}
        client={client}
        setToast={setToast}
        filterSentiment={filterSentiment}
        filterCountry={filterCountry}
        filterLanguage={filterLanguage}
        filterKeywordOrHashtag={filterKeywordOrHashtag}
        sources={sources}
        setTooltip={setTooltip}
        tooltip={tooltip}
      />
      {filterPeriod === "today" ? (
        ""
      ) : (
        <MentionsCharts
          filterPeriod={filterPeriod}
          client={client}
          setToast={setToast}
          filterSentiment={filterSentiment}
          filterCountry={filterCountry}
          filterLanguage={filterLanguage}
          filterKeywordOrHashtag={filterKeywordOrHashtag}
          sources={sources}
          tooltip={tooltip}
          setTooltip={setTooltip}
        />
      )}

      <MentionsSources
        filterPeriod={filterPeriod}
        client={client}
        setToast={setToast}
        filterSentiment={filterSentiment}
        filterCountry={filterCountry}
        filterLanguage={filterLanguage}
        filterKeywordOrHashtag={filterKeywordOrHashtag}
        toggleSource={toggleSource}
        sources={sources}
        setTooltip={setTooltip}
      />

      {/* <TrendingKeywords /> */}
      {subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_ASK_STARTER_PLAN ||
      subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_LISTEN_STARTER_PLAN ? (
        ""
      ) : (
        <TopInfluencers
          filterPeriod={filterPeriod}
          client={client}
          setToast={setToast}
          filterSentiment={filterSentiment}
          filterCountry={filterCountry}
          filterLanguage={filterLanguage}
          filterKeywordOrHashtag={filterKeywordOrHashtag}
          sources={sources}
        />
      )}
      {/* <MentionsGeography /> */}
      {subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_ASK_STARTER_PLAN ||
      subscriptionPlan.subscriptionPlanRef ===
        process.env.REACT_APP_LISTEN_STARTER_PLAN ? (
        ""
      ) : (
        <MentionsMostPopular
          filterPeriod={filterPeriod}
          client={client}
          setToast={setToast}
          handleMentionModal={handleMentionModal}
          filterSentiment={filterSentiment}
          filterCountry={filterCountry}
          filterLanguage={filterLanguage}
          filterKeywordOrHashtag={filterKeywordOrHashtag}
        />
      )}
      {/* <VersusTeamInsights /> */}
    </div>
  );
};

export default Listen;
