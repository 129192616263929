import React, { useEffect, useState } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../../../utils/storage";

const Cost = (props) => {
  const { request } = props;

  const [creditsPerRespondent_interact, setCreditsPerRespondent_interact] =
    useState(0);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client_settings`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setCreditsPerRespondent_interact(
          data.clientSettings.creditsPerRespondent_interact
        );
      })
      .catch((error) => {
        console.log("Error getting client settings:", error);
      });
  }, []);

  if (!request) return "";

  return (
    <div className="call-out push-up">
      <div className="small-text">
        <strong>{request.numberOfRespondents} </strong>respondents
      </div>
      <div className="divider move-down purple"></div>
      <div className="row spaced items-center">
        <div className="small-text uppercase">Total Cost</div>
        <div className="primary bold">
          {(
            request.numberOfRespondents * creditsPerRespondent_interact
          ).toLocaleString()}{" "}
          VC
        </div>
      </div>
    </div>
  );
};

export default Cost;
