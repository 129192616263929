import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

import InnerLoader from "../../../InnerLoader";

const VersusCredits = (props) => {
  const { toggleShowMakeDepositDialog, setTooltip, tooltip, toggleNewRequestModalOpen } = props;
  const [versusCredits, setVersusCredits] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

    //show tooltip only once
    const showNewRequestTooltip = sessionStorage.getItem('showNewRequestTooltip')
    const showTooltip = (status) =>{
      sessionStorage.setItem('showNewRequestTooltip', status );
    }
    const openModal = () => {
      toggleNewRequestModalOpen()
      setTooltip({
        show: false,
        type: "",
        title: "",
        message: "",
        step: 2,
        max_step:5
      })
    }
  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_versus_credits`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setIsLoading(false);
        setVersusCredits(data.versusCredits);
      })
      .catch((error) => {
        console.log("Error getting versus credits", error);
      });
  }, []);

  return (
    <div className="column shrink">
                    {/* add the first step here */}
                    <div
        style={{
          display: tooltip.step === 5.2 && showNewRequestTooltip == null? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content-3 bottom-right"
      >
        {/* step 2 tool tip */}
        <div className="card-section">
          <div>Create Request</div>
          <p className="move-down">
            Click here to send a Media request to your scouts.
          </p>

          <div className="inline-tooltip-frame">
            <span onClick={() => {setTooltip({
              show: false,
              type: "",
              title: "",
              message: "",
              step: 0,
              max_step: 5
            }); showTooltip(false)}}>
              Skip tutorial
          </span>
            <button className="inline-tooltip-button"onClick={() => {openModal(); showTooltip(false)}} >
              Next
          </button>
          </div>
        </div>
      </div>
      
      <div className="card-2">
        <div className="row stretch">
          {isLoading ? (
            <div className="card-section padded">
              <InnerLoader />
            </div>
          ) : (
            <>
              <div className="card-section padded">
                <div className="data huge primary">
                  {versusCredits.toLocaleString()}
                </div>
                <h3 className="move-down">Versus credits</h3>
              </div>
              <div className="card-section padded">
                <span
                  onClick={toggleShowMakeDepositDialog}
                  className="button w-button"
                >
                  Make a Deposit
                </span>
              </div>
            </>
          )}
        </div>

      </div>
    </div>
  );
};

export default VersusCredits;
