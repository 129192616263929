import React from "react";

const RequestsListTitle = (props) => {
  const { toggleNewRequestModalOpen, tooltip, setTooltip } = props;

  return (
    <div className="row spaced center move-up push-down">
      <h2 className="no-margin push-left">
        <span className="muted">All requests</span>
      </h2>

      <span
        className="button subtle with-icon no-margin w-button"
        style={{ marginLeft: "757px" }}
        onClick={() => toggleNewRequestModalOpen()}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-plus-circle icon move-left"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <line x1="12" y1="8" x2="12" y2="16"></line>
          <line x1="8" y1="12" x2="16" y2="12"></line>
        </svg>
        New request
      </span>
    </div>
  );
};

export default RequestsListTitle;
