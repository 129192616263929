import React from "react";

const CustomSelect = React.forwardRef(
  (
    {
      placeholder,
      id,
      children,
      handleClick,
      isActive,
      activeMenu,
      optionsType,
      disabled,
    },
    ref
  ) => {
    return (
      <button
        disabled={disabled}
        id={id}
        data-hover="false"
        data-delay="0"
        style={{
          zIndex: !(isActive && activeMenu == id) ? 0 : "",
          paddingTop: 0,
          paddingBottom: 0,
          pointerEvents: disabled ? "none" : "",
          textOverflow: "ellipsis",
          ...(optionsType !== "freeform" && {
            width: optionsType === "multiplechoice" ? "12rem" : "24rem",
          }),
        }}
        className="input large nudge-left-15 w-select w-dropdown"
        onClick={handleClick}
      >
        <div
          className="dropdown-toggle-2 w-dropdown-toggle"
          id={`w-dropdown-toggle-${id}`}
          aria-haspopup="menu"
          aria-expanded={isActive && activeMenu == id ? "true" : "false"}
          role="button"
          aria-controls={`w-dropdown-list-${id}`}
          tabIndex={id}
        >
          <div
            style={{
              width: "100%",
              textOverflow: "ellipsis",
              overflowX: "clip",
            }}
            className="text-block-5"
          >
            {placeholder}
          </div>
          <svg
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            //   fill="currentColor"
            className="w-6 h-6"
            style={{ height: "1.2rem" }}
          >
            <path
              fillRule="evenodd"
              d="M12.53 16.28a.75.75 0 01-1.06 0l-7.5-7.5a.75.75 0 011.06-1.06L12 14.69l6.97-6.97a.75.75 0 111.06 1.06l-7.5 7.5z"
              clipRule="evenodd"
            />
          </svg>
        </div>

        <nav
          ref={ref}
          style={{
            padding: 0,
            overflowX: "clip",
            width: "100%",
            textOverflow: "ellipsis",
          }}
          className={`dropdown-list-input w-dropdown-list ${
            isActive && activeMenu == id ? "w--open" : ""
          }`}
          id={`w-dropdown-list-${id}`}
          aria-labelledby={`w-dropdown-toggle-${id}`}
        >
          {children}
        </nav>
      </button>
    );
  }
);

export default CustomSelect;
