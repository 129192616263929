import React from "react";
import SurveysTotal from "./SurveysSummary/SurveysTotal";
import QuestionsTotal from "./SurveysSummary/QuestionsTotal";
import ResponsesTotal from "./SurveysSummary/ResponsesTotal";
import VersusCredits from "./SurveysSummary/VersusCredits";

const SurveysSummary = React.forwardRef((props, ref) => {
  const {
    client,
    setToast,
    toggleShowMakeDepositDialog,
    versusCredits,
    toggleNewSurveyModalOpen,
    tooltip,
    setTooltip,
  } = props;
const openModal = () => {
  if(versusCredits){
    toggleNewSurveyModalOpen()
  }
  setTooltip({
    show: true,
    type: "",
    title: "",
    message: "",
    step: 5.2,
    max_step: 5
  })
}
  return (
    <div>
      <div className="row center spaced">
        <h1 className="no-margin push-left">
          <span className="muted">Surveys</span>
        </h1>
        <span
          className={
            versusCredits > 0
              ? `button subtle with-icon no-margin w-button`
              : `subtle with-icon no-margin`
          }
          onClick={versusCredits > 0 ? toggleNewSurveyModalOpen : null}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-plus icon move-left"
          >
            <line x1="12" y1="5" x2="12" y2="19"></line>
            <line x1="5" y1="12" x2="19" y2="12"></line>
          </svg>
          New survey
          <div
        style={{
          display: tooltip.step === 5.1 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content-5 top-right"
      >
        {/* step 2 tool tip */}
        <div className="card-section">
          <div >Create Survey</div>
          <p className="move-down">
            To create a new survey click here
          </p>

          <div className="inline-tooltip-frame">
            <span onClick={() => setTooltip({
              show: false,
              type: "",
              title: "",
              message: "",
              step: 0,
              max_step: 7
            })}>
              Skip tutorial
          </span>
            <button className="inline-tooltip-button"onClick={() => openModal()} >
              Next
          </button>
          </div>
        </div>
      </div>
      
        </span>
        
      </div>
      
      <div className="row has-cards move-down">
        <div className="column">
          <div className="card-2">
            <div className="row stretch">
              <SurveysTotal client={client} setToast={setToast} tooltip={tooltip} setTooltip={setTooltip} />
              
              <QuestionsTotal client={client} setToast={setToast} tooltip={tooltip} setTooltip={setTooltip} />
              <ResponsesTotal client={client} setToast={setToast} tooltip={tooltip} setTooltip={setTooltip} />
            </div>
          </div>
        </div>
        <div className="column shrink">
          <div className="card-2">
      
            <div className="row stretch">
              <VersusCredits client={client} setToast={setToast} tooltip={tooltip} setTooltip={setTooltip} />
              <div ref={ref} className="card-section padded">
                <span
                  onClick={toggleShowMakeDepositDialog}
                  className="button w-button"
                >
                  Make a Deposit
                </span>
                
              </div>
              
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

export default SurveysSummary;
