import React, { useRef, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { ActiveRadio, NotActiveRadio } from "./MultiSelectRadioButton";
import CustomSelect from "../../Shared/CustomSelect";
import UseDropDown from "../../CustomHooks/useDropdown";
import UploadImageModal from "./UploadImageModal";
import AddVideoLinkModal from "./AddVideoLinkModal";

const MultipleChoice = ({
  isNewQuestionModalOpen,
  newQuestionData,
  setNewQuestionData,
  newQuestionStep,
  setNewQuestionStep,
  handleQuestion,
  handleChoiceOptionsReArrangement,
  handleSubmitQuestion,
  text = "Add question",
  choices,
  setToast,
  showChoiceLimit,
  handleAddChoice,
  handleRemoveChoice,
  handleChoiceTextChange,
  handleChoiceMediaChange,
  isSubmitting,
  resetNewQuestionData,
  questionType,
  handleResponseUnits,
  responseUnits,
  showButton,
  handleClick,
  setActionType,
  handleConditionalBranching,
}) => {
  const dropdownRef = useRef(null);
  // const dropdownListRef = useRef(null)
  const [isActive, setIsActive, activeMenu, setActiveMenu] = UseDropDown(
    dropdownRef,
    false
  );

  const [activeChoiceId, setActiveChoiceId] = useState(null);
  const [activeChoiceImageUrl, setActiveChoiceImageUrl] = useState(null);
  const [showVideoLinkModal, setShowVideoLinkModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);
  const [showUploadOptionImageModal, setShowUploadOptionImageModal] =
    useState(false);

  const handleDropDown = (e) => {
    const menuId = e.currentTarget.id;

    setActiveMenu(menuId);
    setIsActive(true);
    if (menuId === activeMenu) {
      setActiveMenu(null);
      setIsActive(false);
    }
  };

  return (
    <>
      <div
        className="card active"
        style={{
          opacity:
            isNewQuestionModalOpen &&
            newQuestionStep === "two" &&
            newQuestionData.optionsType === "multiplechoice"
              ? 1
              : 0,
          display:
            isNewQuestionModalOpen &&
            newQuestionStep === "two" &&
            newQuestionData.optionsType === "multiplechoice"
              ? "block"
              : "none",
        }}
      >
        <div className="card-section-2">
          <span
            // onClick={() => setNewQuestionStep("one")}
            onClick={() => {
              setNewQuestionStep("one");
              // reset input fields and choices
              resetNewQuestionData();
              // reset action
              setActionType("newQuestion");
            }}
            className="with-icon w--current"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left icon nudge-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
            Back
          </span>
          <div className="callout move-down">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-info icon positive-2 push-left"
              src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
              loading="lazy"
              alt=""
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
            <p
              style={{ marginTop: "0px", marginBottom: "0px" }}
              className="helper no-margin expand positive-2"
            >
              You must apply branching after creating your survey questions.
            </p>
          </div>
          <div className="tag short option _3 push-down move-up">
            Multi-choice
          </div>
          <div className="no-margin w-form">
            <div>
              <label htmlFor="question-multichoice">Question</label>
              {newQuestionData?.media_link ? (
                <div
                  style={{
                    paddingTop:
                      newQuestionData?.media_type?.toLowerCase() == "video"
                        ? "56.17021276595745%"
                        : "0",
                    marginTop: "20px",
                    marginBottom: "10px",
                  }}
                  className="video w-video w-embed"
                >
                  {newQuestionData?.media_type?.toLowerCase() == "video" ? (
                    <iframe
                      className="embedly-embed"
                      src={newQuestionData?.media_link}
                      width="940"
                      height="528"
                      allowFullScreen
                      title="Vintage Cars"
                    ></iframe>
                  ) : (
                    <img
                      src={newQuestionData?.media_link}
                      loading="lazy"
                      srcSet={`${newQuestionData?.media_link} 500w, ${newQuestionData?.media_link} 800w, ${newQuestionData?.media_link} 1080w, ${newQuestionData?.media_link} 1200w `}
                      alt=""
                      className="image-6"
                    />
                  )}
                </div>
              ) : (
                ""
              )}
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="name-6"
                data-name="Name 6"
                placeholder="What do you..."
                id="question-multichoice"
                onChange={handleQuestion}
                value={newQuestionData.question}
              />
              <div className="w-layout-grid grid-15">
                <label
                  htmlFor="email"
                  className="push-down"
                  // style={{ width: "100%" }}
                >
                  Set answers
                </label>
                <label
                  htmlFor="email"
                  className="push-down"
                  // style={{ width: "100%" }}
                >
                  If selected, skip to ...
                </label>

                <label
                  htmlFor="email"
                  className="push-down"
                  style={{ opacity: 0 }}
                  // style={{ width: "100%" }}
                >
                  If selected, skip to question
                </label>
              </div>
              <small
                className="important"
                style={{ display: showChoiceLimit ? "block" : "none" }}
              >
                !! There must be at least two choices !!
              </small>
              <DragDropContext onDragEnd={handleChoiceOptionsReArrangement}>
                <Droppable droppableId="choice-container">
                  {(provided) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {choices.map((choice, id) => (
                        <Draggable
                          key={`${id}`}
                          draggableId={`${id}`}
                          index={id}
                        >
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.dragHandleProps}
                              {...provided.draggableProps}
                              // className={`row center ${
                              //   id > 0 ? "move-down" : ""
                              //   }`}
                              className={`w-layout-grid grid-15 ${
                                id > 0 ? "move-down" : ""
                              }`}
                              key={id + 298}
                            >
                              <input
                                type="text"
                                className="input nudge-left large w-input"
                                maxLength="256"
                                name="multiplechoice"
                                placeholder={
                                  choice.text?.option_type === "unspecified"
                                    ? "Other"
                                    : `Choice ${id + 1}`
                                }
                                value={
                                  questionType === "edit"
                                    ? choice.text?.option
                                    : choice.text?.option
                                }
                                onChange={handleChoiceTextChange(
                                  id,
                                  choice.text?.id
                                )}
                              />
                              <CustomSelect
                                id={id}
                                ref={dropdownRef}
                                disabled={
                                  newQuestionData.disableBranching ||
                                  questionType !== "edit"
                                }
                                placeholder={
                                  Boolean(
                                    choice.text?.skip_logic.jump_questionText
                                  )
                                    ? choice.text?.skip_logic.jump_questionText
                                    : "No branching"
                                }
                                activeMenu={activeMenu}
                                isActive={isActive}
                                optionsType={newQuestionData.optionsType}
                                handleClick={handleDropDown}
                              >
                                <a
                                  // href="#"
                                  className="w-dropdown-link"
                                  tabIndex={id}
                                  onClick={() => {
                                    handleConditionalBranching(
                                      choice.text?.id ?? id,
                                      "multiplechoice"
                                    );
                                    setActiveMenu(null);
                                    setIsActive(false);
                                  }}
                                >
                                  No branching
                                </a>

                                {newQuestionData.otherQuestions &&
                                  newQuestionData.otherQuestions.length > 0 &&
                                  newQuestionData.otherQuestions.map((q, i) => (
                                    <a
                                      // href="#"
                                      key={q.questionRef}
                                      style={{
                                        textOverflow: "ellipsis",
                                        overflowX: "clip",
                                      }}
                                      className="w-dropdown-link"
                                      tabIndex={id}
                                      onClick={() => {
                                        handleConditionalBranching(
                                          choice.text?.id ?? id,
                                          "multiplechoice",
                                          q.questionRef
                                        );
                                        setActiveMenu(null);
                                        setIsActive(false);
                                      }}
                                    >
                                      {`${q.sequence}. ${q.question}`}
                                    </a>
                                  ))}
                                <a
                                  // href="#"
                                  className="w-dropdown-link"
                                  tabIndex={id}
                                  onClick={() => {
                                    handleConditionalBranching(
                                      choice.text?.id ?? id,
                                      "multiplechoice",
                                      null,
                                      true
                                    );
                                    setActiveMenu(null);
                                    setIsActive(false);
                                  }}
                                >
                                  End survey
                                </a>
                              </CustomSelect>

                              {/* conditional rendering of the remove svg element for the unspecified */}
                              {choice?.text?.option_type === "unspecified" ||
                              choice?.option_type === "unspecified" ? (
                                <svg
                                  onClick={() => handleRemoveChoice(id)}
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="24"
                                  height="24"
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  stroke="currentColor"
                                  strokeWidth="2"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  className="feather feather-x-circle icon nudge-left nudge-right"
                                  src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                                  alt=""
                                  style={{ marginBottom: "13px" }}
                                >
                                  <circle cx="12" cy="12" r="10"></circle>
                                  <line x1="15" y1="9" x2="9" y2="15"></line>
                                  <line x1="9" y1="9" x2="15" y2="15"></line>
                                </svg>
                              ) : (
                                <div
                                  style={{ height: "100%" }}
                                  className="row spaced center"
                                >
                                  {/* Add button */}
                                  <svg
                                    onClick={handleAddChoice}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-plus-circle icon nudge-left nudge-right"
                                    src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                                    alt=""
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="12" y1="8" x2="12" y2="16"></line>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                  </svg>
                                  {/* Remove button */}
                                  <svg
                                    onClick={() => handleRemoveChoice(id)}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-minus-circle icon nudge-left nudge-right"
                                    src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                                    alt=""
                                  >
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <line x1="8" y1="12" x2="16" y2="12"></line>
                                  </svg>
                                  {/* DnD button */}
                                  <svg
                                    // style={{ display: "none" }}
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-move icon nudge-left nudge-right"
                                    src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                                    alt=""
                                  >
                                    <polyline points="5 9 2 12 5 15"></polyline>
                                    <polyline points="9 5 12 2 15 5"></polyline>
                                    <polyline points="15 19 12 22 9 19"></polyline>
                                    <polyline points="19 9 22 12 19 15"></polyline>
                                    <line x1="2" y1="12" x2="22" y2="12"></line>
                                    <line x1="12" y1="2" x2="12" y2="22"></line>
                                  </svg>
                                  {/* media link button */}
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    onClick={() => {
                                      setShowUploadOptionImageModal(true);
                                      setActiveChoiceId(choice?.text?.id || id);
                                      setActiveChoiceImageUrl(
                                        choice?.text?.image_link
                                      );
                                    }}
                                    width="24"
                                    height="24"
                                    viewBox="0 0 24 24"
                                    fill="none"
                                    stroke="currentColor"
                                    strokeWidth="2"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    className="feather feather-image icon"
                                  >
                                    <rect
                                      x="3"
                                      y="3"
                                      width="18"
                                      height="18"
                                      rx="2"
                                      ry="2"
                                    ></rect>
                                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                                    <polyline points="21 15 16 10 5 21"></polyline>
                                  </svg>
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              {showButton === true && (
                <label
                  htmlFor="email"
                  className="push-down"
                  onClick={handleClick}
                >
                  Add "other"
                </label>
              )}

              <div className="row spaced center push-down mt-3">
                <div className="row">
                  <div className="mt-3">
                    {responseUnits === "single_select" ? (
                      <NotActiveRadio handleUnit={handleResponseUnits} />
                    ) : (
                      <ActiveRadio handleUnit={handleResponseUnits} />
                    )}
                  </div>
                  <div className="move-right" style={{ marginTop: "-3px" }}>
                    Enable multiple selection
                  </div>
                </div>
                <div className="row">
                  <span
                    data-w-id="f267d47f-dc71-7c68-dfd3-e13d90f5fc93"
                    className="button with-icon subtle no-margin small w-button"
                    onClick={() => {
                      setShowUploadImageModal(true);
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-image icon"
                    >
                      <rect
                        x="3"
                        y="3"
                        width="18"
                        height="18"
                        rx="2"
                        ry="2"
                      ></rect>
                      <circle cx="8.5" cy="8.5" r="1.5"></circle>
                      <polyline points="21 15 16 10 5 21"></polyline>
                    </svg>
                  </span>
                  <span
                    data-w-id="c292d606-a939-82d1-787a-ba8912c26f9b"
                    className="button with-icon subtle no-margin small w-button"
                    onClick={() => setShowVideoLinkModal(true)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-video icon"
                    >
                      <polygon points="23 7 16 12 23 17 23 7"></polygon>
                      <rect
                        x="1"
                        y="5"
                        width="15"
                        height="14"
                        rx="2"
                        ry="2"
                      ></rect>
                    </svg>
                  </span>
                  {/* <span data-w-id="f267d47f-dc71-7c68-dfd3-e13d90f5fc99" className="button with-icon subtle small no-margin w-button">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-link-2 icon">
                          <path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
                          <line x1="8" y1="12" x2="16" y2="12"></line>
                        </svg>
                    </span> */}
                </div>
              </div>
              {isSubmitting ? (
                <button
                  className="button is-disabled wide w-button"
                  style={{ cursor: "not-allowed" }}
                >
                  {text}
                </button>
              ) : (
                <button
                  className="button primary wide w-button"
                  onClick={handleSubmitQuestion}
                  disabled={isSubmitting}
                >
                  {text}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showUploadOptionImageModal && (
        <UploadImageModal
          setToast={setToast}
          showModal={showUploadOptionImageModal}
          setShowModal={setShowUploadOptionImageModal}
          mediaLiveUrl={activeChoiceImageUrl}
          setMediaValue={(media) => {
            handleChoiceMediaChange(activeChoiceId)(media.media_link);
          }}
        />
      )}
      {showUploadImageModal && (
        <UploadImageModal
          setToast={setToast}
          showModal={showUploadImageModal}
          setShowModal={setShowUploadImageModal}
          mediaLiveUrl={
            newQuestionData?.media_type == "photo"
              ? newQuestionData?.media_link
              : ""
          }
          setMediaValue={(media) => {
            setNewQuestionData({
              ...newQuestionData,
              ...media,
            });
          }}
        />
      )}
      {showVideoLinkModal && (
        <AddVideoLinkModal
          showModal={showVideoLinkModal}
          setShowModal={setShowVideoLinkModal}
          newQuestionData={newQuestionData}
          setNewQuestionData={setNewQuestionData}
        />
      )}
    </>
  );
};

export default MultipleChoice;
