import { createSlice } from "@reduxjs/toolkit";

export const subscriptionPlanSlice = createSlice({
  name: "subscriptionPlan",
  initialState: {
    plan: "",
  },
  reducers: {
    setSubscriptionPlan: (state, action) => {
      state.plan = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setSubscriptionPlan } = subscriptionPlanSlice.actions;

export default subscriptionPlanSlice.reducer;
