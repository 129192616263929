import React, { PureComponent } from "react";
import Chart from "chart.js";

let thisLineChart;

export default class LineChart extends PureComponent {
  chartRef = React.createRef();

  componentDidMount() {
    this.buildChart();
  }
  componentDidUpdate() {
    this.buildChart();
  }

  buildChart = () => {
    const myChartRef = this.chartRef.current.getContext("2d");

    if (typeof thisLineChart !== "undefined") thisLineChart.destroy();

    thisLineChart = new Chart(myChartRef, {
      type: "line",
      data: {
        labels: this.props.labels,
        datasets: [
          {
            label: "Mentions",
            borderColor: "#be4bdb",
            lineTension: 0,
            backgroundColor: "transparent",
            pointBackgroundColor: "#be4bdb",
            pointBorderColor: "transparent",
            pointHoverBackgroundColor: "#be4bdb",
            borderWidth: 2,
            pointRadius: 0,
            pointHoverRadius: 6,
            pointHighlightFill: "#fff",
            pointHighlightStroke: "#be4bdb",
            data: this.props.data,
          },
        ],
      },
      options: {
        tooltips: {
          backgroundColor: "#212529",
          mode: "index",
          intersect: false,
          titleFontFamily: "sans-serif",
          titleFontStyle: "500",
          bodyFontFamily: "sans-serif",
        },
        legend: {
          display: false,
        },
        scales: {
          yAxes: [
            {
              display: true,
              ticks: {
                beginAtZero: true,
                maxTicksLimit: 5,
                padding: 8,
              },
              gridLines: {
                drawBorder: false,
                color: "#dee2e6",
                borderDash: [8, 8],
                borderWidth: 1,
                lineWidth: 1,
                zeroLineBorderDash: [8, 8],
                zeroLineColor: "#dee2e6",
              },
            },
          ],
          xAxes: [
            {
              ticks: {
                fontFamily: "proxima nova",
                maxTicksLimit: 5,
                maxRotation: 0,
                minRotation: 0,
              },
              gridLines: {
                drawBorder: false,
                display: false,
                color: "#dee2e6",
                borderDash: [8, 8],
                borderWidth: 1,
                lineWidth: 1,
              },
            },
          ],
        },
      },
    });
  };

  render() {
    return (
      <div>
        <div className="chart-bg-2">
          <div className="html-embed sentiment-chart w-embed">
            <canvas
              id="mentionReach"
              width="940"
              height="180"
              ref={this.chartRef}
            ></canvas>
          </div>
        </div>
      </div>
    );
  }
}
