import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../utils/storage";

import Toast from "../Toast/Toast";
import MakeDeposit from "./MakeDeposit";
import EditSurveyModal from "./EditSurveyModal";

const SideBar = (props) => {
  const { questions } = props;
  const user = getUser(),
    idToken = getIdToken(),
    client = getClient();
  const history = useHistory();

  const [toast, setToast] = useState({
    show: false,
    type: "",
    message: "",
  });

  const [campaign, setCampaign] = useState({
    campaignRef: props.campaign.campaignRef,
    status: props.campaign.status,
    numberOfQuestions: props.campaign.questionLength,
    numberOfResponses: props.campaign.responses.numberOfResponses,
    campaignName: props.campaign.campaignName,
    mediaLink: props.campaign.mediaLink || "",
    description: props.campaign.description,
    gender: props.campaign.gender || [],
    startAge: props.campaign.start_age,
    endAge: props.campaign.end_age,
    country: props.campaign.country,
    stateOrRegion: props.campaign.stateOrRegion || [],
    numberOfRespondents: props.campaign.numberOfRespondents || 0,
    interest_id: props.campaign.interest_id || "",
    sports_fan: props.campaign.sports_fan || "",
    soccer_fan: props.campaign.soccer_fan || "",
    favorite_team_id: props.campaign.favorite_team_id || "",
  });

  const [showEditSurveyModal, setShowEditSurveyModal] = useState(false);
  const [isProcessing, setIsProcessing] = useState(false);

  const [showMakeDepositDialog, setShowMakeDepositDialog] = useState(false);

  const toggleShowMakeDepositDialog = () =>
    setShowMakeDepositDialog((showMakeDepositDialog) => !showMakeDepositDialog);

  const toggleShowEditSurveyModal = () =>
    setShowEditSurveyModal((showEditSurveyModal) => !showEditSurveyModal);

  const handleLaunchNow = () => {
    console.log("Launching survey now...");
    setIsProcessing(true);
    setToast({
      show: true,
      type: "processing",
      message: "Launching campaign...",
    });
    const launchCampaign = {
      ...campaign,
      uid: user.uid,
      idToken,
      clientRef: client.clientRef,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_launch_campaign`,
        launchCampaign,
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        setIsProcessing(false);
        if (result.status === 200) {
          history.push(`/dashboard/ask`);
        }
      })
      .catch((error) => {
        console.log("Error updating campaign", error.message);
        setIsProcessing(false);
        setToast({
          show: true,
          type: "warning",
          message:
            error.message ||
            "Something happened when we tried deleting campaign. Please refresh",
        });
      });
  };

  const handleDeleteDraft = () => {
    setToast({ show: true, type: "processing", message: "deleting survey..." });

    const deleteData = {
      campaignRef: campaign.campaignRef,
      uid: user.uid,
      idToken,
      clientRef: client.clientRef,
    };
    setIsProcessing(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_delete_draft_survey`,
        deleteData,
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send toast message for successful edit
          setToast({
            show: true,
            type: "success",
            message: "Successfully deleted campaign",
          });
          setIsProcessing(false);
          history.push(`/dashboard/ask`);
        }
      })
      .catch((error) => {
        console.log("Error deleting campaign", error.message);
        setIsProcessing(false);
        setToast({
          show: true,
          type: "warning",
          message:
            error.message ||
            "Something happened when we tried deleting campaign. Please refresh",
        });
      });
  };

  return (
    <div>
      <div className="side-bar feed ">
        <div className="row spaced center push-down">
          <div className="chip bold push-left pink">
            {campaign.status.toUpperCase()}
          </div>
          {isProcessing ? (
            <span
              className="button subtle  with-icon no-margin w-button "
              style={{ color: "#e6498087", cursor: "not-allowed" }}
            >
              Delete Draft
            </span>
          ) : (
            <span
              className="button danger subtle padded horizontal w-button "
              style={{ color: "#e64980" }}
              onClick={handleDeleteDraft}
            >
              Delete Draft
            </span>
          )}
        </div>
        {/* <div className="chip bold push-up pink">
          {campaign.status.toUpperCase()} 
        </div> */}
        <div>
          <div className="call-out push-up">
            <div className="small-text">
              {" "}
              <strong>{questions.length}</strong>{" "}
              {questions.length > 1 ? "questions" : "question"} x{" "}
              <strong>
                {campaign.numberOfRespondents.toLocaleString("en")}
              </strong>{" "}
              respondents
            </div>
            <div className="divider move-down purple"></div>
            <div className="row spaced center">
              {" "}
              <div className="small-text uppercase">Total Cost</div>
              <div className="primary bold">
                {" "}
                {(
                  questions.length * campaign.numberOfRespondents
                ).toLocaleString("en")}{" "}
                VC{" "}
              </div>
            </div>
            {/* not enough credits */}
            {questions.length * campaign.numberOfRespondents >
            client.versusCredits ? (
              <>
                <div className="divider move-down purple"></div>
                <div className="div-block-12">
                  <div className="row center push-down move-up">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-alert-triangle icon move-left orange"
                      src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                      loading="lazy"
                      alt=""
                    >
                      <path d="M10.29 3.86L1.82 18a2 2 0 0 0 1.71 3h16.94a2 2 0 0 0 1.71-3L13.71 3.86a2 2 0 0 0-3.42 0z"></path>
                      <line x1="12" y1="9" x2="12" y2="13"></line>
                      <line x1="12" y1="17" x2="12.01" y2="17"></line>
                    </svg>
                    <div>
                      <p className="bold-text base-lineheight">
                        Not enough credits
                      </p>
                      <p className="medium-text">
                        Current balance is{" "}
                        <strong>
                          {" "}
                          {client.versusCredits.toLocaleString("en")}{" "}
                        </strong>{" "}
                        VC
                      </p>
                    </div>
                  </div>
                  <button
                    type="button"
                    onClick={toggleShowMakeDepositDialog}
                    className="button primary wide no-margin w-button"
                  >
                    {" "}
                    Buy{" "}
                    {Math.floor(
                      parseInt(
                        questions.length * campaign.numberOfRespondents
                      ) - parseInt(client.versusCredits)
                    ).toLocaleString("en")}{" "}
                    more credits{" "}
                  </button>
                </div>{" "}
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
        <div
          style={{ display: campaign.status === "draft" ? "block" : "none" }}
        >
          <div className="div-block-12">
            <div className="row center push-down">
              {/* not enough credits tooltip/ launch campaign dropdown */}
              {questions.length * campaign.numberOfRespondents >
              client.versusCredits ? (
                <button
                  type="button"
                  disabled
                  data-tooltip="Not enough credits to launch survey"
                  className="button primary wide no-margin w-button"
                >
                  {" "}
                  Launch Survey{" "}
                </button>
              ) : (
                <button
                  type="button"
                  disabled={isProcessing}
                  onClick={handleLaunchNow}
                  className="button primary wide no-margin w-button"
                >
                  {" "}
                  Launch Survey{" "}
                </button>
              )}
              {/* end launch campaign dropdown */}
              {/* <div
                style={{
                  width: isCalendarOpen ? "280px" : "210px",
                  height: isCalendarOpen ? "380px" : "",
                  display: showLaunchSurveyDropdown ? "flex" : "none",
                }}
                className="dropdown-list"
              > */}
              {/* <span className="dropdown-link" onClick={handleLaunchNow}>
                  Launch now
                </span> */}
              {/* <DatePicker
                  selected={surveyLaunchDate}
                  onChange={handleChangeSurveyLaunchDate}
                  onCalendarOpen={() => setIsCalendarOpen(true)}
                  onCalendarClose={() => setIsCalendarOpen(false)}
                />
                <span className="dropdown-link" onClick={handleSetLaunchDate}>
                  Set launch date
                </span> */}
              {/* </div> */}
            </div>
            <span
              style={{ display: "block", textAlign: "center" }}
              className="button subtle center big w-button"
              onClick={toggleShowEditSurveyModal}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-edit-2 icon move-left small"
              >
                <path d="M17 3a2.828 2.828 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5L17 3z" />
              </svg>
              Edit survey info
            </span>
          </div>
        </div>
        <div className="divider in-sidebar"></div>
        <div>
          <div className="data huge">{questions.length}</div>
          <h3 className="move-down">Questions</h3>
        </div>
        <div className="divider in-sidebar"></div>
        <div>
          <div className="data huge violet-text">
            {campaign.numberOfResponses}
          </div>
          <h3 className="violet-text move-down push-up">Respondents</h3>
        </div>
        <div className="divider in-sidebar"></div>
        <div>
          <div className="row spaced">
            <div>
              <div className="data huge primary">0</div>
              <h3 className="move-down primary push-up">
                Filtered Respondents
              </h3>
            </div>
          </div>
        </div>
        {/* <div className="divider in-sidebar"></div>
        <h3 className="move-up">Reports</h3>
        <span
          className="button small no-margin with-icon disabled w-button"
          style={{ cursor: "not-allowed" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-download icon small nudge-left"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>{" "}
          Download report
        </span>
        <span
          className="button small nudge-down with-icon disabled"
          style={{ cursor: "not-allowed" }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mail icon small nudge-left"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>{" "}
          Share with team
        </span> */}
        {/* <div className="dropdown">
          <div
            className="dropdown-list"
            style={{ height: "0px", display: "none" }}
          >
            <a href="#" className="dropdown-link">
              Download PDF
            </a>
          </div>
        </div> */}
      </div>
      {/* Toast */}
      {toast.show ? <Toast toast={toast} setToast={setToast} /> : ""}
      {/* Make deposit modal */}
      {showMakeDepositDialog ? (
        <MakeDeposit
          versusCredits={client.versusCredits}
          toggleShowMakeDepositDialog={toggleShowMakeDepositDialog}
          showMakeDepositDialog={showMakeDepositDialog}
          user={user}
          client={client}
        />
      ) : (
        ""
      )}
      {/* Edit Survey Modal */}
      <EditSurveyModal
        setToast={setToast}
        campaign={campaign}
        setCampaign={setCampaign}
        showEditSurveyModal={showEditSurveyModal}
        toggleShowEditSurveyModal={toggleShowEditSurveyModal}
        user={user}
        idToken={idToken}
        client={client}
      />
    </div>
  );
};

export default SideBar;
