import React from "react";
import MentionModal from "./MentionDetailsModal/MentionModal";

const MentionDetailsModal = (props) => {
  const {
    mentionModal,
    client,
    setToast,
    handleCloseMentionModal,
    respondModal,
    toggleRespondModal,
  } = props;

  if (mentionModal.mention) {
    return (
      <MentionModal
        client={client}
        setToast={setToast}
        mentionModal={mentionModal}
        handleCloseMentionsModal={handleCloseMentionModal}
        respondModal={respondModal}
        toggleRespondModal={toggleRespondModal}
      />
    );
  } else {
    return <div></div>;
  }
};

export default MentionDetailsModal;
