import React, { useState, useEffect } from "react";
import Axios from "axios";

const StateFilter = (props) => {
  const { setToast, states: selectedStates, handleSetStates } = props;

  const [showResults, setShowResults] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [filteredStates, setFilteredStates] = useState([]);
  const [scoutStates, setScoutStates] = useState([]);
  const [searchText, setSearchText] = useState("");

  const handleRemoveSelectedState = (state) => {
    setShowResults(false);
    handleSetStates(state);
  };

  const handleSearchStates = (e) => {
    e.preventDefault();
    let value = e.target.value;
    if (value) {
      setSearchText(value);
      setShowResults(true);
      let val_p = new RegExp(value, "ig");
      let _filteredStates = scoutStates.filter((state) => {
        return val_p.test(state.name);
      });
      setFilteredStates(_filteredStates);
    } else {
      setSearchText("");
      setShowResults(false);
    }
  };

  useEffect(() => {
    const onClick = (e) => {
      // If clicked outside of the filter component(state listing)
      if (!e.target.classList.contains("side-bar-link-state")) {
        setShowResults(false);
        setSearchText("");
      }
    };
    window.addEventListener("click", onClick);
    // cleanUp
    return () => {
      // remove listen when the component is close/is not in view
      window.removeEventListener("click", onClick);
    };
  }, []);

  useEffect(() => {
    setIsLoading(true);
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/scout_get_states`,
      {},
      { headers: { "content-type": "application/json" } }
    )
      .then(({ data, status }) => {
        setScoutStates(data.data.states);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error fetching scout states. Please refresh",
        });
      })
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="push-down">
      <h5 className="top-breather">State</h5>
      <div
        className="search"
        style={{ zIndex: "20", borderRadius: "10px 10px 0px 0px" }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
          alt=""
          className="feather feather-search icon small"
        >
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
        <input
          value={searchText}
          onChange={handleSearchStates}
          type="text"
          maxLength="256"
          name="field-6"
          data-name="Field 6"
          placeholder="Search state(s)"
          id="field-6"
          required="required"
          className="input in-search w-input"
        />
      </div>
      {showResults && (
        <div className="states-search-dropdown">
          {isLoading ? (
            <span> loading ... </span>
          ) : filteredStates.length ? (
            filteredStates.map((state) => (
              <div
                key={state.name}
                onClick={() => {
                  setShowResults(false);
                  handleSetStates(state.name);
                  setSearchText("");
                }}
                className="side-bar-link"
                style={{ cursor: "pointer" }}
              >
                {state.name}
              </div>
            ))
          ) : (
            ""
          )}
        </div>
      )}
      <div
        id="rangeSlider"
        className="fill"
        style={{ marginTop: "10px" }}
      ></div>
      {selectedStates &&
        selectedStates.length > 0 &&
        selectedStates.map((state) => (
          <div
            key={state}
            data-w-id="eeb5a43c-8b38-b652-42e3-70de8537380e"
            className="active-state"
          >
            <div
              className="side-bar-link-state search-border"
              style={{
                margin: "2px -8px",
                padding: "8px",
                color: "#4a5568",
                fontSize: "12px",
                lineHeight: "150%",
                fontWeight: "600",
              }}
            >
              {" "}
              {state} state
            </div>
            <div
              onClick={() => handleRemoveSelectedState(state)}
              className="close-btn"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                alt=""
                className="feather feather-x icon small"
              >
                <line x1="18" y1="6" x2="6" y2="18"></line>
                <line x1="6" y1="6" x2="18" y2="18"></line>
              </svg>
            </div>
          </div>
        ))}
    </div>
  );
};

export default StateFilter;
