import React, { useState } from "react";

const Modal = ({ children, title, openModal, onCloseModal }) => {
  return (
    <div
      data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a509"
      className="modal"
      style={{
        display: openModal ? "flex" : "none",
        opacity: openModal ? 1 : 0,
      }}
    >
      <div className="modal-container">
        <h2>{title}</h2>
        <div data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a50d">
          <svg
            onClick={() => onCloseModal()}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-header"></div>
          <div className="modal-body">{children}</div>
        </div>
      </div>
    </div>
  );
};

export default Modal;
