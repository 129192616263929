import { configureStore } from "@reduxjs/toolkit";
import subscriptionPlan from "./subscriptionPlanSlice";
import surveyFilters from "./surveyFilters";

export default configureStore({
  reducer: {
    subscriptionPlan,
    surveyFilters,
  },
});
