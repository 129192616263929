import React, { useState, useEffect } from "react";
import Axios from "axios";

import { getUser, getIdToken } from "../../../utils/storage";

const SurveyFreeformOptions = (props) => {
  //check to see where i can catch the
  const {
    option,
    questionRef,
    campaignRef,
    setToast,
    states,
    targetFilterObject,
    age,
    gender,
    tribe,
    educationLevel,
    religion,
    relationshipStatus,
    employmentStatus,
    childrenInHousehold,
    personalEarningsMonthly,
    householdEarningsMonthly,
    personsInHousehold,
    childrenUnder18,
    imageLinkExist,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [isInlineMessageVisible, setIsInlineMessageVisible] = useState(false);
  const [optionsData, setOptionsData] = useState({
    totalSurveyRespondents: 0,
    totalQuestionRespondents: 0,
    totalFilteredSurveyRespondents: 0,
    totalFilteredQuestionRespondents: 0,
  });

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_survey_freeform_question_responses_data`,
      {
        uid,
        idToken,
        questionRef,
        campaignRef,
        states,
        age,
        gender,
        tribe,
        educationLevel,
        religion,
        relationshipStatus,
        employmentStatus,
        childrenInHousehold,
        personalEarningsMonthly,
        householdEarningsMonthly,
        personsInHousehold,
        childrenUnder18,
        ...targetFilterObject,
      },
      { headers: { "content-type": "application/json" } }
    )
      .then(({ data, status }) => {
        setOptionsData({
          ...optionsData,
          totalSurveyRespondents: data.totalSurveyRespondents,
          totalQuestionRespondents: data.totalQuestionRespondents,
          totalFilteredSurveyRespondents: data.totalFilteredSurveyRespondents,
          totalFilteredQuestionRespondents:
            data.totalFilteredQuestionRespondents,
        });
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error fetching options data. Please refresh",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    age,
    states,
    gender,
    tribe,
    educationLevel,
    religion,
    relationshipStatus,
    employmentStatus,
    childrenInHousehold,
    personalEarningsMonthly,
    householdEarningsMonthly,
    personsInHousehold,
    childrenUnder18,
    targetFilterObject,
  ]);

  return (
    <div>
      <div className="row" key={option.option}>
        {option?.image_link ? (
          <div className="response-media push-left">
            <img
              src={option?.image_link}
              loading="lazy"
              sizes="60px"
              srcSet={`${option?.image_link} 500w, ${option?.image_link} 800w, ${option?.image_link} 1080w, ${option?.image_link} 1200w `}
              alt=""
              className="image-6"
            />
          </div>
        ) : (
          <>
            {" "}
            {imageLinkExist ? (
              <div className="response-media-no-border push-left"> </div>
            ) : (
              ""
            )}
          </>
        )}
        <div
          className="bar-container has-cover move-down overflow-show"
          style={{ width: "100%" }}
        >
          <div className="medium-text nudge-right">{option.option}</div>
          <div className="data tiny nudge-left violet-text">
            {optionsData.totalFilteredQuestionRespondents === 0
              ? 0
              : (
                  (optionsData.totalFilteredQuestionRespondents /
                    optionsData.totalFilteredSurveyRespondents) *
                  100
                ).toFixed(1)}
            %
          </div>
          <div
            className="measure-bar tall cover inline-message-trigger"
            onMouseEnter={() => setIsInlineMessageVisible(true)}
            onMouseLeave={() => setIsInlineMessageVisible(false)}
          >
            <div
              className="metric violet"
              style={{
                width: `${
                  optionsData.totalFilteredQuestionRespondents === 0
                    ? 0
                    : (optionsData.totalFilteredQuestionRespondents /
                        optionsData.totalFilteredSurveyRespondents) *
                      100
                }%`,
              }}
            ></div>
            <div
              className="metric comparison _70 inline-message-trigger"
              style={{
                left: `${
                  optionsData.totalQuestionRespondents === 0
                    ? 0
                    : (optionsData.totalQuestionRespondents /
                        optionsData.totalSurveyRespondents) *
                      100
                }%`,
              }}
            ></div>
          </div>
          <div
            className="inline-message-content-copy anchor-right auto"
            // style={{ left: '615px', top: '385px' }}
            style={{
              display: isInlineMessageVisible ? "flex" : "none",
              width: "150px",
            }}
          >
            <div className="card-section">
              <div className="row">
                <div className="column no-padding push-left">
                  <div className="data violet-text muted small">
                    {optionsData.totalFilteredQuestionRespondents}
                  </div>
                  <div className="data violet-text small">
                    {optionsData.totalFilteredQuestionRespondents === 0
                      ? 0
                      : (
                          (optionsData.totalFilteredQuestionRespondents /
                            optionsData.totalFilteredSurveyRespondents) *
                          100
                        ).toFixed(1)}
                    %
                  </div>
                  <h3 className="move-down violet-text small-text">Filtered</h3>
                </div>
                <div className="column no-padding">
                  <div className="data muted small">
                    {optionsData.totalQuestionRespondents}
                  </div>
                  <div className="data small">
                    {optionsData.totalQuestionRespondents === 0
                      ? 0
                      : (
                          (optionsData.totalQuestionRespondents /
                            optionsData.totalSurveyRespondents) *
                          100
                        ).toFixed(1)}
                    %
                  </div>
                  <h3 className="move-down small-text">Total</h3>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* {option.option_type === "unspecified" && (
        <FreeFormResponses optionData={optionsData} />
      )} */}
    </div>
  );
};

export default SurveyFreeformOptions;
