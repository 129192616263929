import React, { useState, useEffect } from "react";

const SurveyQuestions = (props) => {
  const { campaignRef, totalQuestionsAndRespondents } = props;

  const [totalQuestions, setTotalQuestions] = useState(0);

  useEffect(() => {
    const campaignData = totalQuestionsAndRespondents.find(
      (entry) => entry.campaignRef === campaignRef
    );
    if (campaignData) {
      setTotalQuestions(campaignData.totalQuestions);
    }
  }, [campaignRef, totalQuestionsAndRespondents]);

  return (
    <h3>
      <span className="data">{totalQuestions}</span> Questions
    </h3>
  );
};

export default SurveyQuestions;
