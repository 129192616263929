import React from "react";

const CompareModal = (props) => {
  const {
    showCompareModal,
    toggleShowCompareModal,
    handleAddCamparisonBrand,
    handleUpdateComparisonBrand,
    setActiveBrandData,
    isUpdatingComparisonBrand,
    data = {}
  } = props;

  const handleChangeComparisonBrand = (e) =>
    setActiveBrandData({ ...data, [e.target.name]: e.target.value });

  return (
    <div
      style={{ display: showCompareModal ? "flex" : "none", opacity: 1 }}
      className="modal"
    >
      <div className="modal-container">
        {data.action == 'new' ?
          <h2>Compare with</h2>
          : <h2>Edit Brand info</h2>
        }
        <div onClick={() => toggleShowCompareModal()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          className="modal-card"
        >
          <div className="modal-body">
            <div className="form-block fill throw-down w-form">
              <div id="email-form" name="email-form" className="fill">
                <div className="control">
                  <label>Brand name</label>
                  <input
                    type="text"
                    className="input large med w-input"
                    maxLength="256"
                    name="brandName"
                    value={data.brandName}
                    onChange={handleChangeComparisonBrand}
                  />
                </div>
                <div className="control">
                  <label>Other brand names</label>
                  <textarea
                    name="otherBrandName"
                    maxLength="5000"
                    className="input textarea large fill w-input"
                    value={data.otherBrandName}
                    onChange={handleChangeComparisonBrand}
                  ></textarea>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer" style={{ opacity: 1, display: "flex" }}>
            {isUpdatingComparisonBrand ? (
              <span className="button is-disabled small primary w-button">
                Saving changes...
              </span>
            ) : data.action !== 'new' ? (
              <span
                className="button primary w-button"
                onClick={() => handleUpdateComparisonBrand()}
              >
                Save changes
              </span>) :
              (<span
                className="button primary w-button"
                onClick={() => handleAddCamparisonBrand(data)}
              >
                Create comparison
              </span>)
              }
            <span
              className="button subtle push-right w-button"
              onClick={() => toggleShowCompareModal()}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompareModal;
