import React from "react";

const DummyButton = (props) => {
  const { extraClass, text } = props;
  return (
    <button
      className={`button is-disabled ${extraClass ? extraClass : ""}`}
      disabled
    >
      {text}
    </button>
  );
};

export default DummyButton;
