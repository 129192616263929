import React, { useEffect, useRef, useState } from "react";
import CustomSelect from "../../Shared/CustomSelect";
import UseDropDown from "../../CustomHooks/useDropdown";
import UploadImageModal from "./UploadImageModal";
import AddVideoLinkModal from "./AddVideoLinkModal";

const Text = ({
  isNewQuestionModalOpen,
  newQuestionData,
  setNewQuestionData,
  newQuestionStep,
  setNewQuestionStep,
  handleQuestion,
  handleSubmitQuestion,
  text = "Add question",
  isSubmitting,
  resetNewQuestionData,
  questionType,
  handleConditionalBranching,
  setToast,
  choices,
}) => {
  const dropdownRef = useRef(null);
  const [isActive, setIsActive, activeMenu, setActiveMenu] = UseDropDown(
    dropdownRef,
    false
  );

  const [answer, setAnswer] = useState("");
  const [showVideoLinkModal, setShowVideoLinkModal] = useState(false);
  const [showUploadImageModal, setShowUploadImageModal] = useState(false);

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };
  const handleDropDown = (e) => {
    const menuId = e.currentTarget.id;

    setActiveMenu(menuId);
    setIsActive(true);
    if (menuId === activeMenu) {
      setActiveMenu(null);
      setIsActive(false);
    }
  };

  return (
    <>
      <div
        className="card active"
        style={{
          opacity:
            isNewQuestionModalOpen &&
            newQuestionStep === "two" &&
            newQuestionData.optionsType === "freeform"
              ? 1
              : 0,
          display:
            isNewQuestionModalOpen &&
            newQuestionStep === "two" &&
            newQuestionData.optionsType === "freeform"
              ? "block"
              : "none",
        }}
      >
        <div className="card-section-2">
          <span
            // onClick={() => setNewQuestionStep("one")}
            onClick={() => {
              setNewQuestionStep("one");
              // reset input fields and choices
              resetNewQuestionData();
            }}
            className="with-icon w--current"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left icon nudge-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
            Back
          </span>
          <div className="callout move-down">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-info icon positive-2 push-left"
              src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
              loading="lazy"
              alt=""
            >
              <circle cx="12" cy="12" r="10"></circle>
              <line x1="12" y1="16" x2="12" y2="12"></line>
              <line x1="12" y1="8" x2="12.01" y2="8"></line>
            </svg>
            <p
              style={{ marginTop: "0px", marginBottom: "0px" }}
              className="helper no-margin expand positive-2"
            >
              You must apply branching after creating your survey questions.
            </p>
          </div>
          <div className="tag short option _4 push-down move-up">Text</div>
          <div className="no-margin w-form">
            <div>
              <label htmlFor="question-yesno">Question</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="name-6"
                data-name="Name 6"
                placeholder="What are..."
                id="question-yesno"
                onChange={handleQuestion}
                value={newQuestionData.question}
              />

              <div className="w-layout-grid grid-15">
                <label
                  htmlFor="email"
                  className="push-down"
                  // style={{ width: "100%" }}
                >
                  Answer
                </label>
                {/* <label
                  htmlFor="email"
                  className="push-left"
                  // style={{ opacity: 0 }}

                >
                  If selected, skip to ...
                </label> */}

                <label
                  htmlFor="email"
                  className="push-down push-freeform-right"
                  style={{ width: "100%" }}
                >
                  If selected, skip to ...
                </label>
              </div>
              {console.log(newQuestionData, "the new question data")}

              {newQuestionData.optionsList.map((choice, index) => (
                <div key={index} className="w-layout-grid grid-16">
                  <input
                    type="text"
                    className="input nudge-left large w-input"
                    name="text"
                    style={{ width: "20rem" }}
                    placeholder="Placeholder"
                    value={answer}
                    onChange={handleChange}
                  />
                  <CustomSelect
                    id={index}
                    ref={dropdownRef}
                    disabled={
                      newQuestionData.disableBranching ||
                      questionType !== "edit"
                    }
                    placeholder={
                      Boolean(choice.skip_logic.jump_questionText)
                        ? choice.skip_logic.jump_questionText
                        : "No branching"
                    }
                    activeMenu={activeMenu}
                    isActive={isActive}
                    optionsType={newQuestionData.optionsType}
                    handleClick={handleDropDown}
                  >
                    <a
                      // href="#"
                      className="w-dropdown-link"
                      tabIndex={index}
                      onClick={() => {
                        handleConditionalBranching(
                          choice.id ?? index,
                          "freeform"
                        );
                        setActiveMenu(null);
                        setIsActive(false);
                      }}
                    >
                      No branching
                    </a>

                    {newQuestionData.otherQuestions &&
                      newQuestionData.otherQuestions.length > 0 &&
                      newQuestionData.otherQuestions.map((q, i) => (
                        <a
                          // href="#"
                          key={q.questionRef}
                          style={{
                            textOverflow: "ellipsis",
                            overflowX: "clip",
                          }}
                          className="w-dropdown-link"
                          tabIndex={index}
                          onClick={() => {
                            handleConditionalBranching(
                              choice.id ?? index,
                              "freeform",
                              q.questionRef
                            );
                            setActiveMenu(null);
                            setIsActive(false);
                          }}
                        >
                          {`${q.sequence}. ${q.question}`}
                        </a>
                      ))}
                    <a
                      // href="#"
                      className="w-dropdown-link"
                      tabIndex={index}
                      onClick={() => {
                        handleConditionalBranching(
                          choice.id ?? index,
                          "freeform",
                          null,
                          true
                        );
                        setActiveMenu(null);
                        setIsActive(false);
                      }}
                    >
                      End survey
                    </a>
                  </CustomSelect>
                </div>
              ))}
              <div className="row center push-down">
                <span
                  data-w-id="f267d47f-dc71-7c68-dfd3-e13d90f5fc93"
                  className="button with-icon subtle no-margin small w-button"
                  onClick={() => {
                    setShowUploadImageModal(true);
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-image icon"
                  >
                    <rect
                      x="3"
                      y="3"
                      width="18"
                      height="18"
                      rx="2"
                      ry="2"
                    ></rect>
                    <circle cx="8.5" cy="8.5" r="1.5"></circle>
                    <polyline points="21 15 16 10 5 21"></polyline>
                  </svg>
                </span>
                <span
                  data-w-id="c292d606-a939-82d1-787a-ba8912c26f9b"
                  className="button with-icon subtle no-margin small w-button"
                  onClick={() => setShowVideoLinkModal(true)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-video icon"
                  >
                    <polygon points="23 7 16 12 23 17 23 7"></polygon>
                    <rect
                      x="1"
                      y="5"
                      width="15"
                      height="14"
                      rx="2"
                      ry="2"
                    ></rect>
                  </svg>
                </span>
                <span
                  data-w-id="f267d47f-dc71-7c68-dfd3-e13d90f5fc99"
                  className="button with-icon subtle small no-margin w-button"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-link-2 icon"
                  >
                    <path d="M15 7h3a5 5 0 0 1 5 5 5 5 0 0 1-5 5h-3m-6 0H6a5 5 0 0 1-5-5 5 5 0 0 1 5-5h3"></path>
                    <line x1="8" y1="12" x2="16" y2="12"></line>
                  </svg>
                </span>
              </div>
              {isSubmitting ? (
                <button
                  className="button is-disabled wide w-button"
                  style={{ cursor: "not-allowed" }}
                >
                  {text}
                </button>
              ) : (
                <button
                  className="button primary wide w-button"
                  onClick={handleSubmitQuestion}
                  disabled={isSubmitting}
                >
                  {text}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      {showUploadImageModal && (
        <UploadImageModal
          setToast={setToast}
          showModal={showUploadImageModal}
          setShowModal={setShowUploadImageModal}
          mediaLiveUrl={
            newQuestionData?.media_type == "photo"
              ? newQuestionData?.media_link
              : ""
          }
          setMediaValue={(media) => {
            setNewQuestionData({
              ...newQuestionData,
              ...media,
            });
          }}
        />
      )}
      {showVideoLinkModal && (
        <AddVideoLinkModal
          showModal={showVideoLinkModal}
          setShowModal={setShowVideoLinkModal}
          newQuestionData={newQuestionData}
          setNewQuestionData={setNewQuestionData}
        />
      )}
    </>
  );
};

export default Text;
