import React, { useState, useEffect } from "react";
import { OnboardingNavigation } from "./shared";

const TeamMembers = (props) => {
  const { handleAddTeamDetails, handleNavigation } = props;

  const [member, setMember] = useState("");
  const [teamMembers, setTeamMembers] = useState([]);

  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] = useState(
    false
  );

  const handleAreRequiredFieldsCompleted = () => {
    // const unfilledVals = Object.values(brandData).filter((data) => data === "");
    if (!teamMembers.length <= 0) setAreRequiredFieldsCompleted(true);
  };

  const handleChangeMember = (e) => {
    e.preventDefault();
    setMember(e.target.value);
  };

  const handleInviteTeamMember = () => {
    setTeamMembers(teamMembers.concat([{ email: member, canEdit: false }]));
    // Reset member field
    setMember("");
  };

  const toggleCanEdit = (email) => {
    const newTeamMembers = teamMembers.map((member) => {
      if (member.email === email)
        return { ...member, canEdit: !member.canEdit };
      return member;
    });
    setTeamMembers(newTeamMembers);
  };

  useEffect(() => handleAreRequiredFieldsCompleted());

  return (
    <div className="form-block top-breather w-form">
      <div>
        <div className="control">
          <label>Team members</label>
          <div className="row">
            <input
              type="text"
              className="input grouped w-input"
              maxLength="256"
              name="member"
              value={member}
              onChange={handleChangeMember}
            />
            <span
              className="button small grouped group-end no-margin w-button"
              onClick={handleInviteTeamMember}
            >
              Add
            </span>
          </div>
          <label className="helper">Add via email. Ex: john@acme.com</label>
        </div>
        <div className="divider"></div>
        <div className="table bottom-breather">
          <div className="table-body">
            {teamMembers.map((member) => (
              <div className="table-row comfy" key={member.email}>
                <div className="table-body-cell">
                  <div className="avatar">
                    {member.email.charAt(0).toUpperCase()}
                  </div>
                  <div className="push-right">
                    {/* <div className="main">Kemdi Ebi</div> */}
                    <div className="meta">{member.email}</div>
                  </div>
                </div>
                <div className="table-body-cell right-align">
                  <div className="meta push-left">Can edit</div>
                  <div
                    className={`toggle big ${member.canEdit ? "on" : ""}`}
                    onClick={() => toggleCanEdit(member.email)}
                  >
                    <div
                      className={`toggle-switch big ${member.canEdit ? "on" : ""
                        }`}
                      style={{
                        transform:
                          "translate3d(20px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            ))}
            {/* <div className="table-row comfy">
              <div className="table-body-cell">
                <div className="avatar">KE</div>
                <div className="push-right">
                  <div className="main">Kemdi Ebi</div>
                  <div className="meta">e5@enterfive.com</div>
                </div>
              </div>
              <div className="table-body-cell right-align">
                <div className="meta push-left">Can edit</div>
                <div
                  data-w-id="d562cff9-8ddd-7a9b-0468-8c505aed4d16"
                  className="toggle big on"
                >
                  <div
                    className="toggle-switch big on"
                    style={{
                      transform:
                        "translate3d(20px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                      transformStyle: "preserve-3d"
                    }}
                  ></div>
                </div>
              </div>
            </div>
            <div className="table-row comfy">
              <div className="table-body-cell">
                <div className="avatar">IK</div>
                <div className="push-right">
                  <div className="main">Ikezi Kamanu</div>
                  <div className="meta">ikezi@enterfive.com</div>
                </div>
              </div>
              <div className="table-body-cell right-align">
                <div className="meta push-left">Can edit</div>
                <div className="toggle big">
                  <div className="toggle-switch big"></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
        <div className="divider"></div>
        <p className="meta">You can add and remove team members later</p>
        {areRequiredFieldsCompleted ? (
          <span
            onClick={() =>
              handleAddTeamDetails({
                teamMembers,
                skipAddingTeamMembers: false,
              })
            }
            className="button primary large wide w-button"
          >
            Send invites and finish
          </span>
        ) : (
          <span className="button is-disabled large wide w-button" disabled>
            Send invites and finish
          </span>
        )}

        <div style={{ textAlign: 'center'}}>
          Want to add team members later?{" "}
          <span
            style={{ cursor: "pointer", color: "#320b85" }}
            onClick={() =>
              handleAddTeamDetails({ teamMembers, skipAddingTeamMembers: true })
            }
          >
            Skip
          </span>
        </div>
        <OnboardingNavigation
          text='Go back to Search Terms'
          moveTo='search-terms'
          handleNavigation={handleNavigation}
        />
      </div>
    </div>
  );
};

export default TeamMembers;
