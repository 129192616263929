import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import { IntercomProvider } from "react-use-intercom";
import { Provider } from "react-redux";
import store from "./store/store";
import { loadHotjar } from "./hotjar";
import App from "./App";

loadHotjar(
  process.env.REACT_APP_VERSUS_HOTJAR_TRACKING_CODE,
  process.env.REACT_APP_VERSUS_HOTJAR_TRACKING_CODE_VERSION
);

if (process.env.REACT_APP_VERSUS_ENV === "dev") {
  LogRocket.init("p1iqny/versus-dev", { network: { isEnabled: false } });
  setupLogRocketReact(LogRocket);
}

if (process.env.REACT_APP_VERSUS_ENV === "prod") {
  LogRocket.init("p1iqny/versus", { network: { isEnabled: false } });
  setupLogRocketReact(LogRocket);
}

ReactDOM.render(
  <Provider store={store}>
    <IntercomProvider appId={process.env.REACT_APP_INTERCOM_APP_ID}>
      <App />
    </IntercomProvider>
  </Provider>,
  document.getElementById("root")
);
