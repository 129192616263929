import React from "react";
import { DateRangePicker } from "materialui-daterange-picker";
import countries from "../utils/countries.json";
import languages from "../utils/languages.json";

const Filter = (props) => {
  const [open, setOpen] = React.useState(false);
  const toggle = () => setOpen(!open);
  const formatDate = (date) => {
    return new Date(date).toDateString();
  };

  const {
    isFilterOpen,
    filters,
    handleChangePeriod,
    handleChangeSentiment,
    handleClearFilters,
    isCompare,
    countriesToTrack,
    languagesToTrack,
    handleChangeFilter,
  } = props;

  return (
    <div
      className="side-bar"
      style={{
        width: `${isFilterOpen ? "200px" : "0px"}`,
        height: "3608.27px",
      }}
    >
      <div className="div-block-6">
        <h5 className="push-up">Filters</h5>
        {/* Search */}
        {/* <div className="search"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
          className="feather feather-search icon small"
          src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg" alt="">
          <circle cx="11" cy="11" r="8"></circle>
          <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
        </svg>
          <input type="text" className="input in-search w-input" maxLength="256" name="field-6" data-name="Field 6"
            placeholder="Keyword or hashtags" id="field-6" required="" />
        </div> */}
        <h5 className="push-up top-breather">Period</h5>
        <span
          className={`side-bar-link ${
            filters.period === "today" ? "active" : ""
          }`}
          onClick={() => handleChangePeriod("today")}
        >
          Today
        </span>
        <span
          className={`side-bar-link ${
            filters.period === "last 7 days" ? "active" : ""
          }`}
          onClick={() => handleChangePeriod("last 7 days")}
        >
          Past 7 days
        </span>
        <span
          className={`side-bar-link ${
            filters.period === "last 30 days" ? "active" : ""
          }`}
          onClick={() => handleChangePeriod("last 30 days")}
        >
          Past 30 days
        </span>
        <span
          className={`side-bar-link ${
            filters.period === "lastYear" ? "active" : ""
          }`}
          onClick={() => handleChangePeriod("lastYear")}
        >
          Past year
        </span>
        <span
          className={`side-bar-link ${
            /to/.test(filters.period) ? "active" : ""
          }`}
          onClick={toggle}
        >
          {" "}
          Custom{" "}
        </span>
        <div
          style={{ position: "absolute", zIndex: "5", backgroundColor: "red" }}
        >
          <DateRangePicker
            open={open}
            closeOnClickOutside="true"
            toggle={toggle}
            onChange={(range) => {
              // date format = `startDate to endDate`
              console.log(range);
              handleChangePeriod(
                `${formatDate(range.startDate)} to ${formatDate(range.endDate)}`
              );
              toggle();
            }}
          />
        </div>

        <h5 className="push-up top-breather">Sentiment</h5>
        <span
          className={`side-bar-link ${
            filters.sentiment === "any sentiment" ? "active" : ""
          }`}
          onClick={() => handleChangeSentiment("any sentiment")}
        >
          Any sentiment
        </span>
        <span
          className={`side-bar-link ${
            filters.sentiment === "positive sentiment" ? "active" : ""
          }`}
          onClick={() => handleChangeSentiment("positive sentiment")}
        >
          Positive sentiment
        </span>
        <span
          className={`side-bar-link ${
            filters.sentiment === "negative sentiment" ? "active" : ""
          }`}
          onClick={() => handleChangeSentiment("negative sentiment")}
        >
          Negative sentiment
        </span>
        {isCompare ? (
          ""
        ) : (
          <span
            className={`side-bar-link ${
              filters.sentiment === "neutral sentiment" ? "active" : ""
            }`}
            onClick={() => handleChangeSentiment("neutral sentiment")}
          >
            Neutral sentiment
          </span>
        )}

        <h5 className="push-up top-breather">Country</h5>
        <span
          className={`side-bar-link ${filters.country === "" ? "active" : ""}`}
          onClick={() => handleChangeFilter("country", "")}
        >
          All Countries
        </span>
        {countriesToTrack.map((country) => (
          <span
            key={country.Code}
            className={`side-bar-link ${
              filters.country === country.Code ? "active" : ""
            }`}
            onClick={() => handleChangeFilter("country", country.Code)}
          >
            {country.Name}
          </span>
        ))}

        <h5 className="push-up top-breather">Language</h5>
        <span
          className={`side-bar-link ${filters.language === "" ? "active" : ""}`}
          onClick={() => handleChangeFilter("language", "")}
        >
          All Languages
        </span>
        {/* Supports only English for now */}
        <span
          onClick={() => handleChangeFilter("language", "en")}
          className={`side-bar-link ${
            filters.language === "en" ? "active" : ""
          }`}
        >
          English
        </span>
        {/* {languagesToTrack.map((language) => (
          <span
            key={language.Code}
            className={`side-bar-link ${
              filters.language === language.Code ? "active" : ""
            }`}
            onClick={() => handleChangeFilter("language", language.Code)}
          >
            {language.Name}
          </span>
        ))} */}

        {/* <h5 className="push-up top-breather">Geography</h5>
        <a href="#" className="side-bar-link active">Nigeria</a>
        <a href="#" className="side-bar-link">Kenya</a>
        <a href="#" className="side-bar-link">Ethiopia</a>
        <a href="#" className="side-bar-link">South Africa</a> */}

        {/* <h5 className="push-up top-breather">Topics</h5>
        <a href="#" className="side-bar-link">Pricing</a>
        <a href="#" className="side-bar-link">Data</a>
        <a href="#" className="side-bar-link">Experience</a>
        <a href="#" className="side-bar-link">Product</a>
        <a href="#" className="button subtle small with-icon black w-button"><svg
                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor"
                strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"
                className="feather feather-settings icon small move-left">
                <circle cx="12" cy="12" r="3"></circle>
                <path
                  d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z">
                </path>
        </svg> Manage topics</a> */}

        {isCompare ? (
          ""
        ) : (
          <span
            className="button black wide no-margin throw-down w-button"
            onClick={() => handleClearFilters()}
          >
            Clear filters
          </span>
        )}
      </div>
    </div>
  );
};

export default Filter;
