/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

const Pagination = ({
  totalPages,
  currentPage,
  handlePageChange,
  pageNumbers,
}) => {
  return (
    <div
      role="navigation"
      aria-label="pagination navigation"
      className="pagination"
    >
      <div className="page-controls">
        <div className="left-content">
          <button
            disabled={totalPages <= 1 || currentPage === 1}
            onClick={handlePageChange}
            data-page-number={1}
            aria-label="go to first page"
            style={{ display: "flex" }}
            className="button-7 with-icon subtle small w-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-left icon-5"
            >
              <polyline points="11 17 6 12 11 7"></polyline>
              <polyline points="18 17 13 12 18 7"></polyline>
            </svg>
          </button>
          <button
            disabled={totalPages <= 1 || currentPage === 1}
            onClick={handlePageChange}
            data-page-number={currentPage - 1}
            aria-label="go to previous page"
            className="button-7 with-icon subtle small w-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-left icon-5 not-allowed"
            >
              <polyline points="15 18 9 12 15 6"></polyline>
            </svg>
          </button>
        </div>
        <div className="center-content">
          {pageNumbers.map((page, index) => (
            <button
              disabled={
                typeof page !== "number" || page === "..." || totalPages === 1
              }
              onClick={handlePageChange}
              data-page-number={page}
              aria-label={`go to page ${page}`}
              key={index}
              className="button-7 subtle small w-button"
            >
              <strong
                className={
                  page === currentPage && totalPages !== 1
                    ? "bold-text-10"
                    : "icon-5"
                }
              >
                {page}
              </strong>
            </button>
          ))}
        </div>
        <div className="right-content">
          <button
            disabled={totalPages <= 1 || currentPage === totalPages}
            onClick={handlePageChange}
            data-page-number={currentPage + 1}
            aria-label="go to next page"
            className="button-7 with-icon subtle small w-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevron-right icon-5"
            >
              <polyline points="9 18 15 12 9 6"></polyline>
            </svg>
          </button>
          <button
            disabled={totalPages <= 1 || currentPage === totalPages}
            onClick={handlePageChange}
            data-page-number={totalPages}
            aria-label="go to last page"
            className="button-7 with-icon subtle small w-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-chevrons-right icon-5"
            >
              <polyline points="13 17 18 12 13 7"></polyline>
              <polyline points="6 17 11 12 6 7"></polyline>
            </svg>
          </button>
        </div>
      </div>
      <div className="page-counter">
        <p>
          Page {currentPage} of {totalPages}
        </p>
      </div>
    </div>
  );
};

function usePagination({ currentPageNeighbours = 1, totalVisiblePages = 6 }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(6);
  const [pageNumbers, setPageNumbers] = useState([]);

  const handlePageChange = (e) => {
    const pageNumber = Number(e.currentTarget.dataset["pageNumber"]);
    setCurrentPage(pageNumber);
  };

  useEffect(() => {
    const pages = [];
    if (totalPages > totalVisiblePages) {
      // Always show the first page
      pages.push(1);

      // Determine start and end for the middle section
      let startPage = Math.max(2, currentPage - currentPageNeighbours); //ensure that the beginning of the middle pages doesn't go below page=2
      let endPage = Math.min(
        totalPages - 1,
        currentPage + currentPageNeighbours
      ); // ensure that the end of the middle pages doesn't go above the second to the last page

      // Adjust if near the beginning or end of the range
      if (currentPage - currentPageNeighbours <= 2) {
        startPage = 2;
        endPage = Math.min(totalPages - 1, totalVisiblePages - 2);
      }

      if (totalPages - currentPage <= currentPageNeighbours + 1) {
        startPage = Math.max(2, totalPages - (totalVisiblePages - 3));
        endPage = totalPages - 1;
      }

      // Add ellipsis and middle pages
      if (startPage > 2) {
        pages.push("...");
      }
      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      if (endPage < totalPages - 1) {
        pages.push("...");
      }

      // Always show the last page
      if (totalPages > 1) {
        pages.push(totalPages);
      }
    } else {
      for (let i = 1; i <= totalPages; i++) {
        pages.push(i);
      }
    }

    setPageNumbers(pages);
  }, [totalPages, currentPage, currentPageNeighbours, totalVisiblePages]);

  return {
    setTotalPages,
    setCurrentPage,
    currentPage,
    PaginationComponent: () => (
      <Pagination
        totalPages={totalPages}
        currentPage={currentPage}
        pageNumbers={pageNumbers}
        handlePageChange={handlePageChange}
      />
    ),
  };
}

export default usePagination;
