import axios from "axios";

export const _getTotalSurveysQuestionsAndRespondents = async ({
  uid,
  idToken,
  campaignsRefs,
}) => {
  try {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_questions_and_respondents_for_surveys`,
      {
        uid,
        idToken,
        campaignsRefs,
      },
      {
        headers: {
          "content-type": "application/json",
        },
        validateStatus: (status) => status < 500,
      }
    );
    if (response.status !== 200) {
      return {
        error: response.data.message,
        data: null,
        success: false,
      };
    }

    return {
      success: true,
      data: response.data.surveysQuestionsAndRespondentsCounts,
      error: null,
    };
  } catch (error) {
    return {
      error,
      data: null,
      success: false,
    };
  }
};
