import React from "react";

const Influencer = (props) => {
  const { influencer, category } = props;

  if (!influencer) {
    return <div></div>;
  } else {
    return (
      <div className="psuedo-card">
        <div className="row">
          <div className="avatar move-left">
            <img
              src={influencer.profile_image_url_https}
              alt="Twitter DP"
              className={
                category === "promoters"
                  ? "positive"
                  : category === "detractors"
                  ? "negative"
                  : ""
              }
            />
            {/* {getInitials(influencer.name)} */}
          </div>
          <div>
            <div className="row center">
              <p className="medium-text medium-weight">
                {influencer.name}
                <br />
              </p>
              <div
                data-tooltip="followers"
                className="small-text muted with-icon move-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-users icon small nudge-left"
                >
                  <path d="M17 21v-2a4 4 0 0 0-4-4H5a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M23 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>{" "}
                {influencer.followers_count}
              </div>
              <div
                data-tooltip="Mentions"
                className="small-text muted with-icon move-right"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-at-sign icon small nudge-left"
                >
                  <circle cx="12" cy="12" r="4"></circle>
                  <path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-3.92 7.94"></path>
                </svg>{" "}
                {influencer.friends_count}
              </div>
            </div>
            <div className="small-text muted">{influencer.description}</div>
          </div>
        </div>
      </div>
    );
  }
};

export default Influencer;
