import React, { useEffect, useState } from "react";
import DummyButton from "../../DummyButton";
import RangeSlider from "../../RangeSlider/RangeSlider";
import Axios from "axios";
import IndustryInterestModal from "./IndustryInterestModal";
import AddLocationModal from "./AddLocationModal";
import DisplayTaskCategory from "./DisplayTaskCategory";
import { generateRandomNumber } from "../utils";

const Demographics = ({
  idToken,
  client,
  user,
  gender,
  tooltip,
  setTooltip,
  setSportsFan,
  openModal,
  activeStep,
  surveyData,
  gotoSurveyStep,
  surveyType = "campaign",
  handleNewSurveyData,
  setNewSurveyData,
  handleSliderChange,
}) => {
  const [interests, setInterests] = useState([]);
  const [isUpdated, setIsUpdated] = useState(0);
  const [isLoadingInterest, setIsLoadingInterest] = useState(false);
  const [potentialReach, setPotentialReach] = useState(0);
  const [activeIndustryInterestId, setActiveIndustryInterestId] = useState(0);
  const [industryInterestAction, setIndustryInterestAction] = useState("new");
  const [openIndustryInterestModal, setOpenIndustryInterestModal] =
    useState(false);
  const [stateId, setStateId] = useState(0);
  const [locationModalAction, setLocationModalAction] = useState("new");
  const [states, setStates] = useState([]);
  const [activeStateIds, setActiveStateIds] = useState([]);
  const [stateLga, setStateLga] = useState([]);
  const [openAddLocationModal, setOpenAddLocationModal] = useState(false);

  const handleOpenIndustryInterestModal = (id = null) => {
    if (id) {
      setActiveIndustryInterestId(id);
    } else {
      setActiveIndustryInterestId(0);
    }
    setOpenIndustryInterestModal(true);
  };

  const handleOpenAddLocationModal = (id = null) => {
    if (id) {
      setStateId(id);
    } else {
      setStateId(0);
    }
    setOpenAddLocationModal(true);
  };

  const handleUpdatedEstimatedRespondent = () => {
    const num = generateRandomNumber();
    setIsUpdated(num);
  };

  const handleGender = (e) => {
    handleNewSurveyData(e);
    handleUpdatedEstimatedRespondent();
  };

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/get_total_estimated_filtered_survey_respondents`,
      {
        uid: user.uid,
        clientRef: client.clientRef,
        idToken,
        age: [surveyData.startAge, surveyData.endAge],
        gender: surveyData.gender,
        lga_ids: surveyData.lga_ids,
        interest_ids: surveyData.interest_ids,
      }
    )
      .then(({ status, data }) => {
        if (status === 200) {
          setPotentialReach(data.totalFilteredSurveyRespondents);
        }
      })
      .catch((error) =>
        console.log("Error getting organization sectors", error)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUpdated]);

  useEffect(() => {
    setIsLoadingInterest(true);
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_interests`,
      {
        uid: user.uid,
        idToken,
        clientRef: client.clientRef,
      }
    )
      .then(({ status, data }) => {
        if (status === 200) {
          setInterests(data.interests);
        }
      })
      .catch((error) =>
        console.log("Error getting organization sectors", error)
      )
      .finally(() => {
        setIsLoadingInterest(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="step-2 fill w-form"
      style={{
        display: "block",
        opacity: 1,
        height: "100%",
        width: "600px",
      }}
    >
      <div>
        <div className="subhead no-margin">
          {`${surveyData.campaignName || surveyData.requestName}: ${
            surveyData.description.length <= 35
              ? surveyData.description
              : surveyData.description.slice(0, 35)
          }...`}{" "}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => gotoSurveyStep("one")}
            className="primary"
          >
            {" "}
            Edit
          </span>
        </div>
        {surveyType == "request" && (
          <DisplayTaskCategory
            surveyData={surveyData}
            gotoSurveyStep={gotoSurveyStep}
          />
        )}
        <label
          htmlFor="name-4"
          className="field-label white-text large throw-down"
        >
          Define your target demographic
        </label>
        <label htmlFor="name-3" className="field-label">
          Target gender
        </label>
        <label className="w-checkbox move-down">
          <input
            type="checkbox"
            checked={gender.includes("Male") ? true : false}
            value="Male"
            name="gender"
            className="w-checkbox-input checkbox"
            onChange={handleGender}
          />
          <span className="w-form-label">Male</span>
        </label>
        <div
          style={{
            display: tooltip.step === 5.3 ? "flex" : "none",
            width: "300px",
          }}
          className="inline-tooltip-content-3 top-left"
        >
          {/* step 2 tool tip */}
          <div className="card-section">
            <div>Target gender</div>
            <p className="move-down">
              choose the gender you want to target for your survey.
            </p>
            <h6>you have to pick at least one</h6>

            <div className="inline-tooltip-frame">
              <span
                onClick={() =>
                  setTooltip({
                    show: false,
                    type: "",
                    title: "",
                    message: "",
                    step: 0,
                    max_step: 7,
                  })
                }
              >
                Skip tutorial{" "}
              </span>
              <button
                className="inline-tooltip-button"
                onClick={() =>
                  setTooltip({
                    show: true,
                    type: "",
                    title: "",
                    message: "",
                    step: 5.4,
                    max_step: 5,
                  })
                }
              >
                Next{" "}
              </button>
            </div>
          </div>
        </div>

        <label className="w-checkbox">
          <input
            type="checkbox"
            checked={gender.includes("Female") ? true : false}
            value="Female"
            name="gender"
            className="w-checkbox-input checkbox"
            onChange={handleGender}
          />
          <span className="w-form-label">Female</span>
        </label>
        <label className="w-checkbox">
          <input
            type="checkbox"
            checked={gender.includes("Other") ? true : false}
            value="Other"
            name="gender"
            className="w-checkbox-input checkbox"
            onChange={handleGender}
          />
          <span className="w-form-label">Non-binary</span>
        </label>
        <label htmlFor="name-4" className="field-label">
          Target age range
        </label>
        <div className="row spaced push-down">
          <h5>16</h5>
          <h5>65</h5>
        </div>
        <div
          style={{
            display: tooltip.step === 5.4 ? "flex" : "none",
            width: "300px",
          }}
          className="inline-tooltip-content-3 top-left"
        >
          {/* step 2 tool tip */}
          <div className="card-section">
            <div>Target age range</div>
            <p className="move-down">Select your age range for your survey </p>

            <div className="inline-tooltip-frame">
              <span
                onClick={() =>
                  setTooltip({
                    show: false,
                    type: "",
                    title: "",
                    message: "",
                    step: 0,
                    max_step: 5,
                  })
                }
              >
                Skip tutorial{" "}
              </span>
              <button
                className="inline-tooltip-button"
                onClick={() =>
                  setTooltip({
                    show: true,
                    type: "",
                    title: "",
                    message: "",
                    step: 5.5,
                    max_step: 5,
                  })
                }
              >
                Next{" "}
              </button>
            </div>
          </div>
        </div>

        <div className="html-embed-2 w-embed">
          <RangeSlider
            handleSliderChange={(e) => {
              handleSliderChange(e);
              handleUpdatedEstimatedRespondent();
            }}
            values={["26", "45"]}
          />
        </div>

        <label htmlFor="interest-id" className="field-label">
          Filter by scout's industry or interest
        </label>
        <div className="align-left col-6">
          {surveyData.interest_ids.map((id) => {
            const interest = interests.find((i) => i.id == id);
            if (interest) {
              return (
                <span
                  key={interest.id}
                  data-w-id="5f1e59f5-d99e-ff1c-86e1-b3cc336242e4"
                  style={{ padding: "10px", marginRight: "5px" }}
                  className="button tag location w-button move-left"
                  onClick={() => {
                    setIndustryInterestAction("update");
                    handleOpenIndustryInterestModal(interest.id);
                  }}
                >
                  {" "}
                  {interest.name}
                </span>
              );
            }
          })}
          <span
            data-w-id="5f1e59f5-d99e-ff1c-86e1-b3cc336242f0"
            className="button subtle small location w-button with-icon move-left"
            style={{ padding: "4px", marginTop: "18px", width: "70px" }}
            onClick={() => {
              setIndustryInterestAction("new");
              handleOpenIndustryInterestModal(null);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-plus icon move-left"
              style={{ marginTop: "5px" }}
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add
          </span>
        </div>

        <label htmlFor="state-lga" className="field-label">
          Filter by scout's location
        </label>
        <div className="align-left col-6">
          {surveyData?.stateOrRegion?.map((stateId) => {
            let state = null;
            if (states.length) {
              state = states.find((prevState) => {
                return prevState.id == stateId;
              });
            }
            if (state) {
              return (
                <span
                  key={state?.id}
                  data-w-id="5f1e59f5-d99e-ff1c-86e1-b3cc336242e4"
                  style={{ padding: "10px", marginRight: "5px" }}
                  className="button tag location w-button move-left"
                  onClick={() => {
                    setLocationModalAction("update");
                    handleOpenAddLocationModal(state.id);
                  }}
                >
                  {state?.name}
                </span>
              );
            }
          })}
          <span
            data-w-id="5f1e59f5-d99e-ff1c-86e1-b3cc33624301"
            className="button subtle small location w-button with-icon move-left"
            style={{ padding: "4px", marginTop: "18px", width: "70px" }}
            onClick={() => {
              setLocationModalAction("new");
              handleOpenAddLocationModal(null);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-plus icon move-left"
            >
              <line x1="12" y1="5" x2="12" y2="19"></line>
              <line x1="5" y1="12" x2="19" y2="12"></line>
            </svg>
            Add
          </span>
        </div>

        <label htmlFor="potential-reach" className="field-label">
          Potential reach
        </label>
        <p className="data">{potentialReach || 0}</p>

        <div
          style={{
            display: tooltip.step === 5.5 ? "flex" : "none",
            width: "300px",
          }}
          className=" inline-tooltip-content-3 top-left"
        >
          {/* step 2 tool tip */}
          <div className="card-section">
            <div>Sports Fan</div>
            <p className="move-down">
              If you are targeting sports fan, select "Yes" under Sports Fan and
              follow the steps. If you are not targeting sports fan, select "No"
            </p>

            <div className="inline-tooltip-frame">
              <span
                onClick={() =>
                  setTooltip({
                    show: false,
                    type: "",
                    title: "",
                    message: "",
                    step: 0,
                    max_step: 5,
                  })
                }
              >
                Skip tutorial
              </span>
              <button
                className="inline-tooltip-button"
                onClick={() => openModal("Three")}
              >
                Next{" "}
              </button>
            </div>
          </div>
        </div>

        <label htmlFor="name-4" className="field-label">
          Sports Fan
        </label>
        <div className="row">
          <div
            style={{
              marginRight: "10px",
              display: "flex",
              alignItems: "center",
            }}
            className="w-checkbox move-down"
          >
            <input
              type="radio"
              value="true"
              style={{ marginRight: "5px" }}
              checked={surveyData.sports_fan === true}
              className="w-checkbox-input checkbox"
              onChange={setSportsFan}
            />
            <span style={{ marginLeft: "-5px" }} className="w-form-label">
              Yes
            </span>
          </div>
          <div
            style={{ display: "flex", alignItems: "center" }}
            className="w-checkbox move-down"
          >
            <input
              type="radio"
              value="false"
              style={{ marginRight: "5px" }}
              checked={surveyData.sports_fan === false}
              className="w-checkbox-input checkbox"
              onChange={setSportsFan}
            />

            <span style={{ marginLeft: "-5px" }} className="w-form-label">
              No
            </span>
          </div>
        </div>

        {surveyData.sports_fan && (
          <div>
            <label htmlFor="name-4" className="field-label">
              Football/Soccer Fan
            </label>

            <label className="w-checkbox move-down">
              <input
                type="checkbox"
                value={surveyData.soccer_fan}
                name="soccer_fan"
                className="w-checkbox-input checkbox"
                onChange={handleNewSurveyData}
                defaultChecked={surveyData.soccer_fan === true}
              />
              <span className="w-form-label">Yes</span>
            </label>

            <label htmlFor="name-4" className="field-label">
              Football Team
            </label>
            <select
              id="favorite_team_id"
              name="favorite_team_id"
              className="input select large w-select"
              onChange={handleNewSurveyData}
              value={surveyData.favorite_team_id}
            >
              <option value="">Select one...</option>
              <option value="1">Chelsea F.C</option>
              <option value="2">Liverpool F.C</option>
              <option value="3">Tottenham F.C</option>
              <option value="4">Arsenal F.C</option>
              <option value="5">Manchester United F.C</option>
              <option value="6">Manchester City F.C</option>
              <option value="7">Other</option>
              <option value="8">I don't have a favorite team listed</option>
            </select>
          </div>
        )}
        <label htmlFor="survey-location" className="field-label">
          Location
        </label>
        <p className="data">Nigeria</p>
        <p className="helper">More locations coming soon</p>
        {gender.length > 0 ? (
          <button
            onClick={() => gotoSurveyStep("three")}
            className="button primary medium large w-button"
          >
            Next
          </button>
        ) : (
          <DummyButton
            text="&nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;"
            extraclassName="large w-button"
          />
        )}
      </div>
      {openIndustryInterestModal && (
        <IndustryInterestModal
          surveyData={surveyData}
          openModal={openIndustryInterestModal}
          setOpenModal={setOpenIndustryInterestModal}
          industryInterestAction={industryInterestAction}
          activeIndustryInterestId={activeIndustryInterestId}
          setActiveIndustryInterestId={setActiveIndustryInterestId}
          handleUpdatedEstimatedRespondent={handleUpdatedEstimatedRespondent}
          interests={interests}
          isLoadingInterest={isLoadingInterest}
          handleNewSurveyData={handleNewSurveyData}
        />
      )}
      {openAddLocationModal && (
        <AddLocationModal
          surveyData={surveyData}
          openModal={openAddLocationModal}
          setOpenModal={setOpenAddLocationModal}
          stateId={stateId}
          setStateId={setStateId}
          states={states}
          setStates={setStates}
          activeStep={activeStep}
          stateLga={stateLga}
          setStateLga={setStateLga}
          activeStateIds={activeStateIds}
          setActiveStateIds={setActiveStateIds}
          setNewSurveyData={setNewSurveyData}
          locationModalAction={locationModalAction}
          handleNewSurveyData={handleNewSurveyData}
          handleUpdatedEstimatedRespondent={handleUpdatedEstimatedRespondent}
        />
      )}
    </div>
  );
};

export default Demographics;
