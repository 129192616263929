import React, { useState, useEffect } from "react";
import axios from "axios";

const MentionsLimits = (props) => {
  const {
    client,
    showUpgradeModal,
    toggleShowUpgradeModal,
    tooltip,
    setTooltip,
  } = props;
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [isMentionsLimitTooltipVisible, setIsMentionsLimitTooltipVisible] =
    useState(false);

  const [twitterMentions, setTwitterMentions] = useState(0); // Twitter mentions per month
  const [allMentions, setAllMentions] = useState(0); // all mentions per month
  //show tooltip only once
  const showListenTooltip = sessionStorage.getItem("showListenTooltip");
  const showTooltip = (status) => {
    sessionStorage.setItem("showListenTooltip", status);
  };

  useEffect(() => {
    // Get client subscription data
    const subscriptionPlanRef = client.subscriptionPlanRef
      ? client.subscriptionPlanRef
      : "f6e67bdd-bb89-44cc-9963-d9536abd80b6"; // Set SME plan as default

    function getSubscriptionPlan() {
      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_subscription_plan`,
        { subscriptionPlanRef },
        { headers: { "content-type": "application/json" } }
      );
    }
    function getTwitterMentions() {
      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_monthly_twitter_mentions`,
        { clientRef: client.clientRef },
        { headers: { "content-type": "application/json" } }
      );
    }

    function getAllMentionsCount() {
      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_all_monthly_mentions_count`,
        { clientRef: client.clientRef },
        { headers: { "content-type": "application/json" } }
      );
    }
    axios
      .all([getSubscriptionPlan(), getAllMentionsCount()])
      .then(
        axios.spread((subscriptionPlanResult, mentionsResult) => {
          setSubscriptionPlan(subscriptionPlanResult.data.subscriptionPlan);
          const mentions = mentionsResult.data.totalMentionsCount;
          setAllMentions(mentions);
        })
      )
      .catch((error) =>
        console.log("error getting subscription plan and mentions", error)
      );
  }, []);

  if (!subscriptionPlan) return "";

  return (
    <div
      className="inline-message move-down"
      onMouseOver={() =>
        setIsMentionsLimitTooltipVisible(
          (isMentionsLimitTooltipVisible) => !isMentionsLimitTooltipVisible
        )
      }
      onMouseOut={() =>
        setIsMentionsLimitTooltipVisible(
          (isMentionsLimitTooltipVisible) => !isMentionsLimitTooltipVisible
        )
      }
    >
      <div
        className={`inline-message-trigger small-text bold ${
          subscriptionPlan.mentionsAllowed - allMentions <= 5000
            ? "important"
            : ""
        }`}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-help-circle icon small nudge-left"
        >
          <circle cx="12" cy="12" r="10"></circle>
          <path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3"></path>
          <line x1="12" y1="17" x2="12.01" y2="17"></line>
        </svg>{" "}
        {subscriptionPlan.mentionsAllowed - allMentions >= 0
          ? subscriptionPlan.mentionsAllowed - allMentions
          : 0}{" "}
        left
      </div>
      <div
        style={{
          display: isMentionsLimitTooltipVisible ? "flex" : "none",
          width: "300px",
        }}
        className="inline-message-content"
      >
        <div className="card-section">
          <div className="data tiny">Mention limit</div>
          <p className="move-down">
            You have used{" "}
            {((allMentions / subscriptionPlan.mentionsAllowed) * 100).toFixed(
              2
            )}
            % of your mention limit allocated for your{" "}
            <strong>{subscriptionPlan.subscriptionPlanName} plan.</strong>{" "}
            Upgrade your account to increase your limit.
          </p>
          <span
            className="button primary small w-button"
            onClick={toggleShowUpgradeModal}
          >
            Upgrade plan
          </span>
        </div>
      </div>
      <div
        style={{
          display:
            (tooltip.step === 1) & (showListenTooltip === null)
              ? "flex"
              : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content top"
      >
        {/* step 1 tool tip */}
        <div className="card-section">
          {/* <p className="data tiny">Step 1 of 7</p> */}
          <div>Mention limit</div>
          <p className="move-down">
            This is the total number of mentions streamed in over the showing
            time frame
          </p>

          <div className="inline-tooltip-frame">
            <span
              onClick={() => {
                setTooltip({
                  show: false,
                  type: "",
                  title: "",
                  message: "",
                  step: 0,
                  max_step: 7,
                });
                showTooltip(false);
              }}
            >
              Skip tutorial
            </span>
            <button
              className="inline-tooltip-button"
              onClick={() => {
                setTooltip({
                  show: true,
                  type: "",
                  title: "",
                  message: "",
                  step: 2,
                  max_step: 7,
                });
                showTooltip(false);
              }}
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: tooltip.step === 4 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content-3 left-up"
      >
        {/* step 4 tool tip */}
        <div className="card-section">
          <div>Versus Compare</div>
          <p className="move-down"> Compare your brand against competitors</p>

          <div className="inline-tooltip-frame">
            <span
              onClick={() =>
                setTooltip({
                  show: false,
                  type: "",
                  title: "",
                  message: "",
                  step: 0,
                  max_step: 7,
                })
              }
            >
              Skip tutorial
            </span>
            <button
              className="inline-tooltip-button"
              onClick={() =>
                setTooltip({
                  show: true,
                  type: "",
                  title: "",
                  message: "",
                  step: 5,
                  max_step: 7,
                })
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default MentionsLimits;
