import React from "react";

const CompareOff = () => {
  return (
    <div className="content full">
      <div className="sign-up">
        <div className="bottom-breather big-breath">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-zap-off icon large"
          >
            <polyline points="12.41 6.75 13 2 10.57 4.92"></polyline>
            <polyline points="18.57 12.91 21 10 15.66 10"></polyline>
            <polyline points="8 8 3 14 12 14 11 22 16 16"></polyline>
            <line x1="1" y1="1" x2="23" y2="23"></line>
          </svg>
        </div>

        <h1 className="bold">Comparisons dashboard is turned off</h1>
        <h2>
          Please contact the <a href="mailto:info@versus.africa">Versus Team</a>{" "}
          to add this feature.
        </h2>
      </div>
    </div>
  );
};

export default CompareOff;
