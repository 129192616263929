import React, { useState, useEffect } from "react";
import axios from "axios";
import UpgradeModal from "./UpgradeModal";

const ListenBanner = (props) => {
  const { client } = props;

  const [showBanner, setShowBanner] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [twitterMentions, setTwitterMentions] = useState(null); // Twitter mentions per month
  const [allMentions, setAllMentions] = useState(0); // all mentions per month
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  const toggleShowUpgradeModal = () =>
    setShowUpgradeModal((showUpgradeModal) => !showUpgradeModal);

  useEffect(() => {
    // Get client subscription data
    const subscriptionPlanRef = client.subscriptionPlanRef
      ? client.subscriptionPlanRef
      : "f6e67bdd-bb89-44cc-9963-d9536abd80b6"; // Set SME plan as default

    function getSubscriptionPlan() {
      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_subscription_plan`,
        { subscriptionPlanRef },
        { headers: { "content-type": "application/json" } }
      );
    }

    function getAllMentionsCount() {
      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_all_monthly_mentions_count`,
        { clientRef: client.clientRef },
        { headers: { "content-type": "application/json" } }
      );
    }

    function getTwitterMentions() {
      return axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_monthly_twitter_mentions`,
        { clientRef: client.clientRef },
        { headers: { "content-type": "application/json" } }
      );
    }
    axios
      .all([getSubscriptionPlan(), getAllMentionsCount()])
      .then(
        axios.spread((subscriptionPlanResult, mentionsResult) => {
          const plan = subscriptionPlanResult.data.subscriptionPlan;
          const mentions = mentionsResult.data.totalMentionsCount;
          setSubscriptionPlan(plan);
          setAllMentions(mentions);
          if (mentions >= plan.mentionsAllowed) {
            setShowBanner(true);
          }
        })
      )
      .catch((error) =>
        console.log("error getting subscription plan and mentions", error)
      );
  }, []);

  return (
    <div>
      <div
        style={{ display: showBanner ? "flex" : "none", cursor: "pointer" }}
        onClick={toggleShowUpgradeModal}
      >
        <span className="banner important w-inline-block link">
          <p>
            <strong>
              You’re missing out on{" "}
              {`${
                subscriptionPlan && allMentions
                  ? allMentions - subscriptionPlan.mentionsAllowed
                  : ""
              }+`}{" "}
              mentions. Upgrade to track them
            </strong>
          </p>
          <div className="banner-action">Upgrade</div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-credit-card icon move-right"
          >
            <rect x="1" y="4" width="22" height="16" rx="2" ry="2"></rect>
            <line x1="1" y1="10" x2="23" y2="10"></line>
          </svg>
        </span>
      </div>
      <UpgradeModal
        client={client}
        showUpgradeModal={showUpgradeModal}
        toggleShowUpgradeModal={toggleShowUpgradeModal}
      />
    </div>
  );
};

export default ListenBanner;
