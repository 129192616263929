import React, { useState, useEffect } from "react";

// Our hook
export default function useDebounce(value, delay) {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value);
  const [start, setStart] = useState(false);

  useEffect(() => {
    // Set debouncedValue to value (passed in) after the specified delay
    const handler = setTimeout(() => {
      setDebouncedValue(value);
      setStart(true);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value]);

  return [start, debouncedValue];
}
