import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";

import { getUser, getIdToken } from "../../../utils/storage";
import SurveyOptions from "./SurveyOptions";
import SurveyFreeformOptions from "./SurveyFreeformOptions";

const SurveyList = (props) => {
  const {
    isNavExpanded,
    clientRef,
    campaign,
    setCampaignQuestions,
    targetFilterObject,
    setToast,
    age,
    states,
    gender,
    tribe,
    educationLevel,
    religion,
    relationshipStatus,
    employmentStatus,
    childrenInHousehold,
    personalEarningsMonthly,
    householdEarningsMonthly,
    personsInHousehold,
    childrenUnder18,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_survey_questions`,
      {
        uid,
        idToken,
        clientRef,
        campaignRef: campaign.campaignRef,
      },
      { headers: { "content-type": "application/json" } }
    )
      .then(({ data }) => {
        let _q = data.questions.sort((a, b) => a.sequence - b.sequence);
        setQuestions(_q);
        setCampaignQuestions(_q);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error fetching questions. Please refresh",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="content medium"
      style={{
        marginLeft: isNavExpanded ? "80%" : "50%",
        width: "max-content",
      }}
    >
      <div className="row center spaced throw-up">
        <Link to="/dashboard/ask" className="with-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-arrow-left icon nudge-left"
          >
            <line x1="19" y1="12" x2="5" y2="12"></line>
            <polyline points="12 19 5 12 12 5"></polyline>
          </svg>
          Back to surveys
        </Link>
      </div>
      <div className="row center">
        <h1 className="no-margin push-left">
          <span className="muted">{campaign.campaignName}</span>
        </h1>
      </div>
      {/* <div className="row has-cards move-down"></div> */}
      <div>
        <div>
          {questions.map((question, i) => {
            const imageLinkExist = question.options.some(
              (option) => option?.image_link
            );
            return (
              <div
                className="card-2 q-container"
                style={{ width: "550px" }}
                key={question.questionRef}
              >
                <div className="card-section padded">
                  <div>
                    <div className="row center move-up">
                      <p className="num">{i + 1}</p>
                      <div className="nudge-right">
                        <div className="tag">
                          {question.response_type === "freeform"
                            ? "Text"
                            : question.response_type}
                        </div>
                      </div>
                    </div>
                    {question?.media_type?.toLowerCase() == "video" ? (
                      <iframe
                        className="embedly-embed"
                        src={question?.media_link}
                        width="940"
                        height="528"
                        allowFullScreen
                        title="Vintage Cars"
                      ></iframe>
                    ) : (
                      <img
                        src={question?.media_link}
                        loading="lazy"
                        srcSet={`${question?.media_link} 500w, ${question?.media_link} 800w, ${question?.media_link} 1080w, ${question?.media_link} 1200w `}
                        alt=""
                        className="image-6"
                      />
                    )}
                    <div className="data small">{question.question}</div>
                  </div>
                  <div className="push-down">
                    {/* {question.options.options_list.map((option, i) => ( */}
                    {question.response_type === "nps"
                      ? question.options
                          .sort((a, b) => Number(a.option) - Number(b.option))
                          .map((option) => (
                            <SurveyOptions
                              questionRef={question.questionRef}
                              option={option}
                              targetFilterObject={targetFilterObject}
                              key={option.id}
                              setToast={setToast}
                              states={states}
                              age={age}
                              gender={gender}
                              tribe={tribe}
                              educationLevel={educationLevel}
                              religion={religion}
                              relationshipStatus={relationshipStatus}
                              employmentStatus={employmentStatus}
                              childrenInHousehold={childrenInHousehold}
                              personalEarningsMonthly={personalEarningsMonthly}
                              householdEarningsMonthly={
                                householdEarningsMonthly
                              }
                              personsInHousehold={personsInHousehold}
                              childrenUnder18={childrenUnder18}
                              imageLinkExist={imageLinkExist}
                            />
                          ))
                      : question.response_type === "freeform"
                      ? question.options.map((option) => (
                          <SurveyFreeformOptions
                            questionRef={question.questionRef}
                            campaignRef={question.campaignRef}
                            option={option}
                            targetFilterObject={targetFilterObject}
                            key={option.id}
                            setToast={setToast}
                            states={states}
                            age={age}
                            gender={gender}
                            tribe={tribe}
                            educationLevel={educationLevel}
                            religion={religion}
                            relationshipStatus={relationshipStatus}
                            employmentStatus={employmentStatus}
                            childrenInHousehold={childrenInHousehold}
                            personalEarningsMonthly={personalEarningsMonthly}
                            householdEarningsMonthly={householdEarningsMonthly}
                            personsInHousehold={personsInHousehold}
                            childrenUnder18={childrenUnder18}
                            imageLinkExist={imageLinkExist}
                          />
                        ))
                      : question.options.map((option) => (
                          <SurveyOptions
                            questionRef={question.questionRef}
                            option={option}
                            targetFilterObject={targetFilterObject}
                            key={option.id}
                            setToast={setToast}
                            states={states}
                            age={age}
                            gender={gender}
                            tribe={tribe}
                            educationLevel={educationLevel}
                            religion={religion}
                            relationshipStatus={relationshipStatus}
                            employmentStatus={employmentStatus}
                            childrenInHousehold={childrenInHousehold}
                            personalEarningsMonthly={personalEarningsMonthly}
                            householdEarningsMonthly={householdEarningsMonthly}
                            personsInHousehold={personsInHousehold}
                            childrenUnder18={childrenUnder18}
                            imageLinkExist={imageLinkExist}
                          />
                        ))}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default SurveyList;
