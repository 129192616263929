import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import BarChart from "../Charts/BarChart";
import LineChart from "../Charts/LineChart";
import SentimentBarChart from "../Charts/SentimentBarChart";
import SentimentLineChart from "../Charts/SentimentLineChart";

const MentionsCharts = (props) => {
  const {
    filterPeriod,
    client,
    setToast,
    filterSentiment,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
    sources,
    tooltip,
    setTooltip
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [chartData, setChartData] = useState({
    labels: [],
    mentions: [],
    positive: [],
    negative: [],
    neutral: [],
  });

  const url = `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_chart_data`;

  useEffect(() => {
    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          country: filterCountry,
          language: filterLanguage,
          keyword_or_hashtag: filterKeywordOrHashtag,
          sources,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const chartData = result.data.chartData;
          setChartData({
            labels: chartData.labels,
            mentions: chartData.mentions,
            positive: chartData.positive,
            negative: chartData.negative,
            neutral: chartData.neutral,
          });
        }
      })
      .catch((error) => {
        console.log("Error obtaining chart data", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving chart data. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    idToken,
    setToast,
    sources,
    url,
    user.uid,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  ]);

  return (
    <div className="row has-cards">
      <div
                style={{
                  display: tooltip.step === 5  ? "flex" : "none",
                }}
                className=" inline-tooltip-content-4 left-up"
              >
                {/* step 5 tool tip */}
                <div  className="card-section" >
                  <div >Settings</div>
                  <p className="move-down">
                    Settings</p>

                  <div className="inline-tooltip-frame">
                    <span onClick={() => setTooltip({
                      show: false,
                      type: "",
                      title: "",
                      message: "",
                      step: 0,
                      max_step: 5
                    })}>
                      Skip tutorial
                    </span>
                    <button className="inline-tooltip-button" onClick={() => setTooltip({
                      show: true,
                      type: "",
                      title: "",
                      message: "",
                      step: 0,
                      max_step: 5
                    })} >
                      Finish
          </button>
                  </div>
                </div>
              </div>

      <div className="column">
        <div className="card-2">
        
          <div className="card-section padded-x">
            <div className="row spaced baseline no-margin no-padding">
              <h3>Mentions over time</h3>
              <div className="row">
                <div className="tag small purple">Mentions</div>
                {/* <div className="tag violet nudge-right small">Reach</div> */}
              </div>
            </div>
            {filterPeriod === "last 30 days" || filterPeriod === "lastYear" ? (
              <LineChart labels={chartData.labels} data={chartData.mentions} />
            ) : (
              <BarChart labels={chartData.labels} data={chartData.mentions} />
            )}

            <div className="divider push-up"></div>

            <div className="push-down">
              <div className="row spaced baseline no-margin no-padding">
                <h3 className="move-down">Sentiment over time</h3>
                
                <div className="row">
                  {filterSentiment === "any sentiment" ||
                  filterSentiment === "positive sentiment" ? (
                    <div className="tag positive small">Positive</div>
                  ) : (
                    ""
                  )}
                  {filterSentiment === "any sentiment" ||
                  filterSentiment === "negative sentiment" ? (
                    <div className="tag negative nudge-left nudge-right small">
                      Negative
                    </div>
                  ) : (
                    ""
                  )}
                  {filterSentiment === "any sentiment" ||
                  filterSentiment === "neutral sentiment" ? (
                    <div className="tag small">Neutral</div>
                  ) : (
                    ""
                  )}
                </div>
        
              </div>

              {filterPeriod === "last 30 days" ||
              filterPeriod === "lastYear" ? (
                <SentimentLineChart
                  labels={chartData.labels}
                  positiveSentimentData={chartData.positive}
                  negativeSentimentData={chartData.negative}
                  neutralSentimentData={chartData.neutral}
                  sentiment={filterSentiment}
                />
              ) : (
                <SentimentBarChart
                  labels={chartData.labels}
                  positiveSentimentData={chartData.positive}
                  negativeSentimentData={chartData.negative}
                  neutralSentimentData={chartData.neutral}
                  sentiment={filterSentiment}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MentionsCharts;
