import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

// import FlaggedMentions from './Newsfeed/FlaggedMentions';
import AllMentions from "./Newsfeed/AllMentions";

const Newsfeed = (props) => {
  const {
    isMentionsfeedOpen,
    client,
    setToast,
    filterPeriod,
    filterSentiment,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
    handleMentionModal,
    toggleIsMentionsfeedOpen,
    sources,
    handleChangeFilter,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [newsfeed, setNewsfeed] = useState([]);
  const [keyword, setKeyword] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!keyword || keyword === "") {
      return;
    }
    handleChangeFilter("keyword_or_hashtag", keyword);
  };

  useEffect(() => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_newsfeed`;
    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          sentiment: filterSentiment,
          country: filterCountry,
          language: filterLanguage,
          keyword_or_hashtag: filterKeywordOrHashtag,
          sources,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const newsfeed = result.data.newsfeed;
          setNewsfeed(newsfeed);
        }
      })
      .catch((error) => {
        console.log("Error obtaining newsfeed", error.message);
        setToast({
          show: true,
          type: "warning",
          message: "We had problems retrieving newsfeed. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    filterSentiment,
    idToken,
    setToast,
    sources,
    user.uid,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  ]);

  return (
    <div className="side-spacer">
      <div
        className="side-bar feed"
        style={{
          transform:
            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
          transformStyle: "preserve-3d",
          display: isMentionsfeedOpen ? "block" : "none",
        }}
      >
        <div
          className="with-icon small-text"
          onClick={toggleIsMentionsfeedOpen}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-eye-off icon small nudge-left"
          >
            <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
            <line x1="1" y1="1" x2="23" y2="23"></line>
          </svg>{" "}
          Hide mentions
        </div>
        <form
          onSubmit={(e) => {
            handleSubmit(e);
          }}
          className="searchContainer"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search icon small"
            src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
            alt=""
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <input
            name="keyword_or_hashtag"
            type="text"
            value={keyword}
            autoFocus={true}
            placeholder="Keyword or hashtags"
            onChange={(e) => setKeyword(e.target.value)}
          />
          {keyword && (
            <button
              onClick={() => {
                setKeyword("");
                handleChangeFilter("keyword_or_hashtag", "");
              }}
              type="button"
            >
              X
            </button>
          )}
        </form>
        <div>
          <div>
            <div className="row align-right push-up"></div>
            <h5>Active filters</h5>
            <div className="push-up">
              <div className="tag nudge-left">
                {filterPeriod.toLowerCase().replace(/last/i, "past")}
              </div>
              <div className="tag nudge-left">
                {filterSentiment.toLowerCase()}
              </div>
              <div className="tag nudge-left">
                {filterKeywordOrHashtag.toLowerCase()}
              </div>
            </div>
            <div className="push-up">
              {sources.categories.map((source, i) => (
                <div className="tag nudge-left" key={i}>
                  {source}
                </div>
              ))}
            </div>
            <div className="push-up">
              {sources.socialmedia.map((source, i) => (
                <div className="tag nudge-left" key={i}>
                  {source}
                </div>
              ))}
            </div>
            <div className="push-up">
              {sources.news.map((source, i) => (
                <div className="tag nudge-left" key={i}>
                  {source}
                </div>
              ))}
            </div>
            <div className="push-up">
              {sources.others.map((source, i) => (
                <div className="tag nudge-left" key={i}>
                  {source}
                </div>
              ))}
            </div>

            {/* <FlaggedMentions /> */}
            <AllMentions
              newsfeed={newsfeed}
              handleMentionModal={handleMentionModal}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Newsfeed;
