import React, { useState, useEffect } from "react";

import { login, updatePassword } from "../../services/auth";
import { getUser } from "../../utils/storage";

const ChangePassword = (props) => {
  const { setToast } = props;

  const [password, setPassword] = useState({
    oldpassword: "",
    newpassword: "",
  });
  const handleChangePassword = (e) =>
    setPassword({ ...password, [e.target.name]: e.target.value });

  const [isUpdatingPassword, setIsUpdatingPassword] = useState(false);

  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] = useState(
    false
  );
  const handleAreRequiredFieldsCompleted = () => {
    const unfilledVals = Object.values(password).filter((data) => data === "");
    if (unfilledVals.length <= 0) setAreRequiredFieldsCompleted(true);
  };

  const handleUpdatePassword = () => {
    setIsUpdatingPassword(true);

    login(getUser().email, password.oldpassword)
      .then((user) => {
        updatePassword(password.newpassword)
          .then((msg) => {
            setIsUpdatingPassword(false);

            setPassword({ ...password, oldpassword: "", newpassword: "" });

            setToast({
              show: true,
              type: "success",
              message: msg || "Successfully updated password",
            });
          })
          .catch((error) => {
            setIsUpdatingPassword(false);

            console.log("Error updating password", error);
            setToast({
              show: true,
              type: "warning",
              message:
                error.message || "Error updating password. Please try again",
            });
          });
      })
      .catch((error) => {
        setIsUpdatingPassword(false);

        console.log("Login error", error);
        setToast({
          show: true,
          type: "warning",
          message: error.message || "Error updating password. Please try again",
        });
      });
  };

  useEffect(() => {
    handleAreRequiredFieldsCompleted();
  });

  return (
    <div className="card">
      <div className="card-section">
        <h3>Change password</h3>
        <div className="form-block fill push-down">
          <div>
            <div className="control push-down">
              <label>Old password</label>
              <input
                type="password"
                className="input-2"
                maxLength="256"
                id="old-password"
                name="oldpassword"
                value={password.oldpassword}
                onChange={handleChangePassword}
              />
            </div>
            <div className="control">
              <label>New password</label>
              <input
                type="password"
                className="input-2"
                maxLength="256"
                id="new-password"
                name="newpassword"
                value={password.newpassword}
                onChange={handleChangePassword}
              />
              <label className="helper">
                No less than 6 letters. Pro tip: make it a long funny phrase.
              </label>
            </div>
          </div>
        </div>
        {!areRequiredFieldsCompleted ? (
          <span className="button small is-disabled" disabled>
            Reset password
          </span>
        ) : isUpdatingPassword ? (
          <span className="button small is-disabled" disabled>
            Resetting password...
          </span>
        ) : (
          <span className="button small" onClick={handleUpdatePassword}>
            Reset password
          </span>
        )}
      </div>
    </div>
  );
};

export default ChangePassword;
