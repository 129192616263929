import React, { useRef, useState } from "react";
import Modal from "../../Shared/Modal";
import { uploadFile } from "../../../services/storage";

const UploadImageModal = ({
  setToast,
  showModal,
  setShowModal,
  setMediaValue,
  mediaLiveUrl = "",
}) => {
  const type = "photo";
  const fileRef = useRef();
  const [isProcessing, setIsProcessing] = useState(false);
  const [imageUploadUrl, setImageUploadURL] = useState("");
  const [imageFileObject, setImageFileObject] = useState(null);
  const [imagePreviewUrl, setImagePreviewURL] = useState(mediaLiveUrl || null);

  const handleAddImage = (e) => {
    let file = e.target.files[0];
    let imgUrl = URL.createObjectURL(file);
    setImageFileObject(file);
    setImagePreviewURL(imgUrl);
  };

  const handleDeleteImagePreview = (e) => {
    e.preventDefault();
    // setIsProcessing(true);
    // setToast({
    //   show: true,
    //   type: "processing",
    //   message: "Deleting media link...",
    // });
    // try {
    //   const imageName = imagePreviewUrl
    //     ?.split("%2F")[1]
    //     .replace("?alt=media", "");
    //   var storageRef = firebase
    //     .storage()
    //     .ref()
    //     .child(`${REACT_APP_FIREBASE_IMAGE_STORAGE_PATH}/${imageName}`);
    //   // Delete the file
    //   storageRef
    //     .delete()
    //     .then(() => {
    setImagePreviewURL("");
    setMediaValue({
      media_link: null,
      media_type: null,
    });
    //   setToast({
    //     show: true,
    //     type: "success",
    //     message: "Successfully deleted media link",
    //   });
    // })
    // .catch((error) => {
    //   setToast({
    //     show: true,
    //     type: "danger",
    //     message: "Error Deleting media link",
    //   });
    // })
    // .finally(() => {
    //   setIsProcessing(false);
    // });
    // } catch (error) {}
  };

  const handleUploadImage = async (e) => {
    e.preventDefault();
    setIsProcessing(true);
    setToast({
      show: true,
      type: "processing",
      message: "Uploading media ...",
    });

    // 'file' comes from the Blob or File API
    const { error, snapshot, success } = await uploadFile(
      imageFileObject,
      imageFileObject.name
        ?.replace("/ /g", "-")
        ?.replace("(", "e")
        ?.replace(")", "-5")
    );
    setIsProcessing(false);

    if (!success) {
      console.log("Error Uploading media", error);
      setToast({
        show: true,
        type: "danger",
        message: "Error Uploading media",
      });
    } else {
      if (snapshot) {
        let url = `https://firebasestorage.googleapis.com/v0/b/${
          snapshot.metadata.bucket
        }/o/${encodeURIComponent(snapshot.metadata.fullPath)}?alt=media`;
        setImageUploadURL(url);

        setToast({
          show: true,
          type: "success",
          message: "Successfully uploaded media",
        });
        if (url) {
          setMediaValue({
            media_link: url,
            media_type: type,
          });
          setShowModal(false);
        }
      }
    }
  };

  return (
    <Modal
      title="Add Image"
      openModal={showModal}
      onCloseModal={() => {
        setImagePreviewURL(null);
        setShowModal(false);
      }}
    >
      <div className="no-margin w-form">
        <form
          onSubmit={handleUploadImage}
          id="email-form-3"
          name="email-form-3"
          data-name="Email Form 3"
          method="get"
          data-wf-page-id="63f7bbc5acfc212cfc492c62"
          data-wf-element-id="d65c368f-91e0-da60-40b5-b913879548e4"
          aria-label="Email Form 3"
        >
          {imagePreviewUrl ? (
            <div
              className="image-upload push-up image-preview"
              style={{ backgroundImage: `url(${imagePreviewUrl})` }}
            ></div>
          ) : (
            <div className="image-upload push-up">
              <div className="image-upload-contents">
                <img
                  src="https://assets-global.website-files.com/5b3e1bdab90c1f1276fef2c4/651166853a7709d6b5d82ba2_image.svg"
                  loading="lazy"
                  alt=""
                  className="image-7 push-up"
                />
              </div>
              <div className="paragraph-3 grey-text-2">
                Browse and upload an image
              </div>
              <div className="paragraph-2">Max. file size is 2MB</div>
              <span
                className="button small push-down w-button"
                onClick={() => {
                  fileRef.current.click();
                }}
              >
                Select image
              </span>
              <input
                name="imageUploadUrl"
                accept="image/*"
                ref={fileRef}
                onChange={handleAddImage}
                hidden={true}
                type="file"
              />
            </div>
          )}
          <label htmlFor="helper" className="helper">
            Supported images: .JPG, .JPEG, .PNG
            <br />
            Recommended aspect ratio: 16:9 (Rectangle)
          </label>
          <div className="action-btns">
            <input
              type="submit"
              onClick={handleDeleteImagePreview}
              value="Delete"
              data-wait="Please wait..."
              data-w-id="d65c368f-91e0-da60-40b5-b913879548f5"
              className="button danger wide w-button"
              disabled={isProcessing}
              style={{ cursor: isProcessing ? "not-allowed" : "pointer" }}
            />
            {isProcessing || !imageFileObject ? (
              <input
                type="button"
                value={isProcessing ? "Please wait..." : "Upload"}
                data-wait="Please wait..."
                data-w-id="d65c368f-91e0-da60-40b5-b913879548f6"
                className="button subtle is-disabled wide w-button"
                disabled={true}
                style={{ cursor: "not-allowed" }}
              />
            ) : (
              <input
                type="submit"
                value="Upload"
                data-wait="Please wait..."
                data-w-id="d65c368f-91e0-da60-40b5-b913879548f6"
                className="button primary wide w-button"
              />
            )}
          </div>
        </form>
      </div>
    </Modal>
  );
};

export default UploadImageModal;
