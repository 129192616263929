import React, { useState, useEffect } from "react";
import Axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import FullWidthLoader from "../FullWidthLoader";
import DownloadAndShare from "../Shared/DownloadAndShare";

import FilterBarViewControl from "../Listen/FilterBarViewControl";
import BrandSelector from "./BrandSelector/BrandSelector";
import Summary from "./Summary/Summary";
import MentionsChart from "./MentionsChart/MentionsChart";
import PromoterInfluencers from "./Influencers/PromoterInfluencers";
import Influencers from "./Influencers/Influencers";
import PopularMentions from "./PopularMentions/PopularMentions";

const Compare = (props) => {
  const {
    client,
    // comparisonBrands,
    setToast,
    period,
    isFilterOpen,
    toggleFilterOpen,
    sentiment,
    filterCountry,
    filterLanguage,
    toggleSource,
    sources,
    tooltip,
    setTooltip
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [comparisonBrands, setComparisonBrands] = useState([]);

  const [hasFetchedComparisonBrands, setHasFetchedComparisonBrands] =
    useState(false);

  const [brand1, setBrand1] = useState(null);
  const [brand2, setBrand2] = useState(null);
  const [brand3, setBrand3] = useState(null);
  const [brand4, setBrand4] = useState(null);

  useEffect(() => {
    Axios.post(
      `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_comparison_brands`,
      {
        uid,
        idToken,
        clientRef: client.clientRef,
      },
      { headers: { "content-type": "application/json" } }
    )
      .then(({ data }) => {
        setHasFetchedComparisonBrands(true);

        data.comparisonBrands.unshift({
          otherBrandNames: client.otherBrandNames,
          clientRef: client.clientRef,
          brandName: client.brandName,
          compareDbRef: client.clientRef,
        });

        setComparisonBrands(data.comparisonBrands);

        setBrand1({
          clientRef: client.clientRef,
          brandName: client.brandName,
          otherBrandNames: client.otherBrandNames,
          compareDbRef: client.clientRef,
        });
        setBrand2(data.comparisonBrands[1]);
        setBrand3(data.comparisonBrands[2]);
        setBrand4(data.comparisonBrands[3]);
      })
      .catch((error) => {
        setHasFetchedComparisonBrands(true);
        console.log("Error obtaining comparison brands", error);
      });
  }, [sentiment, filterCountry, filterLanguage, sources, period]);

  if (!hasFetchedComparisonBrands) {
    return <FullWidthLoader />;
  } else {
    return (
      <div className="content full">
        <div className="row center spaced push-up">
          <FilterBarViewControl
            isFilterOpen={isFilterOpen}
            toggleFilterOpen={toggleFilterOpen}
          />
        </div>

        <div className="row baseline spaced">
          <BrandSelector
            comparisonBrands={comparisonBrands}
            brand1={brand1}
            brand2={brand2}
            brand3={brand3}
            brand4={brand4}
            setBrand1={setBrand1}
            setBrand2={setBrand2}
            setBrand3={setBrand3}
            setBrand4={setBrand4}
          />
          <DownloadAndShare
            period={period}
            sentiment={sentiment}
            country={filterCountry}
            language={filterLanguage}
            sources={sources}
            clientRef={client.clientRef}
            brandName={client.brandName}
            path="versus_v2_get_comparison_pdf"
            reportName="CompareReport"
            brands={[brand1, brand2, brand3]}
            tooltip={tooltip}
            setTooltip={setTooltip}
            isCompare={true}
          />
        </div>

        <Summary
          client={client}
          brand1={brand1}
          brand2={brand2}
          brand3={brand3}
          brand4={brand4}
          period={period}
          sentiment={sentiment}
          country={filterCountry}
          language={filterLanguage}
          sources={sources}
          setToast={setToast}
        />

        <MentionsChart
          setToast={setToast}
          client={client}
          brand1={brand1}
          brand2={brand2}
          brand3={brand3}
          brand4={brand4}
          period={period}
          sentiment={sentiment}
          country={filterCountry}
          language={filterLanguage}
          sources={sources}
        />

        <Influencers
          setToast={setToast}
          client={client}
          brand1={brand1}
          brand2={brand2}
          brand3={brand3}
          brand4={brand4}
          period={period}
          sentiment={sentiment}
          country={filterCountry}
          language={filterLanguage}
        />

        <PopularMentions
          setToast={setToast}
          client={client}
          brand1={brand1}
          brand2={brand2}
          brand3={brand3}
          brand4={brand4}
          period={period}
          sentiment={sentiment}
          country={filterCountry}
          language={filterLanguage}
        />

        {/* <VersusTeamInsights /> */}
      </div>
    );
  }
};

export default Compare;
