import React from "react";
import Respondents from "./Respondents";
import dayjs from "dayjs";

const RequestCard = (props) => {
  const { requests } = props;

  return (
    <>
      {requests.map((request, i) => (
        <div className="column _25" key={i}>
          <a
            href={`/dashboard/interact/request/${request.campaignRef}`}
            className="card-2 active w-inline-block"
          >
            <div
              className={`card-marker ${
                request.status === "live" ? "active" : "blue"
              }`}
            ></div>
            <div className="card-section vertical padded">
              <div
                className={`chip ${
                  request.status === "live" ? "primary" : "blue"
                }`}
              >
                {request.status}
              </div>
              <div className="data move-down">{request.name}</div>
              <div className="time-variables push-down">
                {request.status === "finished" ? (
                  <>
                    <div className="row spaced baseline">
                      <h5 className="no-margin">
                        {`Started:  ${dayjs(request.activated_at).format(
                          "MMM D, YYYY  HH:mm"
                        )}`}{" "}
                      </h5>
                    </div>
                    <div className="row spaced baseline move-down">
                      <h5 className="no-margin">
                        {`Ended:  ${
                          request.completed_at
                            ? dayjs(request.completed_at).format(
                                "MMM D, YYYY HH:mm"
                              )
                            : "_ _ "
                        }`}
                      </h5>
                      {"  "}
                    </div>
                  </>
                ) : (
                  <>
                    <div className="row spaced baseline">
                      <h5 className="no-margin">
                        {`Created:  ${dayjs(request.created_at).format(
                          "MMM D, YYYY HH:mm"
                        )}`}
                      </h5>
                    </div>
                    <div className="row spaced baseline move-down">
                      <h5 className="no-margin">{`Started:  ${dayjs(
                        request.activated_at
                      ).format("MMM D, YYYY HH:mm")}`}</h5>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card-section expand padded">
              <div className="row">
                {request.mediaType === "audio" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-mic icon move-left"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                    <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                    <line x1="12" y1="19" x2="12" y2="23"></line>
                    <line x1="8" y1="23" x2="16" y2="23"></line>
                  </svg>
                ) : request.mediaType === "video" ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-video icon move-left"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <polygon points="23 7 16 12 23 17 23 7"></polygon>
                    <rect
                      x="1"
                      y="5"
                      width="15"
                      height="14"
                      rx="2"
                      ry="2"
                    ></rect>
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-camera icon move-left"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                    <circle cx="12" cy="13" r="4"></circle>
                  </svg>
                )}
              </div>
              <Respondents request={request} />
            </div>
          </a>
        </div>
      ))}
    </>
  );
};

export default RequestCard;
