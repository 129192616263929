import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";

import { getUser, getIdToken } from "../../utils/storage";

import PersonalProfile from "./PersonalProfile";
import ChangePassword from "./ChangePassword";
import FullWidthLoader from "../FullWidthLoader";

const Account = (props) => {
  const { setToast } = props;

  const user = getUser(),
    idToken = getIdToken();
  const history = useHistory();

  const [clientTeam, setClientTeam] = useState({
    client: null,
    team: null,
  });

  useEffect(() => {
    if (clientTeam.client || clientTeam.team) return;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client`,
        {
          uid: user.uid,
          email: user.email,
          idToken,
        },
        { headers: { "Content-type": "Application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          setClientTeam({
            ...clientTeam,
            client: result.data.client,
            team: result.data.team,
          });
        }
      })
      .catch((error) => {
        console.log("Error getting client team", error);

        setToast({
          show: true,
          type: "warning",
          message: "Had some hiccups along the way. Mind refreshing?",
        });

        history.push("/login");
      });
  }, [clientTeam, history, idToken, setToast, user.email, user.uid]);

  if (!clientTeam.client || !clientTeam.team) return <FullWidthLoader />;

  return (
    <div className="content small">
      <div className="row center">
        <h1 className="no-margin push-left">
          <span className="muted">Account</span>
        </h1>
      </div>
      <div className="row has-cards push-down">
        <div className="column">
          <PersonalProfile
            client={clientTeam.client}
            user={user}
            team={clientTeam.team}
          />
          <ChangePassword client={clientTeam.client} setToast={setToast} />
        </div>
      </div>
    </div>
  );
};

export default Account;
