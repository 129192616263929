import React from "react";

const Avatar = (props) => {
  const { user, team, client } = props; // client props temporarily provided to support backwards compatibility

  if (!team) return "";

  return (
    <div
      className={`avatar ${user && user.photoURL ? "with-picture" : ""}`}
      style={{
        backgroundImage: `${
          user && user.photoURL ? "url(" + user.photoURL + ")" : ""
        }`,
      }}
    >{`${
      team.firstName
        ? team.firstName.charAt(0).toUpperCase()
        : client.firstName.charAt(0).toUpperCase()
    }${
      team.lastName
        ? team.lastName.charAt(0).toUpperCase()
        : client.lastName.charAt(0).toUpperCase()
    }`}</div>
  );
};

export default Avatar;
