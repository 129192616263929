import React from "react";

function DeprecatedComment() {
  return (
    <div>
      <p>
        Facebook has{" "}
        <a href="https://developers.facebook.com/docs/plugins/embedded-comments">
          deprecated comments publishing
        </a>{" "}
        since September 6, 2021. We are working on a custom alternative display.
        Please click Read more to view this comment on Facebook.
      </p>
    </div>
  );
}

export default DeprecatedComment;
