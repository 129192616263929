import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { getUser, getIdToken, getClient } from "../../utils/storage";
import Demographics from "../Shared/createCampaign/Demographics";
import SampleSize from "../Shared/createCampaign/SampleSize";
import CampaignTask from "../Shared/createCampaign/CampaignTask";
import DummyButton from "../DummyButton";

const NewRequestModal = (props) => {
  const {
    isNewRequestModalOpen,
    toggleNewRequestModalOpen,
    setToast,
    tooltip,
    setTooltip,
  } = props;

  const history = useHistory();

  const [newRequestStep, setNewRequestStep] = useState("one"); // "one", "two", "three"
  const [requestType, setRequestType] = useState(null); // "campaign", "request"
  const [showDummyButton, setShowDummyButton] = useState(false);
  const [insuffientFunds, setInsuffientFunds] = useState(false);
  const [creditsPerRespondent_interact, setCreditsPerRespondent_interact] =
    useState(0);
  const [usdPerRespondent_interact, setUsdPerRespondent_interact] = useState(0);

  const country = "Nigeria";

  const defaultRequestData = {
    requestName: "",
    description: "",
    gender: [],
    startAge: "26",
    endAge: "45",
    country: process.env.REACT_APP_VERSUS_ENV === "dev" ? 160 : 2, //"NG",,
    stateOrRegion: [],
    numberOfRespondents: "500",
    mediaType: "",
    interactType: "",
    interest_ids: [],
    lga_ids: [],
    sports_fan: false,
    soccer_fan: "",
    favorite_team_id: "",
    category: "",
    link: "",
    instructions: "",
  };

  const user = getUser(),
    idToken = getIdToken(),
    client = getClient();

  const gotoSurveyStep = (step) => setNewRequestStep(step);

  const closeNewRequestModal = () => {
    // Reset new survey step to one
    setNewRequestStep("one");
    // Toggle new survey modal
    toggleNewRequestModalOpen();
  };

  const [newRequestData, setNewRequestData] = useState(defaultRequestData);

  const setMediaType = (e) => {
    setNewRequestData({
      ...newRequestData,
      mediaType: e.target.id,
      interactType: "app_based",
    });
  };

  const setSportsFan = (e) => {
    setNewRequestData({
      ...newRequestData,
      sports_fan: e.currentTarget.value === "true",
    });
  };

  const openModal = (value) => {
    if (value == "Two") {
      gotoSurveyStep("two");
      setTooltip({
        show: true,
        type: "",
        title: "",
        message: "",
        step: 5.3,
        max_step: 5,
      });
    }
    if (value == "Three") {
      gotoSurveyStep("three");
      setTooltip({
        show: true,
        type: "",
        title: "",
        message: "",
        step: 5.6,
        max_step: 5,
      });
    }
  };

  const handleNewRequestData = (e) => {
    if (e.target.name === "numberOfRespondents") {
      // Ensure number is not greater than client versus credit can buy
      if (
        e.target.value * creditsPerRespondent_interact >
        client.versusCredits
      ) {
        setToast({
          show: true,
          type: "warning",
          message: `You should buy more versus credits to target more than ${client.versusCredits} respondents`,
        });
        setInsuffientFunds(true);
      } else {
        setToast({ show: false, type: "", message: "" });
        e.target.value = Math.ceil(e.target.value);
        setInsuffientFunds(false);
      }
      setNewRequestData({
        ...newRequestData,
        numberOfRespondents: e.target.value,
      });
    } else if (e.target.name === "gender") {
      const { checked, value } = e.target;
      let genders = newRequestData.gender.slice();
      if (checked) {
        if (!genders.includes(value)) {
          genders.push(value);
        }
      } else {
        if (genders.includes(value)) {
          genders = genders.filter((g) => g !== value);
        }
      }
      setNewRequestData({ ...newRequestData, gender: genders });
    } else if (e.target.name === "gender") {
      const { checked, value } = e.target;
      let genders = newRequestData.gender.slice();
      if (checked) {
        if (!genders.includes(value)) {
          genders.push(value);
        }
      } else {
        if (genders.includes(value)) {
          genders = genders.filter((g) => g !== value);
        }
      }
      setNewRequestData({ ...newRequestData, gender: genders });
    } else if (
      // e.target.name === "sports_fan" ||
      e.target.name === "soccer_fan"
    ) {
      let { checked } = e.target;
      setNewRequestData({ ...newRequestData, [e.target.name]: checked });
    } else {
      setNewRequestData({ ...newRequestData, [e.target.name]: e.target.value });
    }
  };

  const handleSliderChange = (values) =>
    setNewRequestData({
      ...newRequestData,
      startAge: values[0],
      endAge: values[1],
    });

  const handleCreateNewRequest = () => {
    const {
      requestName,
      description,
      gender,
      startAge,
      endAge,
      country,
      stateOrRegion,
      numberOfRespondents,
      mediaType,
      interactType,
      soccer_fan,
      sports_fan,
      interest_ids,
      lga_ids,
      favorite_team_id,
      category,
      link,
      instructions,
    } = newRequestData;

    if (mediaType === "" || requestName === "" || description === "") {
      return setToast({
        show: true,
        type: "danger",
        message: "One or more fields are missing",
      });
    }

    setShowDummyButton(true);
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_create_new_request`,
      method: "post",
      headers: { "Content-type": "application/json" },
      data: {
        uid: user.uid,
        idToken,
        clientRef: client.clientRef,
        clientName: client.organizationName,
        requestName,
        description,
        gender,
        startAge,
        endAge,
        country,
        stateOrRegion,
        numberOfRespondents: Number(numberOfRespondents),
        mediaType,
        interactType,
        soccer_fan,
        sports_fan,
        interest_ids,
        lga_ids,
        favorite_team_id,
        category,
        link,
        instructions,
      },
    })
      .then((result) => {
        if (result.status === 200) {
          setToast({
            show: true,
            type: "success",
            message: "Your request has been created",
          });
          setShowDummyButton(false);
          setNewRequestStep("one");
          setNewRequestData(defaultRequestData);
          // Close NewRequestModal
          toggleNewRequestModalOpen();
          const requestRef = result.data.requestRef;
          history.push(`/dashboard/interact/request/${requestRef}`);
        }
      })
      .catch((error) => {
        setShowDummyButton(false);
        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Fail. Something must have gone wrong",
        });
      });
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client_settings`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setCreditsPerRespondent_interact(
          data.clientSettings.creditsPerRespondent_interact
        );
        setUsdPerRespondent_interact(
          data.clientSettings.usdPerRespondent_interact
        );
      })
      .catch((error) => {
        console.log("Error getting client settings:", error);
      });
  }, []);

  const { gender, mediaType, numberOfRespondents, requestName, description } =
    newRequestData;

  return (
    <div>
      <div
        className="modal-2"
        style={{
          display: isNewRequestModalOpen ? "flex" : "none",
          opacity: isNewRequestModalOpen ? 1 : 0,
          paddingTop: newRequestStep === "two" ? "300px" : "64px",
        }}
      >
        <div className="modal-container">
          <h2>New Request</h2>
          <div onClick={closeNewRequestModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x icon close"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
          <div className="progress-container bottom-breather push-down">
            <div className="row spaced">
              <h5>Request Details</h5>
              <h5>Demographics</h5>
              <h5>Sample Size</h5>
            </div>
            <div className="progress-tracker">
              <div
                className="progress-bar"
                style={{
                  width:
                    newRequestStep === "two"
                      ? "52%"
                      : newRequestStep === "three"
                      ? "100%"
                      : "10%",
                }}
              ></div>
            </div>
          </div>

          {/* Step 1 */}
          <div
            className="step-1 fill w-form"
            style={{
              display: newRequestStep === "one" ? "block" : "none",
              opacity: newRequestStep === "one" ? 1 : 0,
              height: newRequestStep === "one" ? "100%" : "0px",
              width: "600px",
            }}
          >
            <div>
              <label htmlFor="request-name" className="field-label large">
                What is your request?
              </label>
              <label htmlFor="request-name" className="field-label">
                Name your Request
              </label>
              <input
                type="text"
                id="request-name"
                name="requestName"
                maxLength="256"
                className="input large w-input"
                onChange={handleNewRequestData}
              />
              <label htmlFor="request-description" className="field-label">
                Describe what it is about
              </label>
              <p className="helper nudge-up">
                Be thorough, give details on what you need
              </p>
              <textarea
                id="request-description"
                name="description"
                maxLength="5000"
                placeholder="Explain your request in one sentence..."
                className="input large textarea fill w-input"
                onChange={handleNewRequestData}
              ></textarea>

              <label htmlFor="name-7" className="field-label">
                {" "}
                Campaign{" "}
              </label>
              <div className="row" style={{ marginTop: "5px" }}>
                <label className="radio-button-field-3 w-radio">
                  <input
                    onChange={() => {
                      setRequestType("campaign");
                      setNewRequestData({
                        ...newRequestData,
                        mediaType: "photo",
                        interactType: "link_based",
                      });
                    }}
                    type="radio"
                    id="radio"
                    name="campaign"
                    value="Radio"
                    dataname="campaign"
                    data-w-id="6e03107f-92a0-fb5d-4d05-b04402e90f7b"
                    className="w-form-formradioinput w-radio-input"
                  />
                  <span
                    className="radio-button-label-2 w-form-label"
                    htmlFor="radio"
                  >
                    Yes
                  </span>
                </label>{" "}
                &nbsp; &nbsp; &nbsp;
                <label className="radio-button-field-4 w-radio">
                  <input
                    onChange={() => {
                      setRequestType("request");
                      setNewRequestData({
                        ...newRequestData,
                        mediaType: "",
                        interactType: "app_based",
                      });
                    }}
                    type="radio"
                    id="radio"
                    name="campaign"
                    value="Radio"
                    dataname="campaign"
                    data-w-id="6db5896c-4075-1848-4dab-872c8e2ce580"
                    className="w-form-formradioinput w-radio-input"
                  />
                  <span
                    className="radio-button-label-3 w-form-label"
                    htmlFor="radio"
                  >
                    No
                  </span>
                </label>
              </div>

              {requestType == "request" && (
                <div>
                  <label htmlFor="name-4" className="field-label">
                    How should respondents reply?
                  </label>
                  <div className="row">
                    <label
                      className={`w-checkbox option ${
                        newRequestData.mediaType === "video" && "active"
                      }`}
                    >
                      <input
                        type="radio"
                        id="video"
                        name="mediaType"
                        data-name="Checkbox"
                        onChange={setMediaType}
                        className="w-checkbox-input checkbox move-left"
                      />
                      <span className="has-icon w-form-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-video icon nudge-left nudge-right small"
                        >
                          <polygon points="23 7 16 12 23 17 23 7"></polygon>
                          <rect
                            x="1"
                            y="5"
                            width="15"
                            height="14"
                            rx="2"
                            ry="2"
                          ></rect>
                        </svg>
                        Video
                      </span>
                    </label>
                    <label
                      className={`w-checkbox option ${
                        newRequestData.mediaType === "photo" && "active"
                      }`}
                    >
                      <input
                        type="radio"
                        id="photo"
                        name="mediaType"
                        dataname="photo"
                        className="w-checkbox-input checkbox move-left"
                        onChange={setMediaType}
                      />
                      <span className="has-icon w-form-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-camera icon nudge-left nudge-right small"
                        >
                          <path d="M23 19a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4l2-3h6l2 3h4a2 2 0 0 1 2 2z"></path>
                          <circle cx="12" cy="13" r="4"></circle>
                        </svg>
                        Picture
                      </span>
                    </label>
                    <label
                      className={`w-checkbox option ${
                        newRequestData.mediaType === "audio" && "active"
                      }`}
                    >
                      <input
                        type="radio"
                        id="audio"
                        name="mediaType"
                        data-name="audio"
                        onChange={setMediaType}
                        className="w-checkbox-input checkbox move-left"
                      />
                      <span className="has-icon w-form-label">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-mic icon nudge-left nudge-right small"
                        >
                          <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
                          <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
                          <line x1="12" y1="19" x2="12" y2="23"></line>
                          <line x1="8" y1="23" x2="16" y2="23"></line>
                        </svg>
                        Audio
                      </span>
                    </label>
                  </div>
                  <div
                    style={{
                      display: tooltip.step === 2 ? "flex" : "none",
                      width: "300px",
                    }}
                    className=" inline-tooltip-content-3 top-left"
                  >
                    {/* step 5 tool tip */}
                    <div className="card-section">
                      <div>Media surveys</div>
                      <p className="move-down">
                        Surveys can be sent as video, picture or audio. Select
                        how you want you Scouts to respond.
                      </p>

                      <div className="inline-tooltip-frame">
                        <span
                          onClick={() =>
                            setTooltip({
                              show: false,
                              type: "",
                              title: "",
                              message: "",
                              step: 0,
                              max_step: 5,
                            })
                          }
                        >
                          Skip tutorial{" "}
                        </span>
                        <button
                          className="inline-tooltip-button"
                          onClick={() =>
                            setTooltip({
                              show: true,
                              type: "",
                              title: "",
                              message: "",
                              step: 3,
                              max_step: 5,
                            })
                          }
                        >
                          Finish{" "}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {requestName && description && requestType && mediaType ? (
                <button
                  onClick={() => {
                    if (requestType == "campaign") {
                      gotoSurveyStep("campaignTask");
                    } else {
                      gotoSurveyStep("two");
                    }
                  }}
                  className="button primary medium large w-button"
                >
                  Next
                </button>
              ) : (
                <DummyButton
                  text="&nbsp; &nbsp; &nbsp; Next &nbsp; &nbsp; &nbsp;"
                  extraClass="large w-button"
                />
              )}
            </div>
          </div>

          {/* campaignTask */}
          <CampaignTask
            activeStep={newRequestStep}
            newRequestData={newRequestData}
            gotoSurveyStep={gotoSurveyStep}
            handleNewRequestData={handleNewRequestData}
          />

          {/* Step 2 */}
          {newRequestStep == "two" && (
            <Demographics
              idToken={idToken}
              client={client}
              user={user}
              gender={gender}
              tooltip={tooltip}
              setTooltip={setTooltip}
              setSportsFan={setSportsFan}
              openModal={openModal}
              surveyType="request"
              activeStep={newRequestStep}
              surveyData={newRequestData}
              setNewSurveyData={setNewRequestData}
              gotoSurveyStep={gotoSurveyStep}
              handleNewSurveyData={handleNewRequestData}
              handleSliderChange={handleSliderChange}
            />
          )}

          {/* Step 3 */}
          <SampleSize
            tooltip={tooltip}
            country={country}
            surveyType="request"
            setTooltip={setTooltip}
            activeStep={newRequestStep}
            insuffientFunds={insuffientFunds}
            showDummyButton={showDummyButton}
            numberOfRespondents={numberOfRespondents}
            surveyData={newRequestData}
            usdPerRespondent_interact={usdPerRespondent_interact}
            versusCreditsPerRespondent={creditsPerRespondent_interact}
            gotoSurveyStep={gotoSurveyStep}
            handleNewSurveyData={handleNewRequestData}
            handleCreateNewSurvey={handleCreateNewRequest}
          />

          {/* <div className="divider"></div><a href="/"  className="button bottom-breather white w-button">Cancel</a> */}
        </div>
      </div>
    </div>
  );
};

export default NewRequestModal;
