import React, { useEffect, useState } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

const Respondents = (props) => {
  const { request } = props;

  const [respondents, setRespondents] = useState(0);

  useEffect(() => {
    if (!request) return;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_request_respondents`,
        {
          requestRef: request.campaignRef,
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setRespondents(data.totalNumberOfRequestRespondents);
      })
      .catch((error) => {
        console.log("Error getting request respondents:", error);
      });
  }, [request]);

  if (!request) return "";

  return (
    <div>
      <div className="data huge">{respondents}</div>
      <h3 className="move-up move-down">Respondents</h3>
      <div>
        <div className="tag move-left">
          {request.start_age} - {request.end_age} yrs
        </div>
        {request.gender.length > 0 &&
          request.gender.map((gender, i) => (
            <div key={i} className="tag move-left">
              {gender}
            </div>
          ))}

        <div className="tag">
          {request.stateOrRegion.length > 0
            ? JSON.stringify(request.stateOrRegion) + ", " + request.country
            : request.country}
        </div>
      </div>
    </div>
  );
};

export default Respondents;
