import React, { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import "dayjs/locale/es";
import advancedFormat from "dayjs/plugin/advancedFormat";
import dayjs from "dayjs";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

dayjs.extend(advancedFormat);

const Response = (props) => {
  const {
    response,
    handleSelectedResponse,
    openResponseModal,
    isSelected,
    approveResponse,
    rejectResponse,
    responseAction,
    toggleIsShareModalOpen,
  } = props;

  const history = useHistory();

  const [isDownloading, setIsDownloading] = useState(false);

  const toggleIsSelected = () => {
    handleSelectedResponse(response);
  };

  const downloadResponse = () => {
    setIsDownloading(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_download_request_response`,
        {
          requestRef: response.requestRef,
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
          scoutRef: response.scoutRef,
          fileUrl: response.mediaUrls[0],
        },
        {
          headers: { "content-type": "application/json" },
          // responseType: "blob",
        }
      )
      .then((result) => {
        setIsDownloading(false);
        // const url = window.URL.createObjectURL(new Blob([result.data]));

        const url = result.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.click();
        // window.URL.revokeObjectURL(url);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.log("error downloading response", error, error.response);
        if (error && error.response && error.response.status === 401) {
          // Retrun to login screen
          history.push("/login");
        }
      });
  };

  return (
    <div className="column _33">
      <div className={`card-2 active ${isSelected ? "selected" : ""}`}>
        <div className="card-section vertical">
          <div
            data-w-id="a5ede4e9-9b14-523d-df46-6f79270d998b"
            className={`thumb ${
              response.mediaType === "audio"
                ? "audio"
                : response.mediaType === "video"
                ? "vid"
                : ""
            }`}
            onClick={() => openResponseModal(response)}
          >
            {response.mediaType === "audio" ||
            response.mediaType === "video" ? (
              <div className="div-block-11">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-play icon black"
                  src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                  loading="lazy"
                  alt=""
                >
                  <polygon points="5 3 19 12 5 21 5 3"></polygon>
                </svg>
              </div>
            ) : (
              <div className="text-block">{response.mediaUrls.length}</div>
            )}
          </div>
        </div>
        <div className="card-section expand" onClick={toggleIsSelected}>
          <div style={{ gap: "5px" }} className="row spaced center">
            <div className="small-text">
              {`${response.row_number}. ${dayjs(response.created_at).format(
                "MMM Do, hh:mma"
              )}`}
            </div>
            {response.status === "pending" ? (
              <div className="row">
                {responseAction.isApproving &&
                responseAction.ref === response.responseRef ? (
                  <span className="button small no-margin move-left positive">
                    ...
                  </span>
                ) : (
                  <span
                    data-tooltip="Approve"
                    className="button small no-margin move-left positive is-icon w-button"
                    onClick={() => approveResponse(response.responseRef)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-thumbs-up icon small"
                    >
                      <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
                    </svg>
                  </span>
                )}

                {responseAction.isRejecting &&
                responseAction.ref === response.responseRef ? (
                  <span className="button small no-margin move-left positive">
                    ...
                  </span>
                ) : (
                  <span
                    data-tooltip="Reject"
                    data-w-id="a5ede4e9-9b14-523d-df46-6f79270d9992"
                    className="button small no-margin move-left negative is-icon w-button"
                    onClick={() => rejectResponse(response.responseRef)}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-thumbs-down icon small"
                    >
                      <path d="M10 15v4a3 3 0 0 0 3 3l4-9V2H5.72a2 2 0 0 0-2 1.7l-1.38 9a2 2 0 0 0 2 2.3zm7-13h2.67A2.31 2.31 0 0 1 22 4v7a2.31 2.31 0 0 1-2.33 2H17"></path>
                    </svg>
                  </span>
                )}
              </div>
            ) : (
              ""
            )}

            {response.status === "approved" ? (
              <div className="row right">
                {isDownloading ? (
                  <span
                    data-tooltip="Download"
                    className="button small no-margin move-left subtle is-icon w-button"
                  >
                    ...
                  </span>
                ) : (
                  <span
                    data-tooltip="Download"
                    className="button small no-margin move-left subtle is-icon w-button"
                    onClick={downloadResponse}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-download icon small"
                    >
                      <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                      <polyline points="7 10 12 15 17 10"></polyline>
                      <line x1="12" y1="15" x2="12" y2="3"></line>
                    </svg>
                  </span>
                )}

                <span
                  className="button small no-margin subtle is-icon w-button"
                  onClick={toggleIsShareModalOpen}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-share icon small"
                  >
                    <path d="M4 12v8a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-8"></path>
                    <polyline points="16 6 12 2 8 6"></polyline>
                    <line x1="12" y1="2" x2="12" y2="15"></line>
                  </svg>
                </span>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Response;
