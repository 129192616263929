import React, { useEffect, useState } from "react";
import axios from "axios";
import { getClient, getIdToken, getUser } from "../../../../utils/storage";
import DummyButton from "../../../DummyButton";

const ShareWithTeam = (props) => {
  const {
    isShareModalOpen = false,
    toggleIsShareModalOpen,
    requestRef,
    selectedResponses,
    setToast,
  } = props;

  const [teamMembers, setTeamMembers] = useState([]);
  const [emails, setEmails] = useState([]);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isBusySharing, setIsBusySharing] = useState(false);

  const handleSelectAll = (e) => {
    if (e.target.checked) {
      const allEmails = teamMembers.reduce(
        (acc, cur) => acc.concat([cur.email]),
        []
      );
      setIsAllSelected(true);
      setEmails(allEmails);
    } else {
      setIsAllSelected(false);
      setEmails([]);
    }
  };
  const handleAddEmail = (e) => {
    const email = e.target.name,
      isChecked = e.target.checked;
    if (isChecked) {
      setEmails(emails.concat([email]));
    } else {
      setIsAllSelected(false);
      setEmails(emails.filter((e) => e !== email));
    }
  };

  const handleShare = () => {
    const mediaLocations = selectedResponses
      .filter((response) => response.status === "approved")
      .map((res) => ({ url: res.mediaUrls[0], scoutRef: res.scoutRef }));

    setIsBusySharing(true);

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_share_request_responses`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
          requestRef,
          mediaLocations,
          emails,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data, status }) => {
        console.log(status, data);
        setIsBusySharing(false);
        toggleIsShareModalOpen();
        setToast({
          show: true,
          type: "success",
          message: "Successfully shared response files with team",
        });
        setTimeout(() => setToast({ show: false }), 3000);
      })
      .catch((error) => {
        setIsBusySharing(false);
        console.log("Error sharing request responses with team:", error);
        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Fail. Something must have gone wrong",
        });
        setTimeout(() => setToast({ show: false }), 3000);
      });
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_team_members`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        const { teamMembers } = data;
        setTeamMembers(teamMembers);
      })
      .catch((error) => {
        console.log("Error getting team members:", error);
      });
  }, []);

  if (!isShareModalOpen) {
    return null;
  } else {
    return (
      <div className="modal" style={{ display: "flex", opacity: 1 }}>
        <div className="modal-container">
          <h2>Share responses</h2>
          <div onClick={toggleIsShareModalOpen}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x icon close"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
          <div
            className="modal-card"
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
          >
            <div className="modal-body">
              <div className="fill throw-down w-form">
                <form id="email-form" name="email-form" data-name="Email Form">
                  <label className="field-label push-up">
                    Select teammates
                  </label>
                  <label htmlFor="select-all" className="w-checkbox move-down">
                    <input
                      type="checkbox"
                      id="select-all"
                      name="select-all"
                      className="w-checkbox-input checkbox"
                      onChange={handleSelectAll}
                      checked={isAllSelected}
                    />
                    <span className="w-form-label">Select all</span>
                  </label>
                  <div className="divider move-down"></div>
                  {teamMembers.map((member) => (
                    <label
                      className="w-checkbox move-down"
                      key={member.teamRef}
                    >
                      <input
                        type="checkbox"
                        name={member.email}
                        className="w-checkbox-input checkbox"
                        onChange={handleAddEmail}
                        checked={emails.includes(member.email)}
                      />
                      <span className="w-form-label">{member.email}</span>
                    </label>
                  ))}
                </form>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{ opacity: 1, display: "flex" }}
            >
              {isBusySharing ? (
                <DummyButton text="Sharing file..." />
              ) : (
                <span className="button primary w-button" onClick={handleShare}>
                  Share Zip file with teammates
                </span>
              )}

              <span
                className="button subtle push-right w-button"
                onClick={toggleIsShareModalOpen}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ShareWithTeam;
