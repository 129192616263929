import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import querystring from "query-string";
import axios from "axios";

import { login, getIdToken } from "../../services/auth";
import { saveUser, saveIdToken, saveClient } from "../../utils/storage";

import DummyButton from "../DummyButton";

const Login = (props) => {
  const { setToast } = props;
  // const history = useHistory();
  const location = useLocation();

  const retUrl = querystring.parse(location.search).ret;

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
  });
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isLoginBusy, setIsLoginBusy] = useState(false);

  const toggleIsLoginBusy = () => setIsLoginBusy((isLoginBusy) => !isLoginBusy);

  const handleChange = (event) => {
    event.preventDefault();
    if (event.target.name === "email") validateEmail(event.target.value.trim());
    setLoginData({
      ...loginData,
      [event.target.name]: event.target.value.trim(),
    });
  };

  const handleSubmit = () => {
    // Make sure browser is online
    if (!window.navigator.onLine) {
      return setToast({
        show: true,
        type: "warning",
        message: "Looks like you are offline",
      });
    }

    toggleIsLoginBusy();

    // setToast({
    //   show: true,
    //   type: "processing",
    //   message: "Hold on while we try to get you inside..."
    // });
    // Login user on submission of email and password
    login(loginData.email.trim(), loginData.password.trim())
      .then((user) => {
        // console.log("user", user.user);
        user = user.user;
        //get user id token for server auth verification
        getIdToken().then((idToken) => {
          // console.log('user after idtoken', user);
          saveIdToken(idToken);

          // Save user in localstorage
          saveUser(user);
          // console.log('Login * logged in user');
          //clear the session storage data, this will enable the pop ups to show at every login
          sessionStorage.clear();
          // Get user plan
          axios({
            url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client`,
            method: "post",
            headers: { "Content-type": "application/json" },
            data: { email: user.email, uid: user.uid, idToken },
          })
            .then(({ data }) => {
              const { subscriptionPlan, client } = data;
              saveClient(client);
              if (client.smeSetupStage === "createdClient") {
                // client has only completed signup, redirect to subscribe to plan
                window.location.href = `${retUrl || "/subscribe"}`;
              } else {
                // client has completed steps beyond singup. He has paid for subscription
                // and probably completed other steps. Check if the client has onboarded.
                if (!client.isOnboarded) {
                  window.location.href = `${
                    retUrl || `/onboarding?token=${client.onboardingToken}`
                  }`;
                } else {
                  // client has onboarded
                  if (subscriptionPlan.isListenActive) {
                    // history.push(`${retUrl || "/dashboard/listen"}`);
                    window.location.href = `${retUrl || "/dashboard/listen"}`;
                  } else {
                    // history.push(`${retUrl || "/dashboard/ask"}`);
                    window.location.href = `${retUrl || "/dashboard/ask"}`;
                  }
                }
              }
              toggleIsLoginBusy();
            })
            .catch((error) => {
              console.log("error getting client", error);
              setToast({
                show: true,
                type: "warning",
                message:
                  error.message ||
                  "Oops! For some reason we couldn't get through",
              });
              toggleIsLoginBusy();
            });
        });
      })
      .catch((error) => {
        toggleIsLoginBusy();

        console.log("error logging in", error);
        setToast({
          show: true,
          type: "warning",
          message:
            error.message || "Oops! For some reason we couldn't get through",
        });
      });
  };

  const validateEmail = (email) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
      ? setIsEmailValid(true)
      : setIsEmailValid(false);

  return (
    <div>
      <p className="with-icon positive bottom-breather">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-lock icon push-left"
        >
          <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
          <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
        </svg>{" "}
        We use 128-bit encryption. Your details are safe.
      </p>
      <div className="form-block w-form">
        <div>
          <div className="control">
            <label>Email</label>
            <input
              type="text"
              className="input large w-input"
              maxLength="256"
              name="email"
              onChange={handleChange}
              required=""
            />
          </div>
          <div className="control">
            <label>Password</label>
            <input
              type="password"
              className="input large w-input"
              maxLength="256"
              name="password"
              onChange={handleChange}
              required=""
            />
          </div>
          <div>
            Forgot your password?{" "}
            <Link to="/forgot-password">Send a reset mail.</Link>
          </div>
          {isLoginBusy ? (
            <DummyButton
              text="Logging you in..."
              extraClass="button large wide"
            />
          ) : (
            <button
              className={`button primary large wide ${
                isEmailValid && loginData.password ? "" : "is-disabled"
              }`}
              onClick={handleSubmit}
            >
              Log in
            </button>
          )}

          <div>
            {/* Don’t have an account? <Link to="/signup">Request a demo.</Link> */}
            Don’t have an account? <Link to="/signup">Sign up.</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
