import React from "react";

const MentionsBarViewControl = (props) => {
  const { isMentionsfeedOpen, toggleIsMentionsfeedOpen, tooltip, setTooltip } = props;

  return (
    <div>
      <div className="row"></div>
      <div
        className="with-icon small-text"
        style={{ display: isMentionsfeedOpen ? "none" : "flex" }}
        onClick={toggleIsMentionsfeedOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-eye icon small nudge-left"
        >
          <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
          <circle cx="12" cy="12" r="3"></circle>
        </svg>{" "}
        Show mentions feed
      </div>
      <div
        style={{
          display: tooltip.step === 6 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content top-left"
      >
        {/* step 6 tool tip */}
        <div className="card-section">
          <div >Toogle Mentions</div>
          <p className="move-down">
            View or hide brand mentions
          </p>

          <div className="inline-tooltip-frame">
            <span onClick={() => setTooltip({
              show: false,
              type: "",
              title: "",
              message: "",
              step: 0,
              max_step: 7
            })} >
              Skip tutorial
          </span>
            <button className="inline-tooltip-button" onClick={() => setTooltip({
              show: true,
              type: "",
              title: "",
              message: "",
              step: 7,
              max_step: 7
            })} >
              Next
          </button>
          </div>
        </div>
      </div>
      
      <div
        data-w-id="e1fca23e-0fde-ee21-2ebf-9f035a837a38"
        className="with-icon small-text"
        style={{ display: isMentionsfeedOpen ? "flex" : "none" }}
        onClick={toggleIsMentionsfeedOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-eye-off icon small nudge-left"
        >
          <path d="M17.94 17.94A10.07 10.07 0 0 1 12 20c-7 0-11-8-11-8a18.45 18.45 0 0 1 5.06-5.94M9.9 4.24A9.12 9.12 0 0 1 12 4c7 0 11 8 11 8a18.5 18.5 0 0 1-2.16 3.19m-6.72-1.07a3 3 0 1 1-4.24-4.24"></path>
          <line x1="1" y1="1" x2="23" y2="23"></line>
        </svg>{" "}
        Hide mentions feed
      </div>
    </div>
  );
};

export default MentionsBarViewControl;
