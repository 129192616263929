import React, { useState } from "react";
import axios from "axios";
import { TwitterTweetEmbed } from "react-twitter-embed";
import FacebookProvider from "../../FbEmbeds/FacebookProvider";
import EmbeddedPost from "../../FbEmbeds/EmbeddedPost";

import { getUser, getIdToken } from "../../../utils/storage";

import RespondModal from "./RespondModal";
import DeprecatedComment from "../../FbEmbeds/DeprecatedComment";
import InstagramEmbed from "../../Shared/InstagramEmbed";

const Modal = (props) => {
  const {
    client,
    setToast,
    mentionModal,
    handleCloseMentionsModal,
    respondModal,
    toggleRespondModal,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const mention = mentionModal.mention;

  const [busyState, setBusyState] = useState(false);
  const toggleBusyState = () => setBusyState((busyState) => !busyState);

  const [isSubmitBusy, setIsSubmitBusy] = useState(false);
  const toggleIsSubmitBusy = () =>
    setIsSubmitBusy((isSubmitBusy) => !isSubmitBusy);

  const [isRetagBusy, setIsRetagBusy] = useState(false);
  const toggleIsRetagBusy = () => setIsRetagBusy((isRetagBusy) => !isRetagBusy);

  const [showSentimentRetagDropdown, setShowSentimentRetagDropdown] =
    useState(false);
  const toggleSentimentRetagDropdown = () =>
    setShowSentimentRetagDropdown(
      (showSentimentRetagDropdown) => !showSentimentRetagDropdown
    );

  const [responseEmailData, setResponseEmailData] = useState({
    email: "",
    message: "",
  });

  const handleChangeResponseEmailData = (e) => {
    e.preventDefault();
    setResponseEmailData({
      ...responseEmailData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmitResponseEmail = (mentionUrl) => {
    toggleIsSubmitBusy();
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_respond_to_mention`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          brandName: client.brandName,
          mentionUrl,
          emailAddress: responseEmailData.email,
          message: responseEmailData.message,
        },
        {
          headers: { "content-type": "application/json" },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          // reset submit button
          toggleIsSubmitBusy();
          // remove mention details modal
          handleCloseMentionsModal();
          // Send a success toast message to inform user that mention is being looked into.
          setToast({
            show: true,
            type: "success",
            message: "Successfully sent respond to mention email",
          });
        }
      })
      .catch((err) => {
        console.log("Error sending respond to mention email", err);
        // reset submit button
        toggleIsSubmitBusy();
        // remove mention details modal
        handleCloseMentionsModal();
        // send warning toast message informing user of a problem
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems sending respond to mention email. Please try again in a few",
        });
      });
  };

  const handleRetaggedMention = (retaggedSentiment) => {
    // Set retag busy state to true
    toggleIsRetagBusy();

    // close retag dropdown
    toggleSentimentRetagDropdown();

    // send retagged mention for processing
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_retag_mention`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          mention,
          brandName: client.brandName,
          retaggedSentiment,
        },
        {
          headers: { "content-type": "application/json" },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          // reset retag sentiment button
          toggleIsRetagBusy();

          // remove mention details modal
          handleCloseMentionsModal();
          // Send a success toast message to inform user that mention is being looked into.
          setToast({
            show: true,
            type: "success",
            message:
              "Successfully sent retagged mention to Versus team for review",
          });
        }
      })
      .catch((err) => {
        console.log("Error marking mention as irrelevant", err);
        // reset retag sentiment button
        toggleIsRetagBusy();

        // remove mention details modal
        handleCloseMentionsModal();
        // send warning toast message informing user of a problem
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems sending retagged sentiments to Versus team. Please try again in a few",
        });
      });
  };

  const handleMarkAsIrrelevant = (mention) => {
    toggleBusyState();
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_mark_irrelevant_mention`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          mention,
          brandName: client.brandName,
        },
        {
          headers: { "content-type": "application/json" },
        }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send a success toast message to inform user that mention is being looked into.
          setToast({
            show: true,
            type: "success",
            message:
              "Successfully sent marked mention to Versus team for review",
          });
          // reset mark as irrelevant button
          toggleBusyState();
          // remove mention details modal
          handleCloseMentionsModal();
        }
      })
      .catch((err) => {
        console.log("Error marking mention as irrelevant", err);
        // send warning toast message informing user of a problem
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems sending marked mention to Versus team. Please try again in a few",
        });
      });
  };

  return (
    <div
      className="modal"
      style={{ display: mentionModal.isOpen ? "flex" : "none", opacity: 1 }}
    >
      <div className="modal-container">
        <h2>
          {mention.sourceType === "news"
            ? "News Article"
            : mention.sourceType === "others"
            ? "Other sources"
            : mention.sourceType === "twitter"
            ? "Tweet"
            : mention.sourceType === "facebook"
            ? "Facebook Mention"
            : "Instagram Mention"}
        </h2>
        <div onClick={handleCloseMentionsModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div
            className={`s-border ${
              mention.sentiment_class === 0
                ? "negative"
                : mention.sentiment_class === 1
                ? "neutral"
                : "positive"
            }`}
          ></div>
          <div
            className="modal-card-content"
            style={{
              display: mentionModal.showMention ? "block" : "none",
              width: "600px",
              opacity: mentionModal.showMention ? 1 : 0,
            }}
          >
            <div className="modal-header">
              <div className="meta">
                {mention.sourceType === "news" ||
                mention.sourceType === "others"
                  ? mention.source
                  : mention.sourceType === "twitter"
                  ? "www.twitter.com"
                  : mention.sourceType === "facebook"
                  ? "www.facebook.com"
                  : "www.instagram.com"}
              </div>
              {mention.sourceType === "news" ||
              mention.sourceType === "others" ? (
                <div className="meta push-right">
                  {mention.url.match(/:\/\/(www[0-9]?\.)?(.[^/:]+)/i)[2]}
                </div>
              ) : (
                ""
              )}

              {/* <div className="meta push-right">11.5k monthly views</div> */}
            </div>
            <div className="modal-body">
              {mention.sourceType === "twitter" ? (
                <div>
                  <div
                    className={`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    } bold`}
                  >
                    {/* {Math.round(mention.sentiment_score * 100)}% */}
                    {`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    }`}
                  </div>
                  <TwitterTweetEmbed
                    tweetId={mention.id_str}
                  ></TwitterTweetEmbed>
                </div>
              ) : mention.sourceType === "facebook" ? (
                <div>
                  <div
                    className={`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    } bold`}
                  >
                    {/* {Math.round(mention.sentiment_score * 100)}% */}
                    {`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    }`}
                  </div>
                  {mention.item === "status" || mention.item === "photo" ? (
                    <FacebookProvider appId={process.env.REACT_APP_FB_APP_ID}>
                      <EmbeddedPost
                        href={`https://www.facebook.com/${
                          mention.post_id.split("_")[0]
                        }/posts/${mention.post_id.split("_")[1]}`}
                        width="500"
                      />
                    </FacebookProvider>
                  ) : mention.item === "comment" ? (
                    // <FacebookProvider appId={process.env.REACT_APP_FB_APP_ID}>
                    //   <EmbeddedComment
                    //     href={`https://www.facebook.com/${
                    //       mention.post_id.split("_")[0]
                    //     }/posts/${mention.post_id.split("_")[1]}?comment_id=${
                    //       mention.comment_id.split("_")[1]
                    //     }`}
                    //   />
                    // </FacebookProvider>
                    <DeprecatedComment />
                  ) : (
                    <FacebookProvider appId={process.env.REACT_APP_FB_APP_ID}>
                      <EmbeddedPost
                        href={`https://www.facebook.com/${
                          mention.post_id.split("_")[0]
                        }/posts/${mention.post_id.split("_")[1]}`}
                        width="500"
                      />
                    </FacebookProvider>
                  )}
                </div>
              ) : mention.sourceType === "instagram" ? (
                <div>
                  <div
                    className={`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    } bold`}
                  >
                    {/* {Math.round(mention.sentiment_score * 100)}% */}
                    {`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    }`}
                  </div>
                  <InstagramEmbed {...mention} />
                </div>
              ) : (
                <div>
                  <div
                    className={`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    } bold`}
                  >
                    {`${
                      mention.sentiment_class === 0
                        ? "negative"
                        : mention.sentiment_class === 1
                        ? "neutral"
                        : "positive"
                    }`}
                  </div>
                  <div className="data large">
                    {mention.sourceType === "news" ||
                    mention.sourceType === "others"
                      ? mention.title
                      : ""}
                  </div>
                  <p className="paragraph">
                    {mention.sourceType === "news"
                      ? sanitize(mention.content) ||
                        sanitize(mention.description)
                      : mention.sourceType === "others"
                      ? sanitize(mention.content)
                      : ""}
                  </p>
                </div>
              )}
            </div>
          </div>
          <RespondModal
            client={client}
            responseEmails={client.responseEmails}
            respondModal={respondModal}
            handleChangeResponseEmailData={handleChangeResponseEmailData}
          />

          <div
            className="modal-footer center"
            style={{
              display: mentionModal.showMention ? "flex" : "none",
              opacity: mentionModal.showMention ? 1 : 0,
            }}
          >
            <span
              className="button primary no-margin"
              onClick={toggleRespondModal}
            >
              Respond
            </span>
            <a
              href={
                mention.sourceType === "facebook" &&
                (mention.item === "status" ||
                  mention.item === "photo" ||
                  mention.item === "tagged")
                  ? `https://facebook.com/${
                      mention.post_id.split("_")[0]
                    }/posts/${mention.post_id.split("_")[1]}`
                  : mention.sourceType === "facebook" &&
                    mention.item === "comment"
                  ? `https://facebook.com/${
                      mention.post_id.split("_")[0]
                    }/posts/${mention.post_id.split("_")[1]}?comment_id=${
                      mention.comment_id.split("_")[1]
                    }`
                  : mention.url
              }
              target="_blank"
              rel="noopener noreferrer"
              className="button subtle small move-right"
            >
              Read more
            </a>
            {busyState ? (
              <span className="button is-disabled subtle small move-right">
                Processing mention...
              </span>
            ) : (
              <span
                className="button subtle small move-right"
                onClick={() => handleMarkAsIrrelevant(mention)}
              >
                Mark as irrelevant
              </span>
            )}
            <div className="dropdown move-right">
              {isRetagBusy ? (
                <span className="button is-disabled small subtle">
                  Sending tagged sentiment...
                </span>
              ) : (
                <span
                  className="button with-icon small subtle"
                  onClick={toggleSentimentRetagDropdown}
                >
                  Tag sentiment
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-chevron-down icon move-right"
                  >
                    <polyline points="6 9 12 15 18 9"></polyline>
                  </svg>
                </span>
              )}

              <div
                className="dropdown-list align-top"
                style={{
                  width: "160px",
                  display: showSentimentRetagDropdown ? "flex" : "none",
                }}
              >
                <span
                  className={`dropdown-link ${
                    mention.sentiment_class === 2 ? "active" : ""
                  }`}
                  onClick={() => handleRetaggedMention("positive")}
                >
                  Positive
                </span>
                <span
                  className={`dropdown-link ${
                    mention.sentiment_class === 1 ? "active" : ""
                  }`}
                  onClick={() => handleRetaggedMention("neutral")}
                >
                  Neutral{" "}
                </span>
                <span
                  className={`dropdown-link ${
                    mention.sentiment_class === 0 ? "active" : ""
                  }`}
                  onClick={() => handleRetaggedMention("negative")}
                >
                  Negative
                </span>
              </div>
            </div>
          </div>
          <div
            className="modal-footer respond-footer"
            style={{
              display: mentionModal.showMention ? "none" : "flex",
              opacity: mentionModal.showMention ? 0 : 1,
            }}
          >
            {isSubmitBusy ? (
              <span className="button is-disabled no-margin">
                Sending response email...
              </span>
            ) : (
              <span
                className="button primary no-margin"
                onClick={() => {
                  let url;
                  if (
                    mention.sourceType === "news" ||
                    mention.sourceType === "others"
                  ) {
                    url = mention.url;
                  } else if (mention.sourceType == "instagram") {
                    url = mention.permalink || mention.media_url;
                  } else {
                    url = `https://twitter.com/user/status/${mention.id_str}`;
                  }
                  handleSubmitResponseEmail(url);
                }}
              >
                Submit
              </span>
            )}

            <span
              className="button subtle push-right"
              onClick={handleCloseMentionsModal}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modal;

/**
 * Sanitize a string of text by stripping away HTML tags
 * @param {String} text A string of text that may contain HTML tags
 * @returns
 */
function sanitize(text) {
  return text.replace(/<\/?[^>]+(>|$)/g, "");
}
