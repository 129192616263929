import React, { useState } from "react";

import BrandSelectorDropdown from "./BrandSelectorDropdown";

import type { ComparisonBrand, ComparisonBrands } from "../types/types";

const BrandSelect = (props) => {
  const { brand, brands, setBrand } = props;

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  return (
    <div>
      <div
        className="row baseline"
        onClick={() => setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen)}
      >
        <h2 className="muted with-icon no-shrink">
          {brand.brandName}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-chevron-down icon"
          >
            <polyline points="6 9 12 15 18 9"></polyline>
          </svg>
        </h2>
      </div>
      <BrandSelectorDropdown
        brands={brands}
        isDropdownOpen={isDropdownOpen}
        activeBrand={brand}
        setBrand={setBrand}
        setIsDropdownOpen={setIsDropdownOpen}
      />
    </div>
  );
};

export default BrandSelect;
