import React, { useState, useEffect } from "react";
import { OnboardingNavigation, RequiredField, WarningIcon } from "./shared";

const SearchTerms = (props) => {
  const {
    handleAddSearchTerms,
    handleNavigation,
    isSavingSearchTerms,
    client,
    onboardingToken,
    fbIntegration,
    onboardingData,
  } = props;

  const [brandData, setBrandData] = useState({
    brandName: onboardingData?.brandName || client.brandName || "",
    otherBrandNames:
      onboardingData?.otherBrandNames || client.otherBrandNames || "",
    filterProfanity:
      onboardingData?.filterProfanity || client.filterProfanity || false,
    twitterHandles:
      onboardingData?.twitterHandles || client.twitterHandles || "",
    twitterAccountType:
      onboardingData?.twitterAccountType || client?.twitterAccountType || "",
    twitterAuthToken:
      onboardingData?.twitterAuthToken || client?.twitterAuthToken || "",
  });
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [comparisonBrand, setComparisonBrand] = useState([]);
  const [comparisonBrands, setComparisonBrands] = useState([]);

  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] =
    useState(false);

  const handleAreRequiredFieldsCompleted = () => {
    const unfilledVals = Object.values(brandData).filter((data) => data === "");
    let paramsLength = unfilledVals.length;
    if (brandData.twitterAccountType == "") {
      paramsLength -= 1;
    }
    if (brandData.twitterAuthToken == "") {
      paramsLength -= 1;
    }
    if (paramsLength <= 0) {
      setAreRequiredFieldsCompleted(true);
    } else {
      setAreRequiredFieldsCompleted(false);
    }
  };

  const handleChangeBrandData = (e) => {
    if (e.target.name === "filterProfanity") {
      e.persist();
      setBrandData({ ...brandData, filterProfanity: e.target.checked });
    } else {
      e.preventDefault();
      setBrandData({ ...brandData, [e.target.name]: e.target.value });
    }
  };

  const handleAddCamparisonBrand = () => {
    if (comparisonBrands.length >= 3) return toggleShowCompareModal();
    setComparisonBrands(comparisonBrands.concat(comparisonBrand));
    setComparisonBrand({ brandName: "", otherBrandNames: "" });
    toggleShowCompareModal();
  };

  const handleChangeComparisonBrand = (e) => {
    if (e.target.name === "clientRef") return;
    e.preventDefault();
    setComparisonBrand({ ...comparisonBrand, [e.target.name]: e.target.value });
  };

  const toggleShowCompareModal = () =>
    setShowCompareModal((showCompareModal) => !showCompareModal);

  useEffect(() => handleAreRequiredFieldsCompleted(), [brandData]);

  return (
    <div className="form-block top-breather w-form">
      <div className="control">
        <label>Facebook Page (Optional)</label>
        {fbIntegration ? (
          <span className="button small w-button wide is-disabled">
            Connected
          </span>
        ) : (
          <a
            href={`${process.env.REACT_APP_FB_GRAPH_API_BASEURL}/dialog/oauth?client_id=${process.env.REACT_APP_FB_APP_ID}&redirect_uri=${process.env.REACT_APP_FB_REDIRECT_URL}&state=${client.clientRef}***${onboardingToken}&scope=public_profile,pages_show_list,pages_manage_metadata,pages_read_user_content`}
            className="button small w-button wide"
            // target="_blank"
            // rel="noopener noreferrer"
          >
            Connect
          </a>
        )}
        <label className="helper">
          Connect a Facebook page to also receive mentions
          <br />‍
          <div className="main-color_1">
            <WarningIcon /> You must be an admin on the Facebook page before you
            connect
          </div>
        </label>
      </div>
      <div className="control">
        <label>
          Brand name
          <RequiredField />
        </label>
        <input
          type="text"
          className="input large w-input"
          maxLength="256"
          name="brandName"
          value={brandData?.brandName}
          onChange={handleChangeBrandData}
        />
        <label className="helper">
          Versus will make this name top priority
        </label>
      </div>
      <div className="control">
        <label>
          Alternative brand names
          <RequiredField />
        </label>
        <textarea
          name="otherBrandNames"
          maxLength="5000"
          className="input textarea large w-input"
          value={brandData?.otherBrandNames}
          onChange={handleChangeBrandData}
        ></textarea>
        <label className="helper">
          Other ways people refer to your brand. Separate with a comma. Ex:
          Acme, Acme corp, Acme bank, etc.
          <br />‍
          <div className="main-color_1">
            <WarningIcon /> These names must be unique to your brand and not
            generic
          </div>
          <br />
          Versus will track these mentions too.
        </label>
      </div>
      <div className="control">
        <label>
          Twitter handles
          <RequiredField />
        </label>
        <input
          type="text"
          className="input large w-input"
          maxLength="256"
          name="twitterHandles"
          placeholder="@exampleHandle"
          required=""
          value={brandData?.twitterHandles}
          onChange={handleChangeBrandData}
        />
        <label className="helper">
          Your brand’s twitter handles. Separate with a comma.
          <br />
        </label>
      </div>
      <div className="control">
        <label htmlFor="field-6">Account type</label>
        <select
          id="Account-Type"
          name="twitterAccountType"
          dataname="field"
          className="input select large w-select"
          value={brandData?.twitterAccountType}
          onChange={handleChangeBrandData}
        >
          <option value="">Select one...</option>
          <option value="Basic">Basic</option>
          <option value="Pro">Pro</option>
        </select>
      </div>
      <div className="control">
        <label htmlFor="Access-Token">Authentication token</label>
        <input
          type="text"
          className="input large w-input"
          maxLength="256"
          name="twitterAuthToken"
          dataname="Access Token"
          placeholder=""
          id="Access-Token"
          value={brandData?.twitterAuthToken}
          onChange={handleChangeBrandData}
        />
        <div className="divider spacer"></div>
      </div>

      <label className="w-checkbox">
        <input
          type="checkbox"
          checked={brandData.filterProfanity}
          name="filterProfanity"
          className="w-checkbox-input checkbox"
          onChange={handleChangeBrandData}
        />
        <span htmlFor="checkbox" className="w-form-label">
          Filter out profanity
        </span>
      </label>
      <div className="divider"></div>
      <div className="meta">
        You can edit your search terms anytime you want
      </div>
      {isSavingSearchTerms ? (
        <span className="button is-disabled large wide w-button">
          Saving search terms...
        </span>
      ) : areRequiredFieldsCompleted ? (
        <span
          onClick={() =>
            handleAddSearchTerms({
              filterProfanity: brandData.filterProfanity,
              brandName: brandData.brandName,
              otherBrandNames: brandData.otherBrandNames,
              comparisonBrands,
              twitterHandles: brandData.twitterHandles,
              twitterAccountType: brandData.twitterAccountType,
              twitterAuthToken: brandData.twitterAuthToken,
            })
          }
          className="button primary large wide w-button"
        >
          Save search terms
        </span>
      ) : (
        <span className="button is-disabled large wide w-button">
          Save search terms
        </span>
      )}

      <OnboardingNavigation
        text="Go back to Personal Details"
        moveTo="personal-details"
        handleNavigation={handleNavigation}
      />

      {/* Compare modal */}
      <div
        style={{ display: showCompareModal ? "flex" : "none", opacity: 1 }}
        className="modal"
      >
        <div className="modal-container">
          <h2>Compare with</h2>
          <div onClick={toggleShowCompareModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-x icon close"
            >
              <line x1="18" y1="6" x2="6" y2="18"></line>
              <line x1="6" y1="6" x2="18" y2="18"></line>
            </svg>
          </div>
          <div
            style={{
              opacity: 1,
              transform:
                "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
              transformStyle: "preserve-3d",
            }}
            className="modal-card"
          >
            <div className="modal-body">
              <div className="form-block fill throw-down w-form">
                <div id="email-form" name="email-form" className="fill">
                  <div className="control">
                    <label>Brand name</label>
                    <input
                      type="text"
                      className="input large med w-input"
                      maxLength="256"
                      name="brandName"
                      value={comparisonBrand.brandName}
                      onChange={handleChangeComparisonBrand}
                    />
                  </div>
                  <div className="control">
                    <label>Other brand names</label>
                    <textarea
                      name="otherBrandNames"
                      maxLength="5000"
                      className="input textarea large fill w-input"
                      value={comparisonBrand.otherBrandNames}
                      onChange={handleChangeComparisonBrand}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="modal-footer"
              style={{ opacity: 1, display: "flex" }}
            >
              <span
                className="button primary w-button"
                onClick={handleAddCamparisonBrand}
              >
                Create comparison
              </span>
              <span
                className="button subtle push-right w-button"
                onClick={toggleShowCompareModal}
              >
                Cancel
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchTerms;
