import React from "react";

import Avatar from "../Avatar";

const PersonalProfile = (props) => {
  const { client, user, team } = props;

  return (
    <div className="card">
      <div className="card-section">
        <h3>Personal profile</h3>
        <div className="form-block push-down fill w-form">
          <div>
            <div className="row center push-up">
              {/* Temporarily supply client props for backwards compatibility */}
              <Avatar user={user} team={team} client={client} />
              {/* <div className="avatar huge push-down">{`${team.firstName.charAt(0)}${team.lastName.charAt(0)}`}</div> */}
              <div className="move-right vertical align-left">
                {/* <label>Profile picture</label> */}
                {/* <div className="meta">240kb maximum size</div> */}
                {/* <span
                  className="button small subtle with-icon no-margin-bottom"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-upload icon small nudge-left"
                  >
                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                    <polyline points="17 8 12 3 7 8"></polyline>
                    <line x1="12" y1="3" x2="12" y2="15"></line>
                  </svg>{" "}
                  Upload logo
                </span> */}
              </div>
            </div>
            <div className="row">
              <div className="control move-left">
                <label>First name</label>
                <input
                  type="text"
                  className="input-2"
                  maxLength="256"
                  defaultValue={
                    team.firstName ? team.firstName : client.firstName
                  }
                  disabled
                />
              </div>
              <div className="control">
                <label>Last name</label>
                <input
                  type="text"
                  className="input-2 w-input"
                  maxLength="256"
                  defaultValue={team.lastName ? team.lastName : client.lastName}
                  disabled
                />
              </div>
            </div>
            <div className="control">
              <label>Email</label>
              <input
                type="text"
                className="input-2"
                maxLength="256"
                defaultValue={team.email ? team.email : client.email}
                disabled
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalProfile;
