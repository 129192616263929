import { createSlice } from "@reduxjs/toolkit";

export const surveyFiltersSlice = createSlice({
  name: "surveyFilters",
  initialState: {
    activeFilter: {
      index: 0,
      filter: "All",
    },
  },
  reducers: {
    setActiveFilter: (state, action) => {
      state.activeFilter = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setActiveFilter } = surveyFiltersSlice.actions;

export default surveyFiltersSlice.reducer;
