import React, { useState, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";

import { getUser, getIdToken } from "../../../utils/storage";

const VersusCredits = (props) => {
  const { client, setToast, tooltip, setTooltip } = props;
  const location = useLocation();
  const depositStatus = location.search.slice(1).split("=")[1] === "success";

  const user = getUser(),
    idToken = getIdToken();

  const [versusCredits, setVersusCredits] = useState(0);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_versus_credits`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const versusCredits = result.data.versusCredits;
          setVersusCredits(versusCredits);
        }
      })
      .catch((error) => {
        console.log("Error obtaining versus credits", error.message);
        setToast({
          show: true,
          type: "warning",
          message:
            error.message ||
            "We had problems retrieving total surveys. Please refresh",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [depositStatus]);

  return (
    <div className="card-section padded">
      <div className="data huge primary">{versusCredits}</div>
      <h3 className="move-down">Versus credits</h3>
      <div
        style={{
          display: tooltip.step === 4 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content top"
      >
        {/* step 4 tool tip */}
        <div className="card-section">
          <div>Wallet</div>
          <p className="move-down">
            This contains the number of versus credits you have available
          </p>

          <div className="inline-tooltip-frame">
            <span onClick={() => setTooltip({
              show: false,
              type: "",
              title: "",
              message: "",
              step: 0,
              max_step: 5
            })}>
              Skip tutorial
          </span>
            <button className="inline-tooltip-button"onClick={() => setTooltip({
              show: true,
              type: "",
              title: "",
              message: "",
              step: 5,
              max_step: 5
            })} >
              Next
          </button>
          </div>
        </div>
      </div>
      <div
        style={{
          display: tooltip.step === 5 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content top-right"
      >
        {/* step 5 tool tip */}
        <div className="card-section">
          <div>Add Versus credits</div>
          <p className="move-down">
            Click this button to add credits to your wallet
          </p>

          <div className="inline-tooltip-frame">
            <span onClick={() => setTooltip({
              show: false,
              type: "",
              title: "",
              message: "",
              step: 0,
              max_step: 5
            })}>
              Skip tutorial
          </span>
            <button className="inline-tooltip-button"onClick={() => setTooltip({
              show: true,
              type: "",
              title: "",
              message: "",
              step: 5.1,
              max_step: 5
            })} >
              Finish
          </button>
          </div>
        </div>
      </div>
      
    </div>
  );
};

export default VersusCredits;
