import React, { useState, useEffect } from "react";
import { Link, withRouter, useHistory } from "react-router-dom";
import axios from "axios";

import { getClient, getUser, getIdToken } from "../../../../utils/storage";
import RejectResponse from "./RejectResponse";
import DummyButton from "../../../DummyButton";

import Response from "./Response";
import ResponseModal from "./ResponseModal";
import Carousel from "./Carousel";
import DownloadAndShare from "./DownloadAndShare";
import ShareWithTeam from "./ShareWithTeam";
import usePagination from "../../../Shared/usePagination";

const RequestResponses = (props) => {
  const {
    request,
    requestRef,
    setToast,
    age,
    gender,
    tribe,
    education,
    religion,
    maritalStatus,
    employmentStatus,
    childrenInHousehold,
    personalMonthlyIncome,
    householdMonthlyIncome,
    peopleInHousehold,
    anyChildUnder18,
    tooltip,
    setTooltip,
  } = props;

  const history = useHistory();

  const { PaginationComponent, currentPage, setTotalPages } = usePagination({});

  const [responses, setResponses] = useState([]);

  const [selectedResponses, setSelectedResponses] = useState([]);
  const handleSelectedResponse = (response) => {
    let newResponses;
    if (selectedResponses.includes(response)) {
      newResponses = selectedResponses.filter(
        (selection) => selection !== response
      );
    } else {
      newResponses = selectedResponses.slice();
      newResponses.push(response);
    }
    setSelectedResponses(newResponses);
  };

  const [responseInView, setResponseInView] = useState(null);
  const handleChangeResponseInView = (direction) => {
    const indexOfResponseInView = responses.findIndex(
      (response) => response === responseInView
    );

    if (direction === "previous") {
      if (indexOfResponseInView <= 0) {
        return;
      } else {
        setResponseInView(responses[indexOfResponseInView - 1]);
      }
    }
    if (direction === "next") {
      if (indexOfResponseInView >= responses.length) {
        return;
      } else {
        setResponseInView(responses[indexOfResponseInView + 1]);
      }
    }
  };

  const [viewResponseModal, setViewResponseModal] = useState(false);
  const openResponseModal = (response) => {
    setResponseInView(response);
    setViewResponseModal(true);
  };
  const closeResponseModal = () => setViewResponseModal(false);

  const [viewCarousel, setViewCarousel] = useState(false);
  const toggleViewCarousel = () =>
    setViewCarousel((viewCarousel) => !viewCarousel);

  const [showRejectModal, setShowRejectModal] = useState(false);
  const [approving, setApproving] = useState(false);

  const toggleShowRejectModal = () => setShowRejectModal((prev) => !prev);

  const [responseAction, setResponseAction] = useState({
    ref: "",
    isApproving: false,
    isRejecting: false,
  });

  const [tabInFocus, setTabInFocus] = useState("pending"); // all, pending, approved, rejected

  const [isShareModalOpen, setIsShareModalOpen] = useState(false);

  const toggleIsShareModalOpen = () =>
    setIsShareModalOpen((isShareModalOpen) => !isShareModalOpen);

  const batchApproveResponses = () => {
    if (selectedResponses.length === 0) {
      return setToast({
        show: true,
        type: "warning",
        message: "No response selected",
      });
    }
    setApproving(true);
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_batch_approve_request_responses`,
      method: "post",
      headers: { "Content-type": "application/json" },
      data: {
        uid: getUser().uid,
        idToken: getIdToken(),
        clientRef: getClient().clientRef,
        requestRef,
        ...(selectedResponses.length > 1
          ? { responseRefs: selectedResponses.map((res) => res.responseRef) }
          : { responseRef: selectedResponses[0].responseRef }),
      },
    })
      .then((result) => {
        setApproving(false);
        setSelectedResponses([]);
        setToast({ show: true, type: "success", message: result.data.message });
        setTimeout(() => setToast({ show: false }), 5000);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Fail. Something must have gone wrong",
        });
        setTimeout(() => setToast({ show: false }), 5000);
        setApproving(false);
      });
  };

  const approveResponse = (responseRef) => {
    // Set response action object
    setResponseAction({
      ...responseAction,
      ref: responseRef,
      isApproving: true,
    });

    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_approve_request_response`,
      method: "post",
      headers: { "Content-type": "application/json" },
      data: {
        uid: getUser().uid,
        idToken: getIdToken(),
        clientRef: getClient().clientRef,
        responseRef: responseRef.toString(),
      },
    })
      .then((result) => {
        // Reset responseAction
        setResponseAction({ ...responseAction, ref: "", isApproving: false });

        setToast({ show: true, type: "success", message: result.data.message });
        setTimeout(() => setToast({ show: false }), 5000);
      })
      .catch((error) => {
        // Reset responseAction
        setResponseAction({ ...responseAction, ref: "", isApproving: false });

        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Failed. Something must have gone wrong",
        });
        setTimeout(() => setToast({ show: false }), 5000);
      });
  };

  const rejectResponse = (responseRef) => {
    setResponseAction({ ...responseAction, ref: responseRef });
    toggleShowRejectModal();
  };

  const handleSelectAll = (event) => {
    setSelectedResponses(event.target.checked ? responses : []);
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_request_responses`,
        {
          clientRef: getClient().clientRef,
          requestRef: requestRef,
          uid: getUser().uid,
          idToken: getIdToken(),
          status: tabInFocus,
          age,
          gender,
          tribe,
          education,
          religion,
          maritalStatus,
          employmentStatus,
          childrenInHousehold,
          personalMonthlyIncome,
          householdMonthlyIncome,
          peopleInHousehold,
          anyChildUnder18,
          page: currentPage,
          limit: 9,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        if (data.responses) {
          setResponses(data.responses);
        }
        if (data.totalPages) {
          setTotalPages(data.totalPages);
        }
      })
      .catch((error) => {
        console.error("Error getting response data", error);
        if (error.response && error.response.status === 401) {
          history.push("/login");
        }
      });
  }, [
    age,
    childrenInHousehold,
    anyChildUnder18,
    education,
    employmentStatus,
    gender,
    householdMonthlyIncome,
    personalMonthlyIncome,
    peopleInHousehold,
    props,
    maritalStatus,
    religion,
    requestRef,
    tabInFocus,
    tribe,
    history,
    setTotalPages,
    currentPage,
  ]);

  if (!request) return "";

  return (
    <>
      <div className="content">
        <div className="row center spaced throw-up throw-up">
          <Link to="/dashboard/interact" className="with-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left icon nudge-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
            Back to requests
          </Link>
        </div>

        <div className="row center">
          <h1 className="no-margin push-left">
            <span className="muted">{request.requestName}</span>
          </h1>
        </div>

        <div className="divider"></div>

        <div data-duration-in="250" data-duration-out="250" className="w-tabs">
          <div className="request-responses-tab-menu w-tab-menu" role="tablist">
            <span
              className={`nav-link small w-inline-block w-tab-link ${
                tabInFocus === "pending" ? "w--current" : ""
              }`}
              role="tab"
              aria-controls="w-tabs-0-data-w-pane-1"
              aria-selected={tabInFocus === "pending" ? true : false}
              onClick={() => setTabInFocus("pending")}
            >
              <div>Pending</div>
            </span>
            <span
              className={`nav-link small w-inline-block w-tab-link ${
                tabInFocus === "approved" ? "w--current" : ""
              }`}
              role="tab"
              aria-controls="w-tabs-0-data-w-pane-1"
              aria-selected={tabInFocus === "approved" ? true : false}
              onClick={() => setTabInFocus("approved")}
            >
              <div>Approved</div>
            </span>
            <span
              className={`nav-link small w-inline-block w-tab-link ${
                tabInFocus === "rejected" ? "w--current" : ""
              }`}
              role="tab"
              aria-controls="w-tabs-0-data-w-pane-2"
              aria-selected={tabInFocus === "rejected" ? true : false}
              onClick={() => setTabInFocus("rejected")}
            >
              <div>Rejected</div>
            </span>
          </div>
          <div className="tabs-content-2 no-padding w-tab-content">
            <div
              data-w-tab="Tab 1"
              id="w-tabs-0-data-w-pane-0"
              role="tabpanel"
              aria-labelledby="w-tabs-0-data-w-tab-0"
            >
              {tabInFocus === "approved" ? (
                <div className="callout move-down">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-info icon positive-2 push-left"
                    src="https://assets.website-files.com/plugins/Basic/assets/placeholder.60f9b1840c.svg"
                    loading="lazy"
                    alt=""
                  >
                    <circle cx="12" cy="12" r="10"></circle>
                    <line x1="12" y1="16" x2="12" y2="12"></line>
                    <line x1="12" y1="8" x2="12.01" y2="8"></line>
                  </svg>
                  <p className="helper no-margin expand positive-2">
                    Files will be deleted <strong>30 days</strong> after the
                    request has ended, please download them before then
                  </p>
                </div>
              ) : (
                ""
              )}
              {tabInFocus !== "rejected" && responses.length > 1 ? (
                <div className="row center spaced">
                  <div className="push-down move-up form-trigger w-form">
                    <div>
                      <label className="w-checkbox checkbox-field">
                        <input
                          type="checkbox"
                          name="select-all"
                          className="w-checkbox-input"
                          onChange={handleSelectAll}
                        />
                        <span className="nudge-down w-form-label">
                          Select all
                        </span>
                      </label>
                    </div>
                  </div>
                  {selectedResponses.length > 1 && tabInFocus === "pending" ? (
                    <div className="row">
                      {approving ? (
                        <DummyButton
                          text="Approving..."
                          extraClassName="small nudge-left"
                        />
                      ) : (
                        <span
                          className="button small nudge-left positive w-button"
                          onClick={batchApproveResponses}
                        >
                          Approve
                        </span>
                      )}
                    </div>
                  ) : (
                    ""
                  )}

                  {selectedResponses.length > 0 && tabInFocus === "approved" ? (
                    <DownloadAndShare
                      requestRef={requestRef}
                      selectedResponses={selectedResponses}
                      setToast={setToast}
                      toggleIsShareModalOpen={toggleIsShareModalOpen}
                      tooltip={tooltip}
                      setTooltip={setTooltip}
                    />
                  ) : (
                    ""
                  )}
                </div>
              ) : (
                ""
              )}

              <div className="row has-cards wrap stretch">
                {responses.map((response) => (
                  <Response
                    response={response}
                    handleSelectedResponse={handleSelectedResponse}
                    key={response.responseRef}
                    openResponseModal={openResponseModal}
                    isSelected={selectedResponses.includes(response)}
                    approveResponse={approveResponse}
                    rejectResponse={rejectResponse}
                    responseAction={responseAction}
                    toggleIsShareModalOpen={toggleIsShareModalOpen}
                  />
                ))}
              </div>

              {responses && responses.length ? <PaginationComponent /> : ""}
            </div>
          </div>
        </div>
      </div>

      <ResponseModal
        viewResponseModal={viewResponseModal}
        closeResponseModal={closeResponseModal}
        responseInView={responseInView}
        toggleViewCarousel={toggleViewCarousel}
        handleChangeResponseInView={handleChangeResponseInView}
        approveResponse={approveResponse}
        rejectResponse={rejectResponse}
        responseAction={responseAction}
        toggleIsShareModalOpen={toggleIsShareModalOpen}
      />
      <Carousel
        viewCarousel={viewCarousel}
        responseInView={responseInView}
        toggleViewCarousel={toggleViewCarousel}
      />
      <RejectResponse
        setToast={setToast}
        showRejectModal={showRejectModal}
        toggleShowRejectModal={toggleShowRejectModal}
        responseRef={responseAction.ref}
        requestRef={requestRef}
      />

      <ShareWithTeam
        isShareModalOpen={isShareModalOpen}
        toggleIsShareModalOpen={toggleIsShareModalOpen}
        requestRef={requestRef}
        selectedResponses={selectedResponses}
        setToast={setToast}
      />
    </>
  );
};

export default withRouter(RequestResponses);
