import React, { useState, useEffect } from "react";
import { useLocation, Redirect } from "react-router-dom";
import axios from "axios";
import querystring from "query-string";
import Logo from "../images/versus-full.svg";
import useScript from "./CustomHooks/useScript";
import { Link } from "react-router-dom";
import { getClient } from "../utils/storage";

const Subscribe = (props) => {
  const { setToast } = props;
  const urlParams = querystring.parse(useLocation().search);
  const { plan, billing_period } = urlParams;

  const clientData = getClient();

  const [subscriptionData, setSubscriptionData] = useState({
    organizationName: "",
    organizationSector_id: "",
    subscriptionPlanRef: plan ? plan : "",
    subscriptionPlanName: "",
    subscriptionAmount: "",
    subscriptionPlanIsFree: true,
  });
  const [areRequiredFieldsCompleted, setAreRequiredFieldsCompleted] =
    useState(false);
  // eslint-disable-next-line no-unused-vars
  const [loadedStripe, error] = useScript("https://js.stripe.com/v3/");
  const [isRedirectingToStripe, setIsRedirectingToStripe] = useState(false);
  const [isRedirectingToPaystack, setIsRedirectingToPaystack] = useState(false);
  const [onboarding, setOnboarding] = useState({
    redirect: false,
    token: "",
  });
  const [isOnboarded, setIsOnboarded] = useState(false);
  const [isCreatingSubscription, setIsCreatingSubscription] = useState(false);
  const [subscriptionPlans, setSubscriptionPlans] = useState([]);
  const [organizationSectors, setOrganizationSectors] = useState([]);
  const [billingPeriod, setBillingPeriod] = useState(
    billing_period ? billing_period : "monthly"
  );
  const [currency, setCurrency] = useState("");
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [hasAgreedTerms, setHasAgreedTerms] = useState(false);

  const handleAreRequiredFieldsCompleted = () => {
    const unfilledVals = Object.values(subscriptionData).filter(
      (data) => data === ""
    );
    if (unfilledVals.length <= 0 || !currency)
      setAreRequiredFieldsCompleted(true);
  };
  const handleChange = (event) => {
    // event.preventDefault();
    if (event.target.name === "currency") {
      setCurrency(event.target.value);
      //check if subscription data is already set, then update the subscription amount based on the selected currency
      if (subscriptionData.subscriptionAmount) {
        const planData = subscriptionPlans.find(
          (plan) =>
            plan.subscriptionPlanRef === subscriptionData.subscriptionPlanRef
        );
        const {
          price_monthly_USD,
          price_monthly_NGN,
          price_yearly_USD,
          price_yearly_NGN,
        } = planData;

        setSubscriptionData({
          ...subscriptionData,
          subscriptionAmount:
            billingPeriod === "yearly"
              ? event.target.value === "NGN"
                ? price_yearly_NGN
                : price_yearly_USD
              : event.target.value === "NGN"
              ? price_monthly_NGN
              : price_monthly_USD,
        });
      }
    } else if (event.target.name === "subscriptionPlan") {
      const planData = subscriptionPlans.find(
        (plan) => plan.subscriptionPlanRef === event.target.value
      );
      const {
        subscriptionPlanRef,
        subscriptionPlanName,
        price_monthly_USD,
        price_monthly_NGN,
        price_yearly_USD,
        price_yearly_NGN,
        isFree,
      } = planData;

      setSubscriptionData({
        ...subscriptionData,
        subscriptionPlanRef,
        subscriptionAmount:
          billingPeriod === "yearly"
            ? currency === "NGN"
              ? price_yearly_NGN
              : price_yearly_USD
            : currency === "NGN"
            ? price_monthly_NGN
            : price_monthly_USD,
        subscriptionPlanName,
        subscriptionPlanIsFree: isFree,
      });
      setSubscriptionPlan(planData);
    } else if (event.target.name === "billingPeriod") {
      setBillingPeriod(event.target.value);
      setSubscriptionData({
        ...subscriptionData,
        subscriptionAmount:
          event.target.value === "yearly"
            ? currency === "NGN"
              ? subscriptionPlan.price_yearly_NGN
              : subscriptionPlan.price_yearly_USD
            : currency === "NGN"
            ? subscriptionPlan.price_monthly_NGN
            : subscriptionPlan.price_monthly_USD,
      });
    } else if (event.target.name === "hasAgreedTerms") {
      setHasAgreedTerms(event.target.checked);
    } else {
      setSubscriptionData({
        ...subscriptionData,
        [event.target.name]: event.target.value,
      });
    }
  };

  const createStripeCheckoutSession = async (payload) => {
    setIsRedirectingToStripe(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_stripe_create_checkout_session`,
        {
          subscriptionPlan: payload.subscriptionPlan,
          client: payload.client,
          isOnboarding: payload.isOnboarding,
          billingPeriod: payload.billingPeriod,
        },
        { headers: { "content-type": "application/json" } }
      );
      const sessionId = result.data.sessionId;
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      stripe.redirectToCheckout({ sessionId }).then(function (result) {
        setIsRedirectingToStripe(false);
        if (result.error) {
          console.log(
            "Stripe redirect failed due to browser or network error",
            result.error.message
          );
          // Send toast message
          setToast({
            show: true,
            type: "warning",
            message: result.error.message,
          });
        }
      });
    } catch (error) {
      setIsRedirectingToStripe(false);
      console.log("Error creating Stripe checkout session", error);
      setToast({
        show: true,
        type: "danger",
        message: "Failed to create Stripe checkout session",
      });
    }
  };

  const createPaystackCheckoutSession = async (payload) => {
    setIsRedirectingToPaystack(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_paystack_create_checkout_session`,
        {
          subscriptionPlan: payload.subscriptionPlan,
          client: payload.client,
          isOnboarding: payload.isOnboarding,
          billingPeriod: payload.billingPeriod,
        },
        { headers: { "content-type": "application/json" } }
      );
      const paystackAuthorizationData = result.data.paystackAuthorizationData;
      window.location.href = paystackAuthorizationData.authorization_url;
    } catch (error) {
      setIsRedirectingToPaystack(false);
      console.log("Error creating Paystack checkout session", error);
      setToast({
        show: true,
        type: "danger",
        message: "Failed to create Paystack checkout session",
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsCreatingSubscription(true);

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_add_subscription_plan`,
        { clientRef: clientData.clientRef, ...subscriptionData },
        { headers: { "content-type": "application/json" } }
      )
      .then(async (result) => {
        setIsCreatingSubscription(false);
        const client = result.data.client;
        if (result.status === 200) {
          const checkoutPayload = {
            subscriptionPlan: subscriptionData.subscriptionPlanRef,
            client,
            isOnboarding: true,
            billingPeriod,
          };

          if (currency === "NGN") {
            await createPaystackCheckoutSession(checkoutPayload);
          } else {
            await createStripeCheckoutSession(checkoutPayload);
          }
        }

        if (result.status === 202) {
          if (client.smeSetupStage === "paidSubscription") {
            // Client has already paid subscription. Redirect to onboarding
            setOnboarding({
              ...onboarding,
              redirect: true,
              token: client.onboardingToken,
            });
          } else {
            setIsOnboarded(true);
          }
        }
      })
      .catch((error) => {
        setIsCreatingSubscription(false);
        console.log("Error creating subscription", error);

        setToast({
          show: true,
          type: "danger",
          message: "Failed to create subscription",
        });
      });
  };

  useEffect(() => {
    handleAreRequiredFieldsCompleted();
  });
  useEffect(() => {
    // Start Hotjar tracking
    if (window.hj) {
      window.hj("trigger", "subscribe");
    }

    // Clean up the effect
    return () => {};
  }, []);
  useEffect(() => {
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_subscription_plans`
      )
      .then(({ status, data }) => {
        if (status === 200) {
          setSubscriptionPlans(data.subscriptionPlans);
          // Update plan data for preselected plan
          if (plan) {
            const planData = data.subscriptionPlans.find(
              (p) => p.subscriptionPlanRef === plan
            );
            const {
              price_monthly_USD,
              price_yearly_USD,
              price_monthly_NGN,
              price_yearly_NGN,
              subscriptionPlanName,
              subscriptionPlanRef,
            } = planData;

            setSubscriptionData({
              ...subscriptionData,
              subscriptionAmount:
                billingPeriod === "yearly"
                  ? currency === "NGN"
                    ? price_yearly_NGN
                    : price_yearly_USD
                  : currency === "NGN"
                  ? price_monthly_NGN
                  : price_monthly_USD,
              subscriptionPlanName,
              subscriptionPlanRef,
            });
            setSubscriptionPlan(planData);
          }
        }
      })
      .catch((error) => console.log("Error getting subscription plans", error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_organization_sectors`
      )
      .then(({ status, data }) => {
        if (status === 200) {
          setOrganizationSectors(data.organizationSectors);
        }
      })
      .catch((error) =>
        console.log("Error getting organization sectors", error)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!clientData) {
    return <Redirect to="/login" />;
  }

  if (onboarding.redirect) {
    return <Redirect to={`/onboarding?token=${onboarding.token}`} />;
  }

  if (isOnboarded) {
    return <Redirect to="/dashboard/listen" />;
  }

  return (
    <div className="sign-up">
      <img
        src={Logo}
        width="150px"
        alt="Versus Logo"
        className="bottom-breather big-breath"
      />
      <h1 className="bold">
        Subscribe to {subscriptionData.subscriptionPlanName.toUpperCase()} plan
      </h1>
      <div
        style={{
          width: "50%",
          marginRight: "auto",
          marginLeft: "auto",
        }}
      >
        <h2 style={{ marginRight: "auto", marginLeft: "auto" }}>
          Enter your details and subscribe to{" "}
          {subscriptionData.subscriptionPlanName.toUpperCase()} plan
        </h2>
        <div
          style={{ marginRight: "auto", marginLeft: "auto" }}
          className="form-block w-form"
        >
          <form onSubmit={handleSubmit}>
            <div className="control throw-down">
              <label>Organization</label>
              <input
                type="text"
                className="input large w-input"
                maxLength="256"
                name="organizationName"
                onChange={handleChange}
                required=""
              />
            </div>
            <div className="control">
              <label>Business Sector</label>
              <select
                onChange={handleChange}
                name="organizationSector_id"
                className="input select large w-select"
              >
                <option value="">Select one...</option>
                {organizationSectors.map((os) => (
                  <option value={os.id} key={os.id}>
                    {os.sector}
                  </option>
                ))}
              </select>
            </div>
            <div className="control">
              <label>Currency</label>
              <select
                onChange={handleChange}
                name="currency"
                className="input select large w-select"
              >
                <option value="">Select one...</option>
                <option value="USD">USD</option>
                <option value="NGN">NGN</option>
              </select>
            </div>
            <div style={{ maxWidth: "100% !important" }} className="control">
              <label>Subscription plan</label>
              <select
                onChange={handleChange}
                name="subscriptionPlan"
                className="input select large w-select"
                defaultValue={subscriptionData.subscriptionPlanRef}
                disabled={!currency}
              >
                <option value="">Select one...</option>
                {subscriptionPlans.map((plan) => (
                  <option
                    value={plan.subscriptionPlanRef}
                    key={plan.subscriptionPlanRef}
                  >
                    {plan.subscriptionPlanName} (
                    {plan.subscriptionPlanRef ===
                    process.env.REACT_APP_ASK_STARTER_PLAN
                      ? currency === "USD"
                        ? "Pay as you Go - Wallet Top Up in Dashboard"
                        : plan.price_NGN
                      : `${
                          currency === "USD" ? plan.price : plan.price_NGN
                        } monthly`}
                    )
                  </option>
                ))}
              </select>
            </div>
            <div className="control">
              <label>Billing period</label>
              <label className="w-radio">
                <input
                  type="radio"
                  id="monthly"
                  name="billingPeriod"
                  value="monthly"
                  className="w-form-formradioinput radio w-radio-input"
                  onChange={handleChange}
                  defaultChecked={billingPeriod === "monthly"}
                  disabled={!subscriptionPlan}
                />
                <span htmlFor="monthly" className="w-form-label">
                  Monthly
                </span>
              </label>
              <label className="w-radio">
                <input
                  type="radio"
                  id="yearly"
                  name="billingPeriod"
                  value="yearly"
                  className="w-form-formradioinput radio w-radio-input"
                  onChange={handleChange}
                  defaultChecked={billingPeriod === "yearly"}
                  disabled={!subscriptionPlan}
                />
                <span htmlFor="yearly" className="w-form-label">
                  Yearly
                </span>
              </label>
            </div>
            <div className="control">
              <label>Terms and Privacy conditions</label>
              <label className="w-checkbox">
                <input
                  type="checkbox"
                  id="termsAndPrivacy"
                  name="hasAgreedTerms"
                  className="checkbox w-checkbox-input"
                  onChange={handleChange}
                />
                <span htmlFor="termsAndPrivacy" className="w-form-label">
                  I agree to these{" "}
                  <a href="https://docs.versus.africa/terms-and-conditions-versus-client-subscription">
                    terms
                  </a>{" "}
                  and <a href="https://docs.versus.africa/privacy">privacy</a>{" "}
                  conditions
                </span>
              </label>
            </div>
            {isCreatingSubscription ? (
              <button className="button is-disabled large wide w-button">
                Creating subscription...
              </button>
            ) : isRedirectingToStripe ? (
              <button className="button is-disabled large wide w-button">
                Redirecting to Stripe...
              </button>
            ) : isRedirectingToPaystack ? (
              <button className="button is-disabled large wide w-button">
                Redirecting to Paystack...
              </button>
            ) : !areRequiredFieldsCompleted || !hasAgreedTerms ? (
              <button
                className="button is-disabled large wide w-button"
                disabled
              >
                {`Pay ${
                  currency === "NGN"
                    ? `₦${Number(
                        subscriptionData.subscriptionAmount
                      ).toLocaleString("en")}`
                    : `$${Number(
                        subscriptionData.subscriptionAmount
                      ).toLocaleString("en")}`
                } for ${subscriptionData.subscriptionPlanName.toUpperCase()} plan`}
              </button>
            ) : (
              <button
                className="button primary large wide w-button"
                onClick={handleSubmit}
              >
                {`Pay ${
                  currency === "NGN"
                    ? `₦${Number(
                        subscriptionData.subscriptionAmount
                      ).toLocaleString("en")}`
                    : `$${Number(
                        subscriptionData.subscriptionAmount
                      ).toLocaleString("en")}`
                } for ${subscriptionData.subscriptionPlanName.toUpperCase()} plan`}
              </button>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
