import React from "react";

export const RequiredField = () => {
    return <span className="main-color">*</span>;
}

export const OnboardingNavigation = ({ moveTo, text, handleNavigation }) => {

    const callHandleNavigation = (e) => {
        handleNavigation({ stage: moveTo });
    }

    return <div style={{ textAlign: 'center' }}>
        <button type="button" onClick={callHandleNavigation} className="button-link main-color">{text}</button>
    </div>
}

export const WarningIcon = () => {
    return (<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-exclamation-circle" viewBox="0 0 16 16">
        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
        <path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" />
    </svg>)
}

export const PopUpModal = ({ active = true, onClose }) => {

    return (
        <div
            className="modal"
            style={{ display: active ? "flex" : "none", opacity: 1 }}
        >
            <div className="modal-container">
                <div
                    className="modal-card"
                    style={{
                        opacity: 1,
                        transform:
                            "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
                        transformStyle: "preserve-3d",
                        borderRadius: '10px',
                        padding: "10px 0",
                        overflow: 'auto'
                    }}
                >
                    <div 
                        style={{ position: "absolute", right: '10px'}}
                        onClick={onClose}
                    >
                       <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            className="feather feather-x icon close"
                        >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                        </svg>
                    </div>
                    <div style={{ textAlign: 'center', paddingTop: '30px' }} className="modal-body">
                        <div className="data"> Please do not go back or refresh the sign up process. </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

