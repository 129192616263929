import React, { useState } from "react";

const Carousel = (props) => {
  const { viewCarousel, responseInView, toggleViewCarousel } = props;

  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const handleSetActiveImageIndex = (direction) => {
    if (direction === "left") {
      if (activeImageIndex === 0) {
        return;
      } else {
        setActiveImageIndex((activeImageIndex) => activeImageIndex - 1);
      }
    }

    if (direction === "right") {
      if (activeImageIndex + 1 === responseInView.mediaUrls.length) {
        return;
      } else {
        setActiveImageIndex((activeImageIndex) => activeImageIndex + 1);
      }
    }
  };

  if (!responseInView) return "";

  return (
    <div
      className={`w-lightbox-backdrop ${viewCarousel ? "" : "w-lightbox-hide"}`}
      tabIndex="0"
      style={{
        transition: "opacity 300ms ease 0s",
        opacity: viewCarousel ? 1 : 0,
      }}
    >
      <div className="w-lightbox-container">
        <div className="w-lightbox-content w-lightbox-group">
          <div
            className="w-lightbox-view"
            style={{
              transform: "translateX(0px)",
              transition: "opacity 300ms ease 0s, transform 300ms ease 0s",
              opacity: 1,
            }}
          >
            <div className="w-lightbox-frame">
              <figure className="w-lightbox-figure">
                <img
                  className="w-lightbox-img w-lightbox-image"
                  src={responseInView.mediaUrls[activeImageIndex]}
                  alt="response carousel"
                />
              </figure>
            </div>
          </div>
          <div className="w-lightbox-spinner w-lightbox-hide"></div>
          <div
            className={`w-lightbox-control w-lightbox-left ${
              activeImageIndex <= 0 ? "w-lightbox-inactive" : ""
            }`}
            onClick={() => handleSetActiveImageIndex("left")}
          ></div>
          <div
            className={`w-lightbox-control w-lightbox-right ${
              activeImageIndex + 1 >= responseInView.mediaUrls.length
                ? "w-lightbox-inactive"
                : ""
            }`}
            onClick={() => handleSetActiveImageIndex("right")}
          ></div>
          <div
            className="w-lightbox-control w-lightbox-close"
            onClick={toggleViewCarousel}
          ></div>
        </div>
        <div className="w-lightbox-strip">
          {responseInView.mediaUrls.map((urel, i) => (
            <div
              className={`w-lightbox-item ${
                activeImageIndex === i ? "w-lightbox-active" : ""
              }`}
              key={i}
            >
              <div className="w-lightbox-thumbnail">
                <img
                  className="w-lightbox-img w-lightbox-wide w-lightbox-thumbnail-image"
                  src={urel}
                  alt="response carousel active"
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carousel;
