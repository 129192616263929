import React, { useState } from "react";
import axios from "axios";
import Toast from "../Toast/Toast";

import { getUser, getIdToken } from "../../utils/storage";

const DownloadAndShare = (props) => {
  const {
    period,
    buttonStyle = "small subtle",
    sentiment,
    country,
    language,
    KeywordOrHashtag,
    sources,
    clientRef,
    brandName,
    brands,
    path,
    reportName,
    campaignRef,
    age,
    tribe,
    tooltip,
    setTooltip,
    isCompare,
  } = props;
  const uid = getUser().uid,
    idToken = getIdToken(),
    email = getUser().email;

  const [isGettingPdfReport, setIsGettingPdfReport] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    type: "",
    message: "",
  });
  //show tooltip only once
  const showCompareTooltip = sessionStorage.getItem("showCompareTooltip");
  const showTooltip = (status) => {
    sessionStorage.setItem("showCompareTooltip", status);
  };

  const handleGetPdfReport = () => {
    console.log("getting pdf report");
    console.log("brands", brands);
    setIsGettingPdfReport(true);
    const brandsList =
      brands &&
      brands
        .filter(
          (brand) =>
            brand !== null &&
            brand !== undefined &&
            brand.compareDbRef !== undefined
        )
        .map((brand) => ({
          brandRef: brand.compareDbRef,
          brandName: brand.brandName,
        }));
    console.log(country, language);
    const payload =
      reportName === "SurveyReport"
        ? {
            uid,
            age,
            tribe,
            idToken,
            clientRef,
            campaignRef,
            email,
          }
        : {
            brandName,
            uid,
            idToken,
            clientRef,
            period,
            sentiment,
            country,
            language,
            keyword_or_hashtag: KeywordOrHashtag,
            sources,
            ...(brands ? { brands: brandsList } : {}),
          };
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}/${path}`, payload, {
        headers: { "content-type": "application/json" },
        responseType: "blob",
      })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportName}.pdf`);
        link.click();
        window.URL.revokeObjectURL(url);

        setIsGettingPdfReport(false);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: `error downloading report`,
        });
        setIsGettingPdfReport(false);

        console.log("error getting report", error);
      });
  };

  return (
    <div className="row">
      {isGettingPdfReport ? (
        <span
          className={`button is-disabled ${buttonStyle} with-icon w-button`}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`feather feather-download icon move-left ${buttonStyle} `}
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>{" "}
          Downloading report...
        </span>
      ) : (
        <span
          className={`button ${buttonStyle} with-icon w-button`}
          onClick={handleGetPdfReport}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`feather feather-download icon move-left ${buttonStyle} `}
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>{" "}
          Download report
        </span>
      )}
      <div
        style={{
          display: tooltip.step === 2 ? "flex" : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content top"
      >
        <div className="card-section">
          <div>Listen Reports</div>
          <p className="move-down">Quickly get a summary of your dashboard</p>

          <div className="inline-tooltip-frame">
            <span
              onClick={() =>
                setTooltip({
                  show: false,
                  type: "",
                  title: "",
                  message: "",
                  step: 0,
                  max_step: 7,
                })
              }
            >
              Skip tutorial
            </span>
            <button
              className="inline-tooltip-button"
              onClick={() =>
                setTooltip({
                  show: true,
                  type: "",
                  title: "",
                  message: "",
                  step: 3,
                  max_step: 7,
                })
              }
            >
              Next
            </button>
          </div>
        </div>
      </div>

      <div
        style={{
          display:
            tooltip.step === 5.2 && isCompare && showCompareTooltip === null
              ? "flex"
              : "none",
          width: "300px",
        }}
        className=" inline-tooltip-content-3 top-left"
      >
        <div className="card-section">
          <div></div>Compare reports{isCompare}
          <p className="move-down">
            Get a summary of your brand's perfomance against the competition{" "}
          </p>
          <div
            className="inline-tooltip-frame"
            onClick={() => {
              setTooltip({
                show: false,
                type: "",
                title: "",
                message: "",
                step: 0,
                max_step: 7,
              });
              showTooltip(false);
            }}
          >
            <span>Skip tutorial</span>
            <button
              className="inline-tooltip-button"
              onClick={() => {
                setTooltip({
                  show: true,
                  type: "",
                  title: "",
                  message: "",
                  step: 0,
                  max_step: 7,
                });
                showTooltip(false);
              }}
            >
              Finish
            </button>
          </div>
        </div>
      </div>

      {/* <a href="/" className="button no-margin wide with-icon subtle small">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-mail icon move-left small"
        >
          <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
          <polyline points="22,6 12,13 2,6"></polyline>
        </svg>{" "}
        Share with team
      </a> */}
      <Toast toast={toast} />
    </div>
  );
};

export default DownloadAndShare;
