import { storageInstance } from "../utils/firebase";
import { ref, uploadBytes } from "firebase/storage";

const REACT_APP_FIREBASE_IMAGE_STORAGE_PATH =
  process.env.REACT_APP_FIREBASE_IMAGE_STORAGE_PATH || "campaign-images";

const uploadLogo = (brandName, clientRef, logoFile) => {
  const logosRef = ref(
    storageInstance,
    `clientlogos/${brandName.replace(" ", "-")}-${clientRef}`
  );

  return new Promise((resolve, reject) => {
    uploadBytes(logosRef, logoFile)
      .then((snapshot) => resolve(snapshot))
      .catch((error) => reject(new Error(error.message)));
  });
};

const uploadFile = async (file, fileName) => {
  try {
    const storageRef = ref(
      storageInstance,
      `${REACT_APP_FIREBASE_IMAGE_STORAGE_PATH}/${fileName}`
    );
    const snapshot = await uploadBytes(storageRef, file);
    return {
      success: true,
      snapshot,
      error: null,
    };
  } catch (error) {
    return {
      error,
      success: false,
      snapshot: null,
    };
  }
};

export { uploadLogo, uploadFile };
