import React from "react";

import Icon from "./Icon";

const Toast = (props) => {
  const { infoText, toast, setToast } = props;

  // const [showToast, setShowToastrue);

  if (!toast) {
    return <div></div>;
  } else {
    return (
      <div
        className="toast-container"
        style={{ display: `${toast.show ? "block" : "none"}` }}
        // onClick={() => setShowToast(false)}
      >
        <div className={`toast ${toast.type}`}>
          <Icon type={toast.type} />
          <div style={{ width: "100%" }} className="message-content">
            <h4>{toast.message}</h4>
            {toast.infoText ? <p className="push-down">{infoText}</p> : ""}
          </div>
          <button
            onClick={() => setToast({ show: false, type: "", message: "" })}
            style={{
              width: "fit-content",
              fontSize: "1.5rem",
              fontWeight: 500,
              background: "black",
              color: "white",
              cursor: "pointer",
              textAlign: "center",
              padding: "2px",
            }}
          >
            X
          </button>
        </div>
      </div>
    );
  }
};

export default Toast;
