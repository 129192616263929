import React, { useState, useEffect } from "react";
import axios from "axios";

import { getIdToken, getUser } from "../../../../utils/storage";

const TotalQuestionsAndCost = (props) => {
  const { clientRef, campaign, totalRespondents, setToast } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [totalQuestions, setTotalQuestions] = useState(0);
  const [isEndingSurvey, setIsEndingSurvey] = useState(false);
  const [isChangingSurveyStatus, setIsChangingSurveyStatus] = useState(false);
  const [surveyStatus, setSurveyStatus] = useState(campaign.status);

  const handleToggleSurveyStatus = () => {
    setIsChangingSurveyStatus(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_toggle_survey_status`,
        {
          uid,
          idToken,
          clientRef,
          mediaType: "text",
          campaignRef: campaign.campaignRef,
        }
      )
      .then(({ data }) => {
        setSurveyStatus(data?.data?.status);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error Changing survey status. Please refresh",
        });
      })
      .finally(() => {
        setIsChangingSurveyStatus(false);
      });
  };

  const handleEndSurvey = () => {
    setIsEndingSurvey(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_stop_campaign`,
        {
          uid,
          idToken,
          clientRef,
          campaignRef: campaign.campaignRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(() => {
        window.location.reload();
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error Ending survey. Please refresh",
        });
      })
      .finally(() => {
        setIsEndingSurvey(false);
      });
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_survey_questions`,
        {
          uid,
          idToken,
          clientRef,
          campaignRef: campaign.campaignRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setTotalQuestions(data.totalSurveyQuestions);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: "Error fetching questions. Please refresh",
        });
      });
  }, []);

  return (
    <>
      <div>
        <div className="chip bold push-up blue">
          {surveyStatus?.toLowerCase() || campaign?.status}
        </div>
        {["live", "paused"].includes(campaign?.status) ? (
          <span
            id="btn-survey"
            data-w-id="b69e4f6b-ec32-1c13-05ac-a389f1135f49"
            className={`button small subtle float-right no-margin w-button ${
              isChangingSurveyStatus ? "is-disabled" : ""
            } `}
            onClick={() => {
              if (!isChangingSurveyStatus) {
                handleToggleSurveyStatus();
              }
            }}
          >
            {(surveyStatus?.toLowerCase() || campaign?.status) == "paused"
              ? "Resume survey"
              : "Pause survey"}
          </span>
        ) : (
          ""
        )}

        <div className="call-out push-up">
          <div className="small-text">
            <strong>{totalQuestions}</strong> questions x{" "}
            <strong>{totalRespondents}</strong> respondents
          </div>
          <div className="divider move-down purple"></div>
          <div className="row spaced">
            <div className="small-text uppercase">Total Cost</div>
            <div className="primary bold">
              {totalQuestions * totalRespondents} VC
            </div>
          </div>
        </div>
      </div>
      {["live", "paused"].includes(campaign?.status) ? (
        <div className="cta-btns">
          <span
            id="survey-status"
            onClick={handleEndSurvey}
            className={`button primary wide w-button ${
              isEndingSurvey ? "is-disabled" : ""
            }`}
          >
            End survey
          </span>
        </div>
      ) : (
        ""
      )}
      <div className="divider in-sidebar"></div>
      <div>
        <div className="data huge">{totalQuestions}</div>
        <h3 className="move-down">Questions</h3>
      </div>
    </>
  );
};

export default TotalQuestionsAndCost;
