import React from "react";

const NegativeSentimentDataMeasureBar = (props) => {
  const { brand, mention } = props;

  if (!mention) {
    return <div></div>;
  } else {
    return (
      <div className="source-container move-down">
        <span className="link-block w-inline-block">
          <div className="bar-container has-cover">
            <div className="medium-text nudge-right">{brand.brandName}</div>
            <div className="data tiny nudge-left">{`${
              mention.percentageNegative ? mention.percentageNegative : 0
            }% or ${Math.round(
              (mention.percentageNegative / 100) * mention.total
            )}`}</div>
            <div className="measure-bar tall cover">
              <div
                className="sentiment negative single test-data"
                style={{
                  height: "7.99219px",
                  width: `${
                    mention.percentageNegative ? mention.percentageNegative : 0
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </span>
      </div>
    );
  }
};

export default NegativeSentimentDataMeasureBar;
