export const NPS_OPTIONS_GROUP = [
  {
    lowerLimit: 1,
    upperLimit: 6,
    name: "Detractors",
    label: "Detractors (1-6)",
  },
  {
    lowerLimit: 7,
    upperLimit: 8,
    name: "Passive",
    label: "Passive (7-8)",
  },

  {
    lowerLimit: 9,
    upperLimit: 10,
    name: "Promoters",
    label: "Promoters (9-10)",
  },
];

export const SKIP_LOGIC_ACTIONS = {
  NONE: "NONE",
  JUMP: "JUMP",
  END_SURVEY: "END_SURVEY",
};
