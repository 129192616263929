/**
 * Reject Response - Enable clients to reject Interact Response
 */

import React, { useState } from "react";
import axios from "axios";
import { getUser, getIdToken, getClient } from "../../../../utils/storage";
import DummyButton from "../../../DummyButton";

const RejectRequest = (props) => {
  const {
    showRejectModal,
    toggleShowRejectModal,
    responseRef,
    requestRef,
    setToast,
  } = props;

  const user = getUser(),
    idToken = getIdToken(),
    client = getClient();

  const [optionalMessage, setOptionalMessage] = useState("");
  // const [reasonForRejection, setReasonForRejection] = useState([]);
  const [reasonForRejection, setReasonForRejection] = useState("");
  const [rejectingResponse, setRejectingResponse] = useState(false);

  const selectRejectionReason = (e) => {
    const { value } = e.target;
    // if (reasonForRejection.includes(value)) {
    //   const newArr = reasonForRejection.filter((item) => item !== value);
    //   setReasonForRejection(newArr);
    // } else {
    //   setReasonForRejection((prev) => [...prev, value]);
    // }
    setReasonForRejection(value);
  };

  const rejectResponse = () => {
    if (reasonForRejection.length === 0) {
      setToast({
        show: true,
        type: "warning",
        message: "Please select a reason for rejecting this response",
      });
      return setTimeout(() => setToast({ show: false }), 5000);
    }

    setRejectingResponse(true);

    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_reject_request_response`,
      method: "post",
      headers: { "Content-type": "application/json" },
      data: {
        uid: user.uid,
        idToken,
        clientRef: client.clientRef,
        requestRef,
        responseRef: responseRef.toString(),
        reasonForRejection,
        optionalMessage,
      },
    })
      .then((result) => {
        setRejectingResponse(false);
        if (result.status === 200) {
          setToast({
            show: true,
            type: "success",
            message: "Response rejected",
          });
          toggleShowRejectModal();
          setTimeout(() => setToast({ show: false }), 5000);
        }
      })
      .catch((error) => {
        setRejectingResponse(false);
        setToast({
          show: true,
          type: "danger",
          message:
            error.response.data.message ||
            "Fail. Something must have gone wrong",
        });
        toggleShowRejectModal();
        setTimeout(() => setToast({ show: false }), 5000);
      });
  };

  return (
    <div
      className="modal"
      style={{ display: showRejectModal ? "flex" : "none", opacity: 1 }}
    >
      <div className="modal-container">
        <h2>Reject response</h2>
        <div onClick={toggleShowRejectModal}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          data-w-id="09c681c6-e79c-d1c1-e6b1-cb35b5a835be"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
          className="modal-card"
        >
          <div className="modal-card-content">
            <div className="modal-body pad-top">
              <h2>
                <strong>You’re about to reject this Scout’s response</strong>
              </h2>
              <p>
                Rejecting a Submission means the Scout won’t receive credits for
                their submission. They will receive a notification of this, and
                a message informing them of the reason behind their rejection.
                <br />
              </p>
              <h3 className="push-down">
                <strong>Please note:</strong>
              </h3>
              <ul>
                <li>This cannot be reversed</li>
                <li>
                  Unused credits will be returned to your wallet after the
                  request is over
                </li>
                <li>The Scout may reach out to dispute the rejection</li>
              </ul>
              <div className="divider"></div>
              <div className="w-form">
                <form>
                  <label className="push-down move-up">Reason</label>
                  <label className="w-checkbox checkbox-field">
                    <input
                      type="radio"
                      id="wrong-subject-matter"
                      name="reason-for-rejection"
                      value="irrelevant"
                      className="w-checkbox-input checkbox"
                      onChange={selectRejectionReason}
                    />
                    <span className="w-form-label">Irrelevant</span>
                  </label>
                  <label className="w-checkbox checkbox-field">
                    <input
                      type="radio"
                      id="poor-quality"
                      name="reason-for-rejection"
                      value="poor_quality"
                      className="w-checkbox-input checkbox"
                      onChange={selectRejectionReason}
                    />
                    <span className="w-form-label">Poor quality</span>
                  </label>
                  <label className="w-checkbox checkbox-field">
                    <input
                      type="radio"
                      id="inappropriate-content"
                      name="reason-for-rejection"
                      value="NSFW"
                      className="w-checkbox-input checkbox"
                      onChange={selectRejectionReason}
                    />
                    <span className="w-form-label">Not suitable for work</span>
                  </label>
                  <label
                    htmlFor="optional-message"
                    className="push-down move-up"
                  >
                    Optional message
                  </label>
                  <textarea
                    placeholder="Provide context to the Scout"
                    maxLength="5000"
                    id="optional-message"
                    name="field-7"
                    className="input-2 large w-input"
                    value={optionalMessage}
                    onChange={({ target }) => setOptionalMessage(target.value)}
                  ></textarea>
                </form>
                <div className="w-form-done">
                  <div>Thank you! Your submission has been received!</div>
                </div>
                <div className="w-form-fail">
                  <div>
                    Oops! Something went wrong while submitting the form.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            {rejectingResponse ? (
              <DummyButton text="Rejecting response..." />
            ) : (
              <div>
                <span
                  className="button primary black w-button"
                  onClick={rejectResponse}
                >
                  Reject Response
                </span>
                <span
                  className="button subtle push-right w-button"
                  onClick={toggleShowRejectModal}
                >
                  Cancel
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RejectRequest;
