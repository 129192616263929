import React from "react";

const DataMeasureBar = (props) => {
  const { brand, mention, highestMention } = props;

  if (!mention) {
    return <div></div>;
  } else {
    return (
      <div className="source-container move-down">
        <span className="link-block w-inline-block">
          <div className="bar-container has-cover">
            <div className="medium-text nudge-right">{brand.brandName}</div>
            <div className="data tiny nudge-left">{mention.total}</div>
            <div className="measure-bar tall cover">
              <div
                className="metric stat"
                style={{
                  height: "7.99219px",
                  width: `${(mention.total / highestMention) * 100}%`,
                }}
              ></div>
            </div>
          </div>
        </span>
      </div>
    );
  }
};

export default DataMeasureBar;
