import React from "react";

import Influencer from "./Influencer";

const PromoterInfluencers = (props) => {
  const {
    sentiment,
    brand1,
    brand1Influencers,
    brand2,
    brand2Influencers,
    brand3,
    brand3Influencers,
    brand4,
    brand4Influencers,
  } = props;

  return (
    <div className="row has-cards move-down">
      <div className="column">
        <div className="card-2">
          <div className="row stretch">
            <div
              className="card-section horizontal padded expand"
              style={{ opacity: sentiment === "negative sentiment" ? 0.1 : 1 }}
            >
              <h3>Promoter influencers</h3>
              <div
                className="row has-cards no-padding"
                style={{ height: "500px", overflow: "auto" }}
              >
                {brand1 ? (
                  <div className="column padded">
                    <h5 className="push-up">{brand1.brandName}</h5>
                    {brand1Influencers &&
                    Array.isArray(brand1Influencers.positive)
                      ? brand1Influencers.positive.map(
                          (positiveInfluencer, i) => (
                            <Influencer
                              influencer={positiveInfluencer}
                              category="promoters"
                              key={i}
                            />
                          )
                        )
                      : ""}
                  </div>
                ) : (
                  ""
                )}

                {brand2 ? (
                  <div className="column padded">
                    <h5 className="push-up">{brand2.brandName}</h5>
                    {brand2Influencers &&
                    Array.isArray(brand2Influencers.positive)
                      ? brand2Influencers.positive.map(
                          (positiveInfluencer, i) => (
                            <Influencer
                              influencer={positiveInfluencer}
                              category="promoters"
                              key={i}
                            />
                          )
                        )
                      : ""}
                  </div>
                ) : (
                  ""
                )}

                {brand3 ? (
                  <div className="column padded">
                    <h5 className="push-up">{brand3.brandName}</h5>
                    {brand3Influencers &&
                    Array.isArray(brand3Influencers.positive)
                      ? brand3Influencers.positive.map(
                          (positiveInfluencer, i) => (
                            <Influencer
                              influencer={positiveInfluencer}
                              category="promoters"
                              key={i}
                            />
                          )
                        )
                      : ""}
                  </div>
                ) : (
                  ""
                )}

                {brand4 ? (
                  <div className="column padded">
                    <h5 className="push-up">{brand4.brandName}</h5>
                    {brand4Influencers &&
                    Array.isArray(brand4Influencers.positive)
                      ? brand4Influencers.positive.map(
                          (positiveInfluencer, i) => (
                            <Influencer
                              influencer={positiveInfluencer}
                              category="promoters"
                              key={i}
                            />
                          )
                        )
                      : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PromoterInfluencers;
