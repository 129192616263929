import React, { useState, useEffect } from "react";
import Axios from "axios";

import MentionsLineChart from "./MentionsLineChart";
import NetSentimentLineChart from "./NetSentimentLineChart";

import { getUser, getIdToken } from "../../../utils/storage";

const MentionsChart = (props) => {
  const {
    client,
    brand1,
    brand2,
    brand3,
    brand4,
    period,
    country,
    language,
    sources,
    setToast,
  } = props;

  const uid = getUser().uid,
    idToken = getIdToken();

  const [chartData, setChartData] = useState([]);
  const [chartLabels, setChartLabels] = useState([]);

  useEffect(() => {
    const postRequests = [];
    [brand1, brand2, brand3, brand4].forEach((brand) => {
      if (brand) {
        postRequests.push(
          Axios.post(
            `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_chart_data`,
            {
              clientRef: brand.compareDbRef,
              uid,
              idToken,
              period,
              country,
              language,
              // sentiment,
              sources,
            },
            { headers: { "content-type": "application/json" } }
          )
        );
      }
    });

    Axios.all(postRequests)
      .then(
        Axios.spread((result1, result2, result3, result4) => {
          // console.log('chart data', '\n', result1, '\n', result2, '\n', result3, '\n', result4);
          const mentionsChartData = [];
          [result1, result2, result3, result4].forEach((result) => {
            if (result) {
              mentionsChartData.push(result.data.chartData);
            }
          });
          const labels = mentionsChartData[0]
            ? mentionsChartData[0].labels
            : [];
          setChartLabels(labels);
          setChartData(mentionsChartData);
        })
      )
      .catch((error) => {
        console.log("Error fetching all chart data", error);
        setToast({
          show: true,
          type: "warning",
          message: "Error batch-fetching chart data. Please refresh",
        });
      });
  }, [brand1, brand2, brand3, brand4, period, country, language]);

  if (!brand1) {
    return <div></div>;
  } else {
    return (
      <div className="row has-cards">
        <div className="column">
          <div className="card-2">
            <div className="card-section padded-x">
              <div className="row spaced baseline no-margin no-padding">
                <h3>Mentions over time</h3>
                <div className="row">
                  <div className="tag small violet">
                    {brand1 ? brand1.brandName : ""}
                  </div>
                  <div className="tag nudge-right small blue">
                    {brand2 ? brand2.brandName : ""}
                  </div>
                  <div className="tag nudge-right small teal">
                    {brand3 ? brand3.brandName : ""}
                  </div>
                  <div className="tag nudge-right small yellow">
                    {brand4 ? brand4.brandName : ""}
                  </div>
                </div>
              </div>
              <MentionsLineChart
                labels={chartLabels}
                brand1={brand1}
                brand1Data={chartData.find(
                  (brand) => brand.clientRef === brand1.compareDbRef
                )}
                brand2={brand2}
                brand2Data={
                  brand2
                    ? chartData.find(
                        (brand) => brand.clientRef === brand2.compareDbRef
                      )
                    : null
                }
                brand3={brand3}
                brand3Data={
                  brand3
                    ? chartData.find(
                        (brand) => brand.clientRef === brand3.compareDbRef
                      )
                    : null
                }
                brand4={brand4}
                brand4Data={
                  brand4
                    ? chartData.find(
                        (brand) => brand.clientRef === brand4.compareDbRef
                      )
                    : null
                }
              />
              <div className="divider push-up"></div>
              <div className="push-down"></div>
              <div className="row spaced baseline no-margin no-padding">
                <h3>Net sentiment over time</h3>
              </div>
              <NetSentimentLineChart
                labels={chartLabels}
                brand1={brand1}
                brand1Data={chartData.find(
                  (brand) => brand.clientRef === brand1.compareDbRef
                )}
                brand2={brand2}
                brand2Data={
                  brand2
                    ? chartData.find(
                        (brand) => brand.clientRef === brand2.compareDbRef
                      )
                    : null
                }
                brand3={brand3}
                brand3Data={
                  brand3
                    ? chartData.find(
                        (brand) => brand.clientRef === brand3.compareDbRef
                      )
                    : null
                }
                brand4={brand4}
                brand4Data={
                  brand4
                    ? chartData.find(
                        (brand) => brand.clientRef === brand4.compareDbRef
                      )
                    : null
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default MentionsChart;
