import React, { useState } from "react";
import Toast from "../Toast/Toast";
import Axios from "axios";

const CSVExport = ({
  clientRef,
  campaignRef,
  path,
  reportName,
  buttonStyle = "wide",
}) => {
  const [isGettingReport, setIsGettingReport] = useState(false);
  const [toast, setToast] = useState({
    show: false,
    type: "",
    message: "",
  });

  const handleExportReportAsCSV = () => {
    setIsGettingReport(true);

    const payload = {
      campaignRef: campaignRef,
      clientRef: clientRef,
    };

    Axios.post(`${process.env.REACT_APP_API_BASE_URL}/${path}`, payload, {
      headers: { "content-type": "application/json" },
      responseType: "blob",
    })
      .then((result) => {
        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${reportName}.csv`);
        link.click();
        window.URL.revokeObjectURL(url);
        setIsGettingReport(false);
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "warning",
          message: `error downloading report`,
        });
        setIsGettingReport(false);
        console.log("error getting report", error);
      });
  };

  return (
    <div>
      {isGettingReport ? (
        <span
          className={`button is-disabled ${buttonStyle} with-icon w-button`}
          onClick={handleExportReportAsCSV}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`feather feather-download ${buttonStyle} icon move-left`}
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          Exporting report...
        </span>
      ) : (
        <span
          className={`button ${buttonStyle} with-icon w-button`}
          onClick={handleExportReportAsCSV}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`feather feather-download ${buttonStyle} icon move-left`}
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>
          Export as CSV
        </span>
      )}
      <Toast toast={toast} />
    </div>
  );
};

export default CSVExport;
