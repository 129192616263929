import React, { useState } from "react";
import axios from "axios";
import DummyButton from "../../../DummyButton";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

const DownloadAndShare = (props) => {
  const { requestRef, selectedResponses, setToast, toggleIsShareModalOpen } =
    props;

  const uid = getUser().uid,
    idToken = getIdToken(),
    client = getClient();

  const [isDownloading, setIsDownloading] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const handleDownload = () => {
    const mediaLocations = selectedResponses
      .filter((response) => response.status === "approved")
      .map((res) => ({ url: res.mediaUrls[0], scoutRef: res.scoutRef }));
    setIsDownloading(true);
    const payload = {
      uid,
      idToken,
      clientRef: client.clientRef,
      requestRef,
      mediaLocations,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_download_request_responses`,
        payload,
        {
          headers: { "content-type": "application/json" },
          // responseType: "blob",
        }
      )
      .then((result) => {
        // const url = window.URL.createObjectURL(new Blob([result.data]));
        const url = result.data.url;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `responses-${requestRef}.zip`);
        link.click();
        // window.URL.revokeObjectURL(url);

        setIsDownloading(false);
      })
      .catch((error) => {
        setIsDownloading(false);
        console.log("error downloading responses", error);
      });
  };
  const handleShare = () => {
    console.log("getting request responses");
    setIsSharing(true);
    const payload = {
      uid,
      idToken,
      clientRef: client.clientRef,
      requestRef,
    };
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_share_request_responses`,
        payload,
        {
          headers: { "content-type": "application/json" },
          responseType: "blob",
        }
      )
      .then((result) => {
        if (result.status === 200) {
          setToast({
            show: true,
            type: "success",
            message: "Report shared with team",
          });
          setTimeout(() => setToast({ show: false }), 5000);
        }

        setIsSharing(false);
      })
      .catch((error) => {
        setIsSharing(false);
        console.log("error sharing report", error);
      });
  };

  return (
    <div className="row">
      {isDownloading ? (
        <DummyButton
          text="Downloading..."
          extraClass="button with-icon small move-left w-button"
        />
      ) : (
        <span
          onClick={handleDownload}
          className="button with-icon small move-left w-button"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-download icon move-left small"
          >
            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
            <polyline points="7 10 12 15 17 10"></polyline>
            <line x1="12" y1="15" x2="12" y2="3"></line>
          </svg>{" "}
          Download
        </span>
      )}
      {isSharing ? (
        <DummyButton text="Sharing..." extraClassName="small with-icon" />
      ) : (
        <span
          onClick={toggleIsShareModalOpen}
          className="button small with-icon"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-mail icon move-left small"
          >
            <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"></path>
            <polyline points="22,6 12,13 2,6"></polyline>
          </svg>{" "}
          Share
        </span>
      )}
    </div>
  );
};

export default DownloadAndShare;
