import React from "react";

import Mention from "./Mention";

const PopularPositiveMentions = (props) => {
  const {
    sentiment,
    brand1,
    brand1Mentions,
    brand2,
    brand2Mentions,
    brand3,
    brand3Mentions,
    brand4,
    brand4Mentions,
  } = props;

  return (
    <div className="row has-cards">
      <div className="column">
        <div className="card-2">
          <div
            className="card-section padded-x"
            style={{ opacity: sentiment === "negative sentiment" ? 0.1 : 1 }}
          >
            <h3>Popular positive mentions</h3>
            <div
              className="row push-down has-cards no-padding"
              style={{ height: "500px", overflow: "auto" }}
            >
              {brand1 ? (
                <div className="column padded">
                  <h5 className="push-up">{brand1.brandName}</h5>
                  {brand1Mentions
                    ? brand1Mentions.positive.map((positiveMention, i) => (
                        <Mention
                          mention={positiveMention}
                          category="positive"
                          key={i}
                        />
                      ))
                    : ""}
                </div>
              ) : (
                ""
              )}

              {brand2 ? (
                <div className="column padded">
                  <h5 className="push-up">{brand2.brandName}</h5>
                  {brand2Mentions
                    ? brand2Mentions.positive.map((positiveMention, i) => (
                        <Mention
                          mention={positiveMention}
                          category="positive"
                          key={i}
                        />
                      ))
                    : ""}
                </div>
              ) : (
                ""
              )}

              {brand3 ? (
                <div className="column padded">
                  <h5 className="push-up">{brand3.brandName}</h5>
                  {brand3Mentions
                    ? brand3Mentions.positive.map((positiveMention, i) => (
                        <Mention
                          mention={positiveMention}
                          category="positive"
                          key={i}
                        />
                      ))
                    : ""}
                </div>
              ) : (
                ""
              )}

              {brand4 ? (
                <div className="column padded">
                  <h5 className="push-up">{brand4.brandName}</h5>
                  {brand4Mentions
                    ? brand4Mentions.positive.map((positiveMention, i) => (
                        <Mention
                          mention={positiveMention}
                          category="positive"
                          key={i}
                        />
                      ))
                    : ""}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopularPositiveMentions;
