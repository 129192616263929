import React, { useState, useEffect } from "react";
import axios from "axios";

import { getUser, getIdToken } from "../../../utils/storage";

const SocialMedia = (props) => {
  const {
    filterPeriod,
    client,
    setToast,
    filterSentiment,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
    toggleSource,
    sources,
  } = props;

  const user = getUser(),
    idToken = getIdToken();

  const [socialMediaSources, setSocialMediaSources] = useState({
    totalMentions: 0,
    breakdown: {
      twitter: 0,
      facebook: 0,
      instagram: 0,
    },
  });

  const url = `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_socialmedia_mentions_sources`;

  useEffect(() => {
    axios
      .post(
        url,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          period: filterPeriod,
          sentiment: filterSentiment,
          country: filterCountry,
          language: filterLanguage,
          keyword_or_hashtag: filterKeywordOrHashtag,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const { totalTwitterMentions, totalFbMentions, totalIgMentions } =
            result.data;
          setSocialMediaSources({
            totalMentions:
              totalTwitterMentions + totalFbMentions + totalIgMentions,
            breakdown: {
              twitter: totalTwitterMentions,
              facebook: totalFbMentions,
              instagram: totalIgMentions,
            },
          });
        }
      })
      .catch((error) => {
        console.log(
          "Error obtaining socialmedia mentions sources",
          error.message
        );
        setToast({
          show: true,
          type: "warning",
          message:
            "We had problems retrieving socialmedia mentions sources. Please refresh",
        });
      });
  }, [
    client.clientRef,
    filterPeriod,
    filterSentiment,
    idToken,
    setToast,
    url,
    user.uid,
    filterCountry,
    filterLanguage,
    filterKeywordOrHashtag,
  ]);

  return (
    <div
      className="column padded-right no-padding-left"
      style={{
        opacity:
          sources.categories.length === 0 ||
          sources.categories.includes("socialmedia")
            ? 1
            : 0.2,
      }}
    >
      <h3
        className="card-title"
        data-tooltip={
          sources.categories.includes("socialmedia")
            ? "Clear filter by Social media"
            : "Filter by SocialMedia"
        }
        onClick={() => toggleSource(null, "socialmedia", ["twitter"])}
      >
        Social media{" "}
        <span className="data small">{socialMediaSources.totalMentions}</span>
      </h3>
      <div className="source-container">
        <span
          data-tooltip={
            sources.socialmedia.includes("twitter")
              ? "Clear filter by Twitter"
              : "Filter by Twitter"
          }
          className="link-block w-inline-block"
        >
          <div
            className="bar-container has-cover"
            style={{
              cursor: "pointer",
              opacity:
                sources.socialmedia.length === 0 ||
                sources.socialmedia.includes("twitter")
                  ? 1
                  : 0.2,
            }}
            onClick={() => toggleSource("twitter", "socialmedia")}
          >
            <div className="medium-text nudge-right">Twitter</div>
            <div className="data tiny nudge-left">
              {socialMediaSources.breakdown.twitter || 0}
            </div>
            <div className="measure-bar tall cover">
              <div
                className="metric stat"
                style={{
                  width: `${
                    (socialMediaSources.breakdown.twitter /
                      socialMediaSources.totalMentions) *
                      100 || 0
                  }%`,
                  height: "7.99219px",
                }}
              ></div>
            </div>
          </div>
        </span>

        <span
          data-tooltip={
            sources.socialmedia.includes("facebook")
              ? "Clear filter by Facebook"
              : "Filter by Facebook"
          }
          className="link-block w-inline-block"
        >
          <div
            className="bar-container has-cover nudge-down static"
            style={{
              cursor: "pointer",
              opacity:
                sources.socialmedia.length === 0 ||
                sources.socialmedia.includes("facebook")
                  ? 1
                  : 0.2,
            }}
            onClick={() => toggleSource("facebook", "socialmedia")}
          >
            <div className="medium-text nudge-right">Facebook</div>
            <div className="data tiny nudge-left">
              {socialMediaSources.breakdown.facebook || 0}
            </div>
            <div className="measure-bar tall cover">
              <div
                className="metric stat"
                style={{
                  width: `${
                    (socialMediaSources.breakdown.facebook /
                      socialMediaSources.totalMentions) *
                      100 || 0
                  }%`,
                  height: "7.99219px",
                }}
              ></div>
            </div>
          </div>
        </span>
        <span
          data-tooltip={
            sources.socialmedia.includes("instagram")
              ? "Clear filter by Instagram"
              : "Filter by Instagram"
          }
          className="link-block w-inline-block"
        >
          <div
            className="bar-container has-cover nudge-down static"
            style={{
              cursor: "pointer",
              opacity:
                sources.socialmedia.length === 0 ||
                sources.socialmedia.includes("instagram")
                  ? 1
                  : 0.2,
            }}
            onClick={() => toggleSource("instagram", "socialmedia")}
          >
            <div className="medium-text nudge-right">Instagram</div>
            <div className="data tiny nudge-left">
              {socialMediaSources.breakdown.instagram || 0}
            </div>
            <div className="measure-bar tall cover">
              <div
                className="metric stat"
                style={{
                  width: `${
                    (socialMediaSources.breakdown.instagram /
                      socialMediaSources.totalMentions) *
                      100 || 0
                  }%`,
                  height: "7.99219px",
                }}
              ></div>
            </div>
          </div>
        </span>
      </div>
    </div>
  );
};

export default SocialMedia;
