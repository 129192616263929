import React from "react";

const AudioPlayer = (props) => {
  const { responseInView } = props;

  return (
    <div className="w-embed">
      <audio id="player" controls>
        <source src={responseInView.mediaUrls[0]} type="audio/mp3" />
      </audio>
    </div>
  );
};

export default AudioPlayer;
