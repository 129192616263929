import React from "react";

const DisplayTaskCategory = ({ surveyData, gotoSurveyStep }) => {
  const formatCategory = (category) => {
    if (category) {
      return category.replace("_", " ");
    }
  };

  return (
    <>
      {surveyData.category && (
        <div className="subhead no-margin" style={{ marginTop: "7px" }}>
          {formatCategory(surveyData.category)}, {surveyData.link}
          <span
            style={{ cursor: "pointer" }}
            onClick={() => gotoSurveyStep("campaignTask")}
            className="primary"
          >
            {" "}
            Edit
          </span>
        </div>
      )}
    </>
  );
};

export default DisplayTaskCategory;
