import React, { useRef, useState } from "react";
import { Redirect, withRouter } from "react-router-dom";
// import queryString from 'query-string';
import { useSelector } from "react-redux";

import { getUser } from "../../utils/storage";

import MakeDeposit from "./MakeDeposit";
import SurveysSummary from "./SurveysSummary";
import SurveysList from "./SurveysList";
import SurveysFilterTabs from "./SurveysFilterTabs";
import SurveySearchAndSort from "../Shared/SurveySearchAndSort";

const Ask = (props) => {
  const {
    toggleNewSurveyModalOpen,
    client,
    setToast,
    isFilterExpanded,
    setIsFilterExpanded,
    filter,
  } = props;
  const [allSurveysList, setAllSurveysList] = useState([]);
  const [filteredSurveysList, setFilteredSurveysList] = useState([]);
  const filterTabsRef = useRef();
  const user = getUser();
  // const stripePaymentStatus = props.location.search ? queryString.parse(props.location.search).depositstatus : null;

  const subscriptionPlan = useSelector((s) => s.subscriptionPlan.plan);

  const [showMakeDepositDialog, setShowMakeDepositDialog] = useState(false);

  const [showFloatingButton, setShowFloatingButton] = useState(false);

  const toggleShowMakeDepositDialog = () =>
    setShowMakeDepositDialog((showMakeDepositDialog) => !showMakeDepositDialog);
  const [versusCredits] = useState(client.versusCredits);

  const [tooltip, setTooltip] = useState({
    show: false,
    type: "",
    title: "",
    message: "",
    step: 1,
    max_step: 5,
  });

  //handle scroll effects
  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > filterTabsRef.current?.offsetTop) {
        setShowFloatingButton(true);
      } else {
        setShowFloatingButton(false);
      }
    });

    return () => {
      window.removeEventListener("scroll", () => {});
    };
  }, [filterTabsRef.current?.offSetTop]);

  if (!user) {
    return <Redirect to="/login" />;
  }

  if (!subscriptionPlan.isAskActive) {
    return (
      <div className="content full">
        <div className="sign-up">
          <div className="bottom-breather big-breath">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-zap-off icon large"
            >
              <polyline points="12.41 6.75 13 2 10.57 4.92"></polyline>
              <polyline points="18.57 12.91 21 10 15.66 10"></polyline>
              <polyline points="8 8 3 14 12 14 11 22 16 16"></polyline>
              <line x1="1" y1="1" x2="23" y2="23"></line>
            </svg>
          </div>

          <h1 className="bold">Ask dashboard is turned off</h1>
          <h2>
            Please contact the{" "}
            <a href="mailto:info@versus.africa">Versus Team</a> to add this
            feature.
          </h2>
        </div>
      </div>
    );
  }

  return (
    <div className="content">
      <div className="container-sticky">
        <SurveysSummary
          client={client}
          ref={filterTabsRef}
          setToast={setToast}
          toggleShowMakeDepositDialog={toggleShowMakeDepositDialog}
          toggleNewSurveyModalOpen={toggleNewSurveyModalOpen}
          versusCredits={versusCredits}
          tooltip={tooltip}
          setTooltip={setTooltip}
        />
        <SurveySearchAndSort
          allSurveysList={allSurveysList}
          setAllSurveysList={setAllSurveysList}
          filteredSurveysList={filteredSurveysList}
          setFilteredSurveysList={setFilteredSurveysList}
          isFilterExpanded={isFilterExpanded}
          setIsFilterExpanded={setIsFilterExpanded}
        />
      </div>
      <div
        style={{ marginBottom: "1rem" }}
        className="tab-menu row center move-up push-down grey-border-bottom"
      >
        <div className="no-margin push-left">
          <SurveysFilterTabs />
        </div>
      </div>
      {/* List of campaigns */}
      <SurveysList
        client={client}
        setToast={setToast}
        allSurveysList={
          filteredSurveysList.length ? filteredSurveysList : allSurveysList
        }
        setAllSurveysList={setAllSurveysList}
        toggleNewSurveyModalOpen={toggleNewSurveyModalOpen}
        tooltip={tooltip}
        setTooltip={setTooltip}
        filter={filter}
      />

      {/* Make deposit modal */}
      {showMakeDepositDialog ? (
        <MakeDeposit
          versusCredits={client.versusCredits}
          toggleShowMakeDepositDialog={toggleShowMakeDepositDialog}
          showMakeDepositDialog={showMakeDepositDialog}
          user={user}
          client={client}
          tooltip={tooltip}
          setTooltip={setTooltip}
          setToast={setToast}
        />
      ) : (
        ""
      )}

      {/* Static New Survey Button */}

      <button
        style={{
          display: !showFloatingButton ? "none" : "flex",
          animationName: !showFloatingButton ? "fab-reverse-anim" : "",
        }}
        className="fab"
        onClick={toggleNewSurveyModalOpen}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
          className="feather feather-plus icon"
        >
          <line x1="12" y1="5" x2="12" y2="19"></line>
          <line x1="5" y1="12" x2="19" y2="12"></line>
        </svg>
      </button>
    </div>
  );
};

export default withRouter(Ask);
