import React, { useState } from "react";
import { generateRandomNumber } from "../utils";

const IndustryInterestModal = ({
  industryInterestAction,
  openModal,
  setOpenModal,
  interests,
  surveyData,
  isLoadingInterest,
  activeIndustryInterestId,
  setActiveIndustryInterestId,
  handleNewSurveyData,
  handleUpdatedEstimatedRespondent,
}) => {
  const [oldId, setOldId] = useState(activeIndustryInterestId);

  const handleAddIndustryInterest = () => {
    if (activeIndustryInterestId) {
      let industryInterestIds = surveyData?.interest_ids || [];

      if (industryInterestIds.includes(parseInt(activeIndustryInterestId))) {
        industryInterestIds = industryInterestIds.filter(
          (id) => parseInt(id) != parseInt(activeIndustryInterestId)
        );
      } else {
        industryInterestIds.push(parseInt(activeIndustryInterestId));
      }

      const e = {
        target: {
          value: industryInterestIds,
          name: "interest_ids",
        },
      };

      handleNewSurveyData(e);
      handleUpdatedEstimatedRespondent();
      setOpenModal(false);
    }
  };

  const handleUpdateIndustryInterest = () => {
    if (activeIndustryInterestId && oldId) {
      let industryInterestIds = surveyData?.interest_ids || [];

      if (industryInterestIds.includes(parseInt(oldId))) {
        industryInterestIds = industryInterestIds.filter(
          (id) => parseInt(id) != parseInt(oldId)
        );
        industryInterestIds.push(parseInt(activeIndustryInterestId));

        const e = {
          target: {
            value: industryInterestIds,
            name: "interest_ids",
          },
        };

        handleNewSurveyData(e);
        handleUpdatedEstimatedRespondent();
        setOpenModal(false);
      }
    }
  };

  return (
    <div
      data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a509"
      className="modal"
      style={{
        display: openModal ? "flex" : "none",
        opacity: openModal ? 1 : 0,
      }}
    >
      <div className="modal-container">
        <h2>Add Industry/Interest</h2>
        <div data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a50d">
          <svg
            onClick={() => setOpenModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-header"></div>
          <div className="modal-body">
            <div className="no-margin w-form">
              <label htmlFor="Industry-or-Interet-2">
                Scout's Industry or Interest
              </label>
              <select
                id="Industry-or-Interet-2"
                name="Industry-or-Interet-2"
                data-name="Industry Or Interet 2"
                className="input large w-select"
                value={activeIndustryInterestId}
                onChange={(e) => setActiveIndustryInterestId(e.target.value)}
              >
                <option value="">
                  {isLoadingInterest
                    ? "Loading ..."
                    : "Select Industry/Interest"}
                </option>
                {interests.map((interest) => {
                  return (
                    <option key={interest.id} value={interest.id}>
                      {interest.name}
                    </option>
                  );
                })}
              </select>
              &nbsp;
              <div className="row col-16">
                <input
                  type="button"
                  onClick={() => handleAddIndustryInterest()}
                  value="Delete"
                  data-wait="Please wait..."
                  data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a519"
                  className="button danger wide w-button"
                />
                &nbsp; &nbsp;
                <input
                  type="button"
                  onClick={() => {
                    if (industryInterestAction == "new") {
                      handleAddIndustryInterest();
                    } else {
                      handleUpdateIndustryInterest();
                    }
                  }}
                  value="Save Changes"
                  data-wait="Please wait..."
                  data-w-id="fc7f959f-74ff-b010-43f8-3e0a4325a51a"
                  className="button primary wide w-button"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IndustryInterestModal;
