import React, { useState, useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";

import FullWidthLoader from "../FullWidthLoader";
import PersonalDetails from "./PersonalDetails";
import SearchTerms from "./SearchTerms";
import TeamMembers from "./TeamMembers";

import UpgradeModal from "../Listen/UpgradeModal";
import { PopUpModal } from "./shared";

const Onboarding = (props) => {
  const onboardingToken = props.location.search
    ? queryString.parse(props.location.search).token
    : null;
  const stage = props.location.search
    ? queryString.parse(props.location.search).stage
    : null;

  const { setToast } = props;

  const history = useHistory();

  const [client, setClient] = useState(null);
  const [onboardingStage, setOnboardingStage] = useState(
    stage ? stage : "personal-details"
  ); // personal-details, search-terms, team-members
  const [onboardingData, setOnboardingData] = useState(
    stage
      ? JSON.parse(localStorage.getItem("pd"))
      : {
          clientRef: "",
          onboardingToken,
          officeAddress: "",
          responseEmails: "",
          brandName: "",
          otherBrandNames: "",
          filterProfanity: false,
          comparisonBrands: [],
          teamMembers: [],
          twitterHandles: "",
          twitterAccountType: "",
          twitterAuthToken: "",
          countriesToTrack: [],
          languagesToTrack: [],
        }
  );
  const [subscriptionPlan, setSubscriptionPlan] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [isSavingSearchTerms, setIsSavingSearchTerms] = useState(false);
  const [isSavingPersonalDetails, setIsSavingPersonalDetails] = useState(false);
  const [fbIntegration, setFbIntegration] = useState(null);
  const [showDisclaimer, setShowDisclaimer] = useState(true);

  const toggleShowUpgradeModal = () =>
    setShowUpgradeModal((showUpgradeModal) => !showUpgradeModal);

  const toggleShowDisclaimer = () =>
    setShowDisclaimer((showDisclaimer) => !showDisclaimer);

  const handleAddSearchTerms = (searchDetails) => {
    setIsSavingSearchTerms(true);
    const {
      brandName,
      otherBrandNames,
      filterProfanity,
      comparisonBrands,
      twitterHandles,
      twitterAccountType,
      twitterAuthToken,
      countriesToTrack,
      languagesToTrack,
    } = searchDetails;

    if (
      subscriptionPlan.subscriptionPlanRef ===
      process.env.REACT_APP_LISTEN_STARTER_PLAN
    ) {
      const data = {
        ...onboardingData,
        brandName,
        otherBrandNames,
        filterProfanity,
        comparisonBrands,
        twitterHandles,
        twitterAccountType,
        twitterAuthToken,
        countriesToTrack,
        languagesToTrack,
      };
      onboardClient(data);
    } else {
      setOnboardingData({
        ...onboardingData,
        brandName,
        otherBrandNames,
        filterProfanity,
        comparisonBrands,
        twitterHandles,
        twitterAccountType,
        twitterAuthToken,
        countriesToTrack,
        languagesToTrack,
      });
      setOnboardingStage("team-members");
    }
  };

  const handleAddPersonalDetails = (personalDetails) => {
    setIsSavingPersonalDetails(true);
    const { officeAddress, responseEmails } = personalDetails;

    // Skip search terms for Ask starter plan and onboard client
    if (
      subscriptionPlan.subscriptionPlanRef ===
      process.env.REACT_APP_ASK_STARTER_PLAN
    ) {
      const data = {
        ...onboardingData,
        officeAddress,
        responseEmails,
      };
      onboardClient(data);
    } else {
      setOnboardingData({
        ...onboardingData,
        officeAddress,
        responseEmails,
      });
      // Save onboarding data to local storage
      localStorage.setItem(
        "pd",
        JSON.stringify({
          ...onboardingData,
          officeAddress,
          responseEmails,
        })
      );
      setOnboardingStage("search-terms");
    }
  };

  const handleNavigation = ({ stage }) => {
    setOnboardingStage(stage);
    setIsSavingPersonalDetails(false);
    setIsSavingSearchTerms(false);
  };

  const handleAddTeamDetails = (teamDetails) => {
    const { skipAddingTeamMembers, teamMembers } = teamDetails; // No need to add to state since it's final stage

    setToast({
      show: true,
      type: "processing",
      message: "Saving team data and onboarding...",
    });
    const data = { ...onboardingData };

    if (!skipAddingTeamMembers) data.teamMembers = teamMembers;

    onboardClient(data);
  };

  const onboardClient = (data) => {
    // Add clientRef
    data.clientRef = client.clientRef;

    // onboard client
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_onboard_client`,
        data,
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          console.log("Successfully onboarded client");
          setToast({ show: false, type: "", message: "" });

          // Send Ask starter to ask dashboard
          if (
            subscriptionPlan.subscriptionPlanRef ===
            process.env.REACT_APP_ASK_STARTER_PLAN
          ) {
            history.push({ pathname: "/dashboard/ask" });
          } else {
            // Send client to dashboard
            history.push({
              pathname: "/dashboard/listen",
              search: "?firstlogin=true",
            });
          }
        }
      })
      .catch((error) => {
        console.log("Error onbording client", error.message);
        setToast({
          show: true,
          type: "danger",
          message: error.message || "Failed onboarding process",
        });
      });
  };

  // useeffect get client
  useEffect(() => {
    //get client
    // console.log('Onboarding * getting offboard client...')
    axios({
      url: `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_offboard_client`,
      method: "post",
      headers: { "Content-type": "application/json" },
      data: { onboardingToken },
    })
      .then(({ data }) => {
        const { client, subscriptionPlan, fbIntegration } = data;
        setClient(client);
        setSubscriptionPlan(subscriptionPlan);
        setFbIntegration(fbIntegration);
      })
      .catch((error) => {
        console.log("error getting offboard client", error);
        // history.push('/onboarding');
        setToast({
          show: true,
          type: "warning",
          message: error.message || "Error getting offboard client",
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [history, onboardingToken]);

  if (!onboardingToken) {
    return (
      <div>
        <div className="sign-up">
          <img
            src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
            height="32"
            srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
            sizes="102px"
            alt=""
            className="bottom-breather big-breath"
          />

          <h1 className="bold">Welcome to Versus</h1>
          <h2>
            Hey hey, looks like you missed your way. But do come back when you
            have a link from the good folks{" "}
            <span role="img" aria-label="Emoji">
              🤨
            </span>
          </h2>
        </div>
      </div>
    );
  }

  if (!client || !subscriptionPlan) {
    return <FullWidthLoader />;
  }

  return (
    <div>
      <div className="sign-up">
        <img
          src="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png"
          height="32"
          srcSet="https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-500.png 500w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group-p-800.png 800w, https://assets.website-files.com/5b3e1bdab90c1f1276fef2c4/5da78d3fac4d6deacd4589d5_Group.png 1000w"
          sizes="102px"
          alt=""
          className="bottom-breather big-breath"
        />
        <div className="progress-container bottom-breather">
          {subscriptionPlan.isListenActive ? (
            <div className="row spaced">
              <h5 className="primary">Personal details</h5>
              <h5>Search terms</h5>
              {subscriptionPlan.isTeamMembersActive ? (
                <h5>Team members</h5>
              ) : (
                ""
              )}
            </div>
          ) : (
            <div className="row spaced">
              <h5 className="primary">Personal details</h5>
            </div>
          )}
          <div className="progress-tracker">
            <div
              style={{
                width:
                  onboardingStage === "search-terms" &&
                  subscriptionPlan.isTeamMembersActive
                    ? "50%"
                    : onboardingStage === "search-terms" &&
                      !subscriptionPlan.isTeamMembersActive
                    ? "100%"
                    : onboardingStage === "team-members"
                    ? "100%"
                    : "1%",
              }}
              className="progress-bar"
            ></div>
          </div>
        </div>

        <h1 className="bold">
          {onboardingStage === "search-terms"
            ? "Pick your search terms"
            : onboardingStage === "team-members"
            ? "Invite team members"
            : "Welcome to Versus"}
        </h1>
        <h2>
          {onboardingStage === "search-terms"
            ? "Set all the relevant keywords for your brand. Versus will track all these."
            : onboardingStage === "team-members"
            ? "Versus works better with collaboration. Set team member permissions easily."
            : "Setup your account to get started."}
        </h2>
        {onboardingStage === "personal-details" ? (
          <p className="with-icon positive bottom-breather">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-lock icon push-left"
            >
              <rect x="3" y="11" width="18" height="11" rx="2" ry="2"></rect>
              <path d="M7 11V7a5 5 0 0 1 10 0v4"></path>
            </svg>{" "}
            We use 128-bit encryption. Your details are safe.
          </p>
        ) : (
          ""
        )}

        {onboardingStage === "search-terms" ? (
          <SearchTerms
            handleAddSearchTerms={handleAddSearchTerms}
            handleNavigation={handleNavigation}
            subscriptionPlan={subscriptionPlan}
            toggleShowUpgradeModal={toggleShowUpgradeModal}
            isSavingSearchTerms={isSavingSearchTerms}
            client={client}
            onboardingToken={onboardingToken}
            fbIntegration={fbIntegration}
            onboardingData={onboardingData}
          />
        ) : onboardingStage === "team-members" ? (
          <TeamMembers
            handleAddTeamDetails={handleAddTeamDetails}
            handleNavigation={handleNavigation}
            onboardingData={onboardingData}
          />
        ) : (
          <PersonalDetails
            client={client}
            handleAddPersonalDetails={handleAddPersonalDetails}
            subscriptionPlan={subscriptionPlan}
            isSavingPersonalDetails={isSavingPersonalDetails}
            onboardingData={onboardingData}
          />
        )}
      </div>
      <PopUpModal active={showDisclaimer} onClose={toggleShowDisclaimer} />
      <UpgradeModal
        client={client}
        showUpgradeModal={showUpgradeModal}
        toggleShowUpgradeModal={toggleShowUpgradeModal}
        isOnboarding={true}
      />
    </div>
  );
};

export default withRouter(Onboarding);
