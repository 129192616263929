import React from "react";

const BrandSelectorDropdown = (props) => {
  const {
    activeBrand,
    brands,
    isDropdownOpen,
    setBrand,
    setIsDropdownOpen,
  } = props;

  return (
    <div
      className="dropdown-list"
      style={{ width: "160px", display: isDropdownOpen ? "flex" : "none" }}
    >
      <span
        className="dropdown-link"
        onClick={() => {
          setBrand(null);
          setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
        }}
      >
        None
      </span>
      {brands.map((brand, i) => (
        <span
          className={`dropdown-link ${activeBrand === brand ? "active" : ""}`}
          key={i}
          onClick={() => {
            setBrand(brand);
            setIsDropdownOpen((isDropdownOpen) => !isDropdownOpen);
          }}
        >
          {brand.brandName}
        </span>
      ))}
    </div>
  );
};

export default BrandSelectorDropdown;
