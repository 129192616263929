import React, { useState, useEffect, createRef, useRef } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../utils/storage";
import SideBar from "./SideBar";
import FullWidthLoader from "../FullWidthLoader";
import MultipleChoice from "./QuestionDailogs/MultipleChoice";
import YesNo from "./QuestionDailogs/YesNo";
import Nps from "./QuestionDailogs/Nps";
import Text from "./QuestionDailogs/Text";
import UseDropDown from "../CustomHooks/useDropdown";
import { NPS_OPTIONS_GROUP, SKIP_LOGIC_ACTIONS } from "../Shared/constants";

const NewSurvey = (props) => {
  const campaignRef = props.match.params.ref;
  const { setToast, tooltip, setTooltip } = props;

  const editRef = createRef();

  const dropdownRef = useRef(null);
  const [isActive, setIsActive, activeMenu, setActiveMenu] = UseDropDown(
    dropdownRef,
    false
  );

  const [campaign, setCampaign] = useState(null);
  const [isNewQuestionModalOpen, setIsNewQuestionModalOpen] = useState(false);
  const [newQuestionStep, setNewQuestionStep] = useState("one");
  const [newQuestionData, setNewQuestionData] = useState({
    optionsType: "", // nps, yesno, multiplechoice
    question: "", // String
    optionsRange: null, // Array of upper limit and lower limit. Ignored for now
    media_link: null,
    media_type: null,
    optionsList: [], // Array of options (String)
    responseUnits: "single_select", // single_select, multi_select
    otherQuestions: [],
    disableBranching: false,
  });
  const [choices, setChoices] = useState([
    {
      text: {
        option: "",
        option_type: "specified",
        nps_group: null,
        skip_logic: {
          action_type: SKIP_LOGIC_ACTIONS.NONE,
          jump_questionRef: null,
          jump_questionText: "",
        },
        image_link: null,
      },
    },
    {
      text: {
        option: "",
        option_type: "specified",
        nps_group: null,
        skip_logic: {
          action_type: SKIP_LOGIC_ACTIONS.NONE,
          jump_questionRef: null,
          jump_questionText: "",
        },
        image_link: null,
      },
    },
  ]);

  const [jumpQuestionsRefs, setJumpQuestionsRefs] = useState([]);
  const [npsOptionsGroupCount, setNpsOptionsGroupCount] = useState(0);
  const [showChoiceLimit, setShowChoiceLimit] = useState(false);
  const [highestQuestionSequence, setHighestQuestionSequence] = useState(0);
  const [isProcessingReOrdering, setIsProcessingReOrdering] = useState(false);
  const [updateStatus, setUpdateStatus] = useState(false);
  const [actionType, setActionType] = useState("newQuestion");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showOther, setShowOther] = useState(false);
  const [showButton, setShowButton] = useState(true);
  //show tooltip only once
  const showNewSurveyTooltip = sessionStorage.getItem("showNewSurveyTooltip");
  const showTooltip = (status) => {
    sessionStorage.setItem("showNewSurveyTooltip", status);
  };
  //show tooltip only once
  const showNewSurvey1Tooltip = sessionStorage.getItem("showNewSurvey1Tooltip");
  const showNpsTooltip = (status) => {
    sessionStorage.setItem("showNewSurvey1Tooltip", status);
  };

  const user = getUser(),
    idToken = getIdToken(),
    client = getClient();
  const handleClick = () => {
    setShowOther(!showOther);
    setShowButton(!showButton);
    // Remove choice limit warning if choices are more than 2
    if (choices.length >= 2) setShowChoiceLimit(false);
    setChoices(
      choices.concat([
        {
          text: {
            id: "new",
            option: "Other",
            option_type: "unspecified",
            skip_logic: {
              action_type: SKIP_LOGIC_ACTIONS.NONE,
              jump_questionRef: null,
              jump_questionText: "",
            },
            image_link: null,
          },
        },
      ])
    );
  };

  const handleAddChoice = () => {
    // Remove choice limit warning if choices are more than 2
    if (choices.length >= 2) setShowChoiceLimit(false);
    // Add new choice field
    setChoices(
      choices.concat([
        {
          text: {
            option: "",
            option_type: "specified",
            nps_group: null,
            skip_logic: {
              action_type: SKIP_LOGIC_ACTIONS.NONE,
              jump_questionRef: null,
              jump_questionText: "",
            },
            image_link: null,
          },
        },
      ])
    );
  };

  const openModal = (value) => {
    if (value === "One") {
      toggleIsNewQuestionModalOpen();
    }
  };

  const handleRemoveChoice = (id) => {
    // Ensure minimum two choices remain
    if (choices.length <= 2) {
      setShowChoiceLimit(true);
    } else {
      // ensure the add other button is visible if the other option is removed
      if (choices[id].text.option_type === "unspecified") {
        setShowButton(!showButton);
      }
      setChoices(choices.filter((choice, cid) => id !== cid));
    }
  };

  //
  const handleToggleQActions = (e) => {
    const id = e.currentTarget.id;
    // set active question actions id
    setIsActive(true);
    setActiveMenu(id);
    if (id === activeMenu) {
      setIsActive(false);
      setActiveMenu(null);
    }
  };

  const handleQEdit = (question) => {
    // check if the unspecified option is present
    const unspecifiedOptions = question?.options?.options_list?.filter(
      (option) => option.option_type === "unspecified"
    );

    if (unspecifiedOptions.length === 0) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
    const optionsList = question?.options?.options_list;
    let optionsType = question?.options?.type;
    if (
      optionsList.length === 2 &&
      optionsList[0].option.toLowerCase() === "yes" &&
      optionsList[1].option.toLowerCase() === "no"
    ) {
      optionsType = "yesno";
    }

    setChoices(
      optionsList.map((choice) => ({
        text: {
          ...choice,
          skip_logic: {
            ...choice.skip_logic,
            jump_questionText:
              choice.skip_logic.action_type === SKIP_LOGIC_ACTIONS.END_SURVEY
                ? "End survey"
                : choice.skip_logic.action_type === null ||
                  choice.skip_logic.action_type === SKIP_LOGIC_ACTIONS.NONE
                ? "No branching"
                : campaign.questions.find(
                    (q) => q.questionRef === choice.skip_logic.jump_questionRef
                  )?.question,
          },
        },
      }))
    );

    // since yesno question options are not stored in the choices array
    // modify the options list separately
    if (optionsType === "yesno") {
      optionsList.map((choice) => {
        const branchQuestion = campaign.questions.find(
          (q) => q.questionRef === choice.skip_logic.jump_questionRef
        );
        choice.skip_logic.jump_questionText =
          choice.skip_logic.action_type === SKIP_LOGIC_ACTIONS.END_SURVEY
            ? "End survey"
            : choice.skip_logic.action_type === null ||
              choice.skip_logic.action_type === SKIP_LOGIC_ACTIONS.NONE
            ? "No branching"
            : branchQuestion?.question;
        return choice;
      });
    }

    const relatedQuestions = campaign.questions.filter(
      (q) =>
        // !jumpQuestionsRefs.includes(q.questionRef) &&
        q.sequence > question.sequence && q.questionRef != question.questionRef
    );
    setNewQuestionData({
      campaignRef: question.campaignRef,
      questionRef: question.questionRef,
      question: question.question,
      optionsType,
      media_link: question.media_link,
      media_type: question.media_type,
      optionsRange: question.options.range,
      responseUnits: question.responseUnits,
      optionsList,
      disableBranching:
        question.responseUnits === "multi_select" ? true : false,
      otherQuestions: relatedQuestions,
    });
    setActionType("edit");
    setNewQuestionStep("two");
    setIsNewQuestionModalOpen(true);
    editRef.current.scrollIntoView({ bahavoir: "smooth" });
    setIsActive(false);
    setActiveMenu(null);
  };

  const handleQUpdate = () => {
    setToast({
      show: true,
      type: "processing",
      message: "Updating question...",
    });

    let {
      media_link,
      media_type,
      question,
      optionsType,
      campaignRef,
      optionsList,
      questionRef,
      optionsRange = "range",
      responseUnits = "single_select",
    } = newQuestionData;

    // [{ id, option, option_type }]
    if (optionsType === "multiplechoice" || optionsType === "nps") {
      optionsList = choices.map((choice) => choice.text);
    }

    // Set yesno option type as multiplechoice since it is essentially multiplechoice with two options
    if (optionsType === "yesno") {
      optionsType = "multiplechoice";
    }

    setIsSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_update_question`,
        {
          clientRef: client.clientRef,
          uid: user.uid,
          idToken,
          campaignRef,
          questionRef,
          question,
          media_link,
          media_type,
          optionsType,
          optionsList,
          optionsRange,
          responseUnits,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send toast message for successful question add
          setToast({
            show: true,
            type: "success",
            message: "Successfully updated question",
          });
          //updateStatus
          resetNewQuestionData();
          setUpdateStatus(true);
          setActionType("newQuestion");
          setNewQuestionStep("one");
          setIsNewQuestionModalOpen(false);
          setIsSubmitting(false);
          setShowButton(!showButton);
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setToast({
          show: true,
          type: "danger",
          message: "Error  updating question",
        });
        // console.log("Error updating question", error.message);
      });
  };

  const handleQDuplicate = (question) => {
    setToast({
      show: true,
      type: "processing",
      message: "Duplicating question...",
    });
    closeNewQuestionModal();
    // convert ==> [ { id, option }] to ['', '']
    const q_option_list = question.options.options_list;

    setIsActive(false);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_duplicate_question`,
        {
          campaignRef,
          uid: user.uid,
          idToken,
          media_link: question.media_link,
          media_type: question.media_type,
          question: question.question,
          optionsType: question.options.type,
          optionsRange: question.options.range,
          optionsList: q_option_list,
          sequence: highestQuestionSequence + 1,
          responseUnits: question.responseUnits || question.response_units,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send toast message for successful question add
          setToast({
            show: true,
            type: "success",
            message: "Successfully duplicated question",
          });
          // Increment highestQuestionSequence
          setHighestQuestionSequence(
            (highestQuestionSequence) => highestQuestionSequence + 1
          );
          //updateStatus
          setUpdateStatus(true);
          setToast({ show: false, type: "", message: "" });
        }
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "danger",
          message: "Error duplicating question",
        });
        // console.log("Error duplicating question", error.message);
      });
  };

  const handleQDelete = (questionRef) => {
    if (jumpQuestionsRefs.includes(questionRef)) {
      return setToast({
        show: true,
        type: "danger",
        message:
          "You cannot delete a question that has been referenced in other questions' branches. Remove it from all questions' branches and try again.",
      });
    }
    setToast({
      show: true,
      type: "processing",
      message: "Deleting question...",
    });

    setIsActive(false);
    closeNewQuestionModal();
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_delete_question`,
        {
          campaignRef,
          uid: user.uid,
          idToken,
          clientRef: client.clientRef,
          questionRef,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send toast message for successful question deleted
          setToast({
            show: true,
            type: "success",
            message: "Successfully deleted question",
          });
          // Decrement highestQuestionSequence
          setHighestQuestionSequence(
            (highestQuestionSequence) => highestQuestionSequence - 1
          );
          //updateStatus
          setUpdateStatus(true);
          setToast({ show: false, type: "", message: "" });
        }
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "danger",
          message: "Error deleting question",
        });
        // console.log("Error deleting question", error.message);
      });
  };

  const handleQReOrder = (questionRef, orderActionType) => {
    setToast({
      show: true,
      type: "processing",
      message: "re-ordering questions...",
    });

    setIsActive(false);
    closeNewQuestionModal();
    setIsProcessingReOrdering(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_reorder_questions`,
        {
          campaignRef,
          uid: user.uid,
          idToken,
          clientRef: client.clientRef,
          questionRef,
          orderActionType,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send toast message for successful question deleted
          setToast({
            show: true,
            type: "success",
            message: "Successfully re-ordered questions",
          });
          //updateStatus
          setUpdateStatus(true);
          setIsProcessingReOrdering(false);
          setToast({ show: false, type: "", message: "" });
        }
      })
      .catch((error) => {
        setToast({
          show: true,
          type: "danger",
          message: "Error re-ordering questions",
        });
        setIsProcessingReOrdering(false);
        // console.log("Error re-ordering questions", error.message);
      });
  };

  const handleChoiceOptionsReArrangement = (droppedItem) => {
    // Ignore drop outside droppable container
    if (!droppedItem.destination) return;
    if (droppedItem.destination.index !== droppedItem.source.index) {
      let updatedList = [...choices];
      // Remove dragged item
      const [itemToBeOrdered] = updatedList.splice(droppedItem.source.index, 1);
      // console.log(itemToBeOrdered, "itemToBeOrdered b4");
      let newItemToBeOrdered = itemToBeOrdered;
      const dbOriginalIds = choices.map((c) => c.text.id);
      //switch db Ids of item in updatedList
      try {
        // for already existing questions with DB ids -> question update only
        if (itemToBeOrdered?.text.id) {
          // get destination Item
          const destinationItem = choices.find(
            (list, i) => parseInt(i) === parseInt(droppedItem.destination.index)
          );
          // update db item of itemToBeOrdered to destination Id
          newItemToBeOrdered = {
            ...itemToBeOrdered,
            text: { ...itemToBeOrdered.text, id: destinationItem.text.id },
          };
          // update db item of destination to itemToBeOrdered Id inside the updatedList
          updatedList = updatedList.map((list, i) => {
            // droppedItem.destination.index - 1
            // because the item to be ordered has been removed
            if (parseInt(i) === parseInt(droppedItem.destination.index) - 1) {
              return {
                ...list,
                text: {
                  ...list.text,
                  id: itemToBeOrdered.text.id,
                },
              };
            }
            return list;
          });
        } else {
          newItemToBeOrdered = itemToBeOrdered;
        }
      } catch (err) {
        // console.log(err);
        newItemToBeOrdered = itemToBeOrdered;
      }
      // Add dropped item to updatedList
      updatedList.splice(droppedItem.destination.index, 0, newItemToBeOrdered);

      if (itemToBeOrdered?.text.id) {
        // fix db id wrong positioning
        updatedList = updatedList.map((list, i) => {
          // console.log(dbOriginalIds[i]);
          return {
            ...list,
            text: {
              ...list.text,
              id: dbOriginalIds[i],
            },
          };
        });
      }
      // Update State
      setChoices(updatedList);
    }
  };

  const handleChoiceTextChange = (id) => (e) => {
    e.preventDefault();
    const newChoices = choices.map((choice, cid) => {
      if (id !== cid) return choice;
      return {
        ...choice,
        text: {
          ...choice.text,
          option: e.target.value,
          option_type: choice.text.option_type,
        },
      };
    });

    setChoices(newChoices);
  };

  const handleChoiceMediaChange = (id) => (value) => {
    const newChoices = choices.map((choice, cid) => {
      if (id !== cid && id !== choice?.text?.id) return choice;
      return {
        ...choice,
        text: {
          ...choice.text,
          image_link: value,
        },
      };
    });
    setChoices(newChoices);
  };

  const handleChoiceTextUpdate =
    (id, optionId = "new") =>
    (e) => {
      e.preventDefault();
      const newChoices = choices.map((choice, cid) => {
        if (id !== cid) return choice;

        return {
          ...choice,
          text: {
            ...choice.text,
            id: optionId,
            option: e.target.value,
            option_type: choice.text.option_type,
          },
        };
      });

      setChoices(newChoices);
    };

  const handleOptionsType = (type) => {
    // const relatedQuestions = campaign.questions;
    const relatedQuestions = [];
    // Set options list for yesno
    if (type === "yesno") {
      setNewQuestionData({
        ...newQuestionData,
        otherQuestions: relatedQuestions,
        optionsList: newQuestionData.optionsList.concat([
          {
            option: "Yes",
            option_type: "specified",
            skip_logic: {
              action_type: SKIP_LOGIC_ACTIONS.NONE,
              jump_questionRef: null,
            },
          },
          {
            option: "No",
            option_type: "specified",
            skip_logic: {
              action_type: SKIP_LOGIC_ACTIONS.NONE,
              jump_questionRef: null,
            },
          },
        ]),
        optionsType: type,
      });
    } else if (type === "freeform") {
      setNewQuestionData({
        ...newQuestionData,
        otherQuestions: relatedQuestions,
        optionsList: newQuestionData.optionsList.concat([
          {
            option: "",
            option_type: "unspecified",
            skip_logic: {
              action_type: SKIP_LOGIC_ACTIONS.NONE,
              jump_questionRef: null,
            },
          },
        ]),
        optionsType: type,
      });
    } else {
      setShowButton(true);
      setNewQuestionData({
        ...newQuestionData,
        otherQuestions: relatedQuestions,
        optionsType: type,
      });
    }
    // console.log();
    setNewQuestionStep("two");
  };

  const handleQuestion = (e) => {
    e.preventDefault();
    // Handle options for multiplechoice
    // Check if name is choice then
    if (e.target.name === "multiplechoice") {
      // setNewQuestionData({...newQuestionData, optionsList: })
    }
    // Handle other new question data
    setNewQuestionData({ ...newQuestionData, question: e.target.value });
  };

  const toggleIsNewQuestionModalOpen = () => {
    setTooltip({
      show: true,
      type: "",
      title: "",
      message: "",
      step: 4,
      max_step: 7,
    });
    setIsNewQuestionModalOpen(
      (isNewQuestionModalOpen) => !isNewQuestionModalOpen
    );
  };

  const generateNPSOptionsFromGroup = (
    npsGroup,
    jumpQuestionRef = null,
    endSurveyIfSelected = false
  ) => {
    const question = campaign.questions.find(
      (q) => q.questionRef === jumpQuestionRef
    );
    let newChoices = [];
    // generate the options based on the group ranges
    // Detractors (1-6), Passive (7-8), Promoters (9-10)
    for (let i = npsGroup.lowerLimit; i <= npsGroup.upperLimit; i++) {
      newChoices.push({
        text: {
          option: `${i}`,
          option_type: "specified",
          nps_group: npsGroup,
          skip_logic: {
            action_type: jumpQuestionRef
              ? SKIP_LOGIC_ACTIONS.JUMP
              : endSurveyIfSelected
              ? SKIP_LOGIC_ACTIONS.END_SURVEY
              : SKIP_LOGIC_ACTIONS.NONE,
            jump_questionRef: jumpQuestionRef,
            jump_questionText: endSurveyIfSelected
              ? "End survey"
              : question
              ? question.question
              : "No branching",
          },
        },
      });
    }

    return newChoices;
  };

  const handleConditionalBranching = (
    optionId,
    optionsType,
    jumpQuestionRef = null,
    endSurveyIfSelected = false,
    nps_group_name = null
  ) => {
    // get the question to skip to if the current option/ option group
    // is selected
    const question = campaign.questions.find(
      (q) => q.questionRef === jumpQuestionRef
    );
    let updatedChoices;

    // check options type
    switch (optionsType) {
      case "nps":
        const npsGroup = NPS_OPTIONS_GROUP.find(
          (g) => g.name === nps_group_name
        );
        // check if the user is editing an existing NPS type question
        // or adding a new one
        if (actionType === "edit") {
          const ids = choices
            .filter(
              (c) =>
                c.text.option.length &&
                Number(c.text.option) >= npsGroup.lowerLimit &&
                Number(c.text.option) <= npsGroup.upperLimit
            )
            .map((c) => c.text.id);
          updatedChoices = choices.map((c, cid) => {
            if (ids.includes(c.text.id)) {
              c.text.skip_logic.action_type = jumpQuestionRef
                ? SKIP_LOGIC_ACTIONS.JUMP
                : endSurveyIfSelected
                ? SKIP_LOGIC_ACTIONS.END_SURVEY
                : SKIP_LOGIC_ACTIONS.NONE;
              c.text.skip_logic.jump_questionRef = jumpQuestionRef;
              c.text.skip_logic.jump_questionText = endSurveyIfSelected
                ? "End survey"
                : question
                ? question.question
                : "No branching";
              c.text.nps_group = npsGroup;
            }
            return c;
          });
        } else {
          // adding new NPS question options
          // generate the options based on the group ranges
          // Detractors (1-6), Passive (7-8), Promoters (9-10)
          const newChoices = generateNPSOptionsFromGroup(
            npsGroup,
            jumpQuestionRef,
            endSurveyIfSelected
          );
          // keep track of the nps options group that are
          // in state
          setNpsOptionsGroupCount((option) => option + 1);
          updatedChoices = newChoices;
        }
        break;
      case "multiplechoice":
        updatedChoices = choices.map((c, cid) => {
          if (
            (actionType === "edit" && c.text.id === optionId) ||
            optionId === cid
          ) {
            c.text.skip_logic.action_type = jumpQuestionRef
              ? SKIP_LOGIC_ACTIONS.JUMP
              : endSurveyIfSelected
              ? SKIP_LOGIC_ACTIONS.END_SURVEY
              : SKIP_LOGIC_ACTIONS.NONE;
            c.text.skip_logic.jump_questionRef = jumpQuestionRef;
            c.text.skip_logic.jump_questionText = endSurveyIfSelected
              ? "End survey"
              : question
              ? question.question
              : "No branching";
          }
          return c;
        });
        break;
      case "yesno":
        updatedChoices = newQuestionData.optionsList.map((c, cid) => {
          if (
            (actionType === "edit" && c.id === optionId) ||
            optionId === cid
          ) {
            c.skip_logic.action_type = jumpQuestionRef
              ? SKIP_LOGIC_ACTIONS.JUMP
              : endSurveyIfSelected
              ? SKIP_LOGIC_ACTIONS.END_SURVEY
              : SKIP_LOGIC_ACTIONS.NONE;
            c.skip_logic.jump_questionRef = jumpQuestionRef;
            c.skip_logic.jump_questionText = endSurveyIfSelected
              ? "End survey"
              : question
              ? question.question
              : "No branching";
          }
          return c;
        });
        break;
      case "freeform":
        updatedChoices = newQuestionData.optionsList.map((c, cid) => {
          if (
            (actionType === "edit" && c.id === optionId) ||
            optionId === cid
          ) {
            c.skip_logic.action_type = jumpQuestionRef
              ? SKIP_LOGIC_ACTIONS.JUMP
              : endSurveyIfSelected
              ? SKIP_LOGIC_ACTIONS.END_SURVEY
              : SKIP_LOGIC_ACTIONS.NONE;
            c.skip_logic.jump_questionRef = jumpQuestionRef;
            c.skip_logic.jump_questionText = endSurveyIfSelected
              ? "End survey"
              : question
              ? question.question
              : "No branching";
          }
          return c;
        });
        break;
      default:
        return;
    }
    if (optionsType === "nps" && npsOptionsGroupCount > 0) {
      setChoices((choices) => [...choices, ...updatedChoices]);
    } else {
      setChoices(updatedChoices);
    }
  };

  const closeNewQuestionModal = () => {
    resetNewQuestionData();
    setIsNewQuestionModalOpen(false);
  };

  const handleSubmitQuestion = () => {
    setToast({
      show: true,
      type: "processing",
      message: "Submitting question...",
    });
    // return console.log(newQuestionData);
    let {
      question,
      optionsType,
      media_link,
      media_type,
      optionsList,
      optionsRange,
      responseUnits,
    } = newQuestionData;

    // Collect choices into optionsList for multiplechoice optionType. Please preserve this order as it has to come before setting yesno to multiplchoice
    if (optionsType === "multiplechoice") {
      optionsList = choices.map((choice) => choice.text);
    }

    if (optionsType === "nps") {
      optionsList = choices
        .filter((c) => c.text.option.length)
        .map((choice) => choice.text);

      let availableOptionsGroupNames = optionsList.map(
        (option) => option?.nps_group?.name
      );

      availableOptionsGroupNames = [...new Set(availableOptionsGroupNames)];

      if (availableOptionsGroupNames.length < NPS_OPTIONS_GROUP.length) {
        const otherOptions = NPS_OPTIONS_GROUP.filter(
          (group) => !availableOptionsGroupNames.includes(group.name)
        );

        otherOptions.forEach((group) => {
          const newOptions = generateNPSOptionsFromGroup(group).map(
            (op) => op.text
          );
          optionsList = [...optionsList, ...newOptions].sort(
            (a, b) => Number(a.option) - Number(b.option)
          );
        });
      }
    }
    // Set yesno option type as multiplechoice since it is essentially multiplechoice with two options
    if (optionsType === "yesno") {
      optionsType = "multiplechoice";
    }

    setIsSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_add_question`,
        {
          campaignRef,
          uid: user.uid,
          idToken,
          question,
          media_link,
          media_type,
          optionsType,
          optionsRange,
          optionsList,
          sequence: highestQuestionSequence + 1,
          responseUnits,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          // Send toast message for successful question add
          setToast({
            show: true,
            type: "success",
            message: "Successfully added new question",
          });

          // Reset newquestiondata
          resetNewQuestionData();
          // Increment highestQuestionSequence
          setHighestQuestionSequence(
            (highestQuestionSequence) => highestQuestionSequence + 1
          );
          setIsSubmitting(false);
          // Go back to add questions
          setNewQuestionStep("one");
          setIsNewQuestionModalOpen(false);
          setShowButton(!showButton);
          setToast({ show: false, type: "", message: "" });
        }
      })
      .catch((error) => {
        setIsSubmitting(false);
        setToast({
          show: true,
          type: "danger",
          message: "Error submitting question",
        });
        // console.log("Error adding new question", error.message);
      });
  };

  const resetNewQuestionData = () => {
    setNewQuestionData({
      optionsType: "", // nps, yesno, multiplechoice
      question: "", // String
      media_link: null,
      media_type: null,
      optionsRange: null, // Array of upper limit and lower limit. Ignored for now
      optionsList: [], // Array of options (String)
      responseUnits: "single_select",
    });
    // reset choice
    setChoices([
      {
        text: {
          option: "",
          option_type: "specified",
          skip_logic: {
            action_type: SKIP_LOGIC_ACTIONS.NONE,
            jump_questionRef: null,
            jump_questionText: "",
          },
        },
      },
      {
        text: {
          option: "",
          option_type: "specified",
          skip_logic: {
            action_type: SKIP_LOGIC_ACTIONS.NONE,
            jump_questionRef: null,
            jump_questionText: "",
          },
        },
      },
    ]);
  };

  const handleResponseUnits = (units) => {
    const questionHasBranching = choices.some(
      (c) =>
        c.text.skip_logic.action_type === SKIP_LOGIC_ACTIONS.JUMP ||
        c.text.skip_logic.action_type === SKIP_LOGIC_ACTIONS.END_SURVEY
    );
    // disbale skip logic if multi_select is set on question
    if (units === "multi_select") {
      if (questionHasBranching) {
        setChoices(
          choices.map((c) => {
            c.text.nps_group = null;
            c.text.skip_logic.action_type = SKIP_LOGIC_ACTIONS.NONE;
            c.text.skip_logic.jump_questionRef = null;
            c.text.skip_logic.jump_questionText = "";
            return c;
          })
        );
      }

      setNewQuestionData({
        ...newQuestionData,
        disableBranching: true,
        responseUnits: units,
      });
      return;
    }
    setNewQuestionData({
      ...newQuestionData,
      disableBranching: false,
      responseUnits: units,
    });
  };

  useEffect(() => {
    // Get campaign data
    // console.log('getting campaign...')
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_campaign`,
        {
          clientRef: client.clientRef,
          campaignRef,
          uid: user.uid,
          idToken,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then((result) => {
        if (result.status === 200) {
          const campaign = result.data.campaign;
          campaign.questionLength = result.data.questionsData.length;
          campaign.questions = result.data.questionsData;
          campaign.responses = result.data.responsesData;
          setCampaign(campaign);
          // console.log("campaign", campaign);
          // reset updateStatus
          setUpdateStatus(false);
          // Set highest question sequence
          setHighestQuestionSequence(result.data.highestSequence);
          // set the list of questions that have been set to be
          // jumped to in skip logic pattern
          const jump_QuestionsRefs = [];
          result.data.questionsData.forEach((q) => {
            q.options.options_list.forEach((op) => {
              if (op.skip_logic.action_type === SKIP_LOGIC_ACTIONS.JUMP) {
                jump_QuestionsRefs.push(op.skip_logic.jump_questionRef);
              }
            });
          });
          setJumpQuestionsRefs(jump_QuestionsRefs);
        }
      })
      .catch((error) => {
        // console.log("Error obtaining campaign", error.message);
        setToast({
          show: true,
          type: "danger",
          message: `Error obtaining campaign. ${error.message}`,
        });
      });
  }, [
    campaignRef,
    client.clientRef,
    idToken,
    user.uid,
    isNewQuestionModalOpen,
    setToast,
    updateStatus,
  ]);

  const NextQuestionForSkipLogic = ({ jump_questionRef }) => {
    const jump_question = campaign.questions.find(
      (q) => q.questionRef === jump_questionRef
    );

    return (
      Boolean(jump_question) && (
        <div className="question-skip-logic">
          {jump_question.sequence}. {jump_question.question}
        </div>
      )
    );
  };

  if (!campaign) {
    return <FullWidthLoader />;
  } else {
    return (
      <div>
        <div className="content small">
          <Link to="/dashboard/ask" className="with-icon throw-up">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-arrow-left icon nudge-left"
            >
              <line x1="19" y1="12" x2="5" y2="12"></line>
              <polyline points="12 19 5 12 12 5"></polyline>
            </svg>
            Back to surveys
          </Link>
          <div className="row center">
            <h1 className="no-margin push-left">
              <span className="muted">{campaign.campaignName}</span>
            </h1>
          </div>
          <div
            style={{
              display: tooltip.step === 5 ? "flex" : "none",
              width: "300px",
            }}
            className=" inline-tooltip-content-survey-summary right-top"
          >
            {/* step 5 tool tip */}
            <div className="card-section">
              <div>Survey Summary</div>
              <p className="move-down">
                This shows the number of questions you have created and how many
                people the questions are targeting
              </p>

              <div className="inline-tooltip-frame">
                <span
                  onClick={() =>
                    setTooltip({
                      show: false,
                      type: "",
                      title: "",
                      message: "",
                      step: 0,
                      max_step: 5,
                    })
                  }
                >
                  Skip tutorial
                </span>
                <button
                  className="inline-tooltip-button"
                  onClick={() =>
                    setTooltip({
                      show: true,
                      type: "",
                      title: "",
                      message: "",
                      step: 5.1,
                      max_step: 5,
                    })
                  }
                >
                  Next
                </button>
              </div>
            </div>
          </div>
          <div
            style={{
              display: tooltip.step === 5.1 ? "flex" : "none",
              width: "300px",
            }}
            className=" inline-tooltip-content-survey-launch right-top"
          >
            {/* step 5 tool tip */}
            <div className="card-section">
              <div>Launch survey</div>
              <p className="move-down">
                After you have entered all your questions, and your survey info
                is correct, click "Launch Survey" for your survey to go live
              </p>

              <div className="inline-tooltip-frame">
                <span
                  onClick={() =>
                    setTooltip({
                      show: false,
                      type: "",
                      title: "",
                      message: "",
                      step: 0,
                      max_step: 5,
                    })
                  }
                >
                  Skip tutorial
                </span>
                <button
                  className="inline-tooltip-button"
                  onClick={() =>
                    setTooltip({
                      show: true,
                      type: "",
                      title: "",
                      message: "",
                      step: 0,
                      max_step: 5,
                    })
                  }
                >
                  Finish
                </button>
              </div>
            </div>
          </div>

          <div className="row has-cards move-down"></div>
          <div>
            {/* <div className="column"> */}
            {campaign.questions.map((question, i) => {
              const imageLinkExist = question.options.options_list.some(
                (option) => option?.image_link
              );
              return (
                <div className="card-2 q-container" key={question.questionRef}>
                  <div
                    className="q-options"
                    id="no-blur"
                    style={{ right: "65px" }}
                  >
                    <button
                      className="q-more"
                      id={`q-more-${i}`}
                      onClick={handleToggleQActions}
                    >
                      <svg
                        id="no-blur"
                        version="1.1"
                        width="17px"
                        xmlns="http://www.w3.org/2000/svg"
                        x="0px"
                        y="0px"
                        viewBox="0 0 433.633 433.633"
                        style={{
                          textAlign: "center",
                          enableBackground: "new 0 0 433.633 433.633",
                        }}
                      >
                        <g>
                          <path
                            style={{ fill: "#5f3dc4" }}
                            d="M369.894,153.078c-35.004,0-63.739,28.735-63.739,63.739s28.735,63.739,63.739,63.739
		s63.739-28.735,63.739-63.739S404.898,153.078,369.894,153.078z"
                          />
                          <path
                            style={{ fill: "#5f3dc4" }}
                            d="M216.816,153.078c-35.004,0-63.739,28.735-63.739,63.739s28.735,63.739,63.739,63.739
		s63.739-28.735,63.739-63.739S251.82,153.078,216.816,153.078z"
                          />
                          <path
                            style={{ fill: "#5f3dc4" }}
                            d="M63.739,153.078C28.735,153.078,0,181.812,0,216.816s28.735,63.739,63.739,63.739
		s63.739-28.735,63.739-63.739S98.743,153.078,63.739,153.078z"
                          />
                        </g>
                      </svg>
                    </button>
                    {/* question actions */}
                    {isActive && activeMenu === `q-more-${i}` ? (
                      <div ref={dropdownRef} className="q-actions" id="no-blur">
                        <ul id="no-blur">
                          <li id="no-blur">
                            {" "}
                            <span onClick={() => handleQEdit(question)}>
                              <svg
                                width="17px"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512.001 512.001"
                                style={{
                                  enableBackground: "new 0 0 512.001 512.001",
                                }}
                              >
                                <g>
                                  <g>
                                    <path
                                      style={{ fill: "#5f3dc4" }}
                                      d="M496.063,62.299l-46.396-46.4c-21.199-21.199-55.689-21.198-76.888,0C352.82,35.86,47.964,340.739,27.591,361.113
			c-2.17,2.17-3.624,5.054-4.142,7.875L0.251,494.268c-0.899,4.857,0.649,9.846,4.142,13.339c3.497,3.497,8.487,5.042,13.338,4.143
			L143,488.549c2.895-0.54,5.741-2.008,7.875-4.143l345.188-345.214C517.311,117.944,517.314,83.55,496.063,62.299z M33.721,478.276
			l14.033-75.784l61.746,61.75L33.721,478.276z M140.269,452.585L59.41,371.721L354.62,76.488l80.859,80.865L140.269,452.585z
			 M474.85,117.979l-18.159,18.161l-80.859-80.865l18.159-18.161c9.501-9.502,24.96-9.503,34.463,0l46.396,46.4
			C484.375,93.039,484.375,108.453,474.85,117.979z"
                                    />
                                  </g>
                                </g>
                              </svg>
                              Edit{" "}
                            </span>{" "}
                          </li>
                          <li
                            id="no-blur"
                            style={{
                              display:
                                campaign.questions.length >= 8
                                  ? "none"
                                  : "block",
                            }}
                          >
                            {" "}
                            <span onClick={() => handleQDuplicate(question)}>
                              <svg
                                width="17px"
                                version="1.1"
                                id="Capa_1"
                                xmlns="http://www.w3.org/2000/svg"
                                x="0px"
                                y="0px"
                                viewBox="0 0 512 512"
                                style={{ enableBackground: "new 0 0 512 512" }}
                              >
                                <g>
                                  <g>
                                    <g>
                                      <path
                                        style={{ fill: "#5f3dc4" }}
                                        d="M480,96H128c-17.673,0-32,14.327-32,32v352c0,17.673,14.327,32,32,32h352c17.673,0,32-14.327,32-32V128
				C512,110.327,497.673,96,480,96z M480,480H128V128h352V480z"
                                      />
                                      <path
                                        style={{ fill: "#5f3dc4" }}
                                        d="M32,32h352v32h32V32c0-17.673-14.327-32-32-32H32C14.327,0,0,14.327,0,32v352c0,17.673,14.327,32,32,32h32v-32H32V32z"
                                      />
                                    </g>
                                  </g>
                                </g>
                              </svg>{" "}
                              Duplicate{" "}
                            </span>
                          </li>
                          <li id="no-blur">
                            <span
                              onClick={() =>
                                handleQDelete(question.questionRef)
                              }
                            >
                              <svg
                                id="Layer_1"
                                enableBackground="new 0 0 512 512"
                                viewBox="0 0 512 512"
                                width="17px"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <g>
                                  <path
                                    style={{ fill: "#e64980" }}
                                    d="m424 64h-88v-16c0-26.467-21.533-48-48-48h-64c-26.467 0-48 21.533-48 48v16h-88c-22.056 0-40 17.944-40 40v56c0 8.836 7.164 16 16 16h8.744l13.823 290.283c1.221 25.636 22.281 45.717 47.945 45.717h242.976c25.665 0 46.725-20.081 47.945-45.717l13.823-290.283h8.744c8.836 0 16-7.164 16-16v-56c0-22.056-17.944-40-40-40zm-216-16c0-8.822 7.178-16 16-16h64c8.822 0 16 7.178 16 16v16h-96zm-128 56c0-4.411 3.589-8 8-8h336c4.411 0 8 3.589 8 8v40c-4.931 0-331.567 0-352 0zm313.469 360.761c-.407 8.545-7.427 15.239-15.981 15.239h-242.976c-8.555 0-15.575-6.694-15.981-15.239l-13.751-288.761h302.44z"
                                  />
                                  <path
                                    style={{ fill: "#e64980" }}
                                    d="m256 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"
                                  />
                                  <path
                                    style={{ fill: "#e64980" }}
                                    d="m336 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"
                                  />
                                  <path
                                    style={{ fill: "#e64980" }}
                                    d="m176 448c8.836 0 16-7.164 16-16v-208c0-8.836-7.164-16-16-16s-16 7.164-16 16v208c0 8.836 7.163 16 16 16z"
                                  />
                                </g>
                              </svg>{" "}
                              Delete{" "}
                            </span>
                          </li>
                        </ul>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  {/* question filter/sort/position */}
                  <div className="q-options arrows">
                    {i === 0 || isProcessingReOrdering ? (
                      <button style={{ cursor: "not-allowed" }} disabled={true}>
                        <svg
                          width="17px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 240.835 240.835"
                          style={{
                            enableBackground: "new 0 0 240.835 240.835",
                          }}
                        >
                          <g>
                            <path
                              style={{ fill: "#e7e2f1" }}
                              id="Expand_Less"
                              d="M129.007,57.819c-4.68-4.68-12.499-4.68-17.191,0L3.555,165.803c-4.74,4.74-4.74,12.427,0,17.155
      c4.74,4.74,12.439,4.74,17.179,0l99.683-99.406l99.671,99.418c4.752,4.74,12.439,4.74,17.191,0c4.74-4.74,4.74-12.427,0-17.155
      L129.007,57.819z"
                            />
                          </g>
                        </svg>
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          handleQReOrder(question.questionRef, "up")
                        }
                        data-tooltip="Move up"
                      >
                        <svg
                          width="17px"
                          version="1.1"
                          id="Capa_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 240.835 240.835"
                          style={{
                            enableBackground: "new 0 0 240.835 240.835",
                          }}
                        >
                          <g>
                            <path
                              style={{ fill: "#5f3dc4" }}
                              id="Expand_Less"
                              d="M129.007,57.819c-4.68-4.68-12.499-4.68-17.191,0L3.555,165.803c-4.74,4.74-4.74,12.427,0,17.155
		c4.74,4.74,12.439,4.74,17.179,0l99.683-99.406l99.671,99.418c4.752,4.74,12.439,4.74,17.191,0c4.74-4.74,4.74-12.427,0-17.155
		L129.007,57.819z"
                            />
                          </g>
                        </svg>
                      </button>
                    )}
                    {/* down arrow */}
                    {campaign.questionLength - 1 === i ||
                    isProcessingReOrdering ? (
                      <button disabled={true} style={{ cursor: "not-allowed" }}>
                        <svg
                          width="17px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 330 330"
                          style={{ enableBackground: "new 0 0 330 330" }}
                        >
                          <path
                            style={{ fill: "#e7e2f1" }}
                            id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                          />
                          <g></g>
                        </svg>
                      </button>
                    ) : (
                      <button
                        onClick={() =>
                          handleQReOrder(question.questionRef, "down")
                        }
                        data-tooltip="Move down"
                      >
                        <svg
                          width="17px"
                          version="1.1"
                          id="Layer_1"
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          viewBox="0 0 330 330"
                          style={{ enableBackground: "new 0 0 330 330" }}
                        >
                          <path
                            style={{ fill: "#5f3dc4" }}
                            id="XMLID_225_"
                            d="M325.607,79.393c-5.857-5.857-15.355-5.858-21.213,0.001l-139.39,139.393L25.607,79.393
	c-5.857-5.857-15.355-5.858-21.213,0.001c-5.858,5.858-5.858,15.355,0,21.213l150.004,150c2.813,2.813,6.628,4.393,10.606,4.393
	s7.794-1.581,10.606-4.394l149.996-150C331.465,94.749,331.465,85.251,325.607,79.393z"
                          />
                          <g></g>
                        </svg>
                      </button>
                    )}
                  </div>
                  <div className="card-section padded">
                    <div>
                      <div className="row center move-up">
                        <p className="num">{i + 1}</p>
                        <div className="nudge-right">
                          <div className="tag">
                            {question.options.type === "freeform"
                              ? "Text"
                              : question.options.type}
                          </div>
                        </div>
                      </div>
                      {question?.media_link ? (
                        <div
                          style={{
                            paddingTop:
                              question?.media_type?.toLowerCase() == "video"
                                ? "56.17021276595745%"
                                : "0",
                            marginTop: "20px",
                            marginBottom: "10px",
                          }}
                          className="video w-video w-embed"
                        >
                          {question?.media_type?.toLowerCase() == "video" ? (
                            <iframe
                              className="embedly-embed"
                              src={question?.media_link}
                              width="940"
                              height="528"
                              allowFullScreen
                              title="Vintage Cars"
                            ></iframe>
                          ) : (
                            <img
                              src={question?.media_link}
                              loading="lazy"
                              srcSet={`${question?.media_link} 500w, ${question?.media_link} 800w, ${question?.media_link} 1080w, ${question?.media_link} 1200w `}
                              alt=""
                              className="image-6"
                            />
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                      <div className="data small">{question.question}</div>
                    </div>
                    <div className="push-down">
                      {question.options.options_list.map((option, j) => (
                        <div className="row" key={j}>
                          {option?.image_link ? (
                            <div className="response-media push-left">
                              <img
                                src={option?.image_link}
                                loading="lazy"
                                sizes="60px"
                                srcSet={`${option?.image_link} 500w, ${option?.image_link} 800w, ${option?.image_link} 1080w, ${option?.image_link} 1200w `}
                                alt=""
                                className="image-6"
                              />
                            </div>
                          ) : (
                            <>
                              {" "}
                              {imageLinkExist ? (
                                <div className="response-media-no-border push-left">
                                  {" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </>
                          )}
                          <div
                            className="bar-container has-cover move-down overflow-show"
                            key={j}
                            style={{ width: "100%" }}
                          >
                            <div
                              style={{ gap: "1rem" }}
                              className="medium-text row center nudge-right"
                            >
                              {question.response_type === "freeform"
                                ? campaign.status === "draft"
                                  ? " No responses yet"
                                  : ""
                                : `${option.option || ""} `}
                              {question.response_type !== "freeform" && (
                                <>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="#718096"
                                    viewBox="0 0 24 24"
                                    strokeWidth={1.5}
                                    stroke="currentColor"
                                    style={{ height: "16px", width: "16px" }}
                                    className="w-6 h-6"
                                  >
                                    <path
                                      strokeLinecap="round"
                                      strokeLinejoin="round"
                                      d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
                                    />
                                  </svg>
                                  {option.skip_logic.action_type ===
                                  SKIP_LOGIC_ACTIONS.JUMP ? (
                                    <NextQuestionForSkipLogic
                                      jump_questionRef={
                                        option.skip_logic.jump_questionRef
                                      }
                                    />
                                  ) : option.skip_logic.action_type ===
                                    SKIP_LOGIC_ACTIONS.END_SURVEY ? (
                                    "End survey"
                                  ) : (
                                    "No branching"
                                  )}
                                </>
                              )}
                            </div>
                            {question.response_type !== "freeform" && (
                              <>
                                <div className="data tiny nudge-left violet-text">
                                  0%
                                </div>
                                <div className="measure-bar tall cover">
                                  <div
                                    className="metric violet"
                                    style={{ width: "0%" }}
                                  ></div>
                                  <div
                                    className="metric comparison _70"
                                    style={{ left: "0%" }}
                                  ></div>
                                </div>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              );
            })}

            <div
              className="card-2 active"
              style={{
                display: isNewQuestionModalOpen ? "none" : "block",
              }}
            >
              {campaign.questions.length >= 8 ? (
                <div className="full-height vertical center padded">
                  <h3>{8 - campaign.questions.length} / 8 left</h3>
                  <h2 className="main push-up">Can no longer add questions</h2>
                </div>
              ) : (
                <div
                  className="full-height vertical center padded"
                  onClick={toggleIsNewQuestionModalOpen}
                >
                  <h3>{8 - campaign.questions.length} / 8 left</h3>
                  <h2 className="main push-up">Add a question</h2>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-plus icon large"
                    src="https://assets.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
                    alt=""
                  >
                    <line x1="12" y1="5" x2="12" y2="19"></line>
                    <line x1="5" y1="12" x2="19" y2="12"></line>
                  </svg>
                </div>
              )}
            </div>
            <div
              style={{
                display:
                  tooltip.step === 5.2 && showNewSurveyTooltip === null
                    ? "flex"
                    : "none",
                width: "300px",
              }}
              className=" inline-tooltip-content top"
            >
              {/* step 3 tool tip */}
              <div className="card-section">
                <div>Create survey questions</div>
                <p className="move-down">
                  To add a survey question click on the "+" button.
                </p>

                <div className="inline-tooltip-frame">
                  <span
                    onClick={() => {
                      setTooltip({
                        show: false,
                        type: "",
                        title: "",
                        message: "",
                        step: 0,
                        max_step: 7,
                      });
                      showTooltip(false);
                    }}
                  >
                    Skip tutorial
                  </span>
                  <button
                    className="inline-tooltip-button"
                    onClick={() => {
                      openModal("One");
                      showTooltip(false);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            {/* New question modal 1 */}
            <div
              className="card-2 active"
              style={{
                display:
                  isNewQuestionModalOpen && newQuestionStep === "one"
                    ? "block"
                    : "none",
                opacity:
                  isNewQuestionModalOpen && newQuestionStep === "one" ? 1 : 0,
              }}
            >
              <div className="full-height vertical padded">
                <span
                  onClick={closeNewQuestionModal}
                  className="with-icon fill w--current"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    className="feather feather-arrow-left icon nudge-left"
                  >
                    <line x1="19" y1="12" x2="5" y2="12"></line>
                    <polyline points="12 19 5 12 12 5"></polyline>
                  </svg>
                  Back
                </span>
                <h3 className="throw-down">What type of question?</h3>
                <div className="row">
                  <span
                    onClick={() => handleOptionsType("nps")}
                    className="button pink with-icon menu move-left w-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-star icon move-left"
                    >
                      <polygon points="12 2 15.09 8.26 22 9.27 17 14.14 18.18 21.02 12 17.77 5.82 21.02 7 14.14 2 9.27 8.91 8.26 12 2"></polygon>
                    </svg>{" "}
                    NPS{" "}
                  </span>

                  <span
                    onClick={() => handleOptionsType("yesno")}
                    className="button green wide with-icon move-left w-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-thumbs-up icon move-left"
                    >
                      <path d="M14 9V5a3 3 0 0 0-3-3l-4 9v11h11.28a2 2 0 0 0 2-1.7l1.38-9a2 2 0 0 0-2-2.3zM7 22H4a2 2 0 0 1-2-2v-7a2 2 0 0 1 2-2h3"></path>
                    </svg>{" "}
                    Yes or No
                  </span>
                  <span
                    onClick={() => handleOptionsType("freeform")}
                    className="button lime-green menu with-icon move-left w-button"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      stroke-width="2"
                      stroke-linecap="round"
                      strokeLinejoin="round"
                      class="feather feather-type icon move-left"
                    >
                      <polyline points="4 7 4 4 20 4 20 7"></polyline>
                      <line x1="9" y1="20" x2="15" y2="20"></line>
                      <line x1="12" y1="4" x2="12" y2="20"></line>
                    </svg>{" "}
                    Text
                  </span>
                  <span
                    onClick={() => handleOptionsType("multiplechoice")}
                    className="button blue wide with-icon move-left w-button"
                    style={{ lineHeight: "85%" }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="24"
                      height="24"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      className="feather feather-list icon move-left"
                    >
                      <line x1="8" y1="6" x2="21" y2="6"></line>
                      <line x1="8" y1="12" x2="21" y2="12"></line>
                      <line x1="8" y1="18" x2="21" y2="18"></line>
                      <line x1="3" y1="6" x2="3.01" y2="6"></line>
                      <line x1="3" y1="12" x2="3.01" y2="12"></line>
                      <line x1="3" y1="18" x2="3.01" y2="18"></line>
                    </svg>{" "}
                    Multiple choice
                  </span>
                </div>
              </div>
            </div>
            <div
              style={{
                display:
                  tooltip.step === 4 && showNewSurvey1Tooltip === null
                    ? "flex"
                    : "none",
                width: "300px",
              }}
              className=" inline-tooltip-content top"
            >
              {/* step 4 tool tip */}
              <div className="card-section">
                <div>NPS question</div>
                <p className="move-down">
                  This is a single survey question asking Scouts to rate the
                  likelihood that they would do something, whether it is to
                  recommend a brand to a friend, or to invest in a product. E.g.
                  "How likely are you to use this brand" or "How likely you are
                  to invest in cryptocurrency?"
                </p>

                <div className="inline-tooltip-frame">
                  <span
                    onClick={() => {
                      setTooltip({
                        show: false,
                        type: "",
                        title: "",
                        message: "",
                        step: 0,
                        max_step: 7,
                      });
                      showNpsTooltip(false);
                    }}
                  >
                    Skip tutorial
                  </span>
                  <button
                    className="inline-tooltip-button"
                    onClick={() => {
                      setTooltip({
                        show: true,
                        type: "",
                        title: "",
                        message: "",
                        step: 4.1,
                        max_step: 7,
                      });
                      showNpsTooltip(false);
                    }}
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>
            <div
              style={{
                display: tooltip.step === 4.1 ? "flex" : "none",
                width: "300px",
              }}
              className=" inline-tooltip-content top-right"
            >
              {/* step 4 tool tip */}
              <div className="card-section">
                <div>Yes or no question</div>
                <p className="move-down">
                  These are Yes/no questions e.g. "Did you vote in the last
                  election?" or "Have you seen Dr Strange?"
                </p>

                <div className="inline-tooltip-frame">
                  <span
                    onClick={() =>
                      setTooltip({
                        show: false,
                        type: "",
                        title: "",
                        message: "",
                        step: 0,
                        max_step: 7,
                      })
                    }
                  >
                    Skip tutorial
                  </span>
                  <button
                    className="inline-tooltip-button"
                    onClick={() =>
                      setTooltip({
                        show: true,
                        type: "",
                        title: "",
                        message: "",
                        step: 4.2,
                        max_step: 7,
                      })
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div
              style={{
                display: tooltip.step === 4.2 ? "flex" : "none",
                width: "300px",
              }}
              className=" inline-tooltip-content-multiple-options top-right"
            >
              {/* step 4 tool tip */}
              <div className="card-section">
                <div>Mutiple choice questions</div>
                <p className="move-down">
                  You can create a question with multiple options (for accuracy,
                  we recommend a maximum of 8 options)
                </p>

                <div className="inline-tooltip-frame">
                  <span
                    onClick={() =>
                      setTooltip({
                        show: false,
                        type: "",
                        title: "",
                        message: "",
                        step: 0,
                        max_step: 7,
                      })
                    }
                  >
                    Skip tutorial
                  </span>
                  <button
                    className="inline-tooltip-button"
                    onClick={() =>
                      setTooltip({
                        show: true,
                        type: "",
                        title: "",
                        message: "",
                        step: 5,
                        max_step: 7,
                      })
                    }
                  >
                    Next
                  </button>
                </div>
              </div>
            </div>

            <div ref={editRef}>
              {/* Question type dialog NPS */}

              {newQuestionData.optionsType === "nps" && (
                <Nps
                  isNewQuestionModalOpen={isNewQuestionModalOpen}
                  newQuestionData={newQuestionData}
                  newQuestionStep={newQuestionStep}
                  setNewQuestionStep={setNewQuestionStep}
                  handleQuestion={handleQuestion}
                  handleSubmitQuestion={
                    actionType === "edit" ? handleQUpdate : handleSubmitQuestion
                  }
                  text={
                    actionType === "edit" ? "Update Question" : "Add question"
                  }
                  questionType={actionType}
                  isSubmitting={isSubmitting}
                  resetNewQuestionData={resetNewQuestionData}
                  choices={choices}
                  handleConditionalBranching={handleConditionalBranching}
                />
              )}

              {/* Question type dialog YESNO */}

              {newQuestionData.optionsType === "yesno" && (
                <YesNo
                  isNewQuestionModalOpen={isNewQuestionModalOpen}
                  newQuestionData={newQuestionData}
                  newQuestionStep={newQuestionStep}
                  setNewQuestionStep={setNewQuestionStep}
                  handleQuestion={handleQuestion}
                  handleSubmitQuestion={
                    actionType === "edit" ? handleQUpdate : handleSubmitQuestion
                  }
                  text={
                    actionType === "edit" ? "Update Question" : "Add question"
                  }
                  questionType={actionType}
                  isSubmitting={isSubmitting}
                  resetNewQuestionData={resetNewQuestionData}
                  handleConditionalBranching={handleConditionalBranching}
                  choices={choices}
                />
              )}

              {/* Question type dialog MULTIPLECHOICE */}

              {newQuestionData.optionsType === "multiplechoice" && (
                <MultipleChoice
                  isNewQuestionModalOpen={isNewQuestionModalOpen}
                  newQuestionData={newQuestionData}
                  setNewQuestionData={setNewQuestionData}
                  newQuestionStep={newQuestionStep}
                  setNewQuestionStep={setNewQuestionStep}
                  handleQuestion={handleQuestion}
                  handleChoiceOptionsReArrangement={
                    handleChoiceOptionsReArrangement
                  }
                  handleSubmitQuestion={
                    actionType === "edit" ? handleQUpdate : handleSubmitQuestion
                  }
                  text={
                    actionType === "edit" ? "Update Question" : "Add question"
                  }
                  setToast={setToast}
                  questionType={actionType}
                  handleResponseUnits={handleResponseUnits}
                  responseUnits={newQuestionData.responseUnits}
                  isSubmitting={isSubmitting}
                  resetNewQuestionData={resetNewQuestionData}
                  choices={choices}
                  showChoiceLimit={showChoiceLimit}
                  handleAddChoice={handleAddChoice}
                  handleRemoveChoice={handleRemoveChoice}
                  handleChoiceTextChange={
                    actionType === "edit"
                      ? handleChoiceTextUpdate
                      : handleChoiceTextChange
                  }
                  handleChoiceMediaChange={handleChoiceMediaChange}
                  showOther={showOther}
                  setShowButton={setShowButton}
                  showButton={showButton}
                  setShowOther={setShowOther}
                  handleClick={handleClick}
                  setActionType={setActionType}
                  handleConditionalBranching={handleConditionalBranching}
                />
              )}

              {/* Question type dialog TEXT */}

              {newQuestionData.optionsType === "freeform" && (
                <Text
                  isNewQuestionModalOpen={isNewQuestionModalOpen}
                  newQuestionData={newQuestionData}
                  setNewQuestionData={setNewQuestionData}
                  newQuestionStep={newQuestionStep}
                  setNewQuestionStep={setNewQuestionStep}
                  handleQuestion={handleQuestion}
                  handleSubmitQuestion={
                    actionType === "edit" ? handleQUpdate : handleSubmitQuestion
                  }
                  text={
                    actionType === "edit" ? "Update Question" : "Add question"
                  }
                  questionType={actionType}
                  isSubmitting={isSubmitting}
                  resetNewQuestionData={resetNewQuestionData}
                  handleConditionalBranching={handleConditionalBranching}
                  choices={choices}
                  setToast={setToast}
                />
              )}
              {/*  */}
            </div>
            <div
              data-w-id="f51bc055-0f6b-6bd8-27f8-bf5f4a06e44d"
              className="card m-side-scroll active"
              style={{ opacity: 0, display: "none" }}
            >
              <div className="card-section-2">
                <div className="question-header">
                  <div className="row center move-up">
                    <p className="num">4</p>
                    <div className="nudge-right">
                      <div className="tag">question type</div>
                    </div>
                  </div>
                  <div className="data small">Question goes here</div>
                </div>
                <div>
                  <p>No responses yet...</p>
                </div>
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>

        <div className="side-spacer">
          {campaign ? (
            <SideBar campaign={campaign} questions={campaign.questions} />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
};

export default withRouter(NewSurvey);
