import React, { useState, useEffect } from "react";

import useScript from "../CustomHooks/useScript";

import DummyButton from "../DummyButton";
import axios from "axios";
import { getUser, getIdToken } from "../../utils/storage";

const MakeDeposit = (props) => {
  const {
    versusCredits,
    showMakeDepositDialog,
    toggleShowMakeDepositDialog,
    client,
    user,
    setToast,
  } = props;

  // eslint-disable-next-line no-unused-vars
  const [loadedStripe, errorLoadingStripe] = useScript(
    "https://js.stripe.com/v3/"
  );
  const [isRedirectingToStripe, setIsRedirectingToStripe] = useState(false);
  const [isRedirectingToPaystack, setIsRedirectingToPaystack] = useState(false);
  const [exchangeRate_NGNPerUSD, setExchangeRate_NGNPerUSD] = useState(0);

  const [USDDeposit, setUSDDeposit] = useState({
    versusCredits: 1000,
    dollarAmount: 200,
  });

  const [NGNDeposit, setNGNDeposit] = useState({
    versusCredits: 1000,
    nairaAmount: 200 * exchangeRate_NGNPerUSD, //200USD * 752 (exchange rate)
  });

  const [currency, setCurrency] = useState("USD");

  const handleCurrencySelection = (e) => {
    if (e.target.value === "USD") {
      setUSDDeposit({
        ...USDDeposit,
        versusCredits: NGNDeposit.versusCredits,
        dollarAmount: Math.round(
          NGNDeposit.nairaAmount / exchangeRate_NGNPerUSD
        ),
      });
    } else {
      setNGNDeposit({
        ...NGNDeposit,
        versusCredits: USDDeposit.versusCredits,
        nairaAmount: USDDeposit.dollarAmount * exchangeRate_NGNPerUSD,
      });
    }
    setCurrency(e.target.value);
  };

  const handleChangeDeposit = (e) => {
    e.preventDefault();
    if (currency === "USD") {
      if (e.target.value >= 0 && e.target.value < 10000) {
        setUSDDeposit({
          ...USDDeposit,
          dollarAmount: e.target.value,
          versusCredits: Math.floor(e.target.value * 5), //1VC = 0.2USD, (0.2*5 = 1)
        });
      }
    } else {
      //currency is NGN
      if (
        e.target.value >= 0 &&
        e.target.value < 10000 * exchangeRate_NGNPerUSD
      ) {
        setNGNDeposit({
          ...NGNDeposit,
          nairaAmount: e.target.value,
          versusCredits: Math.floor(
            (e.target.value / exchangeRate_NGNPerUSD) * 5
          ), //1VC = 0.2USD (0.2*5 = 1)
        });
      }
    }
  };

  const handleNairaDeposit = async (payload) => {
    setIsRedirectingToPaystack(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_paystack_create_deposit_checkout_session`,
        {
          clientData: payload.clientData,
          amount: payload.amount,
        },
        { headers: { "content-type": "application/json" } }
      );
      const paystackAuthorizationData = result.data.paystackAuthorizationData;
      window.location.href = paystackAuthorizationData.authorization_url;
    } catch (error) {
      setIsRedirectingToPaystack(false);
      console.log("Error creating Paystack checkout session", error);
      setToast({
        show: true,
        type: "danger",
        message: "Failed to create Paystack checkout session",
      });
    }
  };

  const handleDollarDeposit = async (payload) => {
    setIsRedirectingToStripe(true);
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_stripe_create_deposit_checkout_session`,
        { clientData: payload },
        { headers: { "content-type": "application/json" } }
      );
      const sessionId = result.data.sessionId;
      const stripe = window.Stripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      stripe
        .redirectToCheckout({
          sessionId,
        })
        .then(function (result) {
          setIsRedirectingToStripe(false);
          if (result.error) {
            console.log(
              "Stripe redirect failed due to browser or network error"
            );
            // Send toast message
            setToast({
              show: true,
              type: "danger",
              message: "Stripe redirect failed due to browser or network error",
            });
          }
          // If `redirectToCheckout` fails due to a browser or network
          // error, display the localized error message to your customer
          // using `result.error.message`.
        });
    } catch (error) {
      setIsRedirectingToStripe(false);
      console.log("Error creating Stripe checkout session", error);
      setToast({
        show: true,
        type: "danger",
        message: "Failed to create Stripe checkout session",
      });
    }
  };

  const handleSubmitDeposit = async () => {
    let clientData;

    if (currency === "NGN") {
      clientData = {
        email: user.email,
        clientRef: client.clientRef,
        quantity: NGNDeposit.versusCredits,
        dashboardPath: "ask",
      };
      const payload = {
        clientData,
        amount: Number(NGNDeposit.nairaAmount),
      };
      await handleNairaDeposit(payload);
    } else {
      clientData = {
        sku: process.env.REACT_APP_VERSUS_CREDITS_SKU,
        quantity: USDDeposit.versusCredits,
        email: user.email,
        clientRef: client.clientRef,
        dashboardPath: "ask",
      };

      await handleDollarDeposit(clientData);
    }
  };

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_client_settings`,
        {
          uid: getUser().uid,
          idToken: getIdToken(),
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setExchangeRate_NGNPerUSD(data.clientSettings.exchangeRate_NGNperUSD);
      })
      .catch((error) => {
        console.log("Error getting client settings:", error);
      });
  }, []);

  return (
    <div
      className="modal"
      style={{ display: showMakeDepositDialog ? "flex" : "none", opacity: 1 }}
    >
      <div className="modal-container">
        <h2>Wallet</h2>
        <div onClick={toggleShowMakeDepositDialog}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x icon close"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </div>
        <div
          className="modal-card"
          style={{
            opacity: 1,
            transform:
              "translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg)",
            transformStyle: "preserve-3d",
          }}
        >
          <div className="modal-body">
            <h3 className="push-down">Current Balance</h3>
            <div className="data large primary">VC {versusCredits}</div>
            <div className="divider"></div>
            <h3 className="push-down move-up">Make a deposit</h3>
            <div className="data large primary with-icon">
              <strong className="positive">
                {currency === "NGN"
                  ? `₦${NGNDeposit.nairaAmount}`
                  : `$${USDDeposit.dollarAmount}`}
              </strong>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-arrow-right grey-text icon move-left move-right"
              >
                <line x1="5" y1="12" x2="19" y2="12"></line>
                <polyline points="12 5 19 12 12 19"></polyline>
              </svg>{" "}
              {`VC ${
                currency === "NGN"
                  ? NGNDeposit.versusCredits.toLocaleString("en")
                  : USDDeposit.versusCredits.toLocaleString("en")
              }`}
            </div>
            <div style={{ marginTop: "1.5rem" }} className="w-form">
              <div>
                <div className="control">
                  <label>Currency</label>
                  <select
                    onChange={handleCurrencySelection}
                    name="currency"
                    style={{ width: "35%" }}
                    className="input select w-select"
                    defaultValue={currency}
                  >
                    <option value="USD">USD</option>
                    <option value="NGN">NGN</option>
                  </select>
                </div>
                <div className="push-down">
                  <label htmlFor="amount">Amount</label>
                  <input
                    type="number"
                    className="input"
                    maxLength="256"
                    name="amount"
                    max={
                      currency === "NGN"
                        ? (10000 * exchangeRate_NGNPerUSD - 1).toString()
                        : "9999"
                    }
                    value={
                      currency === "NGN"
                        ? NGNDeposit.nairaAmount
                        : USDDeposit.dollarAmount
                    }
                    onChange={handleChangeDeposit}
                  />
                </div>
              </div>
            </div>
            <div className="inline-message-trigger">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                className="feather feather-info icon push-left"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="16" x2="12" y2="12"></line>
                <line x1="12" y1="8" x2="12.01" y2="8"></line>
              </svg>
              20 cents per scout question
            </div>
          </div>

          <div className="modal-footer">
            {isRedirectingToStripe ? (
              <DummyButton
                extraClass="primary"
                text="Redirecting to Stripe..."
              />
            ) : isRedirectingToPaystack ? (
              <DummyButton
                extraClass="primary"
                text="Redirecting to Paystack..."
              />
            ) : (
              <button
                style={{ cursor: !loadedStripe ? "not-allowed" : "pointer" }}
                className="button primary"
                onClick={handleSubmitDeposit}
              >
                {`Deposit ${
                  currency === "NGN"
                    ? `₦${NGNDeposit.nairaAmount}`
                    : `$${USDDeposit.dollarAmount}`
                } for VC ${
                  currency === "NGN"
                    ? NGNDeposit.versusCredits
                    : USDDeposit.versusCredits
                }`}
              </button>
            )}
            <span
              className="button subtle push-right"
              onClick={toggleShowMakeDepositDialog}
            >
              Cancel
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeDeposit;
