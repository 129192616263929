import React, { Component } from "react";
import Initialize from "./Initialize";

export default class Parser extends Component {
  static defaultProps = {
    className: undefined,
  };

  state = {};

  handleReady = (api) => {
    this.setState(
      {
        api,
      },
      this.handleParse
    );
  };

  handleContainer = (container) => {
    this.setState(
      {
        container,
      },
      this.handleParse
    );
  };

  handleParse = () => {
    const { api, container } = this.state;
    if (!api || !container) {
      return;
    }

    api.parse(container);
  };

  render() {
    const { className, children } = this.props;

    return (
      <div className={className} ref={this.handleContainer}>
        <Initialize onReady={this.handleReady}>
          {children({
            handleParse: this.handleParse,
          })}
        </Initialize>
      </div>
    );
  }
}
