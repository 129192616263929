import React, { useEffect, useState } from "react";
import useDebounce from "./useDebounce";

const SurveySearchAndSort = (props) => {
  const {
    allSurveysList,
    setAllSurveysList,
    filteredSurveysList,
    setFilteredSurveysList,
    isFilterExpanded,
    setIsFilterExpanded,
  } = props;
  const [q, setQ] = useState();

  let [startSearch, search] = useDebounce(q, 500);

  const handleSearch = (q) => {
    if (q) {
      let surveysList = allSurveysList?.filter((surveyList) => {
        let regex = new RegExp(q, "ig");
        return (
          regex.test(surveyList.campaignName) || regex.test(surveyList.name)
        );
      });
      setFilteredSurveysList(surveysList);
    } else {
      setFilteredSurveysList([]);
    }
  };

  useEffect(() => {
    if (startSearch) {
      handleSearch(search);
    }
  }, [startSearch, search]);

  return (
    <div>
      <div
        className="row spaced"
        style={{ paddingRight: "1%", paddingTop: "1%" }}
      >
        <div className="left-content">
          <a
            href="#"
            data-w-id="a601360f-e577-88cf-d52e-32ccd0e7158e"
            className="button subtle with-icon no-margin small w-button"
            onClick={() => setIsFilterExpanded(!isFilterExpanded)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="feather feather-code icon move-left _14px"
            >
              <polyline points="6 8 12 2 18 8"></polyline> {/* Pointing Up */}
              <polyline points="6 16 12 22 18 16"></polyline>{" "}
              {/* Pointing Down */}
            </svg>
            Sort
          </a>
        </div>
        <div className="search survey">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-search icon"
            src="https://cdn.prod.website-files.com/plugins/Image/assets/placeholder.60f9b1840c.svg"
            alt=""
          >
            <circle cx="11" cy="11" r="8"></circle>
            <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
          </svg>
          <input
            onChange={(e) => {
              setQ(e.target.value);
            }}
            className="input survey-search w-input"
            maxLength="256"
            name="field-6"
            data-name="Field 6"
            placeholder="Search"
            type="text"
            id="field-6"
            required=""
          />
        </div>
      </div>
    </div>
  );
};

export default SurveySearchAndSort;
