import React, { useEffect, useState } from "react";
import axios from "axios";

import { getUser, getIdToken, getClient } from "../../../../utils/storage";

const FilteredRespondents = (props) => {
  const {
    request,
    age,
    gender,
    tribe,
    education,
    religion,
    maritalStatus,
    employmentStatus,
    childrenInHousehold,
    personalMonthlyIncome,
    householdMonthlyIncome,
    peopleInHousehold,
    anyChildUnder18,
  } = props;

  const [filteredRespondents, setFilteredRespondents] = useState(0);

  useEffect(() => {
    if (!request) return;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_total_filtered_request_respondents`,
        {
          requestRef: request.campaignRef,
          uid: getUser().uid,
          idToken: getIdToken(),
          clientRef: getClient().clientRef,
          age,
          gender,
          tribe,
          education,
          religion,
          maritalStatus,
          employmentStatus,
          childrenInHousehold,
          personalMonthlyIncome,
          householdMonthlyIncome,
          peopleInHousehold,
          anyChildUnder18,
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setFilteredRespondents(data.filteredRespondents);
      })
      .catch((error) => {
        console.log("Error getting total filtered request respondents:", error);
      });
  }, [
    age,
    childrenInHousehold,
    anyChildUnder18,
    education,
    employmentStatus,
    gender,
    householdMonthlyIncome,
    personalMonthlyIncome,
    peopleInHousehold,
    maritalStatus,
    religion,
    request,
    tribe,
  ]);

  if (!request) return "";

  return (
    <div>
      <div className="row spaced">
        <div>
          <div className="data huge violet-text">{filteredRespondents}</div>
          <h3 className="move-down violet-text move-up">
            Filtered Respondents
          </h3>
        </div>
      </div>
      <div>
        <span className="tag violet move-left">{`${age[0]}-${age[1]} yrs`}</span>
      </div>
      <div>
        {gender.length === 0
          ? ""
          : gender.map((gender, i) => (
              <span className="tag violet move-left" key={i}>
                {gender}
              </span>
            ))}
      </div>
      <div>
        {tribe.length === 0
          ? ""
          : tribe.map((tribe, i) => (
              <span className="tag violet move-left" key={i}>
                {tribe}
              </span>
            ))}
      </div>
      <div>
        {education.length === 0
          ? ""
          : education.map((education, i) => (
              <span className="tag violet move-left" key={i}>
                {education}
              </span>
            ))}
      </div>
      <div>
        {religion.length === 0
          ? ""
          : religion.map((religion, i) => (
              <span className="tag violet move-left" key={i}>
                {religion}
              </span>
            ))}
      </div>
      <div>
        {maritalStatus.length === 0
          ? ""
          : maritalStatus.map((maritalStatus, i) => (
              <span className="tag violet move-left" key={i}>
                {maritalStatus}
              </span>
            ))}
      </div>
      <div>
        {employmentStatus.length === 0
          ? ""
          : employmentStatus.map((employmentStatus, i) => (
              <span className="tag violet move-left" key={i}>
                {employmentStatus}
              </span>
            ))}
      </div>
      <div>
        {childrenInHousehold.length === 0 ? (
          ""
        ) : (
          <span className="tag violet move-left">
            {childrenInHousehold[0]} children in household
          </span>
        )}
      </div>
      <div>
        {personalMonthlyIncome.length === 0 ? (
          ""
        ) : (
          <span className="tag violet move-left">
            ₦{personalMonthlyIncome[0].toLocaleString()} personal earnings
            monthly
          </span>
        )}
      </div>
      <div>
        {householdMonthlyIncome.length === 0 ? (
          ""
        ) : (
          <span className="tag violet move-left">
            ₦{householdMonthlyIncome[0].toLocaleString()} household earnings
            monthly
          </span>
        )}
      </div>
      <div>
        {peopleInHousehold.length === 0 ? (
          ""
        ) : (
          <span className="tag violet move-left">
            {peopleInHousehold[0]} persons in household
          </span>
        )}
      </div>
      <div>
        {anyChildUnder18.length === 0
          ? ""
          : anyChildUnder18.map((anyChildUnder18, i) => (
              <span className="tag violet move-left" key={i}>
                {anyChildUnder18} children under 18
              </span>
            ))}
      </div>
    </div>
  );
};

export default FilteredRespondents;
