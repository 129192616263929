import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";

import { getClient, getUser, getIdToken } from "../../../utils/storage";

import RequestSidebar from "./RequestSidebar/RequestSidebar";
import RequestResponses from "./RequestResponses/RequestResponses";
import InteractFilter from "./InteractFilter/InteractFilter";

const MultimediaRequest = (props) => {
  const { isNavExpanded, setToast } = props;

  const { ref } = useParams();

  const [request, setRequest] = useState(null);

  // Demographic filters
  const [age, setAge] = useState(["16", "65"]);
  const [gender, setGender] = useState([]);
  const [tribe, setTribe] = useState([]);
  const [education, setEducation] = useState([]);
  const [religion, setReligion] = useState([]);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [employmentStatus, setEmploymentStatus] = useState([]);
  const [childrenInHousehold, setChildrenInHousehold] = useState([]);
  const [personalMonthlyIncome, setPersonalMonthlyIncome] = useState([]);
  const [householdMonthlyIncome, setHouseholdMonthlyIncome] = useState([]);
  const [peopleInHousehold, setPeopleInHousehold] = useState([]);
  const [anyChildUnder18, setAnyChildUnder18] = useState([]);

  // Demographic filter handlers
  const handleSetAge = (value) => setAge(value);

  const handleSetGender = (value) => {
    const newGender = gender.includes(value)
      ? gender.filter((gender) => gender !== value)
      : gender.concat([value]);
    setGender(newGender);
  };

  const handleSetTribe = (value) => {
    const newTribe = tribe.includes(value)
      ? tribe.filter((tribe) => tribe !== value)
      : tribe.concat([value]);
    setTribe(newTribe);
  };

  const handleSetEducation = (value) => {
    const newEducation = education.includes(value)
      ? education.filter((education) => education !== value)
      : education.concat([value]);
    setEducation(newEducation);
  };

  const handleSetReligion = (value) => {
    const newReligion = religion.includes(value)
      ? religion.filter((religion) => religion !== value)
      : religion.concat([value]);
    setReligion(newReligion);
  };

  const handleSetMaritalStatus = (value) => {
    const newMaritalStatus = maritalStatus.includes(value)
      ? maritalStatus.filter((maritalStatus) => maritalStatus !== value)
      : maritalStatus.concat([value]);
    setMaritalStatus(newMaritalStatus);
  };

  const handleSetEmploymentStatus = (value) => {
    const newEmploymentStatus = employmentStatus.includes(value)
      ? employmentStatus.filter(
          (employmentStatus) => employmentStatus !== value
        )
      : employmentStatus.concat([value]);
    setEmploymentStatus(newEmploymentStatus);
  };

  const handleSetChildrenInHousehold = (value) => setChildrenInHousehold(value);
  const handleSetPersonalMonthlyIncome = (value) =>
    setPersonalMonthlyIncome(value);
  const handleSetHouseholdMonthlyIncome = (value) =>
    setHouseholdMonthlyIncome(value);
  const handleSetPeopleInHousehold = (value) => setPeopleInHousehold(value);

  const handleSetAnyChildUnder18 = (value) => {
    const newAnyChildUnder18 = anyChildUnder18.includes(value)
      ? anyChildUnder18.filter((anyChildUnder18) => anyChildUnder18 !== value)
      : anyChildUnder18.concat([value]);
    setAnyChildUnder18(newAnyChildUnder18);
  };

  // End Demographic filters

  useEffect(() => {
    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}/versus_v2_get_request`,
        {
          clientRef: getClient().clientRef,
          requestRef: ref,
          uid: getUser().uid,
          idToken: getIdToken(),
        },
        { headers: { "content-type": "application/json" } }
      )
      .then(({ data }) => {
        setRequest(data.request);
      })
      .catch((error) => {
        console.log("Error getting request data");
      });
  }, [ref]);

  return (
    <>
      <InteractFilter
        isNavExpanded={isNavExpanded}
        request={request}
        age={age}
        handleSetAge={handleSetAge}
        gender={gender}
        handleSetGender={handleSetGender}
        tribe={tribe}
        handleSetTribe={handleSetTribe}
        education={education}
        handleSetEducation={handleSetEducation}
        religion={religion}
        handleSetReligion={handleSetReligion}
        maritalStatus={maritalStatus}
        handleSetMaritalStatus={handleSetMaritalStatus}
        employmentStatus={employmentStatus}
        handleSetEmploymentStatus={handleSetEmploymentStatus}
        handleSetChildrenInHousehold={handleSetChildrenInHousehold}
        handleSetPersonalMonthlyIncome={handleSetPersonalMonthlyIncome}
        handleSetHouseholdMonthlyIncome={handleSetHouseholdMonthlyIncome}
        handleSetPeopleInHousehold={handleSetPeopleInHousehold}
        anyChildUnder18={anyChildUnder18}
        handleSetAnyChildUnder18={handleSetAnyChildUnder18}
      />
      <RequestResponses
        isNavExpanded={isNavExpanded}
        request={request}
        requestRef={ref}
        setToast={setToast}
        age={age}
        gender={gender}
        tribe={tribe}
        education={education}
        religion={religion}
        maritalStatus={maritalStatus}
        employmentStatus={employmentStatus}
        childrenInHousehold={childrenInHousehold}
        personalMonthlyIncome={personalMonthlyIncome}
        householdMonthlyIncome={householdMonthlyIncome}
        peopleInHousehold={peopleInHousehold}
        anyChildUnder18={anyChildUnder18}
      />
      <RequestSidebar
        request={request}
        setToast={setToast}
        age={age}
        gender={gender}
        tribe={tribe}
        education={education}
        religion={religion}
        maritalStatus={maritalStatus}
        employmentStatus={employmentStatus}
        childrenInHousehold={childrenInHousehold}
        personalMonthlyIncome={personalMonthlyIncome}
        householdMonthlyIncome={householdMonthlyIncome}
        peopleInHousehold={peopleInHousehold}
        anyChildUnder18={anyChildUnder18}
      />
    </>
  );
};

export default MultimediaRequest;
