/**
 * Contains everything within Compare parent component
 */

import React, { useState } from "react";

import Filter from "../Filter";
import Compare from "./Compare";
import CompareOff from "./CompareOff";
import { useSelector } from "react-redux";

const CompareContainer = (props) => {
  const { client, setToast, isFilterOpen, toggleFilterOpen, tooltip, setTooltip } = props;

  const subscriptionPlan = useSelector((state) => state.subscriptionPlan.plan);

  const [filters, setFilters] = useState({
    period: "last 7 days", // "last 30 days", "today"
    sentiment: "any sentiment", // "positive sentiment", "negative sentiment", "neutral sentiment"
    country: "",
    language: "",
    sources: {
      categories: [],
      socialmedia: [],
      news: [],
      others: [],
    },
  });

  const handleChangePeriod = (period) => setFilters({ ...filters, period });
  const handleChangeSentiment = (sentiment) =>
    setFilters({ ...filters, sentiment });
  const handleChangeFilter = (filter, value) =>
    setFilters((prev) => ({ ...prev, [filter]: value }));
  // const toggleSource = (source, category, sourcesList) => {
  //   // Get a shallow copy of current sources
  //   const sources = { ...filters.sources }; //filters.sources.slice();

  //   if (!source) {
  //     console.log("sourcesList", sourcesList);
  //     // filter by category
  //     // Add all sources under referenced category
  //     sources[category] = sources[category].length > 0 ? [] : sourcesList;
  //     sources.categories = sources.categories.includes(category)
  //       ? sources.categories.filter((cat) => category !== cat)
  //       : sources.categories.concat([category]);
  //     setFilters({ ...filters, sources });
  //   } else {
  //     // Add or remove source if it's present or absent in list
  //     sources[category] = sources[category].includes(source)
  //       ? sources[category].filter((src) => source !== src)
  //       : sources[category].concat([source]);

  //     // Empty categories if all cource categories are empty
  //     if (
  //       sources.news.length <= 0 &&
  //       sources.socialmedia.length <= 0 &&
  //       sources.others.length <= 0
  //     )
  //       sources.categories = [];
  //     // Set sources in filter state
  //     setFilters({ ...filters, sources });
  //   }
  // };
  // const handleClearFilters = () =>
  //   setFilters({
  //     ...filters,
  //     period: "last 7 days",
  //     sentiment: "any sentiment",
  //     sources: { categories: [], socialmedia: [], news: [], others: [] },
  //   });

  // const [mentionModal, setMentionModal] = useState({
  //   isOpen: false,
  //   showMention: true,
  //   mention: {},
  // });

  // const [respondModal, setRespondModal] = useState({
  //   isOpen: false,
  // });

  // const toggleRespondModal = () => {
  //   setMentionModal({
  //     ...mentionModal,
  //     showMention: !mentionModal.showMention,
  //   });
  //   setRespondModal({ ...respondModal, isOpen: !respondModal.isOpen });
  // };

  // const handleMentionModal = (mentionModal) => setMentionModal(mentionModal);

  // const handleCloseMentionModal = () => {
  //   setRespondModal({ ...respondModal, isOpen: false });
  //   setMentionModal({
  //     ...mentionModal,
  //     isOpen: false,
  //     showMention: true,
  //     mention: {},
  //   });
  // };

  if (!subscriptionPlan.isCompareActive) {
    return <CompareOff />;
  } else {
    return (
      <>
        <Filter
          isFilterOpen={isFilterOpen}
          filters={filters}
          handleChangePeriod={handleChangePeriod}
          handleChangeSentiment={handleChangeSentiment}
          countriesToTrack={client.countriesToTrack}
          languagesToTrack={client.languagesToTrack}
          handleChangeFilter={handleChangeFilter}
          isCompare={true}
          // handleClearFilters={handleClearFilters}
          // onChangeFilter={onChangeFilter}
          // toggleFilterOpen={toggleFilterOpen}
        />
        <Compare
          isFilterOpen={isFilterOpen}
          toggleFilterOpen={toggleFilterOpen}
          setToast={setToast}
          client={client}
          period={filters.period}
          // handleMentionModal={handleMentionModal}
          sentiment={filters.sentiment}
          filterCountry={filters.country}
          filterLanguage={filters.language}
          // toggleSource={toggleSource}
          sources={filters.sources}
          tooltip={tooltip}
          setTooltip={setTooltip}
          isCompare={true}
        />
      </>
    );
  }
};

export default CompareContainer;
